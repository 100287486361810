/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useClinic } from "../../contexts/ClinicContext";
import { ClinicStatisticsResponse } from "../../models/statistics/ClinicStatisticsResponse";
import { Option } from "../../models/Option";
import StatisticsApi from "../../api/StatisticsApi";
import {
  getStringFromEnumsOrReturn,
  quantityUnits,
  strings,
} from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox, { AlertType } from "../../components/AlertBox";
import MonthSelectOfStats from "../GlobalVet/Statistics/MonthSelectOfStats";

const Usage: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [months, setMonths] = useState<Option[]>([]);
  const [statistics, setStatistics] =
    useState<Map<string, ClinicStatisticsResponse>>();
  const [currentMonth, setCurrentMonth] = useState<string | number>();
  const [currentStatistics, setCurrentStatistics] =
    useState<ClinicStatisticsResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const { clinic } = useClinic();

  useEffect(() => {
    if (!clinic) return;

    const getStatistics = async () => {
      setLoading(true);

      try {
        const response = await StatisticsApi.getStatisticsOfClinic(clinic.id);
        const monthList: Option[] = [];
        const indexByMonth: Map<string, ClinicStatisticsResponse> = new Map();

        response.data.forEach((item: ClinicStatisticsResponse) => {
          monthList.push({
            value: item.month,
            title: moment(item.month, undefined, strings.getLanguage()).format(
              "YYYY MMMM"
            ),
          });
          indexByMonth.set(item.month, item);
        });
        setStatistics(indexByMonth);
        setMonths(monthList);
        setCurrentMonth(monthList[0]?.value);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getStatistics();
  }, [clinic]);

  useEffect(() => {
    if (!currentMonth) return;
    setCurrentStatistics(statistics?.get(currentMonth.toString()));
  }, [currentMonth, statistics]);

  return (
    <div className="space-y-4 w-full md:max-w-lg space-y-6">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
        {strings.softwareUsage}
      </h2>
      <AlertBox
        type={AlertType.INFO}
        hidden={months.length > 0 || error !== null || loading}
        message={error || strings.statisticsErrorMsg}
        closeAble={false}
      />
      <AlertBox message={error} />
      <div hidden={months.length <= 0 || error != null}>
        <div className="flex">
          <MonthSelectOfStats
            months={months}
            setCurrentMonth={setCurrentMonth}
          />
        </div>
      </div>
      <div hidden={months.length === 0}>
        <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
          {currentStatistics?.statistics?.map((stat) => (
            <div className="px-3 py-2 text-sm flex justify-between items-center space-x-4">
              <div>{getStringFromEnumsOrReturn(stat.name)}</div>
              <div className="ml-3">
                {stat.value} {quantityUnits.PIECES}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Usage;
