/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { MenuItemDoctor } from "./MenuItem";
import { strings } from "../../Strings/Strings";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";
import { Cube } from "../../Icons/Cube";
import { CalendarIcon } from "../../Icons/Calendar";
import { ShoppingCart } from "../../Icons/ShoppingCart";
import { Flag } from "../../Icons/Flag";
import { ClipboardDocumentList } from "../../Icons/ClipboardDocumentList";
import { Cog } from "../../Icons/Cog";
import { Users } from "../../Icons/Users";
import { ChartBar } from "../../Icons/ChartBar";

const iconClassName = "w-6 h-6 flex-shrink-0";

const DoctorMenuItems: MenuItemDoctor[] = [
  {
    type: "CALENDAR",
    name: strings.calendar,
    href: "/",
    clinicFeature: ClinicFeature.CALENDAR,
    icon: <CalendarIcon className={iconClassName} />,
  },
  {
    type: "SALES",
    name: strings.sales,
    href: "/sales",
    clinicFeature: ClinicFeature.INVOICE,
    icon: <ShoppingCart className={iconClassName} />,
  },
  {
    type: "TASKS",
    name: strings.tasks,
    href: "/todos",
    clinicFeature: ClinicFeature.INVENTORY,
    icon: <Flag className={iconClassName} />,
  },
  {
    type: "INVENTORY",
    name: strings.inventory,
    href: "/inventory",
    clinicFeature: ClinicFeature.INVENTORY,
    icon: <Cube className={iconClassName} />,
  },
  {
    type: "OWNERS",
    name: strings.owners,
    href: "/owners",
    clinicFeature: ClinicFeature.PET_OWNERS,
    icon: <Users className={iconClassName} />,
  },
  {
    type: "PRESCRIPTIONS",
    name: strings.prescriptions,
    href: "/prescriptions",
    clinicFeature: ClinicFeature.PRESCRIPTION,
    icon: <ClipboardDocumentList className={iconClassName} />,
  },
  {
    type: "STATISTICS",
    name: strings.statistics,
    href: "/statistics",
    clinicFeature: null,
    icon: <ChartBar className={iconClassName} />,
  },
  {
    type: "SETTINGS",
    name: strings.settings,
    href: "/clinic-settings/",
    clinicFeature: null,
    icon: <Cog className={iconClassName} />,
  },
];

export default DoctorMenuItems;
