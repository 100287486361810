/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { VideoConsultationRole } from "../../models/videoConsultation/VideoConsultationRole";
import {
  doctorPlaceholder,
  dogMainPlaceholder,
} from "../../assets/AssetConfig";
import { User } from "../../common/Icons/User";
import { WrenchScrewdriver } from "../../common/Icons/WrenchScrewdriver";
import Tooltip from "../../components/Tooltip";
import { strings } from "../../common/Strings/Strings";

interface Props {
  role: VideoConsultationRole;
}
const RoleBadge: React.FC<Props> = ({ role }: Props) => {
  if (role === VideoConsultationRole.PET_OWNER) {
    return (
      <Tooltip content={strings.petOwner} placement="top">
        <img
          src={dogMainPlaceholder}
          className="w-6 h-6"
          alt="pet-owner-icon"
        />
      </Tooltip>
    );
  }

  if (role === VideoConsultationRole.VET) {
    return (
      <Tooltip content={strings.vet} placement="top">
        <img src={doctorPlaceholder} className="w-6 h-6" alt="doctor-icon" />
      </Tooltip>
    );
  }

  if (role === VideoConsultationRole.MANAGER) {
    return (
      <Tooltip content={strings.siteManager} placement="top">
        <WrenchScrewdriver
          className="w-6 h-6 flex-shrink-0 dark:text-white"
          variant="outline"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip content={strings.guest} placement="top">
      <User
        className="w-6 h-6 flex-shrink-0 dark:text-white"
        variant="outline"
      />
    </Tooltip>
  );
};

export default RoleBadge;
