/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import {
  dateAndTime,
  strings,
  videoChatRoomEventType,
} from "../../../common/Strings/Strings";
import { RoomEvent } from "../../../models/videoConsultation/RoomEvent";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { PageResponse } from "../../../models/PageResponse";
import { PagerState } from "../../../hooks/hooks";

interface Props {
  events: PageResponse<RoomEvent>;
  pager: PagerState;
}

const EventHistory: React.FC<Props> = ({ events, pager }: Props) => {
  const renderEventRow = (event: RoomEvent) => {
    const user = event.payload?.user;

    return (
      <tr key={`${event.type}_${event.timestamp.toString()}`}>
        <td className="px-4 py-4 w-10 text-sm whitespace-nowrap">
          <p>{`#${event.id}`}</p>
        </td>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          <p>{videoChatRoomEventType[event.type]}</p>
        </td>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          {event.timestamp
            ? moment(event.timestamp).format(
                dateAndTime.momentDateTimeWithSecondsFormat
              )
            : "-"}
        </td>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          {user ? (
            <div className="flex flex-row items-center">
              <div className="w-10 h-10 mr-3">
                <UserProfilePicture userId={user.id} />
              </div>
              <p>{user.name}</p>
            </div>
          ) : (
            "-"
          )}
        </td>
      </tr>
    );
  };

  const renderAlertBox = () => (
    <AlertBox
      closeAble={false}
      message={
        <div className="flex flex-row">
          <p>{strings.thereAreNoEvents}</p>
        </div>
      }
      type={AlertType.INFO}
    />
  );

  return (
    <>
      {events.elements.length === 0 && renderAlertBox()}

      <PageableTable
        content={events}
        hidden={events.elements.length === 0}
        isLoading={false}
        pager={pager}
      >
        <PageableTableHead>
          <tr>
            <th className="text-left tw-th">{strings.eventSequenceNumber}</th>
            <th className="text-left tw-th">{strings.eventType}</th>
            <th className="text-left tw-th">{strings.eventDate}</th>
            <th className="text-left tw-th">{strings.eventData}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {events.elements.map(renderEventRow)}
        </PageableTableBody>
      </PageableTable>
    </>
  );
};

export default EventHistory;
