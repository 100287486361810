/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { downloadInvoicePdf, printInvoicePdf } from "../../../util/FileUtils";
import { strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import InvoiceState from "../InvoiceDetails/InvoiceState";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { ArrowDownTray } from "../../../common/Icons/ArrowDownTray";
import { Printer } from "../../../common/Icons/Printer";

interface Props {
  isOpen: boolean;
  close: () => void;
  invoice?: InvoiceResponse;
}

const SuccessModal: React.FC<Props> = ({
  isOpen,
  close,
  invoice: invoiceProp,
}: Props) => {
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [isPrinting, setPrinting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setInvoice(invoiceProp);
  }, [invoiceProp]);

  return (
    <Modal
      closeButton={false}
      closeOnClickOutside={false}
      handleClose={close}
      show={isOpen}
    >
      <Modal.Header closeButton={false}>
        <h3 className="truncate overflow-hidden xl:text-xl font-bold leading-tight text-zinc-800 dark:text-white flex items-center">
          <CheckCircle className="text-green-500 w-6 h-6 flex-shrink-0 mr-2" />
          {strings.invoiceCreated}
        </h3>
      </Modal.Header>
      <Modal.Body>
        {invoice?.valid === false ? (
          <div>
            {invoice && (
              <InvoiceState
                invoice={invoice}
                returnRefreshedInvoice={(i: InvoiceResponse) => {
                  setInvoice(i);
                }}
              />
            )}
          </div>
        ) : (
          <div className="divide-y dark:divide-gray-700 space-y-6">
            {invoice?.customer?.email ? (
              <div>
                {strings.invoiceHasBeenSentInEmail}{" "}
                <b>{invoice?.customer.email}</b>
              </div>
            ) : (
              <></>
            )}
            <div
              className={`flex justify-center items-center${
                invoice?.customer?.email ? " pt-6" : ""
              }`}
            >
              <div className="mr-3">{strings.printOrDownloadInvoice}</div>
              <div className="ml-auto">
                {isDownloading ? (
                  <LoaderInline />
                ) : (
                  <Button
                    disabled={isDownloading || !invoice}
                    onClick={async () => {
                      setIsDownloading(true);
                      try {
                        if (invoice) {
                          await downloadInvoicePdf(invoice);
                        }
                      } catch (err) {
                        logger.error(err);
                        setError(await getGeneralError(err));
                      } finally {
                        setIsDownloading(false);
                      }
                    }}
                    variant="icon"
                  >
                    <ArrowDownTray />
                  </Button>
                )}
              </div>
              <div className="ml-5">
                {isPrinting ? (
                  <LoaderInline />
                ) : (
                  <Button
                    disabled={isPrinting || !invoice}
                    onClick={async () => {
                      setPrinting(true);
                      try {
                        if (invoice) {
                          await printInvoicePdf(invoice, () =>
                            setPrinting(false)
                          );
                        }
                      } catch (err) {
                        logger.error(err);
                        setError(await getGeneralError(err));
                        setPrinting(false);
                      }
                    }}
                    variant="icon"
                  >
                    <Printer />
                  </Button>
                )}
              </div>
            </div>
            <div className="pt-6">
              <Link to={`/invoices/clinic/${invoice?.id}`}>
                {strings.invoice}
              </Link>
            </div>
            <AlertBox message={error} />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-sub-button"
          onClick={() => {
            navigate(`/sales`);
          }}
          variant="secondary"
        >
          {strings.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;

SuccessModal.defaultProps = {
  invoice: undefined,
};
