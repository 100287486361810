/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactNode } from "react";
import { strings } from "../../common/Strings/Strings";
import Modal from "../../components/Modal/Modal";
import AlertBox from "../../components/AlertBox";
import CloseButton from "../../components/CloseButton";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class VideoConsultationErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error in component:", error, errorInfo);
  }

  handleModalClose = () => {
    this.setState({ hasError: false, errorMessage: "" });
  };

  render() {
    const { hasError, errorMessage } = this.state;

    if (hasError) {
      return (
        <Modal handleClose={this.handleModalClose} show={hasError}>
          <Modal.Header title={strings.videoConsultationError} />
          <Modal.Body>
            <AlertBox closeAble={false} message={errorMessage} />
          </Modal.Body>
          <Modal.Footer extraClassName="justify-end">
            <CloseButton
              asMain
              onClick={this.handleModalClose}
              text={strings.ok}
            />
          </Modal.Footer>
        </Modal>
      );
    }

    return this.props.children;
  }
}

export default VideoConsultationErrorBoundary;
