/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { positiveOrZeroDouble } from "../../../util/Validations";
import { strings } from "../../../common/Strings/Strings";
import NumberFormatter from "../../../util/NumberFormatter";
import ControlledField from "../General/Controlled/ControlledField";

interface Props {
  control: Control<any>;
  name: string;
  currency: string | undefined | null;
  label?: string;
  onChange?: (price?: number) => void;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  sm?: boolean;
  value?: number | null;
  vat?: number;
}

const PriceInputsControlled: React.FC<Props> = (props: Props) => {
  const {
    name,
    control,
    currency,
    label,
    onChange,
    optional,
    readOnly,
    required,
    sm,
    value,
    vat,
  } = props;

  const [netPrice, setNetPrice] = useState<number>();
  const [grossPrice, setGrossPrice] = useState<number>();

  const getGrossPrice = (net: number): number =>
    NumberFormatter.round(net * ((100 + Number(vat || 0)) / 100));

  const getNetPrice = (gross: number): number =>
    NumberFormatter.round(gross / ((100 + Number(vat || 0)) / 100));

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const price = Number(value);
      setNetPrice(price);
      setGrossPrice(getGrossPrice(price));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, vat]);

  useEffect(() => {
    setGrossPrice(netPrice !== undefined ? getGrossPrice(netPrice) : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netPrice, vat]);

  const onNetPriceChange = (net: number | string) => {
    const price = net !== "" ? Number(net) : undefined;
    setNetPrice(price);
    if (onChange) {
      onChange(price);
    }
  };

  const onGrossPriceChange = (gross: number | string) => {
    const price = gross !== "" ? Number(gross) : undefined;
    setGrossPrice(price);
    const net = price !== undefined ? getNetPrice(price) : undefined;
    setNetPrice(net);
    if (onChange) {
      onChange(net);
    }
  };

  return (
    <div className="flex">
      <div className="mr-4">
        <ControlledField
          control={control}
          key={name}
          name={name}
          autoComplete={AutoCompleteOptions.off}
          fieldOptions={{
            pattern: { value: positiveOrZeroDouble.matchRegexp, message: "" },
          }}
          label={label ? `${label} (${strings.net})` : ""}
          onChange={(d: string) => {
            onNetPriceChange(d);
          }}
          optional={optional}
          placeholder={strings.net}
          readOnly={readOnly}
          required={required}
          sm={sm}
          suffix={currency}
          type="number"
          value={netPrice?.toString()}
        />
      </div>
      <div>
        <ControlledField
          control={control}
          key={`${name}_gross`}
          name={`${name}_gross`}
          autoComplete={AutoCompleteOptions.off}
          fieldOptions={{
            pattern: { value: positiveOrZeroDouble.matchRegexp, message: "" },
          }}
          label={label ? `${label} (${strings.gross})` : ""}
          onChange={(d: string) => {
            onGrossPriceChange(d);
          }}
          optional={optional}
          placeholder={strings.gross}
          readOnly={readOnly}
          required={required}
          sm={sm}
          suffix={currency}
          type="number"
          value={grossPrice?.toString()}
        />
      </div>
    </div>
  );
};

export default PriceInputsControlled;

PriceInputsControlled.defaultProps = {
  label: undefined,
  onChange: undefined,
  optional: false,
  readOnly: false,
  required: false,
  sm: false,
  value: undefined,
  vat: undefined,
};
