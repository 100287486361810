/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";
import AlertBox, { AlertType } from "../../components/AlertBox";
import PendingInvoiceList from "./InvoiceListing/PendingInvoiceList";
import InvoiceList from "./InvoiceListing/InvoiceList";
import Button from "../../components/Button";
import { SettingsTabs } from "../../models/clinic/SettingsTabs";

interface Props {
  clinic?: ClinicResponse;
}

export type TabState = "inProgress" | "active";

const WorkerInvoices = ({ clinic }: Props): ReactElement => {
  return (
    <>
      <div className="flex content-center flex-column space-y-6">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
            {strings.sales}
          </h1>
          <div hidden={!clinic} className="ml-3">
            <Link to={`/invoices/new`}>
              <Button minW>
                {strings.newSale}
              </Button>
            </Link>
          </div>
        </div>
        <AlertBox
          message={
            <div>
              <b>{strings.szamlazzAvailable}</b>
              <div>
                <Link to={`/clinic-settings/${SettingsTabs.bank}`}>{strings.goToSzamlazz}</Link>
              </div>
            </div>
          }
          type={AlertType.INFO}
        />
        <PendingInvoiceList clinic={clinic} />
        <InvoiceList clinic={clinic} />
      </div>
    </>
  );
};

export default WorkerInvoices;

WorkerInvoices.defaultProps = {
  clinic: undefined,
};
