/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { Pencil } from "../../../../../common/Icons/Pencil";
import Button from "../../../../../components/Button";
import { canManageClinic } from "../../../../../util/helperFunctions";
import IntegrationModal from "./IntegrationModal";
import FortnoxAuthenticationError from "../../../../Accounting/InvoiceDetails/FortnoxAuthenticationError";
import { useClinic } from "../../../../../contexts/ClinicContext";

export type PrevStateSetter = (prevState: ClinicResponse) => ClinicResponse;

interface Props {
  isInline: boolean;
}

const InvoiceIntegration: React.FC<Props> = ({
  isInline,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isInvoicingSystemEnabled, setIsInvoicingSystemEnabled] =
    useState<boolean>();
  const { clinic } = useClinic();

  useEffect(() => {
    setIsInvoicingSystemEnabled(
      clinic?.szamlazzEnabled || clinic?.fortnoxEnabled
    );
  }, [clinic]);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const alertType = () => {
    if (clinic?.fortnoxEnabled && clinic?.fortnoxAuthenticationRequired) {
      return (
        <FortnoxAuthenticationError
          clinicId={clinic.id}
          title={strings.failedToConnectToFortnox}
        />
      );
    } else if (isInvoicingSystemEnabled) {
      return (
        <AlertBox
          type={AlertType.SUCCESS}
          message={
            <>
              {strings.integrated}:{" "}
              <b>
                {clinic?.fortnoxEnabled ? strings.fortnox : strings.szamlazz}
              </b>
            </>
          }
          closeAble={false}
        />
      );
    } else {
      return (
        <AlertBox
          type={AlertType.INFO}
          message={strings.notIntegrated}
          closeAble={false}
        />
      );
    }
  };
  if (isInline) {
    return (
      <>
        <div className="space-y-4">
         
            <div>
              {strings.formatString(
                strings.invoicingInfo,
                <b>{strings.szamlazz}</b>,
                <b>{strings.fortnox}</b>
              )}
            </div>
            {alertType()}
          {!isInvoicingSystemEnabled && clinic && canManageClinic(clinic) && (
              <Button variant="primary" minW onClick={openModal}>
                {strings.addIntegration}
              </Button>
          )}
        </div>
        <IntegrationModal
          clinic={clinic || undefined}
          isOpen={isModalVisible}
          onHide={closeModal}
        />
      </>
    );
  }
  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.invoicing}</p>
          {isInvoicingSystemEnabled && clinic && canManageClinic(clinic) && (
            <div className="ml-auto">
              <Button
                variant="icon"
                onClick={openModal}
                hidden={!canManageClinic(clinic)}
              >
                <Pencil />
              </Button>
            </div>
          )}
        </div>
        <div className="p-4 space-y-4">
          <div>
            {strings.formatString(
              strings.invoicingInfo,
              <b>{strings.szamlazz}</b>,
              <b>{strings.fortnox}</b>
            )}
          </div>
          {alertType()}
        </div>
        {!isInvoicingSystemEnabled && clinic && canManageClinic(clinic) && (
          <div className="flex p-4">
            <Button variant="primary" minW onClick={openModal}>
              {strings.addIntegration}
            </Button>
          </div>
        )}
      </div>
      <IntegrationModal
        isOpen={isModalVisible}
        onHide={closeModal}
      />
    </>
  );
};

export default InvoiceIntegration;
