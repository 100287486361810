/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import logger from "../../util/logger";

/*
 * Utility functions to generate dummy audio and video tracks for WebRTC streams.
 * These functions help to fill missing tracks when working with WebRTC connections that require both audio and video tracks.
 */

// Creates a silent audio track
export const createDummyAudioTrack = (stopTrack = false): MediaStreamTrack & { enabled: boolean } => {
  // Create a new AudioContext
  const ctx = new AudioContext();

  // Create an OscillatorNode (a source of periodic waveforms)
  const oscillator = ctx.createOscillator();

  // Connect the oscillator to a MediaStreamDestination (to capture the audio stream)
  const dst = oscillator.connect(ctx.createMediaStreamDestination()) as MediaStreamAudioDestinationNode;

  // Start the oscillator
  oscillator.start();

  // Immediately stop the track if required
  if (stopTrack) {
    dst.stream.getAudioTracks()[0].stop();
  }

  // Get the first audio track from the MediaStream, and disable it
  return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
};

// Creates a black video track
export const createDummyVideoTrack = (width = 640, height = 480, stopTrack = false) => {
  const canvas = Object.assign(document.createElement("canvas"), { width, height });

  // Get the 2D rendering context
  const ctx = canvas.getContext("2d");

  // Check if the context is not null
  if (ctx) {
    // Fill the canvas with black
    ctx.fillRect(0, 0, width, height);
  } else {
    logger.error("2D context could not be created");
    return null;
  }

  // Capture the canvas content as a media stream
  const stream = canvas.captureStream();

  // Immediately stop the track if required
  if (stopTrack) {
    stream.getVideoTracks()[0].stop();
  }

  // Get the first video track from the media stream and disable it
  return Object.assign(stream.getVideoTracks()[0], { enabled: false });
};

// Creates a dummy video stream (silent audio and black video)
// Useful for WebRTC configurations where both peers need audio and video tracks
export const createDummyStream = (stopTracks = true): MediaStream => {
  const videoTrack = createDummyVideoTrack(1, 1, stopTracks); // Small dummy video
  const audioTrack = createDummyAudioTrack(stopTracks); // Silent audio

  // Ensure that videoTrack is not null before creating the MediaStream
  if (videoTrack) {
    return new MediaStream([videoTrack, audioTrack]);
  }

  logger.error("Dummy video track could not be created");

  // If video creation failed, return just the audio
  return new MediaStream([audioTrack]);
};

// Fills a given media stream with dummy audio and/or video tracks if missing
export const fillWithDummyTracks = (stream?: MediaStream | null, stopTracks = false): MediaStream => {
  // If the stream is missing, create a new stream with dummy tracks
  if (!stream) {
    return createDummyStream(stopTracks);
  }

  // Clone the original stream to avoid modifying it directly
  const newStream = stream.clone();

  // Check if the stream already has an audio track, if not, add a dummy audio track
  if (newStream.getAudioTracks().length === 0) {
    const audioTrack = createDummyAudioTrack(stopTracks);
    newStream.addTrack(audioTrack);
  }

  // Check if the stream already has a video track, if not, add a dummy video track
  if (newStream.getVideoTracks().length === 0) {
    const videoTrack = createDummyVideoTrack(640, 480, stopTracks);
    if (videoTrack) {
      newStream.addTrack(videoTrack);
    }
  }

  return newStream;
};
