/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ExtraCost } from "../../../models/medical/ExtraCost";
import { ServiceFeeResponse } from "../../../models/servicefee/ServiceFeeResponse";
import { TreatmentFeeResponse } from "../../../models/treatment/TreatmentFeeResponse";

export type UsedFeeActions =
  | { type: "fill"; itemsToAdd: ExtraCost[] }
  | { type: "add"; itemToAdd: ServiceFeeResponse; quantity: number }
  | { type: "addFromTreatment"; itemToAdd: TreatmentFeeResponse }
  | { type: "changeQuantity"; itemToChange: ExtraCost; newQuantity: number }
  | { type: "reduceQuantity"; itemToChange: TreatmentFeeResponse }
  | { type: "changePrice"; itemToChange: ExtraCost; newPrice: number }
  | { type: "remove"; itemToRemove: ExtraCost };

/*
 * This reducer manages the state of `ExtraCost` items that are specifically identified by a unique `referenceId`.
 * The items processed by this reducer are those with a non-null `referenceId`.
 * We expect the `referenceId` to be unique across all items in the state.
 *
 * Actions:
 * - "fill": Replace the entire state with a new list of items.
 * - "add": Adds a new item or updates an existing item based on the `referenceId`.
 * - "changeQuantity": Changes the quantity of an item identified by its `id`.
 * - "reduceQuantity": Reduces the quantity of an item by a given amount.
 * - "changePrice": Updates the price of an item identified by its `id`.
 * - "remove": Removes an item identified by its `referenceId`.
 *
 * Important Note:
 * - The uniqueness of `referenceId` is crucial here, as it is used to uniquely identify items for updating and removal operations.
 * - If `referenceId` is not unique, operations like removing or updating items may unintentionally affect multiple items with the same `referenceId`.
 */
export const feeReducer = (
  state: Array<ExtraCost>,
  action: UsedFeeActions
): ExtraCost[] => {
  switch (action.type) {
    case "fill":
      // Replace the entire state with the provided array of items
      return action.itemsToAdd;

    case "add": {
      const { id: addActionId, price = 0 } = action.itemToAdd;

      // Check if the item already exists by referenceId
      const index = state.findIndex(
        (item: ExtraCost) => item.referenceId === addActionId
      );

      if (index >= 0) {
        // If the item exists, update its quantity
        return state.map((item: ExtraCost, i: number) =>
          i === index
            ? { ...item, quantity: item.quantity + action.quantity }
            : item
        );
      }

      // Convert data
      const extraCost: ExtraCost = {
        ...action.itemToAdd,
        referenceId: addActionId,
        price,
        quantity: action.quantity,
      };

      return [...state, extraCost];
    }

    case "addFromTreatment": {
      const {
        fee: { id: addActionId, price = 0, ...feeDetails },
        quantity,
      } = action.itemToAdd;

      // Check if the item already exists by referenceId
      const index = state.findIndex(
        (item: ExtraCost) => item.referenceId === addActionId
      );

      if (index >= 0) {
        // If the item exists, update its quantity
        return state.map((item: ExtraCost, i: number) =>
          i === index ? { ...item, quantity: item.quantity + quantity } : item
        );
      }

      // Convert data
      const extraCost: ExtraCost = {
        ...feeDetails,
        id: addActionId,
        referenceId: addActionId,
        price,
        quantity,
      };

      return [...state, extraCost];
    }

    case "changeQuantity": {
      const { referenceId: changeActionId } = action.itemToChange;

      // Update the quantity of the item with the specified id
      return state.map((item: ExtraCost) =>
        item.referenceId === changeActionId
          ? { ...item, quantity: action.newQuantity }
          : item
      );
    }

    case "reduceQuantity": {
      const { fee, quantity: reduceQuantity } = action.itemToChange;

      // Find the item to reduce its quantity
      const index = state.findIndex(
        (item: ExtraCost) => item.referenceId === fee.id
      );

      if (index >= 0) {
        const updatedItem = { ...state[index] };

        if (updatedItem.quantity > reduceQuantity) {
          // Reduce the quantity if it's greater than the amount to reduce
          updatedItem.quantity -= reduceQuantity;
          return state.map((item: ExtraCost, i: number) =>
            i === index ? updatedItem : item
          );
        }

        // If the quantity becomes 0 or less, remove the item
        return state.filter((item: ExtraCost) => item.referenceId !== fee.id);
      }

      // If the item does not exist, return the current state
      return state;
    }

    case "changePrice": {
      const { referenceId: changePriceActionId } = action.itemToChange;

      // Update the price of the item with the specified id
      return state.map((item: ExtraCost) =>
        item.referenceId === changePriceActionId
          ? { ...item, price: action.newPrice }
          : item
      );
    }

    case "remove": {
      const { referenceId: removeActionId } = action.itemToRemove;

      // Remove the item by referenceId
      return state.filter(
        (item: ExtraCost) => item.referenceId !== removeActionId
      );
    }

    default:
      // Return the current state for unknown actions
      return state;
  }
};
