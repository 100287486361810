/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { useEffect, useRef, useState } from "react";

/**
 * Custom hook to observe the dimensions (width and height) of an element.
 *
 * @param {boolean} isVisible - A boolean indicating whether the element is currently visible.
 * @param {boolean} [ignorePadding=false] - A boolean indicating whether to exclude padding from the height calculation.
 * @returns {{
 *   ref: React.RefObject<HTMLDivElement>,
 *   dimensions: { width: number, height: number }
 * }}
 *
 * Use case:
 * This hook is useful for responsive components that need to adjust their layout based
 * on the size of their parent or surrounding elements. The `isVisible` parameter ensures that the
 * resize observer does not attempt to measure dimensions when the element is hidden, preventing
 * inaccurate readings (e.g., dimensions being zero). The `ignorePadding` parameter allows you to
 * exclude padding from the height calculation, providing a more precise measurement of the
 * content area when needed.
 */
const useElementDimensions = (isVisible: boolean, ignorePadding = false) => {
  const ref = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    if (!isVisible || !ref.current) {
      return undefined;
    }

    const handleResize = () => {
      const element = ref.current;
      if (!element) return;

      const rect = element.getBoundingClientRect();
      let newHeight = rect.height;

      if (ignorePadding) {
        const computedStyle = window.getComputedStyle(element);
        const paddingTop = parseFloat(computedStyle.paddingTop);
        const paddingBottom = parseFloat(computedStyle.paddingBottom);

        newHeight = rect.height - paddingTop - paddingBottom;
      }

      const newDimensions = {
        width: Math.floor(rect.width || 0),
        height: Math.floor(newHeight || 0),
      };

      // Only update state if the dimensions have changed
      if (newDimensions.width !== dimensions.width || newDimensions.height !== dimensions.height) {
        setDimensions(newDimensions);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    handleResize(); // Initial dimensions
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [dimensions.height, dimensions.width, isVisible, ignorePadding]);

  return { ref, dimensions };
};

export default useElementDimensions;
