/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { urlFromBlobPart } from "../../util/FileUtils";
import PictureApi from "../../api/PictureApi";
import logger from "../../util/logger";
import {
  alpacaPlaceholder,
  camelPlaceholder,
  catPlaceholder,
  chickenPlaceholder,
  clinicPlaceholder,
  dogPlaceholder,
  duckPlaceholder,
  fishPlaceholder,
  insectPlaceholder,
  moosePlaceholder,
  mousePlaceholder,
  ostrichPlaceholder,
  petPlaceholder,
  pigPlaceholder,
  rabbitPlaceholder,
  sheepPlaceholder,
  userProfilePlaceholder,
} from "../../assets/AssetConfig";
import { PetSpecies } from "../../models/pet/PetSpeciesEnum";
import { getAverageColor } from "../../util/ImageUtils";

/* Determines the owner of the picture belongs to */
export type OwnerType = "user" | "clinic" | "pet";

type Props = {
  ownerType: OwnerType;
  border?: boolean;
  borderWidth?: string;
  onCalculateAverageColor?: (color?: string) => void;
  ownerId?: string;
  pictureId?: string;
  species?: PetSpecies;
};

const fetchPicture = (type: OwnerType, ownerId: string) => {
  switch (type) {
    case "user":
      return PictureApi.getUserProfilePictureByUserId(ownerId);
    case "clinic":
      return PictureApi.getClinicLogoPictureByClinicId(ownerId);
    case "pet":
      return PictureApi.getPetProfilePictureByPetId(ownerId);
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

/* Placeholder images for missing pet profile picture */
const petPlaceholders: { [key in PetSpecies]?: string } = {
  [PetSpecies.ALPACA]: alpacaPlaceholder,
  [PetSpecies.CAMEL]: camelPlaceholder,
  [PetSpecies.CAT]: catPlaceholder,
  [PetSpecies.CHICKEN]: chickenPlaceholder,
  [PetSpecies.DOG]: dogPlaceholder,
  [PetSpecies.DUCK]: duckPlaceholder,
  [PetSpecies.FISH_FARMED]: fishPlaceholder,
  [PetSpecies.FISH_WILD]: fishPlaceholder,
  [PetSpecies.INSECT]: insectPlaceholder,
  [PetSpecies.MOOSE]: moosePlaceholder,
  [PetSpecies.MOUSE]: mousePlaceholder,
  [PetSpecies.OSTRICH]: ostrichPlaceholder,
  [PetSpecies.PIG]: pigPlaceholder,
  [PetSpecies.PIG_LIVESTOCK]: pigPlaceholder,
  [PetSpecies.RABBIT]: rabbitPlaceholder,
  [PetSpecies.RABBIT_LIVESTOCK]: rabbitPlaceholder,
  [PetSpecies.SHEEP]: sheepPlaceholder,
};

const getPlaceholder = (
  type: OwnerType,
  species: PetSpecies = PetSpecies.OTHER
) => {
  switch (type) {
    case "user":
      return userProfilePlaceholder;
    case "clinic":
      return `${process.env.PUBLIC_URL}${clinicPlaceholder}`;
    case "pet":
      return (species && petPlaceholders[species]) || petPlaceholder;
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

/* Common picture component for users, clinics and pets */
const Picture: React.FC<Props> = ({
  ownerType,
  border = true,
  onCalculateAverageColor,
  borderWidth = "4px",
  ownerId,
  pictureId,
  species,
}: Props) => {
  const [picture, setPicture] = useState<string | null>(null);

  useEffect(() => {
    const downloadPicture = async (): Promise<string | null> => {
      if (!ownerId) return null;

      try {
        const resp = await fetchPicture(ownerType, ownerId);
        if (resp.status === 204) return null;

        const url = await urlFromBlobPart(resp.data);

        const contentType =
          resp.headers["content-type"] ?? "application/octet-stream";

        return `data:${contentType};${url.split(";")[1]}`;
      } catch (error) {
        logger.error(error);
        return null;
      }
    };

    downloadPicture().then(setPicture);

    // The pictureId dependency is important for picture upload and delete in picture settings
  }, [ownerId, ownerType, pictureId]);

  useEffect(() => {
    // Calculate the average color if a color background is needed
    const calculateAverageColor = async () => {
      if (!onCalculateAverageColor) return;

      if (picture === null) {
        // Call the callback function with no argument
        onCalculateAverageColor();
        return;
      }

      // Call the callback function with the calculated average color
      const averageColor = await getAverageColor(picture);
      onCalculateAverageColor(averageColor.rgba);
    };

    void calculateAverageColor();
  }, [onCalculateAverageColor, picture]);

  const isPet = ownerType === "pet";

  return (
    <>
      {isPet ? (
        <div
          className="bg-white bg-no-repeat bg-cover bg-center bg-slate-100 border-solid border-gray-100 dark:border-gray-700 flex h-full items-center justify-center overflow-hidden rounded-full w-full"
          style={{ borderWidth: border ? borderWidth : 0 }}
        >
          <img
            className="object-cover"
            width={picture ? "100%" : "60%"}
            style={{ height: picture ? "100%" : "60%" }}
            alt="Pet profile"
            src={picture || getPlaceholder(ownerType, species)}
          />
        </div>
      ) : (
        <img
          className="bg-center bg-cover bg-no-repeat bg-white border-gray-100 border-solid dark:border-gray-700 h-full object-cover rounded-full w-full"
          style={{ borderWidth: border ? borderWidth : 0 }}
          alt={ownerType}
          src={picture || getPlaceholder(ownerType, species)}
        />
      )}
    </>
  );
};

export default Picture;
