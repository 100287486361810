/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { PrescriptionItemFrontend } from "../../../models/ePrescription/local/PrescriptionItemFrontend";
import { VaraItem } from "../../../models/inventory/item/VaraItem";
import { PackageType } from "../../../models/ePrescription/VARA/PackageType";
import { PrescriptionItemType } from "../../../models/ePrescription/persistence/PrescriptionItemType";
import { strings } from "../../../common/Strings/Strings";
import SearchProduct from "./SimpleProduct/SearchProduct";
import SearchCommodityProduct from "./CommodityProduct/SearchCommodityProduct";
import { CommodityPrescriptionItemFrontend } from "../../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import Licensed from "../findProduct/LicensedProductCreation/CreateLicensed";
import { GeoPharmacy } from "../../../models/pharmacy/GeoPharmacy";
import { LicensedPrescriptionItemFrontend } from "../../../models/ePrescription/local/LicensedPrescriptionItemFrontend";
import { MedicinalProduct } from "../../../models/ePrescription/VARA/MedicinalProduct";
import SelectedProduct from "./SimpleProduct/SelectedProduct";
import Button from "../../../components/Button";
import VaraProductDetailSection from "../../Inventory/CreateItem/VARA/VaraProductDetailSection";
import ProductDetailSectionCommodity from "../productDetails/ProductDetailSectionCommodity";
import { CommodityProduct } from "../../../models/ePrescription/VARA/CommodityProduct";
import { ArrowUnturnLeft } from "../../../common/Icons/ArrowUnturnLeft";
import Tabs from "../../../components/Tabs/Tabs";
import AlertBox, { AlertType } from "../../../components/AlertBox";

interface Props {
  addCommodityPrescriptionPrescriptionItem?(
    newItem: CommodityPrescriptionItemFrontend
  ): void;
  addLicensedPrescriptionItem?(newItem: LicensedPrescriptionItemFrontend): void;
  addPackageToInventory?(p: VaraItem): void;
  addPrescriptionItem?(p: PrescriptionItemFrontend): void;
  geoPharmacies: GeoPharmacy[];
  packageTypes: PackageType[];
  inventory?: boolean;
}

enum Steps {
  search,
  fill,
}

const getTabs = (inventory: boolean) => {
  const tabs = [
    {
      title: strings.searchApprovedProducts,
      tab: PrescriptionItemType.STANDARD,
    },
    {
      title: strings.searchNotApprovedProducts,
      tab: PrescriptionItemType.VARALICENSED,
    },
    {
      title: strings.searchCommodity,
      tab: PrescriptionItemType.COMMODITY,
    },
  ];

  if (!inventory) {
    tabs.push({
      title: strings.createLicensedMedication,
      tab: PrescriptionItemType.LICENSED,
    });
  }

  return tabs;
};

const ProductSelectSteps: React.FC<Props> = ({
  addPrescriptionItem,
  addCommodityPrescriptionPrescriptionItem,
  addLicensedPrescriptionItem,
  addPackageToInventory,
  geoPharmacies,
  packageTypes,
  inventory = false,
}: Props) => {
  const [step, setSteps] = useState<Steps>(Steps.search);
  const [key, setKey] = useState<PrescriptionItemType>(
    PrescriptionItemType.STANDARD
  );
  const [selectedProduct, setSelectedProduct] = useState<MedicinalProduct>();
  const [selectedVaraLicensedProduct, setSelectedVaraLicensedProduct] =
    useState<MedicinalProduct>();
  const [selectedCommodityProduct, setSelectedCommodityProduct] =
    useState<CommodityProduct>();
  const [createdLicensedProduct, setCreatedLicensedProduct] =
    useState<LicensedPrescriptionItemFrontend>();
  const [resetSearch, setResetSearch] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const addCommodity = (c: CommodityPrescriptionItemFrontend) => {
    if (addCommodityPrescriptionPrescriptionItem) {
      addCommodityPrescriptionPrescriptionItem(c);
    }
    setSteps(Steps.search);
    setResetSearch((prev) => !prev);
  };

  const fillStep = () => {
    if (step === Steps.fill) {
      if (key === PrescriptionItemType.STANDARD && selectedProduct) {
        return (
          <SelectedProduct
            addPrescriptionItem={(m) => {
              if (addPrescriptionItem) {
                addPrescriptionItem(m);
              }
              setSteps(Steps.search);
              setResetSearch((prev) => !prev);
              setShowSuccess(true);
            }}
            addPackageToInventory={addPackageToInventory}
            medicine={selectedProduct}
            inventory={inventory}
            isOnlyLicensed={false}
            packageTypes={packageTypes}
          />
        );
      }
      if (
        key === PrescriptionItemType.VARALICENSED &&
        selectedVaraLicensedProduct
      ) {
        return (
          <SelectedProduct
            addPrescriptionItem={(m) => {
              if (addPrescriptionItem) {
                addPrescriptionItem(m);
              }
              setSteps(Steps.search);
              setResetSearch((prev) => !prev);
              setShowSuccess(true);
            }}
            addPackageToInventory={addPackageToInventory}
            medicine={selectedVaraLicensedProduct}
            inventory={inventory}
            isOnlyLicensed
            packageTypes={packageTypes}
          />
        );
      }
      if (key === PrescriptionItemType.COMMODITY && selectedCommodityProduct) {
        return inventory ? (
          <VaraProductDetailSection
            commodityProduct={selectedCommodityProduct}
            packageTypes={packageTypes}
            addPackageToInventory={addPackageToInventory}
          />
        ) : (
          <ProductDetailSectionCommodity
            commodityProduct={selectedCommodityProduct}
            addPrescription={addCommodity}
            packageTypes={packageTypes}
          />
        );
      }
    }

    return <></>;
  };

  const content = () => {
    switch (key) {
      case PrescriptionItemType.STANDARD:
        return (
          <SearchProduct
            isOnlyLicensed={false}
            resetSearch={resetSearch}
            selectProduct={(m) => {
              setSelectedProduct(m);
              setShowSuccess(false);
              setSteps(Steps.fill);
            }}
          />
        );
      case PrescriptionItemType.VARALICENSED:
        return (
          <SearchProduct
            isOnlyLicensed
            resetSearch={resetSearch}
            selectProduct={(m) => {
              setSelectedVaraLicensedProduct(m);
              setShowSuccess(false);
              setSteps(Steps.fill);
            }}
          />
        );
      case PrescriptionItemType.COMMODITY:
        return (
          <SearchCommodityProduct
            resetSearch={resetSearch}
            selectProduct={(c) => {
              setSelectedCommodityProduct(c);
              setShowSuccess(false);
              setSteps(Steps.fill);
            }}
          />
        );
      case PrescriptionItemType.LICENSED:
        return (
          <Licensed
            addPrescription={(lp) => {
              if (addLicensedPrescriptionItem) {
                setCreatedLicensedProduct(lp);
                addLicensedPrescriptionItem(lp);
                setShowSuccess(true);
              }
            }}
            geoPharmacies={geoPharmacies}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {inventory ? (
        step === Steps.fill && (
          <Button
            onClick={() => {
              setSteps(Steps.search);
            }}
            variant="link"
          >
            <div className="flex">
              <ArrowUnturnLeft className="pr-1" />
              {strings.backToSearch}
            </div>
          </Button>
        )
      ) : (
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
            {strings.addItems}
          </h1>
          {step === Steps.fill && (
            <Button
              onClick={() => {
                setSteps(Steps.search);
              }}
              variant="link"
            >
              <div className="flex">
                <ArrowUnturnLeft className="pr-1" />
                {strings.backToSearch}
              </div>
            </Button>
          )}
        </div>
      )}
      <AlertBox
        hidden={!showSuccess}
        message={strings.formatString(
          strings.productAddedSuccessfully,
          <b>
            {selectedProduct?.name ||
              selectedVaraLicensedProduct?.name ||
              selectedCommodityProduct?.name ||
              createdLicensedProduct?.freeTextLicense?.supplementaryInformation}
          </b>
        )}
        type={AlertType.SUCCESS}
      />
      <div hidden={step !== Steps.search}>
        <Tabs tabs={getTabs(inventory)} currentTabKey={key} setTab={setKey} />
        {content()}
      </div>
      {fillStep()}
    </>
  );
};

export default ProductSelectSteps;
