/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import { ReservationResponse } from "../../../models/reservation/ReservationResponse";
import IconBubble from "../../../components/IconBubble";
import { strings } from "../../../common/Strings/Strings";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { Clock } from "../../../common/Icons/Clock";
import { DocumentText } from "../../../common/Icons/DocumentText";
import { Pencil } from "../../../common/Icons/Pencil";
import Tag, { TagType } from "../../../components/Tag";
import PetInfo from "../../Todo/PetInfo";

interface Props {
  reservation: ReservationResponse;
}

const ReservationInfo: React.FC<Props> = ({ reservation }: Props) => {
  const medicalRecordStatus = () => {
    /* Created */
    if (
      !reservation?.lastMedicalRecordId ||
      (reservation?.lastMedicalRecordId &&
        !reservation?.lastSignedMedicalRecordId)
    ) {
      return (
        <div className="d-flex">
          <div style={{ marginRight: "8px" }}>
            <Tag type={TagType.secondary} text={strings.created} />
          </div>
          <div>{reservation?.createdBy?.fullName}</div>
        </div>
      );

      /* Signed */
    }
    if (
      reservation?.lastSignedMedicalRecordId ===
      reservation?.lastMedicalRecordId
    ) {
      return <Tag type={TagType.primary} text={strings.signed} />;

      /* Under correction */
    }
    return <Tag type={TagType.secondary} text={strings.underCorrection} />;
  };

  return (
    <section className="flex flex-row space-x-6">
      {/* Collaborator */}
      <div className="flex space-x-3 items-center">
        <div style={{ width: "40px", height: "40px" }}>
          <UserProfilePicture userId={reservation?.collaborator.userId} />
        </div>
        <div className="font-semibold text-base text-zinc-800 dark:text-white">
          {reservation?.collaborator.fullName}
        </div>
      </div>
      {/* Medical record status */}
      <div className="flex space-x-3 items-center">
        <IconBubble>
          <Clock
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <div className="font-semibold text-base text-zinc-800 dark:text-white">
          {medicalRecordStatus()}
        </div>
      </div>
      {/* Invoice */}
      <div hidden={!reservation?.invoiceId}>
        <div className="flex space-x-3 items-center">
          <IconBubble>
            <DocumentText
              className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
              variant="outline"
            />
          </IconBubble>
          <Link to={`/invoices/clinic/${reservation?.invoiceId}`}>
            <div className="tw-link-tercier">{strings.invoice}</div>
          </Link>
        </div>
      </div>
      {/* Pet */}
      <div className="flex space-x-3 items-center">
        {!reservation?.petOfNewOwner && reservation?.pet && (
          <PetInfo
            pet={{
              clinicPetDetails: {
                ...reservation?.pet,
                chronicDiseases: reservation?.pet.chronicDiseases || [],
              },
            }}
          />
        )}
        {reservation?.petOfNewOwner && (
          <b className="mb-2">{reservation?.petOfNewOwner?.name}</b>
        )}
      </div>
      {/* Pet owner */}
      <div className="flex space-x-3 items-center">
        <div style={{ width: "40px", height: "40px" }}>
          <UserProfilePicture userId={reservation?.petOwner.id} />
        </div>
        <div>
          <div className="font-semibold text-base text-zinc-800 dark:text-white">
            <b>{reservation?.petOwner.fullName}</b>
          </div>
          <div className="font-normal text-sm text-zinc-800 dark:text-white">
            {reservation?.petOwner.phones[0]?.value}
          </div>
        </div>
      </div>
      {/* Description */}
      <div hidden={!reservation?.description}>
        <div className="flex space-x-3 items-center">
          <IconBubble>
            <Pencil
              className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
              variant="outline"
            />
          </IconBubble>
          <div className="font-semibold text-base text-zinc-800 dark:text-white">
            {reservation?.description}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReservationInfo;
