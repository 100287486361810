/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import Modal from "../../../../../components/Modal/Modal";
import {
  getGeneralError,
  isFeatureAvailable,
} from "../../../../../util/helperFunctions";
import fortnoxLogo from "../../../../../assets/Images/fortnox_logo.png";
import szamlazzLogo from "../../../../../assets/Images/szamlazz_logo.png";
import SzamlazzApi from "../../../../../api/SzamlazzApi";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import RadioButtonGroup from "../../../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";
import { useForm } from "react-hook-form";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { Link } from "react-router-dom";
import { SupportTopics } from "../../../../Support/Topics";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import Button from "../../../../../components/Button";
import { FortnoxIntegrationRequest } from "../../../../../models/clinic/FortnoxIntegrationRequest";
import FortnoxApi from "../../../../../api/FortnoxApi";
import { SzamlazzIntegrationRequest } from "../../../../../models/clinic/SzamlazzIntegrationRequest";
import { ClinicFeature } from "../../../../../models/clinic/ClinicFeature";
import FortnoxAuthenticationError from "../../../../Accounting/InvoiceDetails/FortnoxAuthenticationError";
import { useClinic } from "../../../../../contexts/ClinicContext";

interface Props {
  clinic?: ClinicResponse;
  isOpen: boolean;
  onHide: () => void;
}

export type PrevStateSetter = (prevState: ClinicResponse) => ClinicResponse;

const IntegrationModal: React.FC<Props> = ({ isOpen, onHide }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [deleteIntegrationLoading, setDeleteIntegrationLoading] =
    useState<boolean>(false);
  const [disableFortnoxLoading, setDisableFortnoxLoading] =
    useState<boolean>(false);
  const [addIntegrationLoading, setAddIntegrationLoading] =
    useState<boolean>(false);
  const [integrationSuccess, setIntegrationSuccess] = useState<boolean>(false);
  const [integrationOffSuccess, setIntegrationOffSuccess] = useState<
    string | undefined
  >();
  const [shouldReloadClinicOnClose, setShouldReloadClinicOnClose] =
    useState<boolean>(false);
  const { clinic, fetchClinic } = useClinic();

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<{
    apiKey: string;
    invoicingSystem: "fortnox" | "szamlazz" | undefined;
  }>({
    defaultValues: { apiKey: "", invoicingSystem: undefined },
    mode: "onChange",
  });

  const invoicingSystem = watch("invoicingSystem");

  useEffect(() => {
    if (clinic) {
      const isOnlyFortnoxAllowed = isFeatureAvailable(clinic, ClinicFeature.FORTNOX) && !isFeatureAvailable(clinic, ClinicFeature.SZAMLAZZ);
      const isOnlySzamlazzAllowed = !isFeatureAvailable(clinic, ClinicFeature.FORTNOX) && isFeatureAvailable(clinic, ClinicFeature.SZAMLAZZ);

      if (clinic.fortnoxEnabled || isOnlyFortnoxAllowed) {
        setValue("invoicingSystem", "fortnox", {
          shouldValidate: true,
          shouldDirty: false,
        });
      } else if (clinic.szamlazzEnabled || isOnlySzamlazzAllowed) {
        setValue("invoicingSystem", "szamlazz", {
          shouldValidate: true,
          shouldDirty: false,
        });
      }
    }
  }, [clinic, isOpen]);

  const addSzamlazzIntegration = async (
    request: SzamlazzIntegrationRequest
  ) => {
    if (clinic) {
      setAddIntegrationLoading(true);
      setIntegrationSuccess(false);
      try {
        await SzamlazzApi.addIntegration(clinic.id, request);
        fetchClinic(clinic.id);
        setIntegrationSuccess(true);
      } catch (error) {
        setError(await getGeneralError(error));
      } finally {
        setAddIntegrationLoading(false);
      }
    }
  };

  const disableFortnoxIntegration = async () => {
    setIntegrationSuccess(false);
    setIntegrationOffSuccess(undefined);

    const request: FortnoxIntegrationRequest = {
      enabling: false,
    };
    if (clinic) {
      setDisableFortnoxLoading(true);
      try {
        await FortnoxApi.enableFortnox(clinic?.id, request);
        fetchClinic(clinic.id);
        setIntegrationOffSuccess(strings.fortnox);
      } catch (error) {
        setError(await getGeneralError(error));
      } finally {
        setDisableFortnoxLoading(false);
      }
    }
  };

  const deleteSzamlazzIntegration = async () => {
    setIntegrationSuccess(false);
    setIntegrationOffSuccess(undefined);

    if (clinic) {
      setDeleteIntegrationLoading(true);
      try {
        await SzamlazzApi.deleteIntegration(clinic?.id);
        fetchClinic(clinic.id);
        setIntegrationOffSuccess(strings.szamlazz);
      } catch (error) {
        setError(await getGeneralError(error));
      } finally {
        setDeleteIntegrationLoading(false);
      }
    }
  };

  const handleClose = () => {
    if (shouldReloadClinicOnClose && clinic) {
      fetchClinic(clinic.id);
    }
    setIntegrationSuccess(false);
    setIntegrationOffSuccess(undefined);
    setError(null);
    reset();
    onHide();
  };

  const fortnoxComponent = (
    <div className="space-y-2">
      <img width="100px" alt="fortnoxLogo" src={fortnoxLogo} />
      {invoicingSystem === "fortnox" ? (
        <>
          <p className="text-sm">{strings.fortnoxRedirect}</p>

          {clinic?.fortnoxEnabled && clinic?.fortnoxAuthenticationRequired ? (
            <>
              <FortnoxAuthenticationError
                clinicId={clinic?.id}
                title={strings.failedToConnectToFortnox}
              />
            </>
          ) : (
            <Button
              disabled={
                clinic !== null &&
                !isFeatureAvailable(clinic, ClinicFeature.FORTNOX)
              }
              minW
              onClick={() => {
                window.open(
                  `/clinics/${clinic?.id}/fortnox-auth`,
                  "_blank",
                  "noreferrer"
                );
                setShouldReloadClinicOnClose(true);
              }}
            >
              {strings.continue}
            </Button>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );

  const szamlazzComponent = (
    <div className="space-y-2">
      <img width="120px" alt="fortnoxLogo" src={szamlazzLogo} />
      <div className="space-y-2" hidden={invoicingSystem !== "szamlazz"}>
        <p className="text-sm">
          {strings.formatString(
            strings.szamlazzIntegrationInfo1,
            <Link target="_blank" to={`/help/topics/${SupportTopics.szamlazz}`}>
              {" "}
              {strings.moreInformation}
            </Link>
          )}
        </p>
        <Field
          name="apiKey"
          label={strings.szamlazzApiKey}
          register={register}
          autoComplete={AutoCompleteOptions.off}
          required
          error={errors.apiKey}
        />
      </div>
    </div>
  );

  return (
    <Modal handleClose={handleClose} show={isOpen}>
      <Modal.Header title={strings.invoicing} />
      <Modal.Body>
        <AlertBox
          closeAble={false}
          message={strings.fortnoxIntegrationCostWarning}
          type={AlertType.INFO}
        />
        <form>
          {clinic &&
          isFeatureAvailable(clinic, ClinicFeature.FORTNOX) &&
          isFeatureAvailable(clinic, ClinicFeature.SZAMLAZZ) ? (
            <RadioButtonGroup
              control={control}
              name="invoicingSystem"
              options={[
                {
                  disabled: clinic?.fortnoxEnabled || clinic?.szamlazzEnabled,
                  value: "fortnox",
                  content: fortnoxComponent,
                },
                {
                  disabled: clinic?.fortnoxEnabled || clinic?.szamlazzEnabled,
                  value: "szamlazz",
                  content: szamlazzComponent,
                },
              ]}
              required
            />
          ) : (
            <></>
          )}
          {clinic &&
          isFeatureAvailable(clinic, ClinicFeature.FORTNOX) &&
          !isFeatureAvailable(clinic, ClinicFeature.SZAMLAZZ) ? (
            fortnoxComponent
          ) : (
            <></>
          )}
          {clinic &&
          !isFeatureAvailable(clinic, ClinicFeature.FORTNOX) &&
          isFeatureAvailable(clinic, ClinicFeature.SZAMLAZZ) ? (
            szamlazzComponent
          ) : (
            <></>
          )}
          {clinic &&
          !isFeatureAvailable(clinic, ClinicFeature.FORTNOX) &&
          !isFeatureAvailable(clinic, ClinicFeature.SZAMLAZZ) ? (
            <AlertBox closeAble={false} message={strings.noInvoicingSystemSupported} type={AlertType.INFO} />
          ) : (
            <></>
          )}
        </form>
        <AlertBox
          hidden={!integrationSuccess}
          message={strings.savedSuccessfully}
          type={AlertType.SUCCESS}
        />
        <AlertBox
          hidden={!integrationOffSuccess}
          message={
            integrationOffSuccess &&
            strings.formatString(
              strings.integrationTurnedOff,
              integrationOffSuccess
            )
          }
          type={AlertType.SUCCESS}
        />
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        {clinic?.fortnoxEnabled || clinic?.szamlazzEnabled ? (
          <Button
            disabled={deleteIntegrationLoading || disableFortnoxLoading}
            onClick={() => {
              if (clinic?.fortnoxEnabled) {
                void disableFortnoxIntegration();
              } else if (clinic?.szamlazzEnabled) {
                void deleteSzamlazzIntegration();
              }
            }}
            variant="danger"
          >
            {strings.turnOff}
          </Button>
        ) : (
          <Button onClick={handleClose} variant="secondary">
            {strings.close}
          </Button>
        )}
        {invoicingSystem === "fortnox" ? (
          <></>
        ) : (
          <Button
            className="modal-main-button"
            disabled={
              addIntegrationLoading ||
              !isValid ||
              !isDirty ||
              !clinic ||
              !isFeatureAvailable(clinic, ClinicFeature.SZAMLAZZ)
            }
            loading={addIntegrationLoading}
            onClick={handleSubmit(addSzamlazzIntegration)}
          >
            {strings.save}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default IntegrationModal;
