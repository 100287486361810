/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { InvoiceStatus } from "../../../models/invoice/InvoiceStatus";
import NumberFormatter from "../../../util/NumberFormatter";
import InvoiceActions from "../InvoiceDetails/InvoiceActions";
import { InvoicingMethod } from "../../../models/invoice/InvoicingMethod";
import { Tooltip } from "../../../components/Tooltip";
import { InvoiceType } from "../../../models/invoice/InvoiceType";
import { CostAccountingStatus } from "../../../models/costaccounting/CostAccountingStatus";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import Tag, { TagType } from "../../../components/Tag";
import {
  getInvoicingErrorMessage,
  getInvoicingSuccessMessage,
} from "../../../util/InvoiceSystemUtils";

interface Props {
  invoice: InvoiceResponse;
  setError(error: string): void;
}

export const getInvoiceState = (invoice: InvoiceResponse) => {
  let icon;

  if (invoice.invoicingMethod == InvoicingMethod.COST_CALCULATION) {
    return undefined;
  }

  switch (invoice.valid) {
    case false:
      icon = (
        <Tooltip content={getInvoicingErrorMessage(invoice)} placement="right">
          <div className="text-red-500">
            <ExclamationMark />
          </div>
        </Tooltip>
      );
      break;
    case true:
      icon = (
        <Tooltip
          content={getInvoicingSuccessMessage(invoice)}
          placement="right"
        >
          <div className="color-green">
            <CheckCircle />
          </div>
        </Tooltip>
      );
      break;
    default:
      icon = undefined;
      break;
  }

  return icon;
};

export const invoiceType = (invoice: InvoiceResponse) => {
  switch (invoice.type) {
    case InvoiceType.MEDICAL_RECORD:
      if (invoice.medicalRecordId) {
        return (
          <div className="d-flex align-items-center justify-content-start">
            <Link to={`/medical-record/${invoice.medicalRecordId}`}>
              {strings.medicalRecord}
            </Link>
          </div>
        );
      }
      return <>{strings.medicalRecord}</>;

    case InvoiceType.PRESCRIPTION:
      if (invoice.prescriptionId) {
        return (
          <div className="d-flex align-items-center justify-content-start">
            <Link to={`/prescriptions/${invoice.prescriptionId}`}>
              {strings.prescription}
            </Link>
          </div>
        );
      }
      return <>{strings.prescription}</>;

    default:
      return <></>;
  }
};

export const getCostAccountingState = (
  status: CostAccountingStatus | undefined
): ReactElement | undefined => {
  if (status === CostAccountingStatus.RESOLVED) {
    return <Tag type={TagType.success} text={strings.paid} />;
  }
  if (status === CostAccountingStatus.UNRESOLVED) {
    return <Tag type={TagType.danger} text={strings.notPaid} />;
  }
  if (status === CostAccountingStatus.WITHDRAWN) {
    return <Tag text={strings.withdrawn} />;
  }

  return undefined;
};

const InvoiceListRow: React.FC<Props> = ({ invoice, setError }: Props) => (
  <tr>
  <td
    className="px-4 py-3 text-sm font-medium whitespace-nowrap"
    hidden={invoice.status === InvoiceStatus.IN_PROGRESS}
  >
    <Link className="tw-link-dark" to={`/invoices/clinic/${invoice?.id}`} style={{ lineHeight: 0 }}>{invoice?.invoiceNumber}</Link>
  </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
      {invoice?.creationDate
        ? moment(invoice.creationDate).format(
            `${dateAndTime.momentDateFormat} HH:mm`
          )
        : ""}
    </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
      <div className="font-bold">
        {invoice.customer.fullName || invoice.customer.organizationName}
      </div>
      <div>{invoice.customer.email}</div>
    </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
      {invoice?.pet?.name}
    </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right">
      {NumberFormatter.formatPrice(invoice.totalCost, invoice.currency)}
    </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
      {invoiceType(invoice)}
    </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
      {getCostAccountingState(invoice?.costAccounting?.status)}
    </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
      {invoice.status !== InvoiceStatus.IN_PROGRESS && getInvoiceState(invoice)}
    </td>
    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right">
        <InvoiceActions
          invoice={invoice}
          reload={() => {}}
          setError={setError}
        />
    </td>
  </tr>
);

export default InvoiceListRow;
