/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ShiftApi from "../../../api/ShiftApi";
import { strings } from "../../../common/Strings/Strings";
import { useClinic } from "../../../contexts/ClinicContext";
import { DayOfWeek } from "../../../models/shift/DayOfWeek";
import { ShiftSchedulingRequest } from "../../../models/shift/ShiftSchedulingRequest";
import { EmployeeResponse } from "../../../models/user/EmployeeResponse";
import {
  getGeneralError,
  localDateFormat,
  mergeTimeAndDate,
} from "../../../util/helperFunctions";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import Switch from "../../../components/ReactHookFormFields/General/Switch";
import Field from "../../../components/ReactHookFormFields/General/Field";
import AlertBox from "../../../components/AlertBox";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";
import RadioButtonGroup from "../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";
import ShiftDateTimes from "./ShiftDateTimes";

interface Props {
  open: boolean;
  closeNewModal(): void;
  reload(): void;
  selectedTimeAndDate: {
    time: string;
    date: string;
  };
}

type EndType = "count" | "date";

interface FormData {
  collaboratorUser: Array<Pick<EmployeeResponse, "userId" | "fullName">> | null;
  endDate: Date;
  endTime: string;
  frequencyOfRecurrence: number;
  repeat: boolean;
  repeatCount: number;
  repetitionEndDate?: Date | null;
  repetitionType: EndType;
  startTime: string;
  startDate: Date;
  SUNDAY: boolean;
  MONDAY: boolean;
  TUESDAY: boolean;
  WEDNESDAY: boolean;
  THURSDAY: boolean;
  FRIDAY: boolean;
  SATURDAY: boolean;
}

const CalendarWeekNewShiftModal: React.FC<Props> = ({
  reload,
  open,
  selectedTimeAndDate,
  closeNewModal,
}: Props) => {
  const { clinic } = useClinic();
  const [showRepetition, setShowRepetition] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [dateTimeValidation, setDateTimeValidation] = useState<
    string | undefined
  >();
  const methods = useForm<FormData>({
    mode: "onChange",
    defaultValues: { collaboratorUser: null, repeat: false, repeatCount: 0, repetitionType: "count" },
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    reset,
    setError: setFormError,
    watch,
  } = methods;
  const isConfirmedEmployee = (e: EmployeeResponse) => !e.request;
  const { repetitionType } = watch();

  useEffect(() => {
    if (selectedTimeAndDate && open) {
      setValue("startDate", moment(selectedTimeAndDate.date).toDate());
      setValue("startTime", selectedTimeAndDate.time);

      const end = moment(
        mergeTimeAndDate(
          selectedTimeAndDate.time,
          new Date(selectedTimeAndDate.date)
        )
      ).add(8, "hours");
      setValue("endDate", end.toDate());
      setValue("endTime", end.format("HH:mm"));
    }

    setValue("collaboratorUser", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeAndDate, open]);

  useEffect(() => {
    if (clinic) {
      setOptions([
        {
          groupTitle: strings.assistants,
          groupOptions: [
            ...clinic.assistants
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
        {
          groupTitle: strings.authorizedAssistants,
          groupOptions: [
            ...clinic.authorizedAssistants
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
        {
          groupTitle: strings.receptionists,
          groupOptions: [
            ...clinic.receptionists
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
        {
          groupTitle: strings.vets,
          groupOptions: [
            ...clinic.vets
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  const closeModal = (): void => {
    if (error) {
      setError(null);
    }

    setValue("repetitionType", "count");
    setShowRepetition(false);
    reset();

    closeNewModal();
  };

  const getRepeatCount = (startDate: Date, endDate: Date) =>
    Math.ceil(moment(endDate).diff(startDate, "days") / 7);

  const submitAdd = async (data: FormData) => {
    if (!error) {
      setError(null);
    }

    setLoading(true);

    const daysForWeek: DayOfWeek[] = [];
    const d = new Date(data.startDate);

    if (data.MONDAY || d.getDay() === 1) {
      daysForWeek.push(DayOfWeek.MONDAY);
    }
    if (data.TUESDAY || d.getDay() === 2) {
      daysForWeek.push(DayOfWeek.TUESDAY);
    }
    if (data.WEDNESDAY || d.getDay() === 3) {
      daysForWeek.push(DayOfWeek.WEDNESDAY);
    }
    if (data.THURSDAY || d.getDay() === 4) {
      daysForWeek.push(DayOfWeek.THURSDAY);
    }
    if (data.FRIDAY || d.getDay() === 5) {
      daysForWeek.push(DayOfWeek.FRIDAY);
    }
    if (data.SATURDAY || d.getDay() === 6) {
      daysForWeek.push(DayOfWeek.SATURDAY);
    }
    if (data.SUNDAY || d.getDay() === 0) {
      daysForWeek.push(DayOfWeek.SUNDAY);
    }

    const startDateTime = mergeTimeAndDate(data.startTime, data.startDate);
    const endDateTime = mergeTimeAndDate(data.endTime, data.endDate);

    const postData: ShiftSchedulingRequest = {
      collaboratorUserId: data.collaboratorUser?.[0].userId ?? "",
      daysOfWeek: daysForWeek,
      endDate: data.repetitionEndDate
        ? moment(data.repetitionEndDate).format(localDateFormat())
        : undefined,
      minutes: moment(endDateTime).diff(startDateTime, "minutes"),
      repeatCount:
        data.repetitionType === "date" && data.repetitionEndDate
          ? getRepeatCount(data.startDate, data.repetitionEndDate)
          : data.repeatCount,
      startDateTime: moment(startDateTime).format(localDateFormat()),
    };

    try {
      if (clinic) {
        await ShiftApi.addShift(clinic?.id, postData);
        reload();
        closeModal();
      }
    } catch (e) {
      const generalError = await getGeneralError(e);
      if (generalError) {
        setError(generalError);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={closeModal} show={open}>
      <Modal.Header title={strings.newShift} />
      <Modal.Body>
      <FormProvider {...methods}>
        <form className="space-y-4 md:space-y-6">
          <CombinedSelect
            allowNew={false}
            control={control}
            label={strings.createShiftFor}
            labelKey="fullName"
            name="collaboratorUser"
            groupedByOptions={options}
            placeholder={strings.searchEmployee}
            required
            showRequired
          />
          <ShiftDateTimes setDateTimeValidation={setDateTimeValidation} />
          <div className="space-y-2">
            <label>{strings.repetition}</label>
            <div className="flex items-center">
              <Switch
                control={control}
                label={showRepetition ? strings.turnedOn : strings.turnedOff}
                name="repeat"
                onChange={(value: boolean) => {
                  setShowRepetition(value);
                }}
                order="IL"
              />
            </div>
            <div hidden={!showRepetition}>
              <div className="flex flex-wrap items-center space-x-3">
                <Controller
                  control={control}
                  name="MONDAY"
                  render={({ field: { onChange, value } }) => (
                    <Button
                      onClick={() => {
                        onChange(!value);
                      }}
                      variant={value ? "pill-tertiary" : "pill-secondary"}
                      small
                    >
                      {moment().day(1).format("ddd")}
                    </Button>
                  )}
                />
                <Controller
                  control={control}
                  name="TUESDAY"
                  render={({ field: { onChange, value } }) => (
                    <Button
                      onClick={() => {
                        onChange(!value);
                      }}
                      variant={value ? "pill-tertiary" : "pill-secondary"}
                      small
                    >
                      {moment().day(2).format("ddd")}
                    </Button>
                  )}
                />
                <Controller
                  control={control}
                  name="WEDNESDAY"
                  render={({ field: { onChange, value } }) => (
                    <Button
                      onClick={() => {
                        onChange(!value);
                      }}
                      variant={value ? "pill-tertiary" : "pill-secondary"}
                      small
                    >
                      {moment().day(3).format("ddd")}
                    </Button>
                  )}
                />
                <Controller
                  control={control}
                  name="THURSDAY"
                  render={({ field: { onChange, value } }) => (
                    <Button
                      onClick={() => {
                        onChange(!value);
                      }}
                      variant={value ? "pill-tertiary" : "pill-secondary"}
                      small
                    >
                      {moment().day(4).format("ddd")}
                    </Button>
                  )}
                />
                <Controller
                  control={control}
                  name="FRIDAY"
                  render={({ field: { onChange, value } }) => (
                    <Button
                      onClick={() => {
                        onChange(!value);
                      }}
                      variant={value ? "pill-tertiary" : "pill-secondary"}
                      small
                    >
                      {moment().day(5).format("ddd")}
                    </Button>
                  )}
                />
                <Controller
                  control={control}
                  name="SATURDAY"
                  render={({ field: { onChange, value } }) => (
                    <Button
                      onClick={() => {
                        onChange(!value);
                      }}
                      variant={value ? "pill-tertiary" : "pill-secondary"}
                      small
                    >
                      {moment().day(6).format("ddd")}
                    </Button>
                  )}
                />
                <Controller
                  control={control}
                  name="SUNDAY"
                  render={({ field: { onChange, value } }) => (
                    <Button
                      onClick={() => {
                        onChange(!value);
                      }}
                      variant={value ? "pill-tertiary" : "pill-secondary"}
                      small
                    >
                      {moment().day(0).format("ddd")}
                    </Button>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="space-y-3" hidden={!showRepetition}>
            <label>{strings.end}</label>
            <RadioButtonGroup
              control={control}
              name="repetitionType"
              options={[
                {
                  value: "count",
                  content: (
                    <div className="flex items-center">
                      <label className="mr-3 my-0">{strings.setRepforWeeks}</label>
                      <Field
                        error={errors.repeatCount}
                        fieldOptions={{
                          validate: {
                            positive: (v) =>
                              parseInt(v, 10) > 0 ||
                              !v ||
                              strings.numberMustBePositive,
                            isInteger: (v) =>
                              Number.isInteger(v) ||
                              !v ||
                              strings.numberMustBeAnInteger,
                          },
                        }}
                        labelOff
                        name="repeatCount"
                        register={register}
                        readOnly={repetitionType === "date"}
                        setError={setFormError}
                        step="1"
                        type="number"
                        width="100px"
                      />
                    </div>
                  ),
                },
                {
                  value: "date",
                  content: (
                    <div className="flex items-center space-y-2">
                      <label className="mr-3 my-0">{strings.onThisDate}</label>
                      <DatePickerInput
                        control={control}
                        labelOff
                        name="repetitionEndDate"
                        readOnly={repetitionType === "count"}
                      />
                    </div>
                  ),
                },
              ]}
              required
              verticalCenter
            />
          </div>
          <AlertBox className="mb-3" message={error} />
        </form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading || !!dateTimeValidation}
          loading={loading}
          onClick={handleSubmit(submitAdd)}
        >
          {strings.create}
        </Button>
        <CloseButton onClick={closeModal} />
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarWeekNewShiftModal;
