/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import RadioButton from "./RadioButton";

interface RadioOption {
  content?: ReactElement | ReactElement[];
  disabled?: boolean;
  helperText?: string;
  label?: string;
  value: string | number;
}

interface Props {
  control: Control<any>;
  name: string;
  className?: string;
  defaultValue?: string | number;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  onChange?(newValue: string | number): void;
  options: RadioOption[];
  required?: boolean;
  verticalCenter?: boolean;
}

const RadioButtonGroup = ({
  control,
  name: n,
  className = "flex flex-column",
  defaultValue,
  fieldOptions,
  onChange: customOnChange,
  options,
  required,
  verticalCenter
}: Props): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <div className={className}>
      <Controller
        name={n}
        control={control}
        rules={registerOptions}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {options.map((option: RadioOption, index: number) => (
              <div
                className={index < options.length - 1 ? "mb-5" : ""}
                key={index}
              >
                <RadioButton
                  content={option.content}
                  checked={option.value === value}
                  defaultValue={defaultValue === option.value}
                  disabled={option.disabled}
                  error={error}
                  id={option.value.toString()}
                  label={option.label}
                  helperText={option.helperText}
                  onChange={() => {
                    onChange(option.value);

                    if (customOnChange) {
                      customOnChange(option.value);
                    }
                  }}
                  verticalCenter={verticalCenter}
                />
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
};

export default RadioButtonGroup;
