/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { getDefaultVat, getGeneralError } from "../../../util/helperFunctions";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import AlertBox from "../../../components/AlertBox";
import { NewInventoryItemRequest } from "../../../models/inventory/item/Request/NewInventoryItemRequest";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import InventoryItemForm, {
  CreateInventoryItemForm,
} from "./InventoryItemForm";
import CountryApi from "../../../api/CountryApi";
import VaraItemModal from "../Modals/VaraItemModal";
import { VaraItem } from "../../../models/inventory/item/VaraItem";
import { useClinic } from "../../../contexts/ClinicContext";

const defaultFormValues = (
  countryDetails?: CountryDetailsResponse
): CreateInventoryItemForm => ({
  acquisitionUnit: InventoryItemUnit.PIECES,
  accountingCode: null,
  applicableToSpecificSpecies: false,
  barcode: undefined,
  categoryId: undefined,
  lowLevel: 0,
  name: undefined,
  species: [],
  varaNplPackId: undefined,
  varaNvn: undefined,

  acquisitionPrice: 0,
  acquisitionVat: getDefaultVat(countryDetails?.vatRates),
  expirationDate: undefined,
  quantity: 0,

  profitMargin: undefined,
  sellingPrice: 0,
  sellingVat: getDefaultVat(countryDetails?.vatRates) || 0,
});

const InventoryItemPage: React.FC = () => {
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [stepCount, setStepCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isStockStepSkipped, setIsStockStepSkipped] = useState<boolean>(false);
  const [useVara, setUseVara] = useState<boolean>(false);
  const [selectedVaraPackage, setSelectedVaraPackage] = useState<VaraItem>();

  const { itemId } = useParams<{
    itemId: string;
  }>();
  const methods = useForm<CreateInventoryItemForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(countryDetails),
  });
  const { handleSubmit, setValue } = methods;
  const { clinic } = useClinic();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.categoryId) {
      setValue("categoryId", location.state.categoryId);
    }
  }, [location?.state]);

  useEffect(() => {
    const getCountryDetails = async () => {
      if (clinic?.id) {
        try {
          const response = await CountryApi.getCountryDetailsOfClinic(
            clinic.id
          );
          setCountryDetails(response.data);
          setValue("sellingVat", getDefaultVat(response.data?.vatRates) || 0);
          setValue(
            "acquisitionVat",
            getDefaultVat(response.data?.vatRates) || 0
          );
        } catch (e) {
          setError(await getGeneralError(e));
        }
      }
    };

    void getCountryDetails();
  }, [clinic, setValue]);

  const submitForm = async (data: CreateInventoryItemForm) => {
    if (!data.categoryId || !data.name) return;

    const speciesValues = data.species.map((i) => i.value);
    const requestSpecies = Object.keys(PetSpecies)
      .filter((i) => speciesValues.includes(i))
      .map((i) => i as PetSpecies);

    const request: NewInventoryItemRequest = {
      acquisitionUnit: data.acquisitionUnit,
      accountingCode: data.accountingCode?.[0]?.code,
      barcode: data.barcode,
      lowLevel: data.lowLevel,
      name: data.name,
      species: requestSpecies,
      varaNplPackId: data.varaNplPackId,
      varaNvn: data.varaNvn,

      /* first stockrefill - optional */
      acquisitionPrice: data.acquisitionPrice,
      acquisitionVat: data.acquisitionVat,
      expirationDate: data.expirationDate,
      quantity: isStockStepSkipped ? undefined : data.quantity,

      sellingPrice: data.sellingPrice,
      sellingVat: data.sellingVat,
    };

    if (clinic?.id) {
      setLoading(true);
      try {
        const response = await InventoryItemApi.createItem(
          clinic.id,
          data.categoryId,
          request
        );
        navigate("/inventory", {
          state: {
            newItemName: response.data.name,
            newItemId: response.data.id,
          },
        });
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <form className="w-full md:max-w-lg md:tw-card space-y-6 md:p-8">
            <div className="flex justify-between space-x-4">
              <div>
                <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                  {itemId ? strings.editItem : strings.newItem}
                </h1>
              </div>
              <span className="text-gray-500 dark:text-gray-400">
                {stepCount}/{itemId ? 2 : 3}
              </span>
            </div>
            <FormProvider {...methods}>
              <InventoryItemForm
                clinicId={clinic?.id}
                countryDetails={countryDetails}
                submitLoading={loading}
                selectedVaraPackage={selectedVaraPackage}
                setIsStockStepSkipped={setIsStockStepSkipped}
                setStepCount={setStepCount}
                submit={handleSubmit(submitForm)}
                useVara={() => {
                  setUseVara(true);
                }}
              />
            </FormProvider>
            <AlertBox message={error} />
          </form>
        </div>
        <VaraItemModal
          close={() => {
            setUseVara(false);
          }}
          isOpen={useVara}
          returnSelectedVaraPackage={(p) => {
            setSelectedVaraPackage(p);
            setUseVara(false);
          }}
        />
      </section>
    </main>
  );
};

export default InventoryItemPage;
