/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { RefObject, useState } from "react";
import { Socket } from "socket.io-client";
import { PhoneXMark } from "../../common/Icons/PhoneXMark";
import { Microphone } from "../../common/Icons/Microphone";
import { UserPlus } from "../../common/Icons/UserPlus";
import { VideoCamera } from "../../common/Icons/VideoCamera";
import { Check } from "../../common/Icons/Check";
import { VideoCameraOff } from "../../common/Icons/VideoCameraOff";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import Tooltip from "../../components/Tooltip";
import Button from "../../components/Button";
import { strings } from "../../common/Strings/Strings";
import { copyAddressToClipboard, getMicrophoneErrorString, getWebcamErrorString, MediaErrors } from "./Utils";
import { Play } from "../../common/Icons/Play";
import { VideoConsultationRole } from "../../models/videoConsultation/VideoConsultationRole";
import { getAccessToken } from "../../util/LocalStorageVariables";
import { VideoChatUser } from "../../models/videoConsultation/VideoChatUser";
import { Pause } from "../../common/Icons/Pause";
import { ConsultationStatus } from "../../models/videoConsultation/ConsultationStatus";
import FinishConsultationModal from "./FinishConsultationModal";
import NewSessionModal from "./NewSessionModal";
import { Spinner } from "../../common/Icons/Spinner";

interface Props {
  camEnabled: boolean;
  camPermission: PermissionState | "unknown";
  consultationStatus: ConsultationStatus | null;
  disconnectFromRoom: () => void;
  getLocalUserConfig: () => VideoChatUser;
  hasCamera: boolean;
  hasMicrophone: boolean;
  mediaErrors: MediaErrors;
  micEnabled: boolean;
  micPermission: PermissionState | "unknown";
  payableCallTime: number;
  roles: VideoConsultationRole[];
  roomId?: string;
  showMediaErrorModal: () => void;
  showPermissionRequestModal: () => void;
  socketRef: RefObject<Socket | undefined>;
  toggleCam: () => void;
  toggleLoading: "mic" | "cam" | false;
  toggleMic: () => void;
  vetAndPetOwnerArePresent: boolean;
}
const CallControls: React.FC<Props> = ({
  camEnabled,
  camPermission,
  consultationStatus,
  disconnectFromRoom,
  getLocalUserConfig,
  hasCamera,
  hasMicrophone,
  mediaErrors,
  micEnabled,
  micPermission,
  payableCallTime,
  roles,
  roomId,
  showMediaErrorModal,
  showPermissionRequestModal,
  socketRef,
  toggleCam,
  toggleLoading,
  toggleMic,
  vetAndPetOwnerArePresent,
}: Props) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [toggleCamEnabled, setToggleCamEnabled] = useState(true);
  const [toggleMicEnabled, setToggleMicEnabled] = useState(true);

  const [newSessionModalOpen, setNewSessionModalOpen] = useState<boolean>(false);
  const [finishConsultationModalOpen, setFinishConsultationModalOpen] = useState<boolean>(false);

  // The consultation manager has the rights to change consultation state (start, pause, resume, finish)
  const isConsultationManager =
    roles.includes(VideoConsultationRole.VET) || roles.includes(VideoConsultationRole.MANAGER);

  const handleLinkCopyClick = () => {
    copyAddressToClipboard();
    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  const startConsultation = () => {
    if (!roomId || !socketRef.current) return;

    const accessToken = getAccessToken();

    socketRef.current.emit("startCall", roomId, getLocalUserConfig(), accessToken);
  };

  const pauseConsultation = () => {
    if (!roomId || !socketRef.current) return;

    const accessToken = getAccessToken();

    socketRef.current.emit("pauseCall", roomId, getLocalUserConfig(), accessToken);
  };

  const resumeConsultation = () => {
    if (!roomId || !socketRef.current) return;

    const accessToken = getAccessToken();

    socketRef.current.emit("resumeCall", roomId, getLocalUserConfig(), accessToken);
  };

  const handleStartButtonClick = () => {
    if (consultationStatus === ConsultationStatus.FINISHED) {
      setNewSessionModalOpen(true);
      return;
    }

    startConsultation();
  };

  const handleDisconnectButtonClick = () => {
    const canDisconnect =
      !isConsultationManager ||
      consultationStatus === ConsultationStatus.NOT_YET_STARTED ||
      consultationStatus === ConsultationStatus.FINISHED;

    if (canDisconnect) {
      disconnectFromRoom();
      return;
    }

    if (consultationStatus === ConsultationStatus.IN_PROGRESS) {
      pauseConsultation();
    }

    setFinishConsultationModalOpen(true);
  };

  const startButtonVisible =
    consultationStatus === ConsultationStatus.NOT_YET_STARTED || consultationStatus === ConsultationStatus.FINISHED;
  const pauseButtonVisible = consultationStatus === ConsultationStatus.IN_PROGRESS;
  const resumeButtonVisible = consultationStatus === ConsultationStatus.PAUSED;

  return (
    <>
      <div className="video-consultation-btn-group">
        {/* Start consultation button */}
        {isConsultationManager && startButtonVisible && (
          <Tooltip content={strings.startConsultation} placement="top-start">
            <Button
              disabled={!vetAndPetOwnerArePresent}
              onClick={handleStartButtonClick}
              variant={vetAndPetOwnerArePresent ? "video-consultation-green" : "video-consultation-disabled"}
            >
              <Play className="w-8 h-8" variant="outline" />
            </Button>
          </Tooltip>
        )}

        {/* Pause consultation button */}
        {isConsultationManager && pauseButtonVisible && (
          <Tooltip content={strings.pauseConsultation} placement="top-start">
            <Button onClick={pauseConsultation} variant="video-consultation-orange">
              <Pause className="w-8 h-8" variant="outline" />
            </Button>
          </Tooltip>
        )}

        {/* Resume consultation button */}
        {isConsultationManager && resumeButtonVisible && (
          <Tooltip content={strings.startConsultation} placement="top-start">
            <Button
              disabled={!vetAndPetOwnerArePresent}
              onClick={resumeConsultation}
              variant={vetAndPetOwnerArePresent ? "video-consultation-green" : "video-consultation-disabled"}
            >
              <Play className="w-8 h-8" variant="outline" />
            </Button>
          </Tooltip>
        )}

        {/* Copy Meeting Link Button */}
        <Tooltip
          content={linkCopied ? strings.linkCopied : strings.copyMeetingLinkCopiedToClipboard}
          placement="top-start"
        >
          <Button onClick={handleLinkCopyClick} variant="video-consultation">
            {linkCopied ? (
              <Check className="w-8 h-8" variant="outline" />
            ) : (
              <UserPlus className="w-8 h-8" variant="outline" />
            )}
          </Button>
        </Tooltip>

        {/* Toggle Camera Button */}
        <Tooltip
          content={camEnabled ? strings.disableCamera : strings.enableCamera}
          hidden={!hasCamera || camPermission === "denied" || mediaErrors.camError}
          placement="top-start"
        >
          <div className="relative">
            <Button
              disabled={!hasCamera || camPermission === "denied" || !toggleCamEnabled || toggleLoading === "cam"}
              onClick={async () => {
                setToggleCamEnabled(false);
                await toggleCam();
                setToggleCamEnabled(true);
              }}
              variant="video-consultation-gray"
            >
              {toggleLoading === "cam" ? (
                <Spinner className="w-8 h-8" variant="outline" />
              ) : camEnabled ? (
                <VideoCamera className="w-8 h-8" variant="outline" />
              ) : (
                <VideoCameraOff className="w-8 h-8" variant="outline" />
              )}
            </Button>
            {(!hasCamera || camPermission === "denied") && (
              <div className="absolute top-0 -right-2">
                <Tooltip content={getWebcamErrorString(hasCamera, camPermission)} placement="top">
                  <div onClick={() => hasCamera && showPermissionRequestModal()} role="button" tabIndex={-1}>
                    <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" variant="outline" />
                  </div>
                </Tooltip>
              </div>
            )}
            {mediaErrors.camError && (
              <div className="absolute top-0 -right-2">
                <Tooltip content={strings.mediaErrorTooltip} placement="top">
                  <div onClick={showMediaErrorModal} role="button" tabIndex={-1}>
                    <ExclamationMark className="ml-1 text-red-700 dark:text-red-400" />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </Tooltip>

        {/* Toggle Microphone Button */}
        <Tooltip
          content={micEnabled ? strings.disableMicrophone : strings.enableMicrophone}
          hidden={!hasMicrophone || micPermission === "denied" || mediaErrors.micError}
          placement="top-start"
        >
          <div className="relative">
            <Button
              disabled={!hasMicrophone || micPermission === "denied" || !toggleMicEnabled || toggleLoading === "mic"}
              onClick={async () => {
                setToggleMicEnabled(false);
                await toggleMic();
                setToggleMicEnabled(true);
              }}
              variant="video-consultation-gray"
            >
              {toggleLoading === "mic" ? (
                <Spinner className="w-8 h-8" variant="outline" />
              ) : micEnabled ? (
                <Microphone className="w-8 h-8" variant="outline" />
              ) : (
                <MicrophoneOff className="w-8 h-8" variant="outline" />
              )}
            </Button>
            {(!hasMicrophone || micPermission === "denied") && (
              <div className="absolute top-0 -right-2">
                <Tooltip content={getMicrophoneErrorString(hasMicrophone, micPermission)} placement="top">
                  <div onClick={() => hasMicrophone && showPermissionRequestModal()} role="button" tabIndex={-1}>
                    <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
                  </div>
                </Tooltip>
              </div>
            )}
            {mediaErrors.micError && (
              <div className="absolute top-0 -right-2">
                <Tooltip content={strings.mediaErrorTooltip} placement="top">
                  <div onClick={showMediaErrorModal} role="button" tabIndex={-1}>
                    <ExclamationMark className="ml-1 text-red-700 dark:text-red-400" />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </Tooltip>

        {/* Disconnect Button */}
        <Tooltip content={strings.leaveMeeting} placement="top-start">
          <Button className="px-4" onClick={handleDisconnectButtonClick} variant="video-consultation-red">
            <PhoneXMark className="w-8 h-8" variant="outline" />
          </Button>
        </Tooltip>
      </div>
      <NewSessionModal
        onHide={() => setNewSessionModalOpen(false)}
        show={newSessionModalOpen}
        startConsultation={startConsultation}
      />
      <FinishConsultationModal
        disconnectFromRoom={disconnectFromRoom}
        getLocalUserConfig={getLocalUserConfig}
        onHide={() => setFinishConsultationModalOpen(false)}
        payableCallTime={payableCallTime}
        roomId={roomId}
        show={finishConsultationModalOpen}
        socketRef={socketRef}
      />
    </>
  );
};

export default CallControls;
