/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import Button from "../../../components/Button";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { PageProps } from "../../../models/PageProps";
import { useClinic } from "../../../contexts/ClinicContext";
import { InventoryItemDetailsResponse } from "../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import IconBubble from "../../../components/IconBubble";
import { Loader } from "../../../components/Loader";
import { InventoryItemStockItemResponse } from "../../../models/inventory/item/Response/InventoryItemStockItemResponse";
import CountingRow, { CountingFormRowProps } from "./CountingRow";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import NumberFormatter from "../../../util/NumberFormatter";
import { Cog } from "../../../common/Icons/Cog";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import ItemStockModal from "../Stock/ItemStockModal";
import LoaderInline from "../../../components/LoaderInline";
import CountryApi from "../../../api/CountryApi";
import { InventoryItemStockCountingRequest } from "../../../models/inventory/item/Request/InventoryItemStockCountingRequest";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";

export interface InventoryCountingFormProps {
  comment?: string;
  stocks: Array<CountingFormRowProps>;
}

const ItemCountingPage: React.FC<PageProps> = ({
  setPageLoading,
}: PageProps) => {
  const [inventoryItem, setInventoryItem] =
    useState<InventoryItemDetailsResponse>();
  const [availableStocks, setAvailableStocks] =
    useState<InventoryItemStockItemResponse[]>();
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [openStockModal, setOpenStockModal] = useState<boolean>(false);
  const [reloadAvailableStocks, setReloadAvailableStocks] =
    useState<boolean>(false);

  const methods = useForm<InventoryCountingFormProps>({
    mode: "onChange",
    defaultValues: { stocks: [] },
  });
  const { clinic } = useClinic();
  const { itemId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getCountryDetails = async () => {
      if (clinic?.id) {
        try {
          const response = await CountryApi.getCountryDetailsOfClinic(
            clinic.id
          );
          setCountryDetails(response.data);
        } catch (e) {
          setError(await getGeneralError(e));
        }
      }
    };

    void getCountryDetails();
  }, [clinic]);

  useEffect(() => {
    const getInventoryItemDetails = async () => {
      if (!clinic?.id || !itemId) return;
      try {
        const response = await InventoryItemApi.getItem(itemId);
        setInventoryItem(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    void getInventoryItemDetails();
  }, [clinic, itemId, reloadAvailableStocks, setPageLoading]);

  useEffect(() => {
    const getAvailableStocks = async () => {
      if (!itemId) {
        return;
      }
      setLoading(true);
      try {
        const response = await InventoryItemApi.getAvailableStocks(itemId);
        setAvailableStocks(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    };

    void getAvailableStocks();
  }, [itemId, reloadAvailableStocks]);

  const triggerReload = (successMessage?: string) => {
    setLoading(true);
    setSuccessMessage(null);
    if (successMessage) {
      setSuccessMessage(successMessage);
    }
    setReloadAvailableStocks((prev) => !prev);
  };

  const submit = async (values: InventoryCountingFormProps) => {
    if (inventoryItem) {
      setLoading(true);

      const request: InventoryItemStockCountingRequest = {
        comment: values.comment,
        stocks: values.stocks.map(
          ({
            acquisitionPrice,
            acquisitionVat,
            expirationDate,
            isTheSame,
            newQuantity,
            oldQuantity,
            comment,
          }: CountingFormRowProps) => ({
            acquisitionPrice,
            acquisitionVat,
            expirationDate,
            quantity: isTheSame === "same" ? oldQuantity : newQuantity,
            comment,
          })
        ),
      };

      try {
        await InventoryItemApi.addItemCounting(inventoryItem.id, request);
        navigate(-1);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const sumCountings = () => {
    let sum = 0;
    methods.watch("stocks")?.forEach((stock) => {
      if (stock.isTheSame === "same") {
        sum += stock.oldQuantity;
      } else if (stock.isTheSame === "diff") {
        sum += stock.newQuantity;
      }
    });

    return sum;
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div>
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.countInventory}
            </h1>
          </div>
          <div className="tw-card p-4 flex items-center">
            <h2 className="grow text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
              <Link
                className="tw-link-dark"
                to={`/inventory/item/${inventoryItem?.id}/details`}
              >
                {inventoryItem?.name}
              </Link>
            </h2>
            <div className="ml-auto">
              <IconBubble>
                {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
              </IconBubble>
            </div>
          </div>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
            {strings.orderHistory}
          </h2>
          <div>
            <div className="mr-2">
              {strings.currentStock}:{" "}
              <b>
                {NumberFormatter.format(inventoryItem?.stock || 0)}{" "}
                {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
              </b>
            </div>
          </div>
          <AlertBox message={error} />
          <AlertBox message={successMessage} type={AlertType.SUCCESS} />
          {loading ? (
            <LoaderInline />
          ) : (
            <FormProvider {...methods}>
              <form
                className="space-y-6"
                onSubmit={methods.handleSubmit((v) => {
                  void submit(v);
                })}
              >
                <div className="space-y-4 divide-y dark:divide-gray-700">
                  {availableStocks?.map((stock, index) => (
                    <CountingRow
                      acquisitionUnit={inventoryItem?.acquisitionUnit}
                      countryDetails={countryDetails}
                      index={index}
                      stock={stock}
                    />
                  ))}
                </div>
                <div>
                  <Button
                    variant="link"
                    onClick={() => {
                      setOpenStockModal(true);
                    }}
                  >
                    <div className="flex">
                      <PlusIcon /> <span>{strings.addMissingStock}</span>
                    </div>
                  </Button>
                </div>
                <div className="grow grid xl:grid-cols-12 xl:gap-4">
                  <div className="xl:col-span-8" />
                  <div className="xl:col-span-4 space-y-6">
                    <div>
                      <div>
                        {strings.currentStock}:{" "}
                        {NumberFormatter.format(inventoryItem?.stock || 0)}{" "}
                        {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                      </div>
                      <div className="py-1">
                        {strings.countedStock}:{" "}
                        <b>
                          {NumberFormatter.format(sumCountings())}{" "}
                          {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                        </b>
                      </div>
                      <div className="border-t dark:border-gray-700">
                        {strings.countingDifference}:{" "}
                        <b>
                          {NumberFormatter.format(
                            (inventoryItem?.stock || 0) - sumCountings()
                          )}{" "}
                          {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                        </b>
                      </div>
                    </div>
                    <Textarea
                      label={strings.comment}
                      name="comment"
                      optional
                      register={methods.register}
                      maxLength={200}
                    />
                    <Button type="submit">{strings.finishCounting}</Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
        <ItemStockModal
          close={(shouldReloadItem: boolean, successMessage?: string) => {
            setOpenStockModal(false);
            if (shouldReloadItem) {
              triggerReload(successMessage);
            }
          }}
          countryDetails={countryDetails}
          inventoryItem={inventoryItem}
          isOpen={openStockModal}
        />
      </section>
    </main>
  );
};

export default Loader(ItemCountingPage);
