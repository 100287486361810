/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import CloseButton from "../../components/CloseButton";
import AlertBox, { AlertType } from "../../components/AlertBox";
import Field from "../../components/ReactHookFormFields/General/Field";
import EmployeeSelect, {
  findEmployee,
  SimpleEmployee,
} from "../../components/ReactHookFormFields/Specific/EmployeeSelect";
import { ClinicPetAndOwnerResponse } from "../../models/pet/ClinicPetAndOwnerResponse";
import SelectedPetOrOwnerCard from "./SelectedPetOrOwnerCard";
import { NewTodoRequest } from "../../models/todo/NewTodoRequest";
import TodoApi from "../../api/TodoApi";
import { useClinic } from "../../contexts/ClinicContext";
import Textarea from "../../components/ReactHookFormFields/General/Textarea";
import ColourSelect from "../../components/ReactHookFormFields/General/Select/ColourSelect";
import { Colors } from "../../models/Colors";
import DatePickerInput from "../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import {
  getGeneralError,
  localDateTimeStringOf,
} from "../../util/helperFunctions";
import { TodoResponse } from "../../models/todo/TodoResponse";
import { ClinicPetResponse } from "../../models/pet/ClinicPetResponse";
import { PetOwnerResponse } from "../../models/pet/PetOwnerResponse";
import CheckBox from "../../components/ReactHookFormFields/General/CheckBox";
import { UpdateTodoRequest } from "../../models/todo/UpdateTodoRequest";
import { MedicalRecordTodoResponse } from "../../models/todo/MedicalRecordTodoResponse";
import PetSearchSection from "../Calendar/NewReservation/PetSearchSection";
import Button from "../../components/Button";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import Modal from "../../components/Modal/Modal";
import ModalButtonGroup from "../../components/Modal/ModalButtonGroup";

interface Props {
  changeState(): void;
  modifyInput?: TodoResponse;
  onHide: (isSuccess?: boolean) => void;
  show: boolean;
  todoForMedicalRecord?: MedicalRecordTodoResponse;
  todoForPet?: ClinicPetAndOwnerResponse;
  todoForPetOwner?: PetOwnerResponse;
}

interface TodoForm {
  color?: string;
  completed?: boolean;
  description?: string;
  dueDate?: Date | null;
  employee?: SimpleEmployee[];
  title: string;
}

const defaultValues = {
  color: Colors.PRIMARY,
  completed: false,
  description: "",
  dueDate: null,
  employee: [],
  title: "",
};

const NewTodoModal = ({
  changeState,
  modifyInput,
  onHide,
  show,
  todoForPetOwner,
  todoForPet,
  todoForMedicalRecord,
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [modifyTodo, setModifyTodo] = useState<TodoResponse | undefined>(
    modifyInput
  );
  const [selectedPet, setSelectedPet] = useState<ClinicPetResponse>();
  const [selectedPetOwner, setSelectedPetOwner] = useState<PetOwnerResponse>();
  const [usedMedicalRecord, setUsedMedicalRecord] =
    useState<MedicalRecordTodoResponse>();
  const [isDescriptionVisible, setIsDescriptionVisible] = useState<boolean>();
  const [isDueDateVisible, setIsDueDateVisible] = useState<boolean>();
  const [isColourVisible, setIsColourVisible] = useState<boolean>();
  const [success, setSuccess] = useState<string>();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<TodoForm>({ defaultValues });
  const { clinic } = useClinic();

  useEffect(() => {
    if (show) {
      setModifyTodo(modifyInput);
      setError(null);
      setSuccess(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    let assignedEmployee: SimpleEmployee | undefined;

    if (modifyTodo?.assignee && clinic) {
      assignedEmployee = findEmployee(clinic, modifyTodo.assignee.userId);
    }

    if (modifyTodo?.linkedMedicalRecord) {
      setSelectedPet(modifyTodo.linkedMedicalRecord?.pet);
      setSelectedPetOwner(modifyTodo.linkedMedicalRecord?.petOwner);
      setUsedMedicalRecord(modifyTodo.linkedMedicalRecord);
    } else if (modifyTodo?.linkedPet) {
      setSelectedPet(modifyTodo.linkedPet.pet);
      setSelectedPetOwner(modifyTodo.linkedPet.petOwner);
    } else if (modifyTodo?.linkedPetOwner) {
      setSelectedPetOwner(modifyTodo.linkedPetOwner);
    } else {
      setSelectedPet(undefined);
      setSelectedPetOwner(undefined);
    }

    reset({
      color: modifyTodo?.color,
      completed: modifyTodo?.completed,
      dueDate: modifyTodo?.dueDate
        ? moment(modifyTodo?.dueDate).toDate()
        : null,
      title: modifyTodo?.title,
      description: modifyTodo?.description,
      employee: assignedEmployee ? [assignedEmployee] : undefined,
    });

    if (!modifyTodo) {
      if (todoForMedicalRecord) {
        setSelectedPetOwner(todoForMedicalRecord.petOwner);
        setSelectedPet(todoForMedicalRecord.pet);
        setUsedMedicalRecord(todoForMedicalRecord);
      } else if (todoForPet) {
        setSelectedPet(todoForPet.pet);
        setSelectedPetOwner(todoForPet.petOwner);
      } else if (todoForPetOwner) {
        setSelectedPetOwner(todoForPetOwner);
      }
    }

    setIsDescriptionVisible(!!modifyTodo?.description);
    setIsDueDateVisible(!!modifyTodo?.dueDate);
    setIsColourVisible(!!modifyTodo?.color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyTodo, clinic, todoForMedicalRecord, todoForPet, todoForPetOwner]);

  const create = async ({
    color,
    description,
    dueDate,
    title,
    employee,
  }: TodoForm) => {
    if (clinic) {
      setLoading(true);
      setError(null);
      setSuccess(undefined);

      const request: NewTodoRequest = {
        color,
        description,
        dueDate: dueDate ? localDateTimeStringOf(dueDate) : undefined,
        title,
        assigneeUserId: employee?.[0]?.userId,
      };

      /* Decide here the task recipient: medicalRecord/pet/petOwner */
      if (usedMedicalRecord) {
        request.linkedMedicalRecordId =
          usedMedicalRecord.universalMedicalRecordId;
      } else if (selectedPet) {
        request.linkedPetId = selectedPet?.clinicPetDetails.id;
      } else if (selectedPetOwner) {
        request.linkedPetOwnerId = selectedPetOwner.petOwnerDetails.id;
      }

      try {
        const result = await TodoApi.addTodo(clinic.id, request);
        setModifyTodo(result.data);
        setSuccess(strings.taskCreatedSuccessfully);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const update = async ({
    color,
    completed,
    description,
    dueDate,
    title,
    employee,
  }: TodoForm) => {
    if (clinic && modifyTodo) {
      setLoading(true);
      setError(null);
      setSuccess(undefined);

      const request: UpdateTodoRequest = {
        color,
        completed: completed || false,
        description,
        dueDate: dueDate ? localDateTimeStringOf(dueDate) : undefined,
        title,
        assigneeUserId: employee?.[0]?.userId,
      };

      /* Decide here the task recipient: medicalRecord/pet/petOwner */
      if (usedMedicalRecord) {
        request.linkedMedicalRecordId =
          usedMedicalRecord.universalMedicalRecordId;
      } else if (selectedPet) {
        request.linkedPetId = selectedPet?.clinicPetDetails.id;
      } else if (selectedPetOwner) {
        request.linkedPetOwnerId = selectedPetOwner.petOwnerDetails.id;
      }

      try {
        const response = await TodoApi.updateTodo(
          clinic.id,
          modifyTodo.id,
          request
        );
        setModifyTodo(response.data);
        setSuccess(strings.taskSavedSuccessfully);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    setModifyTodo(undefined);

    if (todoForMedicalRecord) {
      setSelectedPetOwner(todoForMedicalRecord.petOwner);
      setSelectedPet(todoForMedicalRecord.pet);
      setUsedMedicalRecord(todoForMedicalRecord);
    } else if (todoForPet) {
      setSelectedPet(todoForPet.pet);
      setSelectedPetOwner(todoForPet.petOwner);
    } else if (todoForPetOwner) {
      setSelectedPet(undefined);
      setSelectedPetOwner(todoForPetOwner);
    }

    onHide(!!success);
  };

  return (
    <Modal handleClose={handleClose} show={show} size="modal-lg">
      <Modal.Header title={!modifyTodo ? strings.newTask : strings.editTask} />
      <Modal.Body>
        <form>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-8 space-y-4">
              <Field
                error={errors.title}
                label={strings.title}
                name="title"
                register={register}
                required
                showRequired
              />
              <div hidden={!selectedPet && !selectedPetOwner}>
                <SelectedPetOrOwnerCard
                  handleClose={() => {
                    setSelectedPet(undefined);
                    setSelectedPetOwner(undefined);
                    setUsedMedicalRecord(undefined);
                  }}
                  selectedPet={selectedPet}
                  selectedPetOwner={selectedPetOwner}
                  todoForMedicalRecord={
                    usedMedicalRecord || modifyTodo?.linkedMedicalRecord
                  }
                />
              </div>
              <div hidden={!!selectedPetOwner || !!selectedPet}>
                <PetSearchSection
                  onSelectPet={(petAndOwner?: ClinicPetAndOwnerResponse) => {
                    if (petAndOwner) {
                      setSelectedPetOwner(petAndOwner.petOwner);
                      setSelectedPet(petAndOwner.pet);
                    }
                  }}
                  onSelectOwner={(petOwner: PetOwnerResponse) => {
                    setSelectedPetOwner(petOwner);
                  }}
                />
              </div>
              <EmployeeSelect
                allowNew={false}
                control={control}
                label={strings.employee}
                name="employee"
                placeholder={strings.searchEmployee}
              />
              <div hidden={!isColourVisible}>
                <ColourSelect
                  control={control}
                  label={strings.color}
                  name="color"
                  options={[
                    "#98E2B0",
                    "#E9DA52",
                    "#FDAE7B",
                    "#F56464",
                    "#DC8FDE",
                    Colors.PRIMARY,
                  ]}
                />
              </div>
              <div hidden={!isDueDateVisible}>
                <DatePickerInput
                  control={control}
                  label={strings.dueDate}
                  name="dueDate"
                />
              </div>
              <div hidden={!isDescriptionVisible}>
                <Textarea
                  label={strings.description}
                  minRows={2}
                  name="description"
                  register={register}
                />
              </div>
              <div hidden={!modifyTodo}>
                <CheckBox
                  label={strings.completed}
                  name="completed"
                  register={register}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 divide-y dark:divide-gray-700">
              <div>{strings.addToTask}</div>
              <div className="pt-1">
                <div hidden={isColourVisible}>
                  <Button
                    variant="link"
                    onClick={() => {
                      setIsColourVisible(true);
                    }}
                  >
                    <div className="flex items-center">
                      <PlusIcon className="w-5 h-5" />
                      <div>{strings.color}</div>
                    </div>
                  </Button>
                </div>
                <div hidden={isDueDateVisible}>
                  <Button
                    variant="link"
                    onClick={() => {
                      setIsDueDateVisible(true);
                    }}
                  >
                    <div className="flex items-center">
                      <PlusIcon className="w-5 h-5" />
                      <div>{strings.dueDate}</div>
                    </div>
                  </Button>
                </div>
                <div hidden={isDescriptionVisible}>
                  <Button
                    variant="link"
                    onClick={() => {
                      setIsDescriptionVisible(true);
                    }}
                  >
                    <div className="flex items-center">
                      <PlusIcon className="w-5 h-5" />
                      <div>{strings.description}</div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <AlertBox
          className="mt-4"
          closeAble={false}
          message={success}
          type={AlertType.SUCCESS}
        />
        <AlertBox className="mt-4" closeAble={false} message={error} />
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={handleClose} text={strings.cancel} />
        <ModalButtonGroup>
          {modifyTodo ? (
            <Button
              className="modal-main-button"
              disabled={loading}
              onClick={handleSubmit(update)}
            >
              {strings.saveChanges}
            </Button>
          ) : (
            <Button
              className="modal-main-button"
              disabled={loading || !isDirty}
              onClick={handleSubmit(create)}
            >
              {strings.saveAndCreate}
            </Button>
          )}
          <Button
            className="modal-sub-button"
            hidden={!modifyTodo}
            variant="danger"
            onClick={changeState}
          >
            {strings.deleteTask}
          </Button>
        </ModalButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTodoModal;
