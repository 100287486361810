/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import { clinicFeeUnits, strings } from "../../../common/Strings/Strings";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { usePager } from "../../../hooks/hooks";
import { paginate } from "../../../util/Paginator";
import { ExtraCost } from "../../../models/medical/ExtraCost";
import VideoConsultationApi from "../../../api/VideoConsultationApi";
import ClinicApi from "../../../api/ClinicApi";
import { getGeneralError } from "../../../util/helperFunctions";
import CountryApi from "../../../api/CountryApi";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { VideoConsultationResponse } from "../../../models/videoConsultation/VideoConsultationResponse";
import LoaderInline from "../../../components/LoaderInline";
import CostCalculator from "../../../util/CostCalculator";
import NumberFormatter from "../../../util/NumberFormatter";
import { formatCallTimeSeconds } from "../../VideoConsultation/Utils";

interface Props {
  medicalRecord?: MedicalRecordResponse;
  videoConsultationId: string;
}

const SessionHistory: React.FC<Props> = ({
  medicalRecord,
  videoConsultationId,
}: Props) => {
  const [clinic, setClinic] = useState<ClinicResponse | null>(null);
  const [videoConsultationInfo, setVideoConsultationInfo] =
    useState<VideoConsultationResponse | null>(null);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pager = usePager(10);

  useEffect(() => {
    const fetchReservationData = async () => {
      setLoading(true);
      try {
        const response = await VideoConsultationApi.getVideoConsultation(
          videoConsultationId
        );
        setVideoConsultationInfo(response.data);

        const clinicResponse = await ClinicApi.getClinic(
          response.data.reservation.clinicId
        );
        setClinic(clinicResponse.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchReservationData();
  }, [videoConsultationId]);

  useEffect(() => {
    if (!clinic) return;

    const fetchCountryDetails = async () => {
      setLoading(true);
      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinic.id);
        setCountryDetails(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchCountryDetails();
  }, [clinic]);

  if (!medicalRecord || !medicalRecord.extraCosts) {
    return null;
  }

  const renderEventRow = (cost: ExtraCost) => {
    const clinicGrossPrice = cost.price
      ? CostCalculator.getGrossPrice(
          cost.price,
          videoConsultationInfo?.fee.vat,
          countryDetails ?? undefined
        )
      : null;

    return (
      <tr key={cost.id}>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          <p>{cost.name}</p>
        </td>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          <p>
            {cost.extraData?.unit ? clinicFeeUnits[cost.extraData.unit] : "-"}
          </p>
        </td>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          <p>
            {cost.extraData?.durationInSeconds
              ? formatCallTimeSeconds(cost.extraData.durationInSeconds)
              : "-"}
          </p>
        </td>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          <p>
            {clinicGrossPrice
              ? NumberFormatter.formatPrice(
                  clinicGrossPrice,
                  countryDetails?.currency
                )
              : "-"}
          </p>
        </td>
        <td className="px-4 py-4 text-sm whitespace-nowrap">
          <p>{`${cost.vat}%`}</p>
        </td>
      </tr>
    );
  };

  const renderAlertBox = () => (
    <AlertBox
      closeAble={false}
      message={
        <div className="flex flex-row">
          <p>{strings.thereAreNoPreviousSessions}</p>
        </div>
      }
      type={AlertType.INFO}
    />
  );

  // Filter out all the extra costs that contains localized "Video consultation" string in the name
  const videoConsultations = medicalRecord.extraCosts.filter((c) =>
    c.name.includes(strings.videoConsultation)
  );

  // Reverse the list, so that the newest consultation is the first element
  const videoConsultationReversed = videoConsultations.reverse();

  // Paginate the elements
  const pagedElements = paginate(
    videoConsultationReversed,
    pager.pageNumber,
    pager.pageSize
  );

  if (loading) return <LoaderInline />;
  if (error) return <AlertBox message={error} />;

  return (
    <>
      {medicalRecord.extraCosts.length === 0 && renderAlertBox()}

      <PageableTable
        content={pagedElements}
        hidden={medicalRecord?.extraCosts.length === 0}
        isLoading={false}
        pager={pager}
      >
        <PageableTableHead>
          <tr>
            <th className="text-left tw-th">{strings.name}</th>
            <th className="text-left tw-th">{strings.unit}</th>
            <th className="text-left tw-th">{strings.finalPayableCallTime}</th>
            <th className="text-left tw-th">{strings.price}</th>
            <th className="text-left tw-th">{strings.vat}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {pagedElements.elements.map(renderEventRow)}
        </PageableTableBody>
      </PageableTable>
    </>
  );
};

export default SessionHistory;
