/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import TimeSelect from "../../../components/ReactHookFormFields/General/Select/TimeSelect";
import AlertBox from "../../../components/AlertBox";
import { mergeTimeAndDate } from "../../../util/helperFunctions";

interface Props {
  setDateTimeValidation: Dispatch<SetStateAction<string | undefined>>;
}

const ShiftDateTimes: React.FC<Props> = ({
  setDateTimeValidation: outsideSetDateTimeValidation,
}: Props) => {
  const { control, watch } = useFormContext();
  const [dateTimeValidation, setDateTimeValidation] = useState<
    string | undefined
  >();
  const { startDate, startTime, endDate, endTime } = watch();

  useEffect(() => {
    outsideSetDateTimeValidation(dateTimeValidation);
  }, [dateTimeValidation, outsideSetDateTimeValidation]);

  useEffect(() => {
    if (startDate && startTime && endDate && endTime) {
      const start = mergeTimeAndDate(startTime, startDate);
      const end = mergeTimeAndDate(endTime, endDate);

      if (Math.abs(moment(start).diff(end, "minutes")) > 1440) {
        setDateTimeValidation(strings.tooLongShiftErrorMsg);
      } else if (moment(start).isSameOrAfter(end)) {
        setDateTimeValidation(strings.startDateEndDateErrorMsg);
      } else {
        setDateTimeValidation(undefined);
      }
    }
  }, [startDate, startTime, endDate, endTime]);

  return (
    <>
      <div>
        <label>{strings.startOfShift} *</label>
        <div className="flex items-center space-x-4">
          <div className="grow">
            <DatePickerInput
              control={control}
              labelOff
              name="startDate"
              required
            />
          </div>
          <TimeSelect control={control} labelOff name="startTime" required />
        </div>
      </div>
      <div>
        <label>{strings.endOfShift} *</label>
        <div className="flex items-center space-x-4">
          <div className="grow">
            <DatePickerInput
              control={control}
              labelOff
              name="endDate"
              required
            />
          </div>
          <TimeSelect control={control} labelOff name="endTime" required />
        </div>
      </div>
      <AlertBox closeAble={false} message={dateTimeValidation} />
    </>
  );
};

export default ShiftDateTimes;
