/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";
import { VideoChatUser } from "../models/videoConsultation/VideoChatUser";
import { VideoChatRoom } from "../models/videoConsultation/VideoChatRooms";
import { VideoConsultationEndRequest } from "../models/videoConsultation/VideoConsultationEndRequest";
import { VideoConsultationResponse } from "../models/videoConsultation/VideoConsultationResponse";
import { SignalServerLogEntry } from "../models/videoConsultation/SignalServerLogEntry";

interface EventPageConfig {
  newestFirst: boolean;
  pageNumber: number;
  pageSize: number;
}

interface UserPageConfig {
  pageNumber: number;
  pageSize: number;
}

export default class VideoConsultationApi {
  private static clinicServiceBaseURL = Params.clinicServiceBaseURL;

  private static videoChatSignalServiceBaseURL =
    Params.videoChatSignalServiceBaseURL;

  private static path = Params.videoChatPrefix;

  static getLogs(): ApiPromise<SignalServerLogEntry[]> {
    const url = `${this.videoChatSignalServiceBaseURL}${this.path}/logs`;
    return axiosCommon.get(url);
  }

  static getRooms(
    eventPageConfig: EventPageConfig,
    userPageConfig: UserPageConfig,
    sessionId?: number
  ): ApiPromise<VideoChatRoom[]> {
    const url = `${this.videoChatSignalServiceBaseURL}${this.path}/rooms`;

    return axiosCommon.get(url, {
      params: {
        newestFirst: eventPageConfig.newestFirst.toString(),
        eventPageNumber: eventPageConfig.pageNumber.toString(),
        eventPageSize: eventPageConfig.pageSize.toString(),
        userPageSize: userPageConfig.pageSize.toString(),
        userPageNumber: userPageConfig.pageNumber.toString(),
        sessionId: (sessionId || 1).toString(),
      },
    });
  }

  static getUsersInRoom(roomId: string): ApiPromise<VideoChatUser[]> {
    const url = `${this.videoChatSignalServiceBaseURL}${this.path}/rooms/${roomId}/users`;
    return axiosCommon.get(url);
  }

  static deleteAllRooms(): ApiPromise<void> {
    const url = `${this.videoChatSignalServiceBaseURL}${this.path}/rooms/reset`;
    return axiosCommon.delete(url);
  }

  static getVideoConsultation(
    consultationId: string
  ): ApiPromise<VideoConsultationResponse> {
    const url = `${this.clinicServiceBaseURL}/video-consultations/${consultationId}`;
    return axiosCommon.get(url);
  }

  static endCallTest(
    durationInMinutes: number,
    consultationId: string
  ): ApiPromise<void> {
    const url = `${this.clinicServiceBaseURL}/video-consultations/${consultationId}/end`;

    const request: VideoConsultationEndRequest = {
      durationInSeconds: durationInMinutes * 60,
    };

    return axiosCommon.post(url, request);
  }
}
