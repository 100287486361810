/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { InventoryItemStockResponse } from "../../../../../models/inventory/item/Response/InventoryItemStockResponse";
import { InventoryItemUnit } from "../../../../../models/inventory/item/InventoryItemUnit";
import { quantityUnits, strings } from "../../../../../common/Strings/Strings";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";
import PriceWithVat from "../../../../../components/PriceWithVat";
import { Clock } from "../../../../../common/Icons/Clock";
import Tooltip from "../../../../../components/Tooltip";
import Button from "../../../../../components/Button";
import InventoryItemApi from "../../../../../api/InventoryItemApi";
import { InventoryItemDetailsResponse } from "../../../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { Trash } from "../../../../../common/Icons/Trash";
import { PencilSquare } from "../../../../../common/Icons/PencilSquare";

interface Props {
  acquisitionUnit?: InventoryItemUnit;
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemDetailsResponse;
  reloadItem(successMessage?: string): void;
  setError(message: string | null): void;
  setItemStockToEdit(stock: InventoryItemStockResponse): void;
  setOpenStockModal(value: boolean): void;
  stock: InventoryItemStockResponse | any;
}

const StockRow: React.FC<Props> = ({
  acquisitionUnit,
  countryDetails,
  inventoryItem,
  reloadItem,
  setError,
  setItemStockToEdit,
  setOpenStockModal,
  stock,
}) => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const deleteStock = async (stockId: string) => {
    if (inventoryItem) {
      setDeleteLoading(true);

      try {
        await InventoryItemApi.deleteItemStock(inventoryItem.id, stockId);
        reloadItem(strings.stockDeletedSuccessfully);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  return (
    <div className="p-3 grow grid xl:grid-cols-4 gap-4" key={`stock-${stock.id}`}>
      <div>
        <div className="uppercase text-sm">{strings.orderHistoryUnit}</div>
        <div className="font-bold">{moment(stock.dateTime).format("LL")}</div>
        {stock.expirationDate ? (
          <Tooltip content={strings.expirationDate}>
            <div className="flex items-center space-x-1.5">
              <Clock className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                {moment(stock.expirationDate).format("LL")}
              </div>
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      <div>
        <div className="text-sm">{strings.acquisitionPrice}:</div>
        <PriceWithVat
          countryDetails={countryDetails}
          netPrice={stock.acquisitionPrice}
          vat={stock.acquisitionVat}
        />
      </div>
      <div />
      <div className="flex items-center">
        <div className="font-bold">
          {stock.quantity} {quantityUnits[acquisitionUnit || ""]}
        </div>
        <div className="ml-auto">
          <Button
            onClick={() => {
              setItemStockToEdit(stock);
              setOpenStockModal(true);
            }}
            variant="icon"
          >
            <PencilSquare />
          </Button>
          <Button
            askAreYouSure={{
              enable: true,
              content: strings.formatString(
                strings.areYouSureToDeleteItemStock,
                <b>{inventoryItem.name}</b>
              ),
              title: strings.deleteStock,
            }}
            className="ml-3"
            disabled={deleteLoading}
            loading={deleteLoading}
            onClick={() => {
              void deleteStock(stock.id);
            }}
            variant="icon"
          >
            <Trash />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StockRow;
