/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/Loader";
import MedicalRecordComponent from "./MedicalRecordComponent";
import { PageProps } from "../../models/PageProps";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../util/LocalStorageVariables";
import { useClinic } from "../../contexts/ClinicContext";
import { canAccessClinic } from "../../util/helperFunctions";

type Params = {
  resId?: string;
  recordId?: string;
  universalId?: string;
};

const MedicalRecord = ({ setPageLoading }: PageProps): ReactElement => {
  const { resId, recordId, universalId } = useParams<Params>();
  const { clinic } = useClinic();
  const role: UserRole | null = getValueOfActiveUser("role");
  const [isUserNotOwner, setIsUserNotOwner] = useState<boolean>(false);

  useEffect(() => {
    const shouldSetNotOwner =
      clinic && role !== "owner" && canAccessClinic(clinic);

    if (shouldSetNotOwner) {
      setIsUserNotOwner(true);
    }
  }, [clinic, role]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-0 xl:space-y-6 pt-2 lg:pt-6">
            <MedicalRecordComponent
              isPage
              isUserNotOwner={isUserNotOwner}
              setPageLoading={setPageLoading}
              resId={resId}
              recordId={recordId}
              universalId={universalId}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(MedicalRecord);
