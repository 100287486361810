/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { strings } from "../../../common/Strings/Strings";
import { CostAccountingStatus } from "../../../models/costaccounting/CostAccountingStatus";
import InvoiceState from "./InvoiceState";
import { InvoiceType } from "../../../models/invoice/InvoiceType";
import { InvoiceStatus } from "../../../models/invoice/InvoiceStatus";
import Tag, { TagType } from "../../../components/Tag";
import InvoiceItems from "./InvoiceItems";
import InvoiceActions from "./InvoiceActions";
import CustomerInvoiceActions from "../../PetOwner/UserPetOwner/Invoices/CustomerInvoiceActions";
import AlertBox from "../../../components/AlertBox";
import { PetProfileTabs } from "../../Pet/PetProfile/ClinicPetProfile";
import { getInvoicingSuccessMessage } from "../../../util/InvoiceSystemUtils";

interface Props {
  error: string | null;
  invoice?: InvoiceResponse;
  isCustomerView: boolean;
  isPairInvoice: boolean;
  reload(): void;
  setError(message: string | null): void;
  setInvoice(invoice: InvoiceResponse): void;
}

export const getInvoiceState = (
  invoice?: InvoiceResponse
): ReactElement | undefined => {
  if (invoice?.status === InvoiceStatus.IN_PROGRESS) {
    return <Tag type={TagType.primary} text={strings.pendingInvoice} />;
  }
  if (invoice?.costAccounting?.status === CostAccountingStatus.RESOLVED) {
    return <Tag type={TagType.success} text={strings.paid} />;
  }
  if (invoice?.costAccounting?.status === CostAccountingStatus.UNRESOLVED) {
    return <Tag type={TagType.danger} text={strings.notPaid} />;
  }
  if (invoice?.costAccounting?.status === CostAccountingStatus.WITHDRAWN) {
    return <Tag type={TagType.secondary} text={strings.withdrawn} />;
  }

  return undefined;
};

const InvoiceInfos = ({
  error,
  invoice,
  isCustomerView,
  isPairInvoice,
  reload,
  setError,
  setInvoice,
}: Props): ReactElement => {
  const invoiceType = (inv: InvoiceResponse) => {
    switch (inv.type) {
      case InvoiceType.MEDICAL_RECORD:
        if (inv.medicalRecordId) {
          return (
            <div className="flex items-center content-start pt-2">
              <Link
                className="tw-link-tercier"
                to={`/medical-record/${inv.medicalRecordId}`}
              >
                {strings.medicalRecord}
              </Link>
            </div>
          );
        }
        return strings.medicalRecord;

      case InvoiceType.PRESCRIPTION:
        if (inv.prescriptionId) {
          return (
            <div className="flex items-center content-start pt-2">
              <Link
                className="tw-link-tercier"
                to={`/prescriptions/${inv.prescriptionId}`}
              >
                {strings.prescription}
              </Link>
            </div>
          );
        }
        return strings.prescription;

      default:
        return <></>;
    }
  };

  const getTitle = () => {
    if (isPairInvoice) {
      if (invoice?.paidImmediately) {
        return (
          <span>
            <Link
              className="tw-link-tercier"
              to={`/invoices/clinic/${invoice?.id}`}
            >
              {strings.insuranceTitle}: {strings.cashInvoice}
            </Link>
          </span>
        );
      }
      return (
        <span>
          <Link
            className="tw-link-tercier"
            to={`/invoices/clinic/${invoice?.id}`}
          >
            {strings.insuranceTitle}: {strings.invoice}
          </Link>
        </span>
      );
    }
    if (invoice?.paidImmediately) {
      return strings.cashInvoice;
    }
    return strings.invoice;
  };

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="space-y-1">
        <div className="flex">
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {getTitle()}
          </h2>
          <div className="ml-3">{getInvoiceState(invoice)}</div>
          <div className="ml-auto">
            {invoice && !isCustomerView && (
              <InvoiceActions
                invoice={invoice}
                isPairInvoice={isPairInvoice}
                isDropdown={false}
                reload={reload}
                setError={setError}
              />
            )}
            {invoice && isCustomerView && (
              <CustomerInvoiceActions
                invoice={invoice}
                setError={setError}
                setInvoice={setInvoice}
              />
            )}
          </div>
        </div>
        <div>
          <>
            <div hidden={invoice?.valid === false}>
              {getInvoicingSuccessMessage(invoice)}
            </div>
            {invoice && (
              <InvoiceState
                className="mt-5"
                invoice={invoice}
                returnError={(err) => {
                  setError(err);
                }}
                returnRefreshedInvoice={setInvoice}
              />
            )}
          </>
        </div>
      </div>
      <AlertBox message={error} />
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6">
          <div className="font-bold">
            {invoice?.customer?.fullName || invoice?.customer?.organizationName}{" "}
            {invoice?.pet && !isCustomerView ? (
              <>
                (
                <Link
                  className="tw-link-tercier"
                  to={`/clinic-pet-profile/${invoice?.pet?.id}/${PetProfileTabs.basic}`}
                >
                  {invoice?.pet?.name}
                </Link>
                )
              </>
            ) : (
              ""
            )}
          </div>
          <div>{invoice?.customer?.phoneNumber}</div>
          <div>{invoice?.customer?.email}</div>
          <div>{invoice?.customer?.taxNumber}</div>
          <div>
            {invoice?.customer?.countryCode} {invoice?.customer?.zip}{" "}
            {invoice?.customer?.city} {invoice?.customer?.street}{" "}
            {invoice?.customer?.houseDetails}
          </div>
          <div>{invoice && invoiceType(invoice)}</div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div>
            {strings.invoiceNumber}: <b>{invoice?.invoiceNumber}</b>
          </div>
          <div hidden={!invoice?.creationDate}>
            {strings.invoiceCreationDate}:{" "}
            <b>{invoice?.creationDate &&
              moment(invoice?.creationDate).format("LLL")}</b>
          </div>
          <div hidden={!invoice?.dueDate}>
            {strings.dueDateForPayment}:{" "}
            <b>{invoice?.dueDate && moment(invoice?.dueDate).format("LL")}</b>
          </div>
          <div>
            {strings.issuer}: <b>{invoice?.issuer?.fullName}</b>
          </div>
        </div>
      </div>
      <div>{invoice && <InvoiceItems invoice={invoice} />}</div>
    </div>
  );
};

export default InvoiceInfos;
