/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ExtraCost } from "../../../models/medical/ExtraCost";

export type UsedOtherActions =
  | { type: "fill"; itemsToAdd: ExtraCost[] }
  | { type: "add"; itemToAdd: ExtraCost }
  | { type: "changeQuantity"; itemToChange: ExtraCost; newQuantity: number }
  | { type: "changePrice"; itemToChange: ExtraCost; newPrice: number }
  | { type: "remove"; itemToRemove: ExtraCost };

/*
 * This reducer manages the state of `ExtraCost` items that do **not** have a `referenceId`.
 * These items are uniquely identified by their `id` values, not by `referenceId`.
 * The items processed by this reducer are those without a `referenceId`.
 *
 * Actions:
 * - "fill": Replace the entire state with a new list of items.
 * - "add": Adds a new item or updates an existing item based on the `id`.
 * - "changeQuantity": Changes the quantity of an item identified by its `id`.
 * - "changePrice": Updates the price of an item identified by its `id`.
 * - "remove": Removes an item identified by its `id`.
 *
 * Important Note:
 * - These items are specifically filtered and handled based on the absence of `referenceId`.
 * - The uniqueness of `referenceId` does not apply to these items since they are identified by their unique `id`.
 */
export const otherReducer = (
  state: Array<ExtraCost>,
  action: UsedOtherActions
): ExtraCost[] => {
  switch (action.type) {
    case "fill":
      // Replace the entire state with new items
      return action.itemsToAdd;

    case "add": {
      const { id, quantity } = action.itemToAdd;
      const existingIndex = state.findIndex(
        (item: ExtraCost) => item.id === id
      );

      if (existingIndex >= 0) {
        // Update quantity for existing item
        return state.map((item: ExtraCost, index: number) =>
          index === existingIndex
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      }

      // Add new item
      return [...state, action.itemToAdd];
    }

    case "changeQuantity": {
      const { id } = action.itemToChange;

      return state.map((item: ExtraCost) =>
        item.id === id ? { ...item, quantity: action.newQuantity } : item
      );
    }

    case "changePrice": {
      const { id } = action.itemToChange;

      return state.map((item: ExtraCost) =>
        item.id === id ? { ...item, price: action.newPrice } : item
      );
    }

    case "remove": {
      const { id } = action.itemToRemove;

      // Remove item with the specified ID
      return state.filter((item: ExtraCost) => item.id !== id);
    }

    default:
      // Return current state for unknown actions
      return state;
  }
};
