/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../../Strings/Strings";
import { PetOwnerResponse } from "../../../../../models/pet/PetOwnerResponse";
import { Link } from "react-router-dom";
import { PlusIcon } from "../../../../Icons/PlusIcon";
import { HighlightedText } from "../../../../../util/StringUtils";
import { PublicPhoneResponse } from "../../../../../models/contact/PublicPhoneResponse";

interface Props {
  petlessOwner: PetOwnerResponse;
  query: string;
}

const PetlessOwnerResultRow: React.FC<Props> = ({
  petlessOwner,
  query,
}: Props) => {
  return (
    <>
      <div className="flex flex-wrap items-center text-sm px-4 py-3">
        <div>
          <HighlightedText
            text={petlessOwner.petOwnerDetails.fullName}
            highlights={query.split(" ")}
          />
          <div className="flex space-x-2">
            {petlessOwner.petOwnerDetails.personalId && (
              <div className="text-sm">
                <HighlightedText
                  text={petlessOwner.petOwnerDetails.personalId}
                  highlights={query.split(" ")}
                />
              </div>
            )}
            {petlessOwner.petOwnerDetails.phones?.map(
              (phone: PublicPhoneResponse) => (
                <div className="text-sm">
                  <HighlightedText
                    text={phone.value}
                    highlights={query.split(" ")}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <div className="ml-auto">
          <Link to={`/add-new-clinic-pet/${petlessOwner.petOwnerDetails.id}`}>
            <div className="flex">
              <PlusIcon className="h-5 w-5 flex-shrink-0" />
              <span>{strings.addPet}</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PetlessOwnerResultRow;