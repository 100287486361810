/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { strings } from "../../common/Strings/Strings";
import { InvoiceStatisticsResponse } from "../../models/invoice/InvoiceStatisticsResponse";
import { useClinic } from "../../contexts/ClinicContext";
import ClinicInvoiceApi from "../../api/ClinicInvoiceApi";
import { getGeneralError } from "../../util/helperFunctions";
import NumberFormatter from "../../util/NumberFormatter";
import { SearchInvoices } from "../../models/apiparams/SearchInvoices";
import DateFilters, { DateTemplate } from "../../components/DateFilters";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import LoaderInline from "../../components/LoaderInline";
import { ArrowDownTray } from "../../common/Icons/ArrowDownTray";
import Button from "../../components/Button";
import {
  downloadPaymentSummaryOfClinic,
  printPaymentSummaryOfClinic,
} from "../../util/FileUtils";
import AlertBox, { AlertType } from "../../components/AlertBox";
import Tooltip from "../../components/Tooltip";
import { Printer } from "../../common/Icons/Printer";
import logger from "../../util/logger";

interface Props {
  countryDetails?: CountryDetailsResponse;
}

const Income = ({ countryDetails }: Props): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [statistics, setStatistics] = useState<InvoiceStatisticsResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [reloadStatistics, setReloadStatistics] = useState<boolean>(false);
  const [isTotalEmptyMessage, setIsTotalEmptyMessage] =
    useState<boolean>(false);

  const filterFormMethods = useForm<SearchInvoices>({
    mode: "onChange",
    defaultValues: {
      endDate: new Date(),
      startDate: new Date(),
      dateTemplate: DateTemplate.today,
    },
  });
  const { getValues: getStatisticsFilterFormValues } = filterFormMethods;

  const { clinic } = useClinic();

  useEffect(() => {
    const getStatistics = async () => {
      try {
        setLoading(true);
        let response: AxiosResponse<InvoiceStatisticsResponse>;
        const request = getStatisticsFilterFormValues();

        if (clinic) {
          response = await ClinicInvoiceApi.getPaymentSummary(
            clinic.id,
            request
          );
        } else {
          return;
        }

        setError(null);
        setStatistics(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getStatistics();
  }, [reloadStatistics, getStatisticsFilterFormValues, clinic]);

  const printOrDownload = async (isDownload: boolean) => {
    setIsTotalEmptyMessage(false);
    if (statistics?.total === 0) {
      setIsTotalEmptyMessage(true);
      return;
    }
    if (clinic) {
      setPdfLoading(true);
      const startDate = moment(getStatisticsFilterFormValues("startDate")).format(
        "YYYY-MM-DD"
      );
      const endDate = moment(getStatisticsFilterFormValues("endDate")).format(
        "YYYY-MM-DD"
      );
      try {
        {
          isDownload
            ? await downloadPaymentSummaryOfClinic(
                clinic.id,
                startDate,
                endDate
              )
            : await printPaymentSummaryOfClinic(
                clinic.id,
                startDate,
                endDate,
                () => {}
              );
        }
      } catch (err) {
        logger.error(err);
        setError(await getGeneralError(err));
      } finally {
        setPdfLoading(false);
      }
    } else {
      logger.error("No clinic.");
    }
  };

  return (
    <div className="space-y-4 w-full md:max-w-lg space-y-6">
      <div className="flex">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.incomeSummary}
        </h2>
        <div className="ml-auto mr-4">
          <Tooltip content={strings.downloadIncomeSummary} placement="top">
            <Button
              className="tw-link"
              disabled={pdfLoading}
              onClick={() => printOrDownload(true)}
              variant="icon"
            >
              <ArrowDownTray />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip content={strings.printIncomeSummary} placement="top">
            <Button
              className="tw-link"
              disabled={pdfLoading}
              onClick={() => printOrDownload(false)}
              variant="icon"
            >
              <Printer />
            </Button>
          </Tooltip>
        </div>
      </div>
      <AlertBox
        closeAble={false}
        hidden={!isTotalEmptyMessage}
        message={strings.totalIncomeIsZeroInfo}
        type={AlertType.INFO}
      />
      <FormProvider {...filterFormMethods}>
        <form>
          <DateFilters
            onChange={() => setReloadStatistics(!reloadStatistics)}
          />
        </form>
      </FormProvider>
      <AlertBox message={error} />
      <div>
        {loading ? (
          <LoaderInline />
        ) : (
          <div className="tw-table-container">
            <table className="tw-table">
              <tbody className="tw-tbody">
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.cashIncome}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.cash,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.cardIncome}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.card,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.transferIncome}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.transfer,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.directDebitIncome}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.directDebit,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.onlineIncome}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.online,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.insuranceIncome}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.insurance,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm whitespace-nowrap font-bold">
                    {strings.totalIncome}
                  </td>
                  <td className="px-3 py-2 text-sm whitespace-nowrap text-right font-bold">
                    {statistics &&
                      NumberFormatter.formatPrice(
                        statistics?.total,
                        countryDetails?.currency
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Income;
