/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { useCallback, useEffect, useState } from "react";

interface UseMediaPermissionsOptions {
  simulateMissingCamera?: boolean;
  simulateMissingMicrophone?: boolean;
}

const useMediaPermissions = ({
  simulateMissingCamera = false,
  simulateMissingMicrophone = false,
}: UseMediaPermissionsOptions = {}) => {
  const [micPermission, setMicPermission] = useState<PermissionState | "unknown">("unknown");
  const [camPermission, setCamPermission] = useState<PermissionState | "unknown">("unknown");
  const [hasMicrophone, setHasMicrophone] = useState<boolean | "unknown">("unknown");
  const [hasCamera, setHasCamera] = useState<boolean | "unknown">("unknown");

  // Check permissions using the Permissions API
  const checkPermissions = async () => {
    try {
      if (navigator.permissions) {
        // Check microphone permission
        const micPerm = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });
        setMicPermission(micPerm.state);
        micPerm.onchange = () => setMicPermission(micPerm.state);

        // Check camera permission
        const camPerm = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });
        setCamPermission(camPerm.state);
        camPerm.onchange = () => setCamPermission(camPerm.state);
      } else {
        console.warn("Permissions API not fully supported in this browser.");
      }
    } catch (error) {
      console.error("Error checking permissions:", error);
    }
  };

  // Check available media devices (camera and microphone)
  const checkAvailableDevices = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === "videoinput");
      const audioDevices = devices.filter((device) => device.kind === "audioinput");

      // Apply simulation overrides
      setHasCamera(simulateMissingCamera ? false : videoDevices.length > 0);
      setHasMicrophone(simulateMissingMicrophone ? false : audioDevices.length > 0);
    } catch (error) {
      console.error("Error checking available devices:", error);

      // Set to unknown if there was an error
      setHasCamera("unknown");
      setHasMicrophone("unknown");
    }
  }, [simulateMissingCamera, simulateMissingMicrophone]);

  const handleDeviceChange = useCallback(() => {
    // Recheck available devices when devices change
    void checkAvailableDevices();
  }, [checkAvailableDevices]);

  useEffect(() => {
    // Initial permission check
    void checkPermissions();

    // Initial device check
    void checkAvailableDevices();
    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
    };
  }, [checkAvailableDevices, handleDeviceChange]);

  return { micPermission, camPermission, hasMicrophone, hasCamera };
};

export default useMediaPermissions;
