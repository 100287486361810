/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { Payer } from "../../../models/costaccounting/Payer";
import { PaymentMethodResponse } from "../../../models/payment/PaymentMethodResponse";
import { NewCostEntryRequest } from "../../../models/costaccounting/NewCostEntryRequest";
import { PaymentType } from "../../../models/costaccounting/PaymentType";
import { getGeneralError } from "../../../util/helperFunctions";
import { strings } from "../../../common/Strings/Strings";
import PaymentMethodApi from "../../../api/PaymentMethodApi";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import SelectInsuranceCompany from "../../../components/ReactHookFormFields/Specific/SelectInsuranceCompany";
import CloseButton from "../../../components/CloseButton";
import AlertBox from "../../../components/AlertBox";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import CostAccountingApi from "../../../api/CostAccountingApi";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";
import RadioButtonGroup from "../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";

interface Props {
  close(): void;
  invoice: InvoiceResponse;
  isOpen: boolean;
  reload(): void;
}

interface PaymentFormData {
  insuranceCompanies: InsuranceCompanyResponse[];
  newAmount?: number;
  payerName: string;
  payer: Payer;
  paymentMethodId: string;
  paymentType: PaymentType;
}

const AddPayment = ({
  invoice,
  isOpen,
  close,
  reload,
}: Props): ReactElement => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodResponse[]>(
    []
  );
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [payerType, setPayerType] = useState<string>(Payer.PET_OWNER);
  const [paymentSaveLoading, setPaymentSaveLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    watch,
  } = useForm<PaymentFormData>({
    defaultValues: {
      payerName: invoice.customer?.fullName,
      paymentType: PaymentType.FULL,
    },
    mode: "onChange",
  });

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const response = await PaymentMethodApi.getPaymentMethodsOfClinic(
          invoice.clinicId
        );
        setPaymentMethods(response.data);
      } catch (err) {
        setPaymentError(await getGeneralError(err));
      }
    };

    if (isOpen) {
      void getPaymentMethods();
    }
  }, [isOpen, invoice]);

  const submitPayment = async ({
    newAmount,
    insuranceCompanies,
    payer,
    payerName,
    paymentMethodId,
    paymentType: pt,
  }: PaymentFormData) => {
    if (!invoice?.costAccounting) {
      return;
    }

    setPaymentSaveLoading(true);
    setPaymentError(null);

    const request: NewCostEntryRequest = {
      newAmount:
        pt === PaymentType.PARTIAL
          ? newAmount || 0
          : invoice?.costAccounting?.remainingCost,
      payerName:
        payer === Payer.INSURANCE_COMPANY && insuranceCompanies?.length > 0
          ? insuranceCompanies[0].name
          : payerName,
      payer,
      paymentType: pt,
      paymentMethodId,
    };

    try {
      await CostAccountingApi.addPayment(invoice.id, request);
      reload();
    } catch (err) {
      setPaymentError(await getGeneralError(err));
    } finally {
      setPaymentSaveLoading(false);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.markAsPaid} />
      <Modal.Body noSpacing>
        <form id="addPaymentForm">
          <div className="px-4 xl:py-5 py-8 space-y-6">
            <RadioButtonGroup
              control={control}
              name="paymentType"
              options={[
                {
                  label: `${strings.remainingCost} (${invoice?.costAccounting?.remainingCost} ${invoice?.costAccounting?.currency})`,
                  value: PaymentType.FULL,
                },
                {
                  label: strings.partialPayment,
                  value: PaymentType.PARTIAL,
                  content: (
                    <Field
                      error={errors.newAmount}
                      fieldOptions={{
                        validate: {
                          positive: (v, formValues) => {
                            const paymentType = formValues.paymentType;
                            return paymentType === PaymentType.PARTIAL
                              ? v > 0 || strings.numberMustBePositive
                              : true;
                          },
                          isGraterThanMax: (v, formValues) => {
                            const paymentType = formValues.paymentType;

                            return paymentType === PaymentType.PARTIAL &&
                              invoice?.costAccounting?.remainingCost
                              ? v <= invoice?.costAccounting?.remainingCost ||
                                  strings.formatString(
                                    strings.numberMustBeLessThan,
                                    invoice?.costAccounting?.remainingCost + 1
                                  )
                              : true;
                          },
                          isRequired: (v, formValues) => {
                            const paymentType = formValues.paymentType;
                            return paymentType === PaymentType.PARTIAL
                              ? !Number.isNaN(v) && v !== ""
                              : true;
                          },
                        },
                      }}
                      name="newAmount"
                      register={register}
                      readOnly={watch("paymentType") === PaymentType.FULL}
                      step="any"
                      suffix={invoice.currency}
                      type="number"
                      width="150px"
                    />
                  ),
                },
              ]}
            />
            <Select
              autoComplete={AutoCompleteOptions.off}
              error={errors.payer}
              isChooseOption={false}
              label={strings.payerType}
              name="payer"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setPayerType(e.target.value);
              }}
              options={Object.keys(Payer).map((i) => ({
                title: strings[i],
                value: i,
              }))}
              register={register}
              required
            />

            {payerType === Payer.PET_OWNER ? (
              <Field
                label={strings.payerName}
                error={errors.payerName}
                fieldOptions={{
                  validate: {
                    isRequired: (v) => {
                      const pT = getValues("payer");

                      return pT === Payer.PET_OWNER ? v?.length > 0 : true;
                    },
                  },
                }}
                name="payerName"
                register={register}
              />
            ) : (
              <SelectInsuranceCompany
                allowNew
                control={control}
                fieldOptions={{
                  validate: {
                    isRequired: (v) => {
                      const pT = getValues("payer");

                      return pT === Payer.INSURANCE_COMPANY
                        ? v?.length > 0
                        : true;
                    },
                  },
                }}
                name="insuranceCompanies"
              />
            )}
            <Select
              autoComplete={AutoCompleteOptions.off}
              error={errors.paymentMethodId}
              label={strings.paymentMethod}
              name="paymentMethodId"
              options={paymentMethods.map((m) => ({
                title: m.name,
                value: m.id,
              }))}
              register={register}
              required
            />
            <AlertBox message={paymentError} />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          form="addPaymentForm"
          loading={paymentSaveLoading}
          onClick={handleSubmit(submitPayment)}
        >
          {strings.save}
        </Button>
        <CloseButton onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddPayment;
