/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { generateUUId } from "../../../../pages/ePrescription/sections/finish/sendingRequestProcesser";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface Props {
  content?: ReactElement | ReactElement[];
  id: string;
  checked?: boolean;
  defaultValue?: boolean;
  disableOpacity?: boolean;
  disabled?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  helperText?: string;
  label?: string;
  onChange?(checked: boolean): void;
  verticalCenter?: boolean;
}

const RadioButton: React.FC<Props> = ({
  content,
  id,
  defaultValue = false,
  checked = defaultValue,
  disabled,
  error,
  helperText,
  label,
  onChange,
  verticalCenter = false,
}: Props) => {
  const handleOnChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  const cursorClass = checked ? "cursor-default" : "cursor-pointer";

  return (
    <div className={`flex ${verticalCenter ? "items-center " : ""}space-x-3 ${error ? "has-error" : ""}`}>
      <input
        checked={checked}
        className="cursor-pointer"
        disabled={disabled}
        id={`radio-button-${id}`}
        name={generateUUId()}
        onChange={handleOnChange}
        readOnly={disabled}
        type="radio"
      />

      <div>
        {label ? (
          <label className={cursorClass} htmlFor={`radio-button-${id}`}>
            {label} <p id={`radio-button-helper-text-${id}`}>{helperText}</p>
          </label>
        ) : (
          <></>
        )}
        {content}
      </div>
    </div>
  );
};

export default RadioButton;
