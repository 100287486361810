/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../common/Strings/Strings";
import { Spinner, SpinnerSize } from "../common/Icons/Spinner";
import { combineClassNames } from "../util/HtmlUtils";

type Alignment = "start" | "center" | "end";

interface Props {
  className?: string;
  dark?: boolean;
  hidden?: boolean;
  horizontalAlign?: Alignment;
  size?: SpinnerSize;
  text?: string;
  textVisible?: boolean;
  verticalAlign?: Alignment;
}

export const LoaderInline: React.FC<Props> = ({
  className = "",
  dark = true,
  hidden = false,
  horizontalAlign = "center",
  size = SpinnerSize.Large,
  text = strings.loading,
  textVisible = false,
  verticalAlign = "center",
}: Props) =>
  hidden ? null : (
    <div
      className={`flex justify-${horizontalAlign} items-${verticalAlign} ${className}`}
    >
      <Spinner dark={dark} size={size} />
      <span
        className={combineClassNames(
          "ml-3 text-sm text-zinc-800 dark:text-white",
          !textVisible ? "sr-only" : ""
        )}
      >
        {text}
      </span>
    </div>
  );

export default LoaderInline;
