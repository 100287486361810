/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const mainStringsEn = {
  noUnreadNotifications: "There are no unread notifications.",
  setupProfilePreferencesPhoneTitle: "Preferences",
  setupProfilePhonePhoneTitle: "Phone",
  setupProfileAddressPhoneTitle: "Address",
  setupProfileRolePhoneTitle: "User role",
  cookieConsentText: "This website uses cookies to enhance the user experience.",
  acceptCookie: "Allow cookies",
  declineCookie: "Decline",
  pleaseSetupYourProfile: "Please, setup your profile.",
  wrongEmailOrPassword: "You have entered an invalid email or password.",
  title: "Title",
  addNewExternalMedicalRecord: "New note",
  medicalRecords: "Medical records",
  couldNotRetrieveMedicalRecords: "Could not retrieve medical records",
  externalMedicalRecords: "Notes",
  externalMedicalRecord: "Note",
  couldNotRetrieveExternalMedicalRecords: "Could not retrieve notes",
  maintenanceModeMessage: "This site is under maintenance. Come back later!",
  maintenanceModeEnabled: "Maintenance mode enabled",
  maintenanceSettings: "Maintenance",
  vetDocumentsWereRejected: "Documents were rejected.",
  vetDocumentSubmitted: "Document has been uploaded and is waiting for approval",
  ePrescriptions: "ePrescriptions",
  couldNotRetrievePrescriptions: "Could not retrieve prescriptions",
  submitDocument: "Submit document",
  addFile: "Add file",
  farmAnimals: "Farm animals",
  createEmergencyAppointment: "Create emergency appointment",
  emergencyAppointment: "Emergency appointment",
  serviceEnabledForCountry: "Service enabled",
  allowReservationFor: "Allow reservation for",
  startOfAppointment: "Start of appointment",
  endOfAppointment: "End of appointment",
  chooseAnEmploymentType: "Choose an employment type.",
  employmentType: "Employment type",
  deceased: "Deceased",
  insuranceNumber: "Insurance number",
  passportNumber: "Passport number",
  alternativeMobilePhone: "Alternative mobile phone",
  sendNewRegistrationConfirmationMail: "Send new email",
  youCannotLoginBecauseEmailIsNotVerifiedYet: "Your email address is not verified yet.",
  fillPetDataBeforeMedicalRecordMsg:
    "Before proceeding to the medical record, please fill all the information about the pet and the owner.",
  proceedToMedicalRecord: "Proceed to medical record",
  emailNotConfirmed: "Email address not confirmed",
  emailConfirmationResendText: "You can request an email to confirm your email address.",
  resendConfirmationEmailBtnTxt: "Resend verification link",

  phoneNotConfirmed: "Phone number not confirmed",
  phoneConfirmationResendText: "You can request an SMS to confirm your phone number.",
  resendConfirmationSmsBtnTxt: "Send SMS",

  organizationNameIsIncorrect: "Organization name is incorrect.",
  organizationOwnersPassword: "Owner's password",
  organizationDeleteConfirmation: "Are you sure you want to delete this clinic?",
  organizationDeleted: "The organization has been successfully deleted.",
  deleteInProgress: "Deleting...",
  organizationDeletionConfirmationEmailSent: "An email has been sent to confirm the deletion of the organization.",
  organizationName: "Organization name",
  organizationNameHelperText: "displayed in GlobalVet",
  clinicDeletionWarning:
    "Warning! All of the past records will be permanently deleted from the system and it cannot be undone.",
  organizationDeletionWarning:
    "Warning! All of the past records will be permanently deleted from the system and it cannot be undone.",
  clinicOwnersPassword: "Owner's password",
  clinicCreated: "The clinic has been successfully created.",
  clinicDeleted: "The clinic has been successfully deleted.",
  clinicDeletionConfirmationEmailSent: "An email has been sent to confirm the deletion of the clinic.",
  clinicName: "Clinic name",
  other: "Other",
  deleteClinic: "Delete clinic",
  deleteOrganization: "Delete organization",
  approveVets: "Approve Vets",
  userId: "User ID",
  fileOrFiles: "File(s)",
  noFile: "No file",
  viewFiles: "View {0} files",
  actions: "Actions",
  medicalCertificate: "Medical certificate",
  submitMedicinalCertificate: "Submit medicinal certificate",
  vetProfessionNotValidated:
    "To be able to create ePrescriptions, you must prove that you work as a doctor. Please submit a certificate.",
  vetProfessionDocumentsRejected: "Your document(s) were rejected.",
  approve: "Approve",
  approveVetProfessionConfirmation: "Confirm vet profession approval",
  approveVetProfessionConfirmationMessage: "Are you sure you want to approve vet profession documents for this vet?",
  documents: "Documents",
  document: "Document",
  rejectVetProfessionConfirmation: "Confirm vet profession rejection",
  rejectVetProfessionConfirmationMessage: "Are you sure you want to reject vet profession documents for this vet?",
  message: "Message",
  rejectionMessagePlaceholder: "Write the reasons for rejection...",

  // emailTypes
  PRIVATE: "Private",
  PRIMARY: "Primary",

  // Days
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",

  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
  Day: "Day",
  Week: "Week",
  Year: "Year",

  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  day: "Day",
  week: "Week",
  today: "Today",
  yesterday: "Yesterday",
  currentMonth: "This month",
  pastMonth: "Past month",
  currentWeek: "This week",
  pastWeek: "Past week",
  currentYear: "This year",
  pastYear: "Past year",

  weekend: "Weekend",
  months: "Reminders",
  weeks: "Weeks",

  // Footer
  copyright: "Copyright ©",
  privacyStatement: "Privacy statement",
  more: "More",

  // Common
  serverNotResponding: "Server is not responding.",
  loading: "Loading...",
  pageNotFound: "Page not found.",
  incorrectFieldValue: "Some of the fields are filled incorrectly.",
  yes: "Yes",
  no: "No",
  OR: "OR",
  or: "or",
  selectAll: "Select all",
  all: "All",
  optional: "optional",
  charactersLeft: "{0} characters left",
  charactersAbove: "{0} characters above",
  minimumCharacters: "This field must be at least {0} characters long.",
  maximumCharacters: "This field must be less than {0} characters long.",
  useCustomValue: "Use custom value:",
  show: "Show",
  showMore: "Show more",
  showLess: "Show less",
  systemId: "System id",
  here: "here",
  moreInformation: "More information",

  // Error messages
  notAllowedCharacter: "Not allowed character",
  notAllowedCharacterSpecific: "Not allowed character ({0}), check Num Lock",
  pleaseFillRequiredFields: "Please fill required fields.",
  numberMustBePositive: "Number must be positive.",
  numberMustBeAnInteger: "Number must be an integer.",
  numberMustBeLessThan: "Number must be less than {0}.",
  profitMarginMustBeLessThan100: "Profit margin must be less than 100%.",

  // Nav
  myOrganizations: "My organizations",
  createNew: "Create new",
  noActiveClinic: "No active clinic",
  pleaseChooseClinic:
    "Please choose an active clinic, or create a new organization, or a new clinic from an existing organization.",
  setActiveClinic: "Choose a clinic...",
  search: "Search",
  searchPetOwner: "Search pet or owner",
  myPets: "My pets",
  pets: "Pets",
  addPet: "Add pet",
  findVet: "Find veterinarian",
  settings: "Settings",
  noResult: "No results.",
  noPetResult: "No pets found.",
  noResultTypeCustomValue: "No results, type custom value",
  findClinic: "Find clinic",
  findClinicByMap: "Find clinic on the map",
  finish: "Finish",
  energySaveMode: "Energy save mode",
  debugMode: "Debug mode",
  darkMode: "Dark mode",
  contactSupport: "Contact support",
  searchingPets: "Searching pets...",
  searchingOwnersWithoutPets: "Searching owners without pets.",

  // login
  login: "Login",
  signIn: "Sign in",
  signInToYourAccount: "Sign in to your account",
  logout: "Logout",
  signOut: "Sign out",
  switchAccount: "Switch account",
  profile: "Profile",
  profileSettings: "Profile settings",
  yourProfile: "Your profile",
  setupYourProfileLater: "Setup your profile later?",
  password: "Password",
  petOrDoctor: "Are you a pet owner or a doctor?",
  loginPetOwnerLabel: "Pet Owner",
  loginVeterinaryWorkerLabel: "Veterinary worker",
  registration: "Registration",
  signUp: "Sign up",
  signUpHere: "Sign up here",
  loginWithFacebookText: "Continue with Facebook",
  loginWithGoogleText: "Continue with Google",
  forgotPassword: "Forgot password?",
  newPassword: "New password",
  newPasswordAgain: "New password again",
  savePassword: "Save password",
  noAuthForLogin: "You have no permission for this page.",
  workerLoginDisabled: "You don't have access to the worker's interface.",
  thirdPartyCookiesMustBeEnabled: "Third-party cookies must be enabled in your browser.",
  petOwnerMode: "Pet owner mode",
  employeeMode: "Clinic employee mode",
  continueAsOwner: "Continue as owner",
  continueAsEmployee: "Continue as employee",
  ownerSideInfo: "Create and manage your pets as owner.",
  employeeSideInfo: "Create and manage the clinic you own or work at.",
  changeRoleInfo: "You can switch between doctor mode and pet owner mode at any time in your profile menu.",
  saveAndContinue: "Save and continue",
  alreadyHaveAccount: "Already have an account?",
  noAccountYet: "Don’t have an account yet?",
  loginHere: "Login here",
  signInHere: "Sign in here",
  backToLogin: "Back to login",

  // Multi-factor authentication
  mfaAuthentication: "Authentication",
  mfaDescriptionSms: "Please enter the verification code that you received by SMS to confirm your identity.",
  mfaDescriptionEmail:
    "Please enter the verification code that you received by email to confirm your identity or click on the confirm button in the email.",
  mfaCancel: "Cancel",
  mfaConfirm: "Confirm",
  mfaLoginFailed: "Login failed",
  mfaInvalidCodeFormat: "Invalid code format; must be 6 digits",
  enterVerificationCode: "Enter verification code",

  // Globalvet settings
  afterAcceptContractText: "Congratulations, now you can use GlobalVET!",
  navigateToYourClinicPageLink: "Visit your clinic's page",
  navigateToYourClinicPage: " and continue your journey in the GlobalVet system.",

  // ClinicInvoices
  from: "From",
  to: "To",
  invoiceState: "Invoice state",
  invoiceCreationDate: "Creation date",
  pdfNotAvailable: "Failed to download PDF invoice",
  sendReminder: "Send reminder",
  invoiceBillingInProgress: "Pending invoices",
  invoiceBilled: "Billed ivoices",
  emailReminderSent: "Reminder was sent",
  withdrawingSuccessful: "Invoice withdrawing was successful",
  warningYouAreAboutToWithdrawAnInvoice: "You are about to withdraw an invoice.",
  addProducts: "Add products",
  paymentAndCustomerInfo: "Payment and customer info",
  itemsAdded: "Items added",
  proceedToInvoices: "Proceed to invoices",
  tryLater: "Try later",
  type: "Type",
  searchToolTip: "Customer name, phone number, e-mail address, pet name",
  dateFilterToolTip:
    "Filter by the date of issue: invoice creation date. Filter by date of payment: date of partial or total payment.",
  custom: "Custom",
  copyAsNew: "Copy as new",
  addAllItemsToo: "Add all inventory items too",
  addAllFeesToo: "Add all service fees too",

  // Registration
  registerWithFacebookText: "Register with Facebook",
  registerWithGoogleText: "Register with Google",
  confirmEmailOk: "Confirmation e-mail sent to ",
  emailAlreadyUsed: "Email address is already used.",
  phoneAlreadyUsed: "Phone number is already used: ",
  emailAddress: "Email address",
  enterYourEmail: "Enter your email",
  emailNotValid: "This is not a valid email.",
  verifyYourEmail: "Verify your email",
  emailVerification: "Email verification",
  emailVerificationLinkInvalid: "Your verification link is invalid, please try again.",
  choosePassword: "Choose password",
  passwordValid:
    "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and the length should be at least 8 characters.",
  confirmPassword: "Confirm password",
  confirmValid: "Password and confirmation password must be the same.",
  createAccount: "Create account",
  mobilePhone: "Mobile phone",
  mobilePhoneNumber: "Mobile phone number",
  primaryPhoneNumberDetails:
    "Your primary phone number should be a {0}. Later, you can add landline phone number as well.",
  primaryPhoneNumberDetailsMobilePhone: "mobile phone number",
  landlinePhone: "Landline phone",
  phoneNotValid: "This is not a valid phone number.",
  publicForVeterinaryWorkers: "Public for veterinary workers",
  prefix: "Name prefix",
  prefixPlaceholder: "e.g. Dr.",
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  invalidPhoneNumber: "Invalid phone number:",
  allowNotifications: "Allow notifications?",
  allowNotificationsButton: "Allow notifications",
  notificationInfo: "If you don’t want to miss any important appointment, please allow notifications.",
  receiveSMSNotifications: "I want to receive SMS notifications about important events",
  errorAccessingSocialData: "Couldn't fetch user data from social provider.",
  accountAlreadyExists: "This user has already registered.",
  successfulRegistration: "Registration successful.",
  haveVetRegistrationNumber: "I am an authorized veterinary worker with a national license number.",
  registrationNumber: "Veterinarian license number",
  addRegistrationNumber: "Add veterinarian license number",
  vetRegistrationNumbersTitle: "Veterinarian license numbers",
  registrationSuccessful: "Registration successful",
  registrationSuccessfulMessage: "Congratulations! You have successfully created your account. Welcome to GlobalVET!",
  continueRegistrationMessage:
    "You can continue the registration process with setting up your profile after signing in.",
  goToSignIn: "Go to Sign in",
  pleaseCheckYourEmailInbox: "Please, check your email inbox",
  sentYouEmailLink: "We have sent an activation link to your email address {0}",
  pleaseCheckSpam: "Please be sure to check your spam folder too.",
  autoLoginChooseRole: "Please choose if you are a pet owner or an organization worker.",
  autoLogin: "You will be automatically logged in.",
  iHaveReadAndAcceptPolicy: "I have read and I accept the ",
  iHaveInformedOwnerAboutPolicy:
    "I have informed the pet owner about the fact that the veterinary service provided here will be documented in the GlobalVET system along with the personal details of the pet owner for quality assurance and traceability purposes.",
  pin: "PIN number",
  setPin: "Set or change PIN number",
  usePinInfoTitle: "Comfortable and safe usage",
  usePinInfo: "In order to use the software comfortably and safely, please set a PIN number.",
  pinUsage: "For veterinarian confirmation and quick user change.",
  pinFormat: "Pin has to be four numbers.",
  savePin: "Save PIN number",
  pinSaved: "Pin saved successfully",
  enterPin: "Please enter your PIN number",
  vetNumber4digits: "Registration number has to be 4 numbers.",
  countryFieldToolTip: "The country in which you provide or use veterinary services.",
  countryOfResidence: "Country of residence",
  createPetOwner: "Create new pet owner",
  gdprAgreement: "I agree to GlobalVET's {0}.",
  gdprAgreementGdpr: "GDPR Consent",
  clinicCreationAgreement: "I agree to GlobalVET's {0} and {1}.",
  clinicCreationAgreementGeneral: "General Terms and Conditions",
  clinicCreationAgreementDataProcessing: "Data Processing Terms and Conditions",
  fillMissingData: "Fill missing information",
  searchPetOwnersToolTip: "Customer name, personal number, phone number, e-mail address, or pet name",

  // Password Recovery
  passwordRecovery: "Password recovery",
  recoveryEmailOk: "Recovery email sent to ",
  recoverySmsOk: "Recovery SMS sent to ",
  userNotFoundWithThisEmail: "User not found with this email address.",
  userNotFoundWithThisPhone: "User not found with this phone number.",
  simpleError: "An error has occurred.",
  sendRecoveryEmail: "Send recovery email",
  sendRecoverySms: "Send code via text message",
  recoverViaEmail: "Recover via email",
  recoverViaMobile: "Recover via mobile phone",
  emailSentSuccessfully: "Email sent successfully.",

  // AddNewPet
  newPet: "New pet",
  addNewPet: "Add new pet",
  newPetAndOwner: "New pet and owner",
  newOwner: "New owner",
  createNewOwner: "Create new owner",
  name: "Name",
  gender: "Gender",
  color: "Color",
  species: "Species",
  breed: "Breed",
  dateOfBirth: "Date of birth",
  invalidDate: "Invalid date.",
  years: "years",
  monthCnt: "months",
  createProfileForPet: "Create profile for my pet",
  chipNumber: "Chip number",
  tattooNumber: "Tattoo number",
  noPetYet: "No pet added yet",
  addFirstPet: "Add your first pet",

  // Edit Pet
  discardChanges: "Discard changes",
  editPet: "Edit pet",
  seeAllPets: "See all pets",
  seeAllHistory: "See all history",

  // Pet profile
  ownerPhone: "Owner's phone",
  applyFilters: "Apply filters",
  petProfile: "Pet profile",
  editProfile: "Edit {0}'s profile",
  ownerProfile: "Owner profile",
  searchBreed: "Search breed...",
  uncompleted: "uncompleted",
  mixed: "Mixed",
  specifyBreed: "Specify breed",

  // Pending pets
  pendingPets: "Pending pets",
  seeAllPendingPets: "See all pending pets",
  acceptOwnership: "Accept ownership",
  declineOwnership: "Decline ownership",
  changeOwner: "Change owner",
  currentPetOwner: "Current pet owner",
  addNote: "Add note",
  note: "Note",
  clinicHasRegisteredYourPet: "{0} clinic has registered your pet.",
  createNewPet: "Create new pet",
  updateExistingPet: "Update my existing pet",
  areYouSureToDecline: "Are you sure you want to decline ownership?",
  waitingForOldOwner: "Waiting for the previous owner to confirm the ownership change of {0}.",
  waitingForNewOwner: "Waiting for new owner to accept ownership of {0}.",
  acceptAsNewOwner: "{0} has granted you the pet ownership of {1}",
  acceptAsOldOwner: "{0} accepted the ownership of {1}. Do you approve the adoption?",
  newOwnerEmail: "New owner email",

  // Email sending
  emailTo: "To",
  emailSubject: "Subject",
  emailMessage: "Message",
  attachToEmail: "Attach to email",
  emailSend: "Send",
  newEmail: "New e-mail",
  defaultInsuranceEmailTitle: "Medical documents, {0} ({1})",
  petOwnerEmails: "petOwnerEmails",

  // MainOwner
  MAIN: "Main",
  MY_PETS: "My Pets",
  APPOINTMENTS: "Appointments",
  PRESCRIPTIONS: "Prescriptions",
  INVOICES: "Invoices",
  FIND_CLINIC: "Find Clinic",
  mainPage: "Main",
  myBills: "Bills",
  myAppointments: "My appointments",
  addAppointment: "Add appointment",
  reserveButtonText: "Confirm reservation",
  organization: "Organization",
  clinic: "Clinic",
  date: "Date",
  reservationType: "Reservation type",
  vet: "Veterinarian",
  pet: "Pet",

  // ProfilePet
  dontHaveAccessToPetProfile: "You don't have access to this pet profile.",
  appointments: "Appointments",
  pictureAlt: "Picture",
  warningChipNumberNotConfirmed: "Chip number is not confirmed yet.",

  // CalendarDayViewForm
  chooseCollab: "-- Choose a colleague --",
  searchEmployee: "Search employee...",
  assistant: "Assistant",
  authorizedAssistant: "Authorized Assistant",
  receptionist: "Receptionist",
  Vet: "Vet",
  areYouSure: "Are you sure?",
  deleteConfirmationTitle: "Confirm delete",
  deleteConfirmation: "Are you sure you want to delete this item?",
  backToCalendar: "Back to calendar",
  editDailyForm: "Edit Daily Form",
  collaborators: "Colleagues",
  id: "Id",
  startTime: "Start time",
  endTime: "End time",
  modify: "Modify",
  addCollab: "Add colleague",
  deleteAll: "Delete all",
  addNewCollab: "Add new colleague",
  roleOfChosenCollab: "Role of the chosen colleague",
  repetition: "Repetition",
  turnedOff: "Turned off",
  turnedOn: "Turned on",
  turnOffRepetition: "Turn on/off repetition.",
  chooseRepfordays: "Choose repetition for these days:",
  setRepforWeeks: "Number of weeks till end",
  onThisDate: "On",
  end: "Ends",
  save: "Save",
  cancel: "Cancel",
  noShiftAtThisTime: "There are no available colleague at this time.",
  downloadMedicalRecordPDF: "Download medical record (PDF)",
  downloadInvoicePDF: "Download invoice (PDF)",
  downloadPDF: "Download PDF",
  openMedicalRecord: "Open medical record",
  corrected: "Corrected",
  underCorrection: "Under correction",

  // Substitution form
  substitution: "Substitution",
  substitutionTitle: "Resolve blocking reservations",
  absentEmployee: "Absent employee",
  startDate: "Start date",
  endDate: "End date",
  startDateTime: "Start date and time",
  endDateTime: "End date and time",
  noReservationsSaveSubstitution:
    "{0} doesn't have any reservations between {1} and {2}. You can delete the shifts safely.",
  cancelAndNotifyPetOwner: "Cancel and notify pet owner",
  chooseSubstitute: "-- Choose substitute or cancel reservation --",
  substitute: "Substitute",
  employeeToDelete: "Employee to remove",
  saveAndDeleteEmployee: "Save and remove employee",
  userHasReservationsBetween:
    "{0} has reservations between {1} and {2}. Please select substitutions or cancel reservations.",
  deleteShifts: "Delete shifts",
  deleteShift: "Delete shift",
  areYouSureToDeleteShift: "Are you sure you want to delete this shift?",
  deleteShiftsFor: "Delete shifts for",
  selectShiftsFrom: "Select shifts from",
  startOfShift: "Start of shift",
  endOfShift: "End of shift",
  startDateEndDateErrorMsg: "End date can not be earlier than start date",
  tooLongShiftErrorMsg: "Shift is too long. Max length: 24 hours.",

  // OrganizationStatistics
  payments: "Payments",
  month: "Month",
  amount: "Amount",
  status: "Status",
  pay: "Pay",
  paySelected: "Pay selected",
  payWithRegisteredCard: "Pay with registered card",
  payWithDifferentCard: "Pay with different card",
  paymentSuccessful: "Payment was successful",
  paymentUnSuccessful: "Payment failed",
  cause: "Cause",
  downloadInvoicePdf: "Download Invoice",

  // Verification
  emailVerificationIsSuccessful: "Email verification is successful.",
  emailVerificationIsSuccessfulInfo: "You can now close this tab or continue the process with signing in.",
  errorDuringEmailVerification: "An error occurred during email verification.",
  confirmEmail: "Confirmation e-mail",
  goToLogin: "Go to login page",
  goToMainPage: "Go to main page",
  redirectInSeconds: "You will be redirected in {1} seconds...",

  // CreateClinic
  createClinic: "Create clinic",
  bracnhName: "Clinic name",
  create: "Create",
  addBasicDetails: "Add Basic Details",
  addressSameAsOrgPrimaryAddress: "Same as primary organization address",
  orgHasNoPrimaryAddress: "The organization has no primary address",
  invalidAddress: "Invalid address",

  // CreateOrganization
  createOrganization: "Create Organization",
  vatNotValid: "VAT number must contain at least 5 digits!",
  chargeWarning:
    "The organization's monthly costs will be charged at the first day of the next month. The price is determined by usage.",
  baseChargeWarning:
    "A small amount of money might be charged against the card to test it, and it will be refunded after the card registration is completed.",
  creditCardModification: "Credit Card Modification",
  giveNewCardData: "Please give your new card's data:",
  creditCardAuthFailed:
    "Your credit card was rejected because it could not be charged. Please consult with your bank that issued the card.",
  creditCardDeclined: "Your credit card was declined.",
  creditCardExpired: "Your credit card has expired.",
  incorrectCVC: "Your credit card's security code was incorrect.",
  incorrectZIP: "Your credit card's ZIP code was incorrect.",
  incorrectNumber: "Your credit card number was incorrect.",
  invalidCVC: "Your credit card's security code was invalid.",
  invalidNumber: "Your credit card number was invalid.",
  stripeHandleError: "Our services related to payments are currently unavailable. Please try later.",
  incorrectName: "Your card holder's name was invalid.",
  chargeFailed: "The payment did not succeed. Please check your card details.",
  chargeSucceeded: "The payment succeeded.",
  businessName: "Business name",
  businessRegistrationNumber: "Registration number",
  invalidRegistrationNumber: "Invalid registration number",
  businessStructure: "Business structure",

  // EditCategory
  cannotRetriveCatData: "Cannot retrieve category data",
  deleteError: "Delete error",
  editCategory: "Edit Category",
  createCategory: "New category",
  catNameWithDots: "Product's name...",
  delete: "Delete",
  deletingCat: "Deleting category",
  areYouSureDeleteCat: "Are you sure to delete {0}?",
  hide: "Hide Items",
  see: "See Items",
  newItem: "New product",

  // EditItemModal
  cannotRetriveItemData: "Cannot retrieve item data",
  editItem: "Edit product",
  createItem: "Create product",
  price: "Price",
  itemPriceWithDots: "Product's price...",
  minimalLevel: "Minimal Level",
  stock: "Stock",
  deleteItem: "Delete product",
  areYouSureDeleteItem: "Are you sure to delete {0}?",
  chooseSpecies: "-- Choose species --",
  nameCannotBeEmpty: "Name cannot be empty!",
  itemNameWithDots: "Product's name...",
  itemUnitWithDots: "Product's unit...",
  deletingItem: "Deleting product",
  acquisitionPrice: "Purchase price",
  acquisitionVat: "Purchase VAT",
  salePrice: "Sale price",
  sellingVat: "Selling VAT",
  acquisitionUnitQuantity: "Unit quantity",
  acquisitionUnit: "Unit",
  sellingDivisor: "Selling divisor",
  currency: "Currency",
  applicableToSpecificSpecies: "Applicable to specific species only",
  customPrice: "Custom price",

  // FindClinic
  setAddress: "Please set your address at your profile first.",
  choooseThisClinic: "Choose clinic",
  warningUserHasNoPrimaryAddress: "User has to set a primary address first to make reservation.",
  warningUserHasNoPrimaryAddressLinkToProfile: "Open your profile settings",

  // Inventory
  cannotRetriveClinicData: "Cannot retrieve clinic data",
  inventorySettings: "Inventory settings",
  filter: "Filter",
  filters: "Filters",
  clearFilters: "Clear filters",
  addItemFromVara: "Add item from VARA",
  addItem: "Add item",
  createNewInventoryItem: "Create new inventory item",
  noItemsAdded: "No items added yet.",
  areYouSureToDeleteCategory:
    "Are you sure you want to delete inventory category? All items assigned to the category will be deleted too.",
  refillStock: "Orders",
  resetRefillValues: "Reset refill values",
  inventoryItemDetails: "Inventory item details",
  orderHistory: "Order history",
  orderHistoryUnit: "Order",
  newOrder: "New order",
  usageHistory: "Usage history",
  usageHistoryUnit: "Usage",
  countingHistory: "Audit history",
  countingHistoryUnit: "Audit",
  newCounting: "New audit",
  dateOfOrder: "Date of order",
  countedQuantity: "Counted quantity",
  countingDetails: "Counting details",
  totalQuantityInPackages: "Total quantity in packages",
  unitPriceCalculator: "Unit price calculator",
  totalPackagePrice: "Total package price",
  stockUsage: "Usage",
  editUsage: "Edit usage",
  editStock: "Edit stock",
  deleteUsage: "Delete usage",
  deleteStock: "Delete stock",
  deleteCounting: "Delete counting",
  stockAddedSuccessfully: "Stock added successfully.",
  stockUpdatedSuccessfully: "Stock updated successfully.",
  stockDeletedSuccessfully: "Stock deleted successfully",
  countingDeletedSuccessfully: "Item counting deleted successfully",
  areYouSureToDeleteItemStock: "Are you sure to delete stock of {0}?",
  areYouSureToDeleteItemUsage: "Are you sure to delete usage of {0}?",
  areYouSureToDeleteItemCounting: "Are you sure to delete counting of {0}?",
  countInventory: "Audit",
  stockOperations: "Stock operations",
  profit: "Gross margin",
  profitMargin: "Gross margin %",
  profitMarginInfo: "Profit margin is the ratio of profit to revenue.",
  currentStock: "Current stock",
  countedStock: "Counted stock",
  countingDifference: "Difference",
  addMissingStock: "Add missing stock",
  finishCounting: "Finish counting",
  stockIsTheSame: "Quantity is the same",
  stockIsDifferent: "Quantity is different",
  salePriceOverTime: "Sale price ({0}) over time",
  purchasePriceOverTime: "Purchase price ({0}) over time",
  purchaseVsSalePriceTitle: "Change of purchase price and sale price",
  skip: "Skip this step",
  lowStockToolTipInfo: "When to remind you about low level.",
  itemCreatedSuccessfully: "Inventory item {0} was created successfully.",
  itemUpdatedSuccessfully: "Inventory item {0} was updated successfully.",
  inventoryManagementUpdatedInfo1: "Attention! Inventory stock management has been updated.",
  inventoryManagementUpdatedInfo2:
    "New features: stock handling, inventory counting, item analytics and history, profit margin and unit price calculators.",

  // Analytics
  analytics: "Analytics",
  notEnoughData: "Not enough data",

  // InventoryStatistics
  cannotRetriveInventoryData: "Cannot retrieve clinic's inventory data.",
  itemName: "Product's name",
  allItems: "All",
  showOnlyLow: "Low stock levels only",
  showOnlyStock: "In stock",
  category: "Category",
  warningAt: "Warning at",
  lowLevel: "Minimum stock level",
  searchCategory: "Search categories",

  // InventoryStatus
  refill: "Refill",
  options: "Options",
  edit: "Edit",
  addToTreatment: "Add to treatment",
  ok: "OK",
  operations: "Operations",
  saveChanges: "Save changes",

  // Invoice History
  costAccounting: "Cost accounting",
  treatmentData: "Treatment data",
  invoiceDetails: "Invoice details",
  code: "Code",
  clinicIdentifier: "Clinic identifier",
  totalCost: "Total cost",
  remainingCost: "Remaining cost",
  sendReminderEmail: "Send email reminder",
  amountToPay: "Amount to pay",
  markAsPaid: "Mark as paid",
  partialPayment: "Partial payment",
  emailReminder: "Email reminder",
  addCorrection: "Add correction",
  paidAmount: "Paid amount",
  payerName: "Payer's name",
  payer: "Payer",
  payerType: "Payer type",
  PET_OWNER: "Pet owner",
  INSURANCE_COMPANY: "Insurance company",
  paymentMethod: "Payment method",
  paymentMethodShort: "Method",
  newRemainingCost: "Remaining cost",
  customer: "Customer",
  issuer: "Issuer",
  paymentHistory: "Payment history",
  modifierParty: "Modifier",
  paymentType: "Payment type",
  warningYouAreAboutToSendEmail: "You are about to send an email. Are you sure?",
  emailReminders: "Email reminders",
  onlinePayment: "Online Payment",
  creditCardDetails: "Credit card details",
  giveYourCardData: "Please give your card details:",
  noData: "n/a",
  noInvoiceHistoryData: "There is no payment history.",
  notPaid: "Not paid",
  filterDateType: "Date type",
  filterByDateOfIssue: "Filter by date of issue",
  filterByDateOfPayment: "Filter by date of payment",
  filterByName: "Filter by name",
  allowOnlinePaymentAndSendEmail: "Allow posterior online payment and email link",
  invoiceSent: "Sent",
  sentToFortnox: "Sent to Fortnox",
  sentToSzamlazz: "Sent to Szamlazz.hu",
  failedToSendToFortnox: "Failed to send to Fortnox",
  failedToSendToSzamlazz: "Failed to send to Szamlazz.hu",
  failedToConnectToFortnox: "Failed to connect to Fortnox",
  sendToFortnox: "Send to Fortnox",
  sendToSzamlazz: "Send to Szamlazz.hu",
  fortnox: "Fortnox",
  szamlazz: "Szamlazz.hu",
  szamlazzIntegrationInfo1:
    "GlobalVet uses Számla Agent to send invoices to Számlázz.hu. Read more about Számla Agent: {0}.",
  szamlazzIntegrationInfo2:
    "In order to start using automatic invoicing by Számlázz.hu, copy paste your Számla Agent API key:",
  invoicingMethodNone: "None",
  explanation: "Explanation",
  paymentDetails: "Payment details",
  paymentState: "Payment state",
  retry: "Retry",
  noEmailReminderSent: "Email reminder was not sent.",
  download: "Download",
  print: "Print",
  printOrDownloadInvoice: "Would you like to print or download invoice?",
  noInvoicesToDisplay: "There are no invoices to display.",
  withdrawInvoice: "Withdraw invoice",
  estimatedCost: "Estimated cost",
  goToInvoices: "Go to invoices",
  editInvoice: "Edit invoice",
  pendingInvoice: "Pending",
  plusVat: "+ VAT",
  invalidAmount: "Invalid amount",
  gross: "gross",
  net: "net",
  grossPrice: "Gross price",
  netPrice: "Net price",
  cashIncome: "Cash income",
  cardIncome: "Credit card income",
  transferIncome: "Transfer income",
  directDebitIncome: "Direct debit income",
  onlineIncome: "Online income",
  insuranceIncome: "Insurance covered income",
  totalIncome: "Total income",
  paymentSummary: "Payment summary",
  invoicingMethod: "Issuing method",
  costSummary: "Cost summary",
  incomeSummary: "Income summary",
  downloadIncomeSummary: "Download income summary",
  printIncomeSummary: "Print income summary",
  totalIncomeIsZeroInfo: "Total income is zero.",
  sales: "Sales",
  newSale: "New sale",
  needInvoice: "Send to Fortnox",
  invoicingSystemIsNotIntegrated: "No invoicing system is integrated.",
  goTo: "Go to: {0} to check available integrations.",
  sameAsCustomerData: "Same as customer data",
  billingInfo: "Billing information",
  invoiceHasBeenSentInEmail: "Invoice has been sent to the following email address:",
  pleaseConfirmMarkAsPaid: "Please confirm that the invoice has been paid:",
  successfullyPaid: "The invoice has been successfully marked as paid.",
  seeAllPaymentHistory: "See all payment history",
  seeAllEmailReminders: "See all email reminders",
  seeAllInvoiceItems: "See all invoice items",
  withdrawPayment: "Withdraw payment",
  payment: "Payment",
  paidImmediately: "The full amount is paid immediately at the clinic",
  dueDateForPayment: "Due date",
  dueDate: "Due date",
  invoiceCreated: "Invoice created successfully",
  deleteInvoice: "Delete invoice",
  withdrawReadyForPayment: "Withdraw 'ready for payment'",
  insuranceTitle: "Split invoice",
  payLater: "Pay later",
  invoiceNumber: "Invoice number",
  invoiceSearchCategory: "Search category",
  szamlazzAvailable: "Számlázz.hu integration available",
  goToSzamlazz: "Go to Számlázz.hu itegration.",
  invoicing: "Invoicing",
  integrationTurnedOff: "Invoice integration of {0} turned off successfully.",
  noInvoicingSystemSupported: "No invoicing system supported by GlobalVet in your country.",

  // MainDoctor
  welcome: "Welcome",
  changeClinic: "Change clinic",
  selectNone: "Select none",
  selectActiveClinic: "Select an active clinic",
  as: " as {0}",
  activeClinic: "Active clinic",
  pleaseSelectActiveClinic: "Please select an active clinic",
  noClinicAssigned: "There are no clinics assigned to you.",
  pending: "You have a pending request from user {0} to join {1} as {2}.",
  pendingRequest: "Pending request",
  employmentRequestAccepted: "Employment request accepted",
  noReservaionsOnThisDay: "No reservations and shifts registered on this day.",
  noShiftsOnThisWeek: "No shifts on this week.",
  newShift: "New shift",
  createShift: "Add shift",
  noReservationsFound: "No reservations found",
  pleaseCheckTheLocation: "Please, check the location on the map!",
  owners: "Pet owners",
  skipForNow: "Skip for now",
  addItLater: "Add it later?",
  setItLater: "Set it later?",
  setPinNumber: "Set PIN number",
  noOrganizationYet: "No organization added yet",
  noClinicYet: "No clinic added yet",
  addFirstOrganization: "Add your first organization",
  pendingInvitations: "Pending invitations",

  // Management
  fees: "Fees",
  country: "Country",
  chooseCountry: "-- Choose country --",
  baseFeeForClinic: "Base fee per clinic",
  baseFeeForEmployee: "Base fee per employee per month",
  baseFeeForVet: "Base fee per vet per month",
  baseFeeForAssistant: "Base fee per assistant per month",
  baseFeeForReceptionist: "Base fee per receptionist per month",
  appointmentFee: "Appointment fee",
  medicalRecordFee: "Medical record fee",
  smsFee: "SMS fee",
  inventoryUsageFee: "Inventory usage fee",
  currencyValid: "Currency must be a floating-point number with two digits accuracy",
  selectedCurrency: "The selected country's currency: {0}",
  editDiscounts: "Edit Discounts",
  discounts: "Discounts",
  existingDiscounts: "Existing Discounts",
  resetSettings: "Reset Settings",
  freeSubscription: "Free subscription",
  active: "Active",
  originalPrice: "Original price",
  discountedPrice: "Discounted price",
  warningSaveDiscount:
    "With the modification of subscriptions, the fee calculation of the organization will change immediately.",
  warningSaveCountryFees:
    "With the modification of the fees of the country, the fee calculation of the affected organizations will change immediately.",
  globalSettings: "Settings",
  policies: "Policies",
  unemployedWorkerLoginEnabled: "Enable worker login to unemployed users",
  existingPrivacyPolicies: "Existing Privacy Policies",
  createPrivacyPolicy: "Create Privacy Policy Statement",
  editPrivacyPolicy: "Edit Privacy Policy Statement",
  languages: "Languages",
  version: "Version",
  grammarChange: "Grammar change only",
  infoNoNewVersion: "No new version will be stored when the checkbox is checked",
  successfulSave: "Saved successfully.",
  addPolicy: "Add policy",
  editPolicy: "Edit policy",
  deletePolicy: "Delete policy",
  deletePolicyConfirmation: "Are you sure you want to delete this policy?",
  warningDeletePolicy: "The existing policy will be archived in case of upcoming lawsuits.",
  privacyPolicy: "Privacy Policy",
  privacyPolicyIncompleteWarning:
    "This privacy policy statement may be incomplete or missing important information. Please keep in mind that the information presented here is most likely not final and may be subject to modifications and adjustments.",
  manageCookies: "Manage cookies",
  gdprConsent: "GDPR Consent",
  generalTermsAndConditions: "General Terms and Conditions",
  dataProcessingTermsAndConditions: "Data Processing Terms and Conditions",
  policyStatement: "Policy statement",
  insuranceCompany: "Insurance company",
  insuranceCompanies: "Insurance companies",
  fax: "Fax",
  paymentTerms: "Payment terms",
  users: "Users",
  profilePicture: "Profile picture",
  petProfilePicture: "Pet profile picture",
  clinicLogo: "Logo",
  facebookId: "Facebook ID",
  facebookEmail: "Facebook email",
  googleId: "Google ID",
  googleEmail: "Google email",
  ipAddress: "IP address",
  userAgent: "User agent",
  gdprExtractSentSuccessfully: "Extract is sent successfully",
  sendExtractToUser: "Send extract to user",
  changePassword: "Change password",
  gvAppVersions: "App versions",
  gvServiceName: "Service",
  gvServiceVersion: "Version",
  gvLastCommit: "Last commit",
  serviceFees: "Service fees",
  customFees: "Custom fees",
  minimalChargeValue: "Minimal charge value",
  paymentDate: "Payment date",
  allOrganizations: "All organizations",
  trialDiscountPercent: "Trial discount",
  trialPeriodDays: "Length of trial period",
  trialEndReminderBeforeDays: "Reminder about trial end",
  daysBefore: "days before",
  discount: "discount",
  siteManagers: "Site managers",
  siteManager: "Site manager",
  permissions: "Permissions",
  fullAccess: "Full access",
  config: "Config",
  countries: "Countries",
  activeCountries: "Active countries",
  inactiveCountries: "Other countries",
  paymentAndBilling: "Payment and billing",
  paymentSystem: "Payment system",
  secretKey: "Secret key",
  billingSystem: "Billing system",
  clientId: "Client ID",
  clientSecret: "Client secret",
  testInvoices: "Test invoices",
  testInvoice: "Test invoice",
  createTestInvoice: "Create test invoice",
  noTestInvoiceCreatedYet: "No test invoice is created yet.",
  testInvoiceWarning:
    "If you've provided the credentials of a production environment, please don't forget to withdraw the created invoice within the billing system.",
  insuranceInvoiceSettings: "Insurance invoice settings",
  systemIntegrations: "System integrations",
  systemIntegrationsAllWorking: "All the integrations are working",
  discountFree: "Free",
  discountCustom: "Custom",
  untilWithdrawn: "until withdrawn",
  remoteAccess: "Remote access",
  foreignClinicWarning:
    "You are accessing a foreign clinic as a site manager. Be careful not to make any unintended changes!",
  leaveClinic: "Leave clinic",
  clinicFeatures: "Clinic features",
  feature: "Feature",
  commissionRate: "Commission rate",
  discountedCommissionRate: "Discounted commission rate",
  originalCommissionRate: "Original commission rate",

  // NavSiteManager
  SITE_MANAGER_SETTINGS: "Settings",
  BILLING: "Billing",
  VET_DOCUMENTS: "Vet documents",
  INSURANCE: "Insurance",
  USERS: "Users",
  FEEDBACKS: "Feedbacks",
  CLINICS: "Clinics",
  ANALYTICS: "Analytics",
  VIDEOCHAT_MONITOR: "Videochat monitor",

  // MedicalRecord
  open: "Open",
  examination: "Examination",
  dontHaveAccessToMedicalRecord: "You don't have access to this medical record.",
  petsMedicalRecord: "{0}'s Medical Record",
  unknownMedicalRecord: "Unknown's Medical Record",
  petOwner: "Pet owner",
  petName: "Pet Name",
  anamnesis: "Anamnesis",
  physicalExamination: "Physical examination",
  diagnostics: "Diagnostics",
  diagnosis: "Diagnosis",
  treatment: "Treatment",
  recommendation: "Recommendation",
  prognosis: "Prognosis",
  certificate: "Certificate",
  referral: "Referral",
  changeIndicator: "Change Indicator",
  weights: "Weights",
  vaccines: "Vaccines",
  medicines: "Medicines",
  history: "History",
  diagnoses: "Diagnoses",
  differentialDiagnoses: "Differential Diagnoses",
  treatments: "Treatments",
  sign: "Sign",
  signCorrection: "Sign correction",
  openForCorrection: "Open for correction",
  correction: "Add correction",
  signedBy: "Signed by",
  correctionSignedBy: "Correction signed by",
  signed: "Signed",
  correctionSigned: "Correction signed",
  signMedicalRecord: "Sign medical record",
  signer: "Signee",
  signText: "After signing the medical record of {0} it becomes read-only and cannot be edited again.",
  addNewInfo: "Add new Information",
  weight: "Weight",
  chooseFromWithDots: "Choose from...",
  close: "Close",
  warning: "Warning",
  error: "Error",
  itemsAtNegativeStock: "A stock refill was possibly forgotten and the item's stock will go below zero.",
  invoice: "Invoice",
  cashInvoice: "Cash invoice",
  createdBy: "Created by",
  created: "Created",
  chronicDiseases: "Chronic Diseases",
  chronicDiseaseHistoryAdded: "{0} registered.",
  chronicDiseaseHistoryRemoved: "{0} removed.",
  connectedPrescriptions: "Prescriptions",
  addInventoryItems: "Add inventory items",
  addServiceFees: "Add service fees",
  usedItems: "Used inventory items",
  usedFees: "Used service fees",
  usedItemsAndFees: "Used inventory items and other fees",
  noServiceFeesAdded: "No fees added yet.",
  medicalRecordHistory: "Medical record history",
  hematology: "Hematology",
  biochemistry: "Biochemistry",
  parameters: "Parameters",
  conventionalUnits: "Conventional Units",
  referenceRange: "Reference range",
  result: "Result",
  costs: "Costs",
  inProgress: "In progress",
  notStarted: "Not started",
  otherInventoryItems: "Other inventory items",
  deleteRelatedItems: "Remove related items as well",
  displayPetOverview: "Display pet overview",
  followUp: "Follow-up",
  createInvoice: "Create invoice",
  addDocuments: "Add documents",
  addFiles: "Add files",
  fileTooLarge: "File is too large. The maximum allowed size is 10 MB.",
  mailedTo: "Mailed to",
  inventoryItems: "Inventory items",
  searchInventoryItems: "Search items",
  searchFees: "Search fees",
  medicalRecordId: "Medical record id",
  resetTreatmentDescriptions: "Reset treatment descriptions",
  contentNotAvailable: "Content is not available.",

  // Prescription
  ePrescription: "ePrescription",
  pharmacy: "Pharmacy",
  prescription: "Prescription",
  prescriptions: "Prescriptions",
  newPrescription: "New prescription",
  prescriptionItem: "Prescription items",
  addItems: "Add Items",
  product: "Product",
  substance: "Substance",
  barcode: "Barcode",
  formulation: "Formulation",
  pharmaceuticalForm: "Pharmaceutical form",
  typeOfPacking: "Packing type",
  strength: "Strength",
  manufacturer: "Manufacturer",
  notAddedYet: "No items added to the prescription yet.",
  notPrescribable: "This product can not be prescribed.",
  withdrawalDate: "Whitdrawal date",
  details: "Details",
  packages: "Packages",
  packagingQuantity: "Package quantity",
  ddd: "Defined Daily Dose (DDD)",
  noDDD: "No available DDD.",
  noManufacturer: "Manufacturer unknown",
  addToPrescription: "Add to prescription",
  dosage: "Dosage",
  instructions: "Instructions",
  dose: "Dose",
  unit: "Unit",
  period: "Period",
  method: "Method",
  addNewInterval: "Add new interval",
  expeditionInterval: "Expedition interval",
  expeditionIntervalUnit: "Expedition interval unit",
  timeInterval: "Time interval",
  timeIntervalUnit: "Time interval unit",
  waitingPeriods: "Waiting periods",
  mustBeBetween: "It must be an integer number between {0} and {1}.",
  days: "days",
  Days: "Days",
  days2: "days",
  Days2: "Daya",
  dayOrDays: "Day/Days",
  limit: "Limit",
  redemption: "Redemption",
  expirationDate: "Expiration date",
  redemptionPeriod: "Redemption period",
  firstRedemption: "First repeatDispensing within",
  latestRequestedTimeForDispensing: "Latest requested time",
  latestRequestedTimeForDispensingDate: "Last date for first withdrawal",
  latestRequestedTimeForDispensingDescription:
    "Can be used to indicate limited validity of the first withdrawal. If the prescription is not dispensed by this date, the entire prescription is considered forfeited.",
  finishPrescription: "Finish prescription",
  allPharmacies: "All pharmacies",
  specificPharmacy: "Specific pharmacy (pharmacies)",
  homeDeliveryFromSpecificPharmacy: "Home delivery",
  municipality: "Municipality",
  chargedAutomatically: "Charged automatically",
  chargedLater: "Charged later",
  notEnoughFields: "More characters needed to the search fields.",
  forPeriod: "for {0} days",
  addSpecificPharmacy: "Add specific pharmacy",
  production: "Production",
  delivery: "Delivery",
  receiver: "Receiver",
  verification: "Verification",
  continue: "Continue",
  backToPrescription: "Back to prescription",
  signature: "Signature",
  selectPharmacy: "Select pharmacy",
  instructionLanguage: "Instruction language",
  unset: "Unset",
  swedishCitizen: "Swedish citizen",
  swedishOrganisation: "Swedish organization",
  foreignCitizen: "Foreign citizen",
  organisationName: "Organization name",
  organisationId: "Organization ID",
  animalType: "Animal type",
  pleaseSelectIfNotSwedish: "Please select, if not Swedish",
  quantityUnit: "Quantity unit",
  packageContent: "Package content",
  licenseInformation: "License information",
  licenseId: "License id",
  licenseProduct: "Licensed medication",
  supplementaryInformation: "Supplementary information",
  dosageForm: "Dosage form",
  pharmacyGLN: "Pharmacy GLN code",
  createLicensedMedication: "Create licensed medication",
  oneYearInterval: "Only one year interval allowed.",
  chooseAPharmacy: "Choose a pharmacy",
  pleaseChoosePackages: "Please choose a package.",
  pleaseFillInstructions: "Please fill in the instructions.",
  tooManyCharactersEnteredToInstructions: "Too many charachters have been typed into the instruction section.",
  tooManyCharactersEntered: "Too many characters have been entered.",
  withdraw: "Withdraw",
  animalCarerInformed: "Animal carer informed",
  onlyTwoFieldsAllowed: "Only two fields are allowed to fill.",
  commentToPharmacist: "Message to the pharmacist",
  mandatoryIfLicenseIdProvided: "Mandatory if licenseId is provided.",
  groupCode: "Group code",
  searchCommodity: "Search commodity products",
  businessCompany: "Business company",
  atcCode: "ATC code",
  productType: "Product type",
  marketed: "Marketed",
  unMarketed: "Not marketed",
  drugClass: "Drug class",
  nplId: "NPL id",
  nplPackId: "NPL pack id",
  nvn: "Nvn (VARA)",
  allProduct: "All products",
  allGroup: "All groups",
  quantityLimit: "Quantity limit",
  prescriptionDrug: "Prescription drug",
  lactose: "lactose",
  latex: "latex",
  showOnlyMarketed: "Show only marketed",
  showOnlyUnMarketed: "Show only unmarketed",
  withdrawn: "Withdrawn",
  chooseASpecificPharmacy: "Choose a specific pharmacy",
  searchLicenseProduct: "Search license product",
  searchApprovedProducts: "Search approved products",
  searchNotApprovedProducts: "Search not approved products",
  receiverPharmacy: "Receiver pharmacy",
  none: "None",
  cancelledPrescriptions: "Cancelled prescriptions",
  prescriptionsWithWarnings: "Prescriptions with warnings",
  failedPrescriptions: "Failed prescriptions",
  cancelledBy: "Cancelled by",
  requestedBy: "Requested by",
  preview: "Preview",
  totalAmount: "Total amount",
  draft: "draft",
  dosageInfo:
    "The dosage form is not mandatory to use, neither the text field. However one of them is required. Look at the preview at the end of this section to see the final instruction text.",
  defaultFeeToolTip: "Default prescription fee is {0} SEK, defined by the clinic at the clinic settings page.",
  successfulPrescription: "The prescription was accepted.",
  warningPrescription: "The prescription was accepted with warnings.",
  failedPrescription: "The prescription was not accepted.",
  cancellationDetails: "Cancellation details",
  statusOfCancellation: "Status of cancellation (reply receipt and return code)",
  cancellationId: "Cancellation id",
  causeOfCancellation: "Cause of cancellation",
  partnerPharmacies: "Partner pharmacies",
  acceptedPrescriptions: "Accepted prescriptions",
  accepted: "Accepted",
  notAccepted: "Not accepted",
  addNewName: "Add new name",
  pinWarning:
    "You don't have a PIN number set. Please create a PIN number at your profile settings page before you start prescribing.",
  treatmentPurpose: "Purpose of the treatment",
  validUntil: "Valid until",
  otherSettings: "Other settings",
  validUntilToolTip:
    "Antimicrobial drugs: Valid for 5 days from the day of prescription regardless of prescription format. Paper prescription and e-prescription: Valid for 1 year, alternatively shortened validity period.",
  notAntimicrobial: "Not antimicrobial",
  therapyShortHelpText: "treatment of sick animals",
  prophylaxisShortHelpText: "disease prevention",
  metaphylaxisShortHelpText: "prevent the spread of infection in groups of animals",
  prophylaxisLongHelpText:
    "administration of a drug to an animal or a group of animals before clinical signs of disease appear, in order to prevent the onset of a disease or a infection.",
  metaphylaxisLongHelpText:
    "administration of a drug to a group of animals thereafter that a clinical disease has been diagnosed in part of group, for the purpose of treating the clinically ill animals and limit the spread to animals in close contact and at risk and who may already be subclinically infected.",
  forFurther: "For further",
  everyOtherWeek: "Every other week",
  everyOtherDay: "Every other day",
  choose: "Choose",
  backToSearch: "Back to search",
  productAddedSuccessfully: "{0} was prescribed successfully.",

  // Tasks
  task: "Task",
  tasks: "Tasks",
  newTask: "New task",
  editTask: "Edit task",
  deleteTask: "Delete task",
  complete: "Complete",
  completed: "Completed",
  onGoing: "On-going",
  employee: "Employee",
  petOrOwner: "Pet or owner",
  areYouSureDeleteTask: "Are you sure you want to delete this task?",
  seeAllTasks: "See all tasks",
  taskCreatedSuccessfully: "Task created successfully.",
  taskSavedSuccessfully: "Task saved successfully.",
  taskDeletedSuccessfully: "Task deleted successfully.",
  addToTask: "Add to task",
  saveAndCreate: "Save and create",

  // ModifyClinic
  basicInfo: "Basic information",
  contactInfo: "Contact information",
  bankAndBilling: "Bank and billing",
  position: "Position",
  managers: "Managers",
  vets: "Vets",
  assistants: "Assistants",
  authorizedAssistants: "Authorized Assistants",
  receptionists: "Receptionists",
  employeeAlreadyAdded: "This employee is already working at this clinic in another role.",
  modifyClinic: "Modify Clinic",
  inventory: "Inventory",
  calendar: "Calendar",
  clinicStatistics: "Usage statistics",
  reservationTypes: "Reservation types",
  deleteReservationType: "Delete reservation type",
  areYouSureDeleteReservationType: "Are you sure you want to delete reservation type: {0}?",
  notifications: "Notifications",
  smsWarning: "SMS notifications will incur extra charges",
  sendRemindersVia: "Send appointment reminders to pet owners via {0}",
  sendRemindersViaEmail: "email",
  sendRemindersViaSms: "SMS",
  inventoryHandling: "Inventory Handling",
  trackInventoryStock: "Tracking inventory stock",
  inventoryTrackingDisableText:
    "This will reset current stock values! The stock values will disappear but pricing and treatments still work as usual.",
  decline: "Decline",
  accept: "Accept",
  inventoryTrackingEnabled: "Inventory stock tracking enabled",
  inventoryTrackingDisabled: "Inventory stock tracking disabled",
  clinicHasNoReservationTypes: "The clinic has no reservation types yet.",
  affiliatedSwedishPharmacies: "Partner pharmacies",
  addPharmacy: "Add pharmacy",
  order: "Order",
  basicInfoTooltip: "Edit the name of the clinic.",
  basicInfoContactTooltip: "Edit contact information of the clinic: e-mail, address, phone number.",
  collaboratorsToolTip: "Manage employees of the clinic: assistants, veterinarians, receptionists, managers.",
  reservationTypesToolTip:
    "Reservations types of the clinic. Owners and clinic employees will be able to use these types to reserve appointments through the calendar.",
  feesToolTip:
    "Basic fees of the clinic. Default prices can be set here, but later these can be customized in each workflow.",
  prescriptionFeeToolTip:
    "Prescription fee has to be linked with an account in case the fee will be paid at a partner pharmacy. Be sure to set a Bankgiro or Plusgiro account at the clinic's settings page.",
  notificationToolTip:
    "Notification settings for owners. The system will send e-mails or text messages before appointments.",
  inventoryToolTip:
    "Inventory settings of the clinic. Enabling inventory tracking will allow the system to reduce stock items after treatments.",
  clinicPharmacyTooltip:
    "The prescription fee will be paid at partner pharmacies, based on mutual agreement with the clinic.",
  deletedProfile: "Deleted profile",
  notificationOnReservationCancel: "Send {0} notifications to the pet owner when reservations are cancelled",
  notificationOnReservationCancelSms: "SMS",
  notificationOnReservationReschedule: "Send {0} notifications to the pet owner when reservations are rescheduled",
  notificationOnReservationRescheduleSms: "SMS",
  calendarSettings: "Calendar settings",
  calendarSettingsToolTip: "Set start and end times of the calendar. Default is 08:00–18:00.",
  confirmEmployeeInvitation: "Are you sure to invite {0}?",
  confirmEmployeeRequestDeletion: "Are you sure to delete the invitation of {0}?",
  inviteEmployee: "Invite employee",
  areYouSureToRemoveEmployee: "Are you sure to remove employee?",
  removeEmployee: "Remove employee",

  // Usage statistics
  statisticsTitle: "Statistics",
  statisticsAndPayment: "Statistics and payment",
  generalData: "General data",
  chooseAMonth: "Choose a month",
  chooseMonth: "-- Choose a month --",
  priceOfUsedItems: "Cost of inventory items used",
  priceOfAppliedFees: "Cost of applied service fees",
  statisticsErrorMsg: "No statistics are available.",
  inventoryStockUsageFee: "Summary of fees for inventory usage",
  sumFeeAtMoment: "Current costs for this month",
  forecastFeeSum: "Further projected costs in this month",
  summaryOfFees: "Total cost",
  noPrevDataFound: "There is no available data",
  monthlySubscriptionHistory: "Subscription History of Month",
  subscriptionPackage: "Subscription Package",
  actual: "Actual",
  currentlyActive: "Currently active",
  feeName: "Fee name",
  count: "Count",
  unitPrice: "Unit price",
  subtotal: "Subtotal",
  usageStatistics: "Usage statistics",
  clinicCosts: "Clinic costs",
  organizationCosts: "Organization costs",
  analyticsEventType: "Event type",
  user: "User",
  activityLogs: "Activity logs",
  ownedClinics: "Owned clinics",
  workplaces: "Workplaces",
  clinicsVisited: "clinics visited",
  lastActivity: "Last activity",
  overviewActivity: "Activity",
  overviewLast24hours: "Last 24 hours",
  overviewLast7days: "Last 7 days",
  overviewLast30days: "Last 30 days",
  overviewTotalEvents: "Total events",
  overviewActiveUsers: "Active users",
  overviewActiveClinics: "Active clinics",
  overviewNewUsers: "New users",
  overviewNewClinics: "New clinics",
  softwareUsage: "Software usage",

  // Organization settings
  modifyOrganization: "Modify Organization",
  editOrganizationNamePlaceholder: "Name of the organization",
  editBusinessNamePlaceholder: "Name of the business that runs the organization",
  editVATNumberPlaceholder: "VAT number of the organization",
  editRegistrationNumberPlaceholder: "Registration number of the organization",
  clinics: "Clinics",
  createNewClinic: "Create new clinic",
  createNewOrganization: "Add organization",
  manager: "Manager",
  creditCard: "Credit card details",
  creditCardNumber: "Card Number",
  fortnoxInvoiceSystem: "Fortnox Invoice System",
  state: "State",
  integrated: "Integrated",
  notIntegrated: "Not Integrated",
  authenticationNeeded: "Authentication needed",
  authenticate: "Authenticate",
  fortnoxIntegration: "Fortnox Integration",
  addIntegration: "Add integration",
  fortnoxIntegrationSuccess: "Fortnox is successfully connected.",
  disableFortnoxConfirmation: "Are you sure you want to disable Fortnox integration?",
  fortnoxRedirect:
    "You will be redirected to the Fortnox website. Please follow the steps to complete the integration.",
  editData: "Edit data",
  turnOff: "Turn off",
  fortnoxIntegrationCostWarning: "Activating and using the integration will cost additional charges.",
  invalidData: "Invalid Data",
  settingsFailed: "Settings failed",
  stripeIntegration: "Stripe integration",
  stripeIntegrationCostWarning: "Activating and using the integration will cost additional charges.",
  szamlazzIntegration: "Szamlazz.hu integration",
  szamlazzIntegrationCostWarning: "Activating and using the integration will cost additional charges.",
  szamlazzApiKey: "API key",
  publicKey: "Public Key",
  privateKey: "Private Key",
  bankAndPlusGiroAccount: "Bankgirot and Plusgirot accounts",
  bankgirot: "Bankgirot",
  plusgirot: "Plusgirot",
  prescriptionFee: "Prescription fee",
  videoConsultationFee: "Video consultation fee",
  basicInfoOrgToolTip: "Basic organization details. The name is arbitrary, the company name is official.",
  basicInfoOrganizationNameToolTip: "An arbitrary name for the organization, used only in the GlobalVET application.",
  basicInfoBusinessNameToolTip: "The official name of the business. This name will appear on the invoice.",
  basicInfoContactOrgToolTip:
    "Edit contact information of the organization: e-mail, address, phone number. Primary contacts will be used first.",
  managersOrgToolTip: "Managers of the organization. Managers are able to edit all the organization's data.",
  giroOrgToolTip:
    "Bankgiro or Plusgiro account is required for prescription fee, when the prescription fee will be paid at a partner pharmacy.",
  creditCardOrgToolTip: "Organizations's credit card details. GlobalVet fees will be charged to this credit card.",
  stripeOrgToolTip:
    "The system use Stripe for online payments. If the organization has a Stripe account, it can be integrated into the system here. This way pet owners will be able to pay online as well.",
  fortnoxToolTip:
    "The system use Fortnox for billing and invoices. If the organization has a Fortnox account, it can be integrated into the system here.",
  szamlazzTooltip:
    "You can connect a Szamlazz.hu account to your clinic in order to create and manage invoices automatically.",
  invoicingInfo: "The system supports {0} and {1}. Connect your invoicing integrations here.",
  statisticsToolTip: "Usage based payment statistics and history.",
  treatmentToolTip:
    "Treatments are used by all clinics of the organization. Treatments can be assigned to a species, or all animals.",
  inventoryOrgToolTip:
    "This inventory will appear to all clinics of the organization. Price values here are default values, can be specified for each clinic at their settings. Stock values are only available at the clinic inventory.",
  addFee: "Add fee",
  createNewServiceFee: "Create new service fee",
  addPaymentMethod: "Add payment method",
  supportedPaymentMethods: "Supported payment methods",
  noPaymentMethods: "The clinic has no supported payment method yet.",
  createCodeInFortnox: "Create code also in Fortnox",
  officialLanguage: "Official language",
  syncWithFortnox: "Sync with Fortnox",
  roundingSettings: "Rounding and vat calculation",
  roundingSettingsTooltip:
    "If the clinic uses an integrated invoicing system as well (Fortnox or Számlazz.hu), then please make sure that you use the same rounding settings as your billing system.",
  roundingStrategyItem: "Rounding of an invoice item",
  roundingStrategyTotal: "Rounding of the invoice total",
  vatCalculationStrategy: "VAT calculation",

  // OwnerClinicListPage
  cannotRetriveOrganizationData: "Cannot retrieve clinic data",
  cannotRetrivePetData: "Cannot retrieve pet data",
  clinicListByOrganization: "Clinic list by organization",
  reserveAppointment: "Reserve appointment",
  organizations: "Organizations",
  chooseOrganization: "-- Choose organization --",
  chooseClinic: "-- Choose clinic --",
  chooseReservationType: "-- Choose reservation type --",
  choosePet: "-- Choose pet --",
  chooseAPet: "Choose a pet",
  next: "Next",
  previous: "Previous",
  unselected: "Not selected",

  // OwnerCalendarPage
  reason: "Reason",
  writeReason: "Write the reason...",

  // OwnerInvoicePage
  invoiceToPay: "Invoices to Pay",
  creationOfInvoice: "Creation of Invoice",
  invoices: "Invoices",
  pdf: "PDF",
  paid: "Paid",
  infoOnlinePaymentDisabled: "Invoices cannot be paid online at this clinic!",
  noInvoicesToPay: "There are no invoices to pay.",
  noInvoices: "There are no invoices.",

  // WorkerCalendarPage
  back: "Back",
  chosenDate: "Chosen date",
  chooseADoctor: "Choose a doctor",
  chooseDoctor: "-- Choose doctor --",
  description: "Description",
  writeSymptoms: "write the symptoms...",
  reserve: "Reserve",
  reservationError: "Unable to reserve for this time interval.",
  reservationInProgress: "Reservation in progress",
  browseNewTimeSlot: "Browse new time slot",
  newReservation: "New reservation",
  deletedItsProfile: "'s profile is deleted.",
  collaborator: "Colleague",
  createShiftFor: "Create shift for",
  reservationDetails: "Reservation details",
  reject: "Reject",
  showReservations: "Show Reservations",
  showShifts: "Show Shifts",
  shifts: "Shifts",
  shift: "Shift",
  modifyShiftFor: "Modify shift for {0}",
  reservations: "Reservations",
  listView: "List view",
  doctorView: "Vet view",
  reScheduleAppointment: "Reschedule appointment",
  reScheduleToolTip: "Choose new time or date in calendar",
  reSchedule: "Reschedule",
  confirmReSchedule: "Confirm reschedule",
  startLaterThanEnd: "End time can not be earlier than start time.",

  // Symptoms
  symptom: "Symptom",
  symptoms: "Symptoms",
  searchOrCreateSymptom: "Search or create new symptom",

  // Profile
  savedSuccessfully: "Saved successfully.",
  passwordChangedSuccessfully: "Password changed successfully",
  changeMyPassword: "Change my password",
  setDefaultLanguage: "Set default language",
  notSpecified: "Not specified",
  contacts: "Contacts",
  language: "Language",
  upload: "Upload",
  security: "Security",
  linkedAccounts: "Linked accounts",
  googleAccount: "Google account",
  facebookAccount: "Facebook account",
  noLinkedAccounts: "There are no linked accounts.",
  accountLinkedSuccessfully: "Account is linked successfully.",
  idNumber: "ID number",
  pinCodeMustBeConfirmed: "Before you start using your PIN number it must be confirmed.",
  codeIsSentToYourMobile: "We have sent the confirmation code to your primary mobile phone.",
  addNewPin: "Add new PIN number",
  verifyPin: "Verify PIN number",
  userHasPinCode: "You already have a PIN number set up.",
  profilePictureIsPublic: "Profile picture will be public for all other users.",
  deleteProfile: "Delete profile",
  deleteProfileWarning:
    "This operation cannot be reverted and it will remove all profile data from the system. Please note that organizations that provided service to you will still have right to access personal data for accounting and traceability purposes.",
  deleteProfileWarningWorker:
    "Past actions that you performed working in a organization will remain visible to the remaining workers of the organization for traceability purposes.",
  deleteProfileCredentials: "Please give your login password to confirm deletion:",
  deleteContactInformation: "Delete contact information",
  areYouSureDeleteContactInformation: "Are you sure you want to delete your contact information?",
  profileBasicInfoToolTip:
    "Basic profile settings. Personal ID is associated with your address. To change it, add a new address.",
  profileContactToolTip:
    "Edit your contact information: e-mail, address, phone number. Primary contact will be used officially.",
  profileVetRegNumberToolTip:
    "Manage authorized, national, veterinary registration numbers. This number will be used in prescriptions and medical records.",
  vetRegNumberInfo: "To be able to create ePrescriptions, you have to add your veterinarian license number.",
  vetRegNumbersEmpty: "You have no veterinarian license numbers added yet.",
  vetRegNumberDeleteConfirmation: "Are you sure you want to delete this veterinarian license number?",
  changesSavedSuccessfully: "The changes have been saved successfully.",

  // ResetPassword
  PasswordRecoveryLinkNotValid: "The password recovery link is not valid.",
  resetPassword: "Reset password",
  resetPasswordEmailInfo:
    "Please enter the email address you used for signing up. We will send you a link via email to reset your password.",
  resetPasswordMobileInfo: "Please enter your mobile phone number you have used for your account.",

  // SearchResult
  ownerName: "Owner name",
  addNewAppointment: "Add new appointment",
  newAppointment: "New appointment",
  newMedicalRecord: "New medical record",
  addNewMedicalRecord: "Add new medical record",
  periodDoesNotFitIntoShift: "Reservation period does not fit into the chosen shift.",
  pages: "pages",
  openLastMedicalRecord: "Open last medical record",
  petHasNoMedicalRecords: "This pet has no medical records yet.",
  total: "Total",
  results: "results",

  // PhoneConfirmation
  phoneConfirmation: "Phone confirmation",
  verifyYourPhone: "Verify your phone",
  confirmationCode: "Confirmation code",
  verificationCode: "Verification code",
  confirmationLinkNotValid: "Phone confirmation link does not exist.",
  invalidVerificationCode: "Verification code is invalid, please try again.",
  confirmationCodeMustBeSixCharacters: "The confirmation code must be exactly 6 characters.",
  phoneConfirmationSuccess: "Phone number confirmed successfully.",
  resendVerificationCode: "Resend verification code",
  phoneCodeSent: "Verification code has been sent to the following phone number: ",

  // TreatmentPage
  dontHavePermissionToCreateTreatments: "You have no permission to create treatments.",
  worngNameOrPrice: "Wrong name or price.",
  showAll: "Show All",
  useFilter: "Use Filter",
  chooseTheSpecies: "Choose species",
  deleteTreatment: "Delete treatment",
  editTreatment: "Edit treatment",
  nameSuggestionToolTip: "Here you can select from name suggestions, created by other organizations.",
  generateIntoMedicalRecordToolTip: "This description will be automatically generated into the medical record.",
  backToTreatments: "Back to treatments",

  // WorkerClinicPage
  thWeek: "{0}. week",
  noShiftOnThisDay: "There are no shifts on this day.",
  owner: "Owner",
  consultationType: "Consultation type",
  time: "Time",
  action: "Action",
  saveAsPDF: "Save as PDF",
  medicalRecord: "Medical record",
  costCalculation: "Cost calculation",

  // Add AddressOwnershipForm
  addAddress: "Add address",
  city: "City",
  zip: "ZIP Code",
  postalCode: "Postal code",
  street: "Street",
  county: "County",
  building: "Building",
  addressLine1: "Street name and house number",
  addressLine2: "Building, floor, apartment, etc.",
  setPrimaryAddress: "Set as primary address",
  deleteAddress: "Delete address",
  deleteAddressConfirmation: "Are you sure you want to delete this address?",
  primaryAddressPromotionTextPart1: "Would you like to set ",
  primaryAddressPromotionTextPart2: " as primary address?",
  primaryEmailPromotionTextPart1: "Would you like to set ",
  primaryEmailPromotionTextPart2: " as primary email?",
  primaryPhonePromotionTextPart1: "Would you like to set ",
  primaryPhonePromotionTextPart2: " as primary phone?",
  setAsPrimary: "Set as primary",
  setPrimaryEmail: "Set as primary email",
  deleteEmail: "Delete email",
  deleteEmailConfirmation: "Are you sure you want to delete this email?",
  setPrimaryPhone: "Set as primary phone",
  deletePhoneNumber: "Delete phone number",
  deletePhoneNumberConfirmation: "Are you sure you want to delete this phone number?",
  checkOnTheMap: "You can specify by drag and drop the pin on the map.",
  iHaveVerifiedTheTimeZoneAndLocation: "I have verified the location and time zone.",
  timeZoneRequired: "Selecting a time zone is required.",

  // UserAddressForm
  newAddressDetails: "Add New Address Details",
  newAddressCheck: "Checking Address",
  checkSuccessful: "Address checking was successful!",
  found: "Found",
  chosen: "Chosen",
  timeZone: "Time Zone",
  addressNotFound: "Failed to find the given address.",
  locationDetectedByAddress: "Location detected by the address.",

  // AddEmailOwnershipForm
  addEmail: "Add email",
  email: "Email",
  emailExample: "example@company.com",
  emailExampleName: "name@company.com",
  yourEmail: "Your email",

  // Switch
  on: "On",
  off: "Off",

  // Modal
  closeModal: "Close modal",

  // Select
  pleaseChoose: "-- Please choose --",

  // AddPhoneOwnershipForm
  addPhoneNumber: "Add phone number",
  phone: "Phone",
  emailRequiredForPhone: "At least one confirmed email address is required to add phone number.",

  // Ownerships
  addresses: "Addresses",
  availableAddresses: "Available addresses",
  address: "Address",
  yourAddress: "Your address",
  location: "Location",
  emails: "Emails",
  phoneNumbers: "Phone numbers",
  phoneNumber: "Phone number",
  phones: "Phones",
  availablePhones: "Available phone numbers",
  emailNotConfirmedYet: "Email address is not confirmed yet.",
  atLeastOneEmailRequired: "At least one email address is required.",
  primary: "Primary",
  private: "Private",
  public: "Public",
  contactType: "Contact type",
  vat: "VAT",
  vatNumber: "VAT Number",
  vatAsTax: "VAT",
  phoneType: "Phone type",
  phoneNotConfirmedYet: "Phone number is not confirmed yet.",
  verificationCodeSentTo: "We sent you a verification code to {0}.",
  verificationError: "Verification error",
  primaryContactNotDeletable:
    "Primary contact is not allowed to delete. Create a new contact first, make it primary, than you can delete the current one.",

  // AddReservationTypeForm
  addReservationType: "Add reservation type",
  createNewReservationType: "Create new reservation type",
  duration: "Duration (mins)",
  durationMustBePositive: "Duration must be a positive integer.",
  isReservableByOwnersQuestion: "Allow online reservations for pet owners for this type?",
  isReservableByOwners: "Allow online reservations",
  isVideoConsultationAllowedQuestion: "Allow video consultation for this type?",
  isVideoConsultationAllowed: "Allow video consultation",
  onlineReservationIsNotAvailable: "Online reservation is not available at this clinic.",

  // EditReservationTypeForm
  editReservationType: "Edit reservation type",

  // AddTreatmentForm
  addTreatment: "Add treatment",
  createNewTreatment: "Create new treatment",
  treatmentName: "Treatment's name",
  treatmentPrice: "Treatment's price",
  chooseASpecies: "Choose a species",
  treatmentNameMustNotBeEmpty: "Treatment's name cannot be empty.",
  addTreatmentSuccess: "Product added to treatment successfully.",
  workCost: "Work cost",
  basePrice: "Base price",

  // AddUserForm
  addNew: "Add new",
  addNewManager: "Add new manager",

  // BillingSection
  billing: "Billing",
  item: "Item",
  quantity: "Quantity",
  extraItems: "Extra items",
  extraItemsAndFees: "Extra items and fees",
  addExtraItem: "Add extra item",
  add: "Add",
  sum: "Sum",
  taxNumber: "Tax number",
  sellItems: "Sell items",
  accountingCode: "Accounting code",
  accountingCodes: "Accounting codes",
  chargeClinic: "Charge clinic",

  // BanCardForm
  cardData: "Card's Data",

  // CategoryList
  categories: "Categories",
  createNewCat: "Create new category",
  Vaccines: "Vaccines",
  Medicines: "Medicines",

  // ChronicDiseasesModal
  alreadyRegisteredDiseases: "Already registered chronic diseases",
  diseaseName: "Name of Disease",
  certified: "Certified",
  notCertified: "Not certified",
  confirmMessage: "Chronic disease will only be marked validated when a veterinarian confirms it on a medical record.",
  certificationDate: "Date of validation",
  addNewChronicDisease: "Add new chronic disease",

  // DeleteReservation
  AreYouSureDeleteAppointment: "Are you sure you want to cancel this appointment?",
  whyDoYouWantToRejectAppointment: "Why do you want to reject this appointment?",
  confirm: "Confirm",
  cancelAppointment: "Cancel appointment",
  rejectAppointment: "Reject appointment",
  rejectError: "Reservation cannot be canceled.",
  cancelAppointmentSuccess: "The appointment is successfully canceled.",
  cancelAppointmentError: "This appointment cannot be canceled. Please contact the clinic.",

  // InventoryNavBar.tsx
  bracnhesInventory: "{0} / Inventory",
  home: "Home",
  inventoryStatus: "Inventory's status",
  statistics: "Statistics",

  // Treatments
  comment: "Comment",
  items: "Items",
  remove: "Remove",
  saveAsNew: "Save as New",
  use: "Use",
  treatmentDescription: "Treatment description",
  advices: "Advices for pet owners",
  suggestedMedicines: "Suggested medicines",
  complications: "Complications",

  // Inventory
  searchWithDots: "Search...",
  new: "New",

  // OwnerCalendar
  toggleWeekends: "Toggle weekends",
  prevWeek: "Prev",
  nextWeek: "Next",
  appointment: "Appointment",
  createUnregisteredPet: "Register",
  theOwnerWillNotSee: "The owner will not see this.",
  personalId: "Personal ID",
  personalIdFormatPlaceholder: "yyyymmddpppp",
  invalidFormat: "Invalid format, proper format is: {0}",
  iDontHavePersonalIdInThisCountry: "I do not have personal ID in this country",
  doesntHavePersonalIdInThisCountry: "Does not have personal ID in this country",
  checkClinicRegistrationsNotificationMsg: "Seems like you visited some clinics before you registered.",
  resolveClinicRegsBtnTitle: "Resolve",
  pendingClinicRegistrations: "Pending clinic registrations",
  createAsNewPet: "Create as new pet",
  claimAsMe: "Claim",
  itWasntMe: "It was not me",
  thereAreNoPendingClinicRegistrations: "There are no pending clinic registrations.",
  iAcceptThatOrganizationWillReceiveMyData:
    "I accept that the organization will receive my personal data and the medical records of the pet in order to facilitate the treatment.",
  noAvailableShiftsForThisReservationType: "There are no available shifts for this reservation type.",

  // Video consultation
  videoConsultation: "Video consultation",
  globalVetVideoConsultation: "GlobalVET video consultation",
  usersInTheRoom: "Users already in the room:",
  enableMicrophone: "Enable microphone",
  enableCamera: "Enable camera",
  noMicrophoneDetected:
    "We can't detect your microphone. Please ensure it's properly connected if you'd like to speak.",
  microphoneAccessDenied: "Microphone access denied! Click here for more information.",
  microphoneWorks: "Your microphone should work as expected.",
  noWebcamDetected:
    "We can't detect your webcam. Please ensure it's properly connected if you'd like others to see you.",
  webcamAccessDenied: "Webcam access denied! Click here for more information.",
  webcamWorks: "Your webcam should work as expected.",
  mediaErrorTooltip: "Click here for more information",
  mediaErrorTitle: "GlobalVET can't use this media device",
  mediaErrorWarningText:
    "There was an error setting up this media device. Please try to do the following steps to see if that can resolve the problem.",
  mediaErrorSuggestion1: "Close any applications that might be using the camera",
  mediaErrorSuggestion2: "Close and reopen the browser",
  mediaErrorSuggestion3: "If you are using an external camera or microphone, disconnect and reconnect it",
  mediaErrorSuggestion4: "Check the media settings of your operating system",
  permissionRequestTitle: "Permission required",
  permissionRequestText:
    "To be able to use this media device, please click 'Allow' or 'Block' in the permission popup displayed by your browser. The appearance of this popup may vary between different browsers.",
  permissionRequestInfo:
    "You can deny the permission if you prefer not to allow access to your microphone or camera. You will still be able to join the meeting.",
  startConsultation: "Start consultation",
  resumeConsultation: "Resume consultation",
  pauseConsultation: "Pause consultation",
  finishConsultation: "Finish consultation",
  copyMeetingLinkCopiedToClipboard: "Copy meeting link to clipboard",
  meetingLinkCopiedToClipboard: "Meeting link has been copied to clipboard!",
  linkCopied: "Link copied!",
  disableCamera: "Disable camera",
  disableMicrophone: "Disable microphone",
  leaveMeeting: "Leave meeting",
  waitingForOtherParticipants: "No participants have joined yet. Please wait while others connect...",
  inviteToMeeting: "Invite people",
  joinConsultation: "Join consultation",
  connectingToVideoConsultationRoom: "Connecting to the video consultation room...",
  areYouSureToClose: "Are you sure you want to close the meeting?",
  youHaveDisconnected: "You have disconnected from the consultation room.",
  meetingDuration: "Duration: ",
  goBackToWaitingRoom: "Go back to waiting room",
  meetingDeviceError: "Device error",
  meetingGrantPermissions:
    "Please connect a microphone and a webcam, grant the required permissions, then reload the page.",
  reloadMeeting: "Reload page",
  addVideoConsultation: "Add video consultation",
  inPersonExamination: "In-person examination",
  onlineVideoConsultation: "Online video consultation",
  connectToVideoConsultation: "Connect to video consultation",
  needPetOwnerEmailError: "Pet owner's e-mail address is required for video consultation.",
  setPetOwnerEmailLink: "Add e-mail address for pet owner.",
  videoConsultationError: "Video consultation error",
  payableCallTime: "Payable call time",
  consultationNotStartedYet: "The consultation has not started yet",
  consultationInProgress: "The consultation is in progress",
  consultationPaused: "The consultation is paused",
  consultationFinished: "The consultation has finished",
  yearSingular: "year",
  yearPlural: "years",
  monthSingular: "month",
  monthPlural: "months",
  daySingular: "day",
  dayPlural: "days",
  hourSingular: "hour",
  hourPlural: "hours",
  minuteSingular: "minute",
  minutePlural: "minutes",
  secondSingular: "second",
  secondPlural: "seconds",
  finishConsultationInfo:
    "You are about to finish an active consultation that is currently paused. You can adjust the payable call time before adding it to the medical record. Alternatively, you have the option to finish the consultation without adding it to the medical record. Please note that finishing the consultation will remove all users from the room and notify them of this decision.",
  finalPayableCallTime: "Final payable call time",
  finalPayableAmount: "Final payable amount",
  continueConsultation: "Continue consultation",
  finishAndAddToMedicalRecord: "Finish and add to medical record",
  justDisconnect: "Just disconnect",
  resetToCurrentTime: "Reset to current time",
  consultationSuccessfullyFinished:
    "Your video consultation has finished successfully, and the corresponding entry has been added to the medical record.",
  newConsultationSession: "New consultation session",
  newConsultationSessionWarning:
    "A consultation has already been completed for this reservation. Would you like to start a new one? It will appear as a separate entry in the medical record.",
  startNewConsultation: "Start new consultation",

  // Video consultation monitor
  roomId: "Room ID",
  noActiveRoomsMessage: "No one is using the videochat service at the moment.",
  noInactiveRoomsMessage: "There are no recently deactivated rooms.",
  videoConsultationRoomMonitor: "Video consultation room monitor",
  videoChatUser: "Videochat user",
  latestJoinDate: "Latest join date",
  timeSinceLatestJoin: "Time since latest join",
  stateOfMediaDevices: "State of media devices",
  micEnabled: "Microphone is enabled.",
  micDisabled: "Microphone is disabled.",
  camEnabled: "Camera is enabled.",
  camDisabled: "Camera is disabled.",
  micWorking: "Microphone is working properly.",
  micMissing: "Microphone could not be detected.",
  micError: "Microphone could not be set up properly.",
  camWorking: "Camera is working properly.",
  camMissing: "Camera could not be detected.",
  camError: "Camera could not be set up properly.",
  roomIsBeingDeleted: "This room is about to be deleted...",
  consultationStatus: "Consultation status",
  waitingForStart: "Waiting for the vet to start the consultation...",
  started: "Started",
  paused: "Paused",
  finished: "Finished",
  waitingForVet: "Waiting for the vet to arrive...",
  waitingForPetOwner: "Waiting for the pet owner to arrive...",
  waitingForBoth: "Waiting for both the vet and the pet owner to arrive...",
  bothPresent: "Both the vet and the pet owner are present",
  eventSequenceNumber: "Event sequence number",
  eventType: "Event type",
  eventDate: "Event date",
  eventData: "Event data",
  thereAreNoEvents: "There are no events associated to this room",
  reservation: "Reservation",
  liveUserInformation: "Live user information",
  eventHistory: "Event history",
  goToMedicalRecord: "Go to medical record",
  startedAt: "Started at",
  totalConsultationTime: "Total consultation time",
  payableConsultationTime: "Payable consultation time",
  totalBreakTime: "Total break time",
  videoConsultationFeeOfTheClinic: "Video consultation fee of the clinic",
  globalVetFee: "GlobalVET fee",
  currentClinicFee: "Current clinic fee",
  currentGlobalVetFee: "Current GlobalVET fee",
  activeRooms: "Active rooms",
  inactiveRooms: "Recently deactivated rooms",
  guest: "Guest",
  roles: "Roles",
  session: "Session",
  sessionHistory: "Session history",
  thereAreNoPreviousSessions: "There are no previous video consultation sessions",
  deleteAllRooms: "Delete all rooms",
  deleteAllRoomsWarning:
    "Attention! Deleting all rooms will result in a clean state for the videochat signal service. This operation can be useful when some rooms have entered an invalid state.",
  addTestMeetingButtonText: "Add 10 minutes of meeting",
  addTestMeetingSuccess: "Successfully added 10 minutes of test meeting to the medical record!",
  logs: "Logs",

  // NumberFormatter
  thousandsSeparator: "\u2009",

  willBeSentToEmail: "Medical record pdf will be sent to the following e-mail address:",

  mute: "Mute",

  anyVet: "Any Vet",
  appointmentPeriodOnOverlappingFreeTimeSlots:
    "The appointment time is not optimal for the clinic. Please, try to adjust it to the start or the end of the free timeslot or choose a specific vet for this reservation!",
  changeLogo: "Change logo",
  uploadLogo: "Upload logo",
  deleteLogo: "Delete logo",
  unsupportedFileType: "Image file type is unsupported. Must be one of the following:",
  changeProfilePicture: "Change profile picture",
  uploadProfilePicture: "Upload profile picture",
  deleteProfilePicture: "Delete profile picture",
  pictureChanged: "Picture was changed successfully.",
  prescriptionOnlyMode: "Prescription-only mode",
  prescriptionOnlyModeTooltip:
    "In prescription-only mode, you can't use and are not charged for other functionalities, like Shift/Reservation Management, Inventory Management and Invoice Generation.",
  switchToPrescriptionOnlyMode: "Switch to Prescription-only mode",
  fullMode: "Full-mode",
  switchToFullMode: "Switch to Full-mode",
  switchToPrescriptionOnlyModeWarning:
    "Switching to Prescription-only mode disables other functionalities, like Inventory, Invoice, Shift and Reservation management.",
  switchToFullModeWarning:
    "Switching to Full-mode will incur extra charges because the subscription fees and other fees will be billed too.",
  switch: "Switch",
  usePrescriptionOnlyMode: "Use prescription-only mode",
  usageMode: "Usage mode",

  // Support
  helpCenter: "Help center",
  guidesForGettingStarted: "Guides for getting started",
  topSupportTopics: "Top topics",
  seeAllSupportTopics: "See all topics",
  supportTopics: "Articles",
  stripeIntegrationSupportContent:
    "GlobalVet uses {0} for online payments. The Stripe framework requires registration and might use additional fees. Steps to integrate Stripe into GlobalVet:",
  stripeIntegrationSupportStep1: "Create Stripe account",
  stripeIntegrationSupportStep2: "Copy Stripe API keys",
  stripeIntegrationSupportStep3: "Paste Stripe API keys",
  szamlazzIntegrationSupportContent: "Steps to integrate automatic invoicing by Számlázz.hu:",
  costsOfSzamlazz: "Costs of Számla Agent.",
  szamlazzIntegrationSupportStep1: "Create Számlázz.hu account and log in",
  szamlazzIntegrationSupportStep2: "Copy Számla Agent API key",
  szamlazzIntegrationSupportStep3: "Paste Számla Agent API key",
  needToContactUs: "Need to get in touch?",
  feelFreeToCotactUs: "If you have any questions please feel free to contact us via {0}.",
  registerFirst: "First you need an user registration. If you do not have an account yet, {0}.",
  userRegistration: "User registration",
  userRegistrationContent:
    "It is simple, you only need a single registration regardless if you are a pet owner or a veterinary worker. When you log in after registration, you can choose if you want to act as a pet owner or as a veterinary worker. You can be both!",
  clinicRegistration: "Clinic registration",
  clinicRegistrationContent:
    "You can register multiple clinics, each one represents a veterinary business of yours. Once you have successfully established your clinic, you can start inviting your colleagues.",
  becomePetOwner: "Become pet owner",
  becomePetOwnerContent:
    "Switch to pet owner mode through the user menu. Now all you have to do is to register your pets into GlobalVet. Then you will be able to book appointments to clinics.",
  becomeClinicEmployee: "Become clinic employee",
  becomeClinicEmployeeContent:
    "Switch to pet clinic employee mode through the user menu. By default, you will see your own clinics and employee requests here. When a clinic owner invites you to join their clinic, you should receive an email and a notification as well.",
  supportTicketHeader: "Create Ticket",
  supportTicketTitle: "Title",
  supportTicketBody: "Message",
  supportTicketType: "Type",
  supportTicketTypeChoose: "-- Choose a Ticket type --",
  supportTicketFullName: "Full name",
  supportTicketEmail: "Email Address",
  supportTicketPhoneNumber: "Phone Number",
  supportTicketLanguage: "Language",
  supportTicketSendButton: "Create Ticket",

  supportTicketCreated:
    "Your ticket was successfully submitted, you should receive an email with a link to it in a few moments.",

  supportTicketChatAnswerTitle: "New Message",
  supportTicketChatAnswerButton: "Send",

  supportTicketState: "State",
  supportTicketIsClosed: (date: string): string => `Your ticket has been closed at ${date}. Thanks for contacting us!`,

  // Support Ticket Types
  supportTicketTypeError: "Error",
  supportTicketTypeSupport: "Support",
  supportTicketTypeSubscription: "Subscription",

  // Support Ticket States
  supportTicketOpen: "Open",
  supportTicketClosed: "Closed",
  backToTicketsPage: "Back to tickets",
  myTickets: "My Tickets",
  closed: "Closed",
  createTicket: "Create Ticket",
  subject: "Subject",
  supportTicketDescription: "Description",
  invalidTicketUrl: "Invalid Ticket URL",
  ticketNotFoundOrNotAuthorized: "Ticket not found or you are not authorized to see it.",
  help: "Need help?",
  siteManagerMode: "Site manager mode",
  shiftSuccessfullyModified: "The shift is successfully modified.",
  weAreSorry: "We are sorry.",

  // Feedbacks
  feedbacks: "Feedbacks",
  survey: "Survey",
  sendFeedback: "Send",
  feedbackAlreadySent: "You have already sent your feedback. Thank you!",
  feedbackSuccess: "Thank you for your feedback!",
  feedbackQuestion: "What do you think of our system?",
  feedbackAnswerPlaceholder: "Please give us feedback",
  feedbackAge: "years old",
  feedbackPets: "pets",
};
