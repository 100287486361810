/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment/moment";
import { ConsultationStatus } from "../../../models/videoConsultation/ConsultationStatus";
import { PresenceStatus } from "../../../models/videoConsultation/PresenceStatus";
import IconBubble from "../../../components/IconBubble";
import { Clock } from "../../../common/Icons/Clock";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { Play } from "../../../common/Icons/Play";
import { Pause } from "../../../common/Icons/Pause";
import { Check } from "../../../common/Icons/Check";
import { QuestionMarkCircle } from "../../../common/Icons/QuestionMarkCircle";
import {
  doctorPlaceholder,
  dogMainPlaceholder,
} from "../../../assets/AssetConfig";
import { CalendarIcon } from "../../../common/Icons/Calendar";
import { formatCallTimeSeconds } from "../../VideoConsultation/Utils";
import { BankNotes } from "../../../common/Icons/BankNotes";

interface Props {
  consultationStatus: ConsultationStatus;
  payableCallTimeInSeconds: number;
  presenceStatus: PresenceStatus;
  startTimestamp?: number;
  totalCallTimeInSeconds: number;
}

const displayConsultationStatus = (status: ConsultationStatus) => {
  if (status === ConsultationStatus.NOT_YET_STARTED) {
    return (
      <>
        <IconBubble>
          <Clock
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <p>{strings.waitingForStart}</p>
      </>
    );
  }

  if (status === ConsultationStatus.IN_PROGRESS) {
    return (
      <>
        <IconBubble>
          <Play
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <p>{strings.started}</p>
      </>
    );
  }

  if (status === ConsultationStatus.PAUSED) {
    return (
      <>
        <IconBubble>
          <Pause
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <p>{strings.paused}</p>
      </>
    );
  }

  return (
    <>
      <IconBubble>
        <Check
          className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
          variant="outline"
        />
      </IconBubble>
      <p>{strings.finished}</p>
    </>
  );
};

const getPresenceStatusString = (status: PresenceStatus) => {
  switch (status) {
    case PresenceStatus.BOTH_PRESENT:
      return strings.bothPresent;
    case PresenceStatus.VET_MISSING:
      return strings.waitingForVet;
    case PresenceStatus.PET_OWNER_MISSING:
      return strings.waitingForPetOwner;
    case PresenceStatus.BOTH_MISSING:
      return strings.waitingForBoth;
    default:
      return strings.waitingForBoth;
  }
};

const displayPresenceStatus = (status: PresenceStatus) => {
  const vetIsMissing =
    status === PresenceStatus.VET_MISSING ||
    status === PresenceStatus.BOTH_MISSING;
  const petOwnerIsMissing =
    status === PresenceStatus.PET_OWNER_MISSING ||
    status === PresenceStatus.BOTH_MISSING;

  return (
    <>
      <IconBubble>
        {status === PresenceStatus.BOTH_PRESENT ? (
          <Check
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        ) : (
          <QuestionMarkCircle
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        )}
      </IconBubble>
      <p>{getPresenceStatusString(status)}</p>
      {vetIsMissing && (
        <img src={doctorPlaceholder} className="w-6 h-6" alt="doctor-icon" />
      )}
      {petOwnerIsMissing && (
        <img
          src={dogMainPlaceholder}
          className="w-6 h-6"
          alt="pet-owner-icon"
        />
      )}
    </>
  );
};

const ConsultationStatusInfo: React.FC<Props> = ({
  consultationStatus,
  payableCallTimeInSeconds,
  presenceStatus,
  startTimestamp,
  totalCallTimeInSeconds,
}: Props) => (
  <div className="flex flex-col space-y-6">
    <div className="flex space-x-6">
      <div className="flex space-x-3 justify-center items-center">
        {displayConsultationStatus(consultationStatus)}
      </div>
      <div className="flex space-x-3 justify-center items-center">
        {displayPresenceStatus(presenceStatus)}
      </div>
    </div>
    <div className="flex space-x-6">
      <div
        className="flex space-x-3 justify-center items-center"
        id="started-at"
      >
        <IconBubble>
          <CalendarIcon
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <div className="flex space-x-3 justify-center items-center">
          <b>{`${strings.startedAt}:`}</b>
          <p>
            {startTimestamp
              ? moment(startTimestamp).format(
                  dateAndTime.momentDateTimeWithSecondsFormat
                )
              : "Not yet started"}
          </p>
        </div>
      </div>
      <div
        className="flex space-x-3 justify-center items-center"
        id="total-consultation-time"
      >
        <IconBubble>
          <Play
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <div className="flex space-x-3 justify-center items-center">
          <b>{`${strings.totalConsultationTime}:`}</b>
          <p>
            {startTimestamp
              ? formatCallTimeSeconds(totalCallTimeInSeconds)
              : "-"}
          </p>
        </div>
      </div>
      <div
        className="flex space-x-3 justify-center items-center"
        id="paid-consultation-time"
      >
        <IconBubble>
          <BankNotes
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <div className="flex space-x-3 justify-center items-center">
          <b>{`${strings.payableConsultationTime}:`}</b>
          <p>
            {startTimestamp
              ? formatCallTimeSeconds(payableCallTimeInSeconds)
              : "-"}
          </p>
        </div>
      </div>
      <div
        className="flex space-x-3 justify-center items-center"
        id="total-break-time"
      >
        <IconBubble>
          <Pause
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <div className="flex space-x-3 justify-center items-center">
          <b>{`${strings.totalBreakTime}:`}</b>
          <p>
            {startTimestamp
              ? formatCallTimeSeconds(
                  totalCallTimeInSeconds - payableCallTimeInSeconds
                )
              : "-"}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ConsultationStatusInfo;
