/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";
import Modal from "../../../components/Modal/Modal";
import UpdateStockForm from "./UpdateStockForm";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { InventoryItemResponse } from "../../../models/inventory/item/Response/InventoryItemResponse";
import Button from "../../../components/Button";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { InventoryItemStockRequest } from "../../../models/inventory/item/Request/InventoryItemStockRequest";
import { InventoryItemStockResponse } from "../../../models/inventory/item/Response/InventoryItemStockResponse";
import {
  getGeneralError,
  localDateFormat,
} from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";

interface Props {
  close(shouldReloadItem: boolean, successMessage?: string): void;
  countryDetails?: CountryDetailsResponse;
  inventoryItem?: InventoryItemResponse;
  isOpen: boolean;
  itemStockToEdit?: InventoryItemStockResponse;
}

const ItemStockModal: React.FC<Props> = ({
  inventoryItem,
  isOpen,
  close,
  countryDetails,
  itemStockToEdit,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const methods = useForm<InventoryItemStockRequest>({
    defaultValues: { quantity: 1 },
  });
  const { setValue, reset } = methods;

  useEffect(() => {
    if (itemStockToEdit) {
      setValue("quantity", itemStockToEdit.quantity);
      if (itemStockToEdit.expirationDate) {
        setValue("expirationDate", itemStockToEdit.expirationDate);
      }
      setValue("acquisitionPrice", itemStockToEdit.acquisitionPrice);
      setValue("acquisitionVat", itemStockToEdit.acquisitionVat);
    } else if(inventoryItem){
      if(inventoryItem.acquisitionPrice){
        setValue("acquisitionPrice", inventoryItem.acquisitionPrice);
      }
      if(inventoryItem.acquisitionVat){
        setValue("acquisitionVat", inventoryItem.acquisitionVat);
      }
    }
  }, [itemStockToEdit, inventoryItem, setValue, isOpen]);

  const handleClose = (shouldReloadItem: boolean, successMessage?: string) => {
    setError(null);
    reset();
    close(shouldReloadItem, successMessage);
  };

  const submit = async (request: InventoryItemStockRequest) => {
    if (inventoryItem) {
      setLoading(true);

      try {
        await InventoryItemApi.addItemStock(inventoryItem.id, {
          ...request,
          expirationDate: request.expirationDate
            ? moment(request.expirationDate).format(localDateFormat())
            : undefined,
        });
        handleClose(true, strings.stockAddedSuccessfully);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const submitEdit = async (request: InventoryItemStockRequest) => {
    if (inventoryItem && itemStockToEdit) {
      setLoading(true);

      try {
        await InventoryItemApi.updateItemStock(
          inventoryItem.id,
          itemStockToEdit.id,
          {
            ...request,
            expirationDate: request.expirationDate
              ? moment(request.expirationDate).format(localDateFormat())
              : undefined,
          }
        );
        handleClose(true, strings.stockUpdatedSuccessfully);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      handleClose={() => {
        handleClose(false);
      }}
      show={isOpen}
    >
      <Modal.Header
        title={itemStockToEdit ? strings.editStock : strings.refillStock}
      />
      <Modal.Body>
        <FormProvider {...methods}>
          {inventoryItem && (
            <form className="space-y-4">
              <UpdateStockForm
                countryDetails={countryDetails}
                inventoryItem={{name: inventoryItem.name, acquisitionUnit: inventoryItem.acquisitionUnit}}
                isPositive
                isRequired
              />
            </form>
          )}
          <AlertBox message={error} />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={loading}
          onClick={
            itemStockToEdit
              ? methods.handleSubmit(submitEdit)
              : methods.handleSubmit(submit)
          }
          loading={loading}
        >
          {itemStockToEdit ? strings.save : strings.add}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemStockModal;
