/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import VideoConsultationApi from "../../../api/VideoConsultationApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { SignalServerLogEntry } from "../../../models/videoConsultation/SignalServerLogEntry";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import { InfoFilled } from "../../../common/Icons/InfoFilled";
import { Card } from "../../../components/Cards/Card";
import AlertBox from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import { dateAndTime } from "../../../common/Strings/Strings";

enum LogLevel {
  INFO = "info",
  WARNING = "warn",
  ERROR = "error",
}

const getIcon = (level: string): ReactElement => {
  switch (level.toLowerCase()) {
    case LogLevel.INFO:
      return (
        <InfoFilled className="w-6 h-6 flex-shrink-0 text-blue-600 dark:text-blue-400" />
      );
    case LogLevel.WARNING:
      return (
        <ExclamationMark className="w-6 h-6 flex-shrink-0 text-orange-600 dark:text-orange-400" />
      );
    case LogLevel.ERROR:
      return (
        <ExclamationMark className="w-6 h-6 flex-shrink-0 text-red-600 dark:text-red-400" />
      );
    default:
      return (
        <ExclamationMark className="w-6 h-6 flex-shrink-0 text-red-600 dark:text-red-400" />
      );
  }
};

const renderLogEntry = (entry: SignalServerLogEntry) => {
  const icon = getIcon(entry.level);
  const formattedTimestamp = moment(entry.timestamp).format(
    dateAndTime.momentDateTimeWithSecondsFormat
  );
  const message = entry.message;

  return (
    <div className="flex flex-row gap-2">
      {icon}
      <p className="font-mono">{`[${formattedTimestamp}] ${message}`}</p>
    </div>
  );
};

const SignalServerLogs: React.FC = () => {
  const [logs, setLogs] = useState<SignalServerLogEntry[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      try {
        const response = await VideoConsultationApi.getLogs();
        setLogs(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchLogs();
  }, []);

  if (loading) {
    return <LoaderInline />;
  }

  if (error) {
    return <AlertBox message={error} />;
  }

  return (
    <Card type="simple" size="max">
      <div className="h-[600px] overflow-y-auto">
        {logs.map((entry: SignalServerLogEntry) => renderLogEntry(entry))}
      </div>
    </Card>
  );
};

export default SignalServerLogs;
