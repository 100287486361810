/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { PageResponse } from "../models/PageResponse";

/**
 * Paginates an array of items.
 *
 * This function is useful when pagination is needed, but the backend cannot provide pagination.
 * In such cases, the frontend can handle pagination by slicing the full array of items and
 * presenting the relevant page of data to the user.
 *
 * @param {Array<T>} array - The array of items to paginate.
 * @param {number} pageNumber - The page number to retrieve (1-based).
 * @param {number} pageSize - The number of items per page.
 * @returns {{ elements: Array<T>, totalElements: number, totalPages: number, currentPage: number }} An object containing the paginated elements, total elements, total pages, and current page.
 * @template T
 */
export const paginate = <T>(
  array: T[],
  pageNumber: number,
  pageSize: number
): PageResponse<T> => {
  // Ensure positive pageNumber and pageSize
  pageNumber = Math.max(0, pageNumber);
  pageSize = Math.max(1, pageSize);

  const start = pageNumber * pageSize;
  const end = start + pageSize;
  const paginatedElements = array.slice(start, end);

  return {
    elements: paginatedElements,
    totalElements: array.length,
    totalPages: Math.ceil(array.length / pageSize),
    currentPage: pageNumber,
  };
};
