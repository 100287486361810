/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const mainStringsSv = {
  noUnreadNotifications: "Inga olästa notifikationer.",
  setupProfilePreferencesPhoneTitle: "Referenser",
  setupProfilePhonePhoneTitle: "Telefon",
  setupProfileAddressPhoneTitle: "Adress",
  setupProfileRolePhoneTitle: "Användarroll",
  cookieConsentText: "Denna webbsidan använder cookies för att förbättra användarupplevelsen.",
  acceptCookie: "Jag accepterar",
  declineCookie: "Jag avböjer",
  pleaseSetupYourProfile: "Snälla, ställ in din profil.",
  wrongEmailOrPassword: "Du har skrivit in fel email eller lösenord.",
  title: "Titel",
  addNewExternalMedicalRecord: "Ny anteckning",
  medicalRecords: "Vårdjournal",
  couldNotRetrieveMedicalRecords: "Kunde inte hämta journal",
  externalMedicalRecords: "Anteckningar",
  externalMedicalRecord: "Anteckning",
  couldNotRetrieveExternalMedicalRecords: "Could not retrieve notes",
  maintenanceModeMessage: "Denna sidan är under underhåll. Kom tillbaka senare!",
  maintenanceModeEnabled: "Underhållsläge aktiverat.",
  maintenanceSettings: "Underhåll",
  vetDocumentsWereRejected: "Dokumenten avvisades.",
  vetDocumentSubmitted: "Dokumentet har laddats upp och väntar på bekräftelse.",
  ePrescriptions: "eRecept",
  couldNotRetrievePrescriptions: "Kunde inte hämta recept",
  submitDocument: "Skicka in dokument",
  addFile: "Lägg till fil",
  farmAnimals: "Bondgårdsdjur",
  createEmergencyAppointment: "Skapa akut bokning",
  emergencyAppointment: "Akut bokning",
  serviceEnabledForCountry: "Tjänsten aktiverad",
  allowReservationFor: "Tillåt reservation av",
  startOfAppointment: "Start av bokning",
  endOfAppointment: "Slutet av bokningen",
  chooseAnEmploymentType: "Välj en anställningstyp.",
  employmentType: "Anställningstyp",
  deceased: "Avliden",
  insuranceNumber: "Försäkringsnummer",
  passportNumber: "Passnummer",
  alternativeMobilePhone: "Alternativ mobiltelefon",
  sendNewRegistrationConfirmationMail: "Skicka nytt meddelande",
  youCannotLoginBecauseEmailIsNotVerifiedYet: "Din emailadress är inte verifierad än.",
  fillPetDataBeforeMedicalRecordMsg:
    "Innan ni fortsätter till journalen, snälla fyll i all information om djuret och djurägaren.",
  proceedToMedicalRecord: "Gå vidare till journalen",
  emailNotConfirmed: "Emailadressen är inte bekräftad.",
  emailConfirmationResendText: "Du kan begära en email för att bekräfta din emailadress.",
  resendConfirmationEmailBtnTxt: "Resend verification link",

  phoneNotConfirmed: "Telefonnummer inte bekräftad",
  phoneConfirmationResendText: "Du kan begära ett sms för att bekräfta ditt telefonnummer.",
  resendConfirmationSmsBtnTxt: "Skicka sms",

  organizationNameIsIncorrect: "Organization name is incorrect.",
  organizationOwnersPassword: "Ägarens lösenord.",
  organizationDeleteConfirmation: "Are you sure you want to delete this clinic?",
  organizationDeleted: "Organisationen har tagits bort.",
  deleteInProgress: "Raderar...",
  organizationDeletionConfirmationEmailSent: "Ett email har skickats för att bekräfta borttagningen av organisationen.",
  organizationName: "Organisations namn",
  clinicDeletionWarning:
    "Varning! Alla tidigare journaler kommer att raderas permanent från systemet och det kan inte bli ogjort.",
  organizationDeletionWarning:
    "Varning! Alla tidigare journaler kommer att raderas permanent från systemet och det kan inte bli ogjort.",
  clinicOwnersPassword: "Djurägarens lösenord",
  clinicCreated: "The clinic has been successfully created.",
  clinicDeleted: "Kliniken har tagits bort.",
  clinicDeletionConfirmationEmailSent: "Ett email har skickats för att bekräfta borttagningen av kliniken.",
  clinicName: "Klinikens namn",
  other: "Andra",
  deleteClinic: "Radera klinik",
  deleteOrganization: "Radera organisationen",
  approveVets: "Godkänna veterinärer.",
  userId: "User ID",
  fileOrFiles: "Fil/Filer",
  noFile: "No file",
  viewFiles: "View {0} files",
  actions: "Insatser",
  medicalCertificate: "Läkarintyg",
  submitMedicinalCertificate: "Ladda upp läkarintyg",
  vetProfessionNotValidated: "För att kunna skapa e-recept behöver du kunna uppvisa rätt behörighet.",
  vetProfessionDocumentsRejected: "Dina dokument avvisades.",
  approve: "Godkänna",
  approveVetProfessionConfirmation: "Confirm vet profession approval",
  approveVetProfessionConfirmationMessage: "Are you sure you want to approve vet profession documents for this vet?",
  documents: "Dokument",
  document: "Dokument",
  rejectVetProfessionConfirmation: "Confirm vet profession rejection",
  rejectVetProfessionConfirmationMessage: "Are you sure you want to reject vet profession documents for this vet?",
  message: "Meddelande",
  rejectionMessagePlaceholder: "Write the reasons for rejection...",

  // emailTypes
  PRIVATE: "Privat",
  PRIMARY: "Primär",

  // Days
  Mon: "Mån",
  Tue: "Tis",
  Wed: "Ons",
  Thu: "Tors",
  Fri: "Fre",
  Sat: "Lör",
  Sun: "Sön",

  MONDAY: "Måndag",
  TUESDAY: "Tisdag",
  WEDNESDAY: "Onsdag",
  THURSDAY: "Torsdag",
  FRIDAY: "Fredag",
  SATURDAY: "Lördag",
  SUNDAY: "Söndag",
  Day: "Dag",
  Week: "Vecka",
  Year: "År",

  monday: "Måndag",
  tuesday: "Tisdag",
  wednesday: "Onsdag",
  thursday: "Torsdag",
  friday: "Fredag",
  saturday: "Lördag",
  sunday: "Söndag",
  day: "Dag",
  week: "Vecka",
  today: "Idag",
  yesterday: "Igår",
  currentMonth: "Denna månaden",
  pastMonth: "Förra månaden",
  currentWeek: "Denna veckan",
  pastWeek: "Förra veckan",
  currentYear: "Detta året",
  pastYear: "Förra året",

  weekend: "Helg",
  months: "Månader",
  weeks: "Veckor",

  // Footer
  copyright: "Copyright ©",
  privacyStatement: "Integritetspolicy",
  more: "Mer",

  // Common
  serverNotResponding: "Servern svarar ej",
  loading: "Laddar...",
  pageNotFound: "Sidan hittas inte.",
  incorrectFieldValue: "Några av fälten är felaktigt ifyllda.",
  yes: "Ja",
  no: "Nej",
  OR: "ELLER",
  or: "eller",
  selectAll: "Välj alla",
  all: "Alla",
  optional: "frivilligt",
  charactersLeft: "{0} Karaktärer kvar",
  charactersAbove: "{0} Flera karaktärer ",
  minimumCharacters: "This field must be at least {0} characters long.",
  maximumCharacters: "This field must be less than {0} characters long.",
  useCustomValue: "Use custom value:",
  show: "Show",
  showMore: "Visa mer",
  showLess: "Visa mindre",
  systemId: "System id",
  here: "here",
  moreInformation: "More information",

  // Error messages
  notAllowedCharacter: "Ingen tillåten karaktär",
  notAllowedCharacterSpecific: "Ingen tillåten karaktär ({0})",
  pleaseFillRequiredFields: "Please fill required fields.",
  numberMustBePositive: "Number must be positive.",
  numberMustBeAnInteger: "Number must be an integer.",
  numberMustBeLessThan: "Number must be less than {0}.",
  profitMarginMustBeLessThan100: "Profit margin must be less than 100%.",

  // Nav
  myOrganizations: "Mina kliniker",
  createNew: "Skapa ny",
  noActiveClinic: "Ingen aktiv klinik",
  pleaseChooseClinic:
    "Vänligen välj en aktiv klinik, eller skapa en ny klinik, eller en ny klinik från en existerande organisation.",
  setActiveClinic: "Välj en klinik...",
  search: "Sök",
  searchPetOwner: "Sök husdjur eller ägare",
  myPets: "Mina djur",
  pets: "Djur",
  addPet: "Lägg till djur",
  findVet: "Hitta veterinär",
  settings: "Inställningar",
  noResult: "Inga resultat",
  noPetResult: "No pets found.",
  noResultTypeCustomValue: "No results, type custom value",
  findClinic: "Hitta klinik",
  findClinicByMap: "Hitta klinik på kartan ",
  finish: "Klar",
  energySaveMode: "Energisparläge",
  debugMode: "Debug mode",
  darkMode: "Dark mode",
  contactSupport: "Kontakta supporten",
  searchingPets: "Searching pets...",
  searchingOwnersWithoutPets: "Searching owners without pets.",

  // login
  login: "Logga in",
  signIn: "Logga in",
  signInToYourAccount: "Logga in på ditt konto",
  logout: "Logga ut",
  signOut: "Logga ut",
  switchAccount: "Växla användare",
  profile: "Profil",
  profileSettings: "Profilinställningar",
  yourProfile: "Your profile",
  setupYourProfileLater: "Setup your profile later?",
  password: "Lösenord",
  petOrDoctor: "Är du en djurägare eller en veterinär?",
  loginPetOwnerLabel: "Djurägare",
  loginVeterinaryWorkerLabel: "Veterinärarbetare",
  registration: "Registrering",
  signUp: "Skapa konto",
  signUpHere: "Skapa konto",
  loginWithFacebookText: "Fortsätt med Facebook",
  loginWithGoogleText: "Fortsätt med Google",
  forgotPassword: "Glömt lösenord?",
  newPassword: "Nytt lösenord",
  newPasswordAgain: "Repetera nytt lösenord",
  savePassword: "Save password",
  noAuthForLogin: "Du har ingen befogenhet att logga in här",
  workerLoginDisabled: "Du har inte tillgång till personalens gränssnitt",
  thirdPartyCookiesMustBeEnabled: "Tredjepartscookies måste vara aktiverade i din webbläsare.",
  petOwnerMode: "Djurägareläge",
  employeeMode: "Klinikens anställdsläge",
  continueAsOwner: "Fortsätt som ägare",
  continueAsEmployee: "Fortsätt som anställd.",
  ownerSideInfo: "Skapa och hantera dina husdjur som en djurägare.",
  employeeSideInfo: "Skapa och hantera kliniken du äger eller jobbar på.",
  changeRoleInfo: "Ändra läge mellan ägare och anställd här när som helst.",
  saveAndContinue: "Spara och fortsätt",
  alreadyHaveAccount: "Ar du redan ett konto?",
  noAccountYet: "Har du inget konto?",
  loginHere: "Login here",
  signInHere: "Logga in på befintligt konto",
  backToLogin: "Tillbaka till inloggningen",

  // Multi-factor authentication
  mfaAuthentication: "Autentisering",
  mfaDescriptionSms:
    "Vänligen skriv in din verifieringskod som du har mottagit via sms för att bekräfta din identitet.",
  mfaDescriptionEmail:
    "Vänligen skriv in din verifieringskod som du mottagit via email för att bekräfta din identitet eller klicka på verifieringsknappen i mailet.",
  mfaCancel: "Avbryt",
  mfaConfirm: "Bekräfta",
  mfaLoginFailed: "Inloggningen misslyckades",
  mfaInvalidCodeFormat: "Ogiltigt inloggninsformat, måste vara 6 siffror.",
  enterVerificationCode: "Enter verification code",

  // Globalvet settings
  afterAcceptContractText: "Congratulations, now you can use GlobalVET!",
  navigateToYourClinicPageLink: "Visit your clinic's page",
  navigateToYourClinicPage: " and continue your journey in the GlobalVet system.",

  // ClinicInvoices
  from: "Från",
  to: "Till",
  invoiceState: "Fakturatillstånd",
  invoiceCreationDate: "Fakturadatum",
  pdfNotAvailable: "PDF finns inte",
  sendReminder: "Skicka påminnelse",
  invoiceBillingInProgress: "Väntande fakturor",
  invoiceBilled: "Faktureras",
  emailReminderSent: "Påminnelsen skickades",
  withdrawingSuccessful: "Annulleringen av fakturan lyckades",
  warningYouAreAboutToWithdrawAnInvoice: "Du håller på att dra tillbaka en faktura.",
  addProducts: "Lägg till produkt",
  paymentAndCustomerInfo: "Betalning och kundinformation.",
  itemsAdded: "Objekt har lagts till",
  proceedToInvoices: "Gå vidare till fakturor",
  tryLater: "Försök senare",
  type: "Typ",
  searchToolTip: "Kundnamn, telefonnummer, emailadress, djurnamn",
  dateFilterToolTip:
    "Filtrera efter utfärdandedatum: datum för skapande av faktura. Filtrera efter betalningsdatum: datum för delbetalning eller total betalning.",
  custom: "Beställnings",
  copyAsNew: "Kopiera som ny",
  addAllItemsToo: "Lägg till alla inventarier också",
  addAllFeesToo: "Lägg till alla serviceavgifter också",

  // Registration
  registerWithFacebookText: "Registrera med facebook",
  registerWithGoogleText: "Registrera med Google",
  confirmEmailOk: "Bekräftelse att e-mail är skickat till",
  emailAlreadyUsed: "Email används redan",
  phoneAlreadyUsed: "Telefonnummer används redan",
  emailAddress: "E-postadress",
  enterYourEmail: "Fyll i din e-postadress",
  emailNotValid: "detta är inte en giltig e-mail",
  verifyYourEmail: "Bekräfta din e-postadress",
  emailVerification: "Bekräfta din e-postadress",
  emailVerificationLinkInvalid: "Your verification link is invalid, please try again.",
  choosePassword: "Välj lösenord",
  passwordValid:
    "Lösenord måste innehålla åtminstone en liten bokstav, en stor bokstav, en siffra och längden ska vara åtminstone 8 tecken",
  confirmPassword: "Upprepa lösenord",
  confirmValid: "Lösenord och bekräftande lösenord måste vara samma",
  createAccount: "Skapa konto",
  mobilePhone: "Mobilnummer",
  mobilePhoneNumber: "Telefonnummber (mobil)",
  primaryPhoneNumberDetails:
    "Telefonnummret behöver vara ett {0}. Andra telefonnummer kan registreras vid senare tillfälle.",
  primaryPhoneNumberDetailsMobilePhone: "mobil-nummer",
  landlinePhone: "Hemtelefon",
  phoneNotValid: "Ogiltigt nummer",
  publicForVeterinaryWorkers: "Offentligt för veterinärarbetare",
  prefix: "Titel",
  prefixPlaceholder: "Exempel Läkare",
  firstName: "Förnamn",
  middleName: "Mellannamn",
  lastName: "Efternamn",
  invalidPhoneNumber: "Ogiltigt nummer",
  allowNotifications: "Tillåt notifikationer?",
  allowNotificationsButton: "Tillåt notifikationer",
  notificationInfo: "För att inte missa viktig information t.ex. möten, tillåt notifikationer.",
  receiveSMSNotifications: "Jag vill ta emot sms påminnelser angående viktiga händelser.",
  errorAccessingSocialData: "Kunde ej skaffa användardata från social leverantör",
  accountAlreadyExists: "Denna användare finns redan registrerad",
  successfulRegistration: "Registrering lyckades",
  haveVetRegistrationNumber: "Jag är en legitimerad veterinär med ett nationellt registrerings nummer. ",
  registrationNumber: "Licensnummer",
  addRegistrationNumber: "Lägg till licensnummer",
  vetRegistrationNumbersTitle: "Veterinärs-licensnummer",
  registrationSuccessful: "Registreringen lyckades",
  registrationSuccessfulMessage: "Congratulations! You have successfully created your account. Welcome to GlobalVET!",
  continueRegistrationMessage:
    "You can continue the registration process with setting up your profile after signing in.",
  goToSignIn: "Go to Sign in",
  pleaseCheckYourEmailInbox: "Kontrollera din inkorg",
  sentYouEmailLink: "Vi har skickat en aktiveringslänk till din angivna e-postadress {0}.",
  pleaseCheckSpam: "Om du inte har fått något, vänligen kontrollera din skräppost.",
  autoLoginChooseRole: "Välj om du är en djurägare eller djurhälsopersonal",
  autoLogin: "Du blir automatiskt inloggad",
  iHaveReadAndAcceptPolicy: "Jag har läst och accepterat ", // användarvillkoren
  iHaveInformedOwnerAboutPolicy:
    "Jag har informerat djurägaren om att veterinärservicen som erbjuds här kommer bli dokumenterad i GlobalVet tillsammans med personlig data om djurägaren för kvalitetsäkring och spårbarhetsändamål",
  pin: "Pinkod",
  setPin: "Skriv in eller ändra pinkod",
  usePinInfoTitle: "Bekväm och säker användning",
  usePinInfo: "Istället för att använda lösenord, vänligen ange en pinkod för framtida inloggning.",
  pinUsage: "För bekräftelse av veterinär och snabbt användarbyte",
  pinFormat: "Pinkod måste innehålla 4 nummer",
  savePin: "Spara pinkod",
  pinSaved: "Pinkoden sparades",
  enterPin: "Ange ditt PIN-nummer",
  vetNumber4digits: "Registreringsnummer måste innehålla 4 nummer",
  countryFieldToolTip: "Det land där du tillhandahåller eller använder veterinärtjänster.",
  countryOfResidence: "Land eller residens",
  createPetOwner: "Skapa ny djurägare",
  gdprAgreement: "I agree to GlobalVET's {0}.",
  gdprAgreementGdpr: "GDPR Consent",
  clinicCreationAgreement: "I agree to GlobalVET's {0} and {1}.",
  clinicCreationAgreementGeneral: "General Terms and Conditions",
  clinicCreationAgreementDataProcessing: "Data Processing Terms and Conditions",
  fillMissingData: "Hiányzó adatok felvitele",
  searchPetOwnersToolTip: "Kundnamn, personnummer, telefonnummer, emailadress eller djurnamn",

  // Password Recovery
  passwordRecovery: "Återställning av lösenord",
  recoveryEmailOk: "Återställning av lösenord är skickat till",
  recoverySmsOk: "Recovery SMS sent to ",
  userNotFoundWithThisEmail: "Användare kan inte hittas med denna email adressen",
  userNotFoundWithThisPhone: "Användare kan inte hittas med detta telefonnumret",
  simpleError: "Ett fel uppstod",
  sendRecoveryEmail: "Skicka återställningsmail",
  sendRecoverySms: "Skicka kod via sms",
  recoverViaEmail: "Återställ med hjälp av din e-postadress",
  recoverViaMobile: "Återställ med hjälp av din mobiltelefon",
  emailSentSuccessfully: "E-post har skickats.",

  // AddNewPet
  newPet: "Nytt djur",
  addNewPet: "Lägg till nytt djur",
  newPetAndOwner: "Nytt djur och djurägare",
  newOwner: "Nytt djurägare",
  createNewOwner: "Skapa nytt djurägare",
  name: "Namn",
  gender: "Kön",
  color: "Färg",
  species: "Djurtyp",
  breed: "Ras",
  dateOfBirth: "Födelsedatum",
  invalidDate: "Ogiltligt datum.",
  years: "ålder",
  monthCnt: "månader",
  createProfileForPet: "Skapa en profil för mitt djur",
  chipNumber: "Chipnummer",
  tattooNumber: "Tatueringsnummer",
  noPetYet: "Inga husdjur tillagda",
  addFirstPet: "Lägg till ditt husdjur",

  // Edit Pet
  discardChanges: "Ignorera ändringar",
  editPet: "Redigera husdjur",
  seeAllPets: "Se alla djur",
  seeAllHistory: "Se all historik",

  // Pet profile
  ownerPhone: "Ägarens telefon",
  applyFilters: "Tillämpa filter",
  petProfile: "Husdjursprofil",
  editProfile: "Edit {0}'s profile",
  ownerProfile: "Ägarprofil",
  searchBreed: "Sök efter ras ...",
  uncompleted: "uncompleted",
  mixed: "Blandras",
  specifyBreed: "Specify breed",

  // Pending pets
  pendingPets: "Inväntande ägarbyten av husdjur",
  seeAllPendingPets: "Se alla inväntande ägarebyten av husdjur",
  acceptOwnership: "Accept ownership",
  declineOwnership: "Decline ownership",
  changeOwner: "Byt ägare",
  currentPetOwner: "Current pet owner",
  addNote: "Lägg till beskrivning",
  note: "Note",
  clinicHasRegisteredYourPet: "{0} clinic has registered your pet.",
  createNewPet: "Create new pet",
  updateExistingPet: "Update my existing pet",
  areYouSureToDecline: "Are you sure you want to decline ownership?",
  waitingForOldOwner: "Waiting for the previous owner to confirm the ownership change of {0}.",
  waitingForNewOwner: "Väntar på att ny ägare ska bekräfta ägarskapet av {0}.",
  acceptAsNewOwner: "{0} has granted you the pet ownership of {1}",
  acceptAsOldOwner: "{0} accepted the ownership of {1}. Do you approve the adoption?",
  newOwnerEmail: "Ny djurägares e-postadress",

  // Email sending
  emailTo: "Till",
  emailSubject: "Ämne",
  emailMessage: "Meddelande",
  attachToEmail: "Bifoga till e-post",
  emailSend: "Skicka",
  newEmail: "Nytt e-postmeddelande",
  defaultInsuranceEmailTitle: "Direktreglering, {0} ({1})",
  petOwnerEmails: "petOwnerEmails",

  // MainOwner
  MAIN: "Huvudsida",
  MY_PETS: "Mina djur",
  APPOINTMENTS: "Mina möten",
  PRESCRIPTIONS: "Recept",
  INVOICES: "Fakturor",
  FIND_CLINIC: "Hitta klinik",
  mainPage: "Huvudsida",
  myBills: "Fakturor",
  myAppointments: "Mina bokningar",
  addAppointment: "Lägg till bokning",
  reserveButtonText: "Bekräfta reservation",
  organization: "Organisation",
  clinic: "Klinik",
  date: "Datum",
  reservationType: "Besöksorsak",
  vet: "Veterinär",
  pet: "Husdjur",

  // ProfilePet
  dontHaveAccessToPetProfile: "Du har inte tillgång till denna djurprofilen",
  appointments: "Bokningar",
  pictureAlt: "Bild",
  warningChipNumberNotConfirmed: "Chipnummer är ej bekräftat ännu",

  // CalendarDayViewForm
  chooseCollab: "Välj en medarbetare",
  searchEmployee: "Sök anställd ...",
  assistant: "Assistent",
  authorizedAssistant: "Auktoriserad assistent",
  receptionist: "Receptionist",
  Vet: "Veterinär",
  areYouSure: "Är du säker?",
  deleteConfirmationTitle: "Confirm delete",
  deleteConfirmation: "Are you sure you want to delete this item?",
  backToCalendar: "Tillbaka till kalender",
  editDailyForm: "Ändra dagligt formulär",
  collaborators: "Medarbetare",
  id: "Id",
  startTime: "Starttid",
  endTime: "Sluttid",
  modify: "Ändra",
  addCollab: "Lägg till medarbetare",
  deleteAll: "Radera allt",
  addNewCollab: "Lägg till en ny medarbetare",
  roleOfChosenCollab: "Roll av vald medarbetare",
  repetition: "Period",
  turnedOff: "Avslagen ",
  turnedOn: "Påslagen",
  turnOffRepetition: "Sätt på eller av för repetition",
  chooseRepfordays: "Välj repetition för dessa dagar",
  setRepforWeeks: "Efter antal veckor",
  onThisDate: "På",
  end: "Avslutas",
  save: "Spara",
  cancel: "Avbryt",
  noShiftAtThisTime: "Det finns ingen tillgänglig medarbetare.",
  downloadMedicalRecordPDF: "Ladda ner journal (PDF)",
  downloadInvoicePDF: "Ladda ner faktura (PDF)",
  downloadPDF: "Ladda PDF",
  openMedicalRecord: "Open medical record",
  corrected: "Corrected",
  underCorrection: "Under correction",

  // Substitution form
  substitution: "Utbyte",
  substitutionTitle: "Resolve blocking reservations",
  absentEmployee: "Frånvarande anställd",
  startDate: "Startdatum",
  endDate: "Slutdatum",
  startDateTime: "Startdatum och tid.",
  endDateTime: "Slutdatum och tid ",
  noReservationsSaveSubstitution: "{0} har inte några reservationer mellan {1} och {2}. Du kan spara utbyte. ",
  cancelAndNotifyPetOwner: "Avsluta och meddela djurägare",
  chooseSubstitute: "-- Välj utbyte eller avsluta reservation --",
  substitute: "Ersättning",
  employeeToDelete: "Anställd att ta bort",
  saveAndDeleteEmployee: "Spara och ta bort anställd",
  userHasReservationsBetween:
    "{0} has reservations between {1} and {2}. Please select substitutions or cancel reservations.",
  deleteShifts: "Radera ändringar",
  deleteShift: "Radera ändring",
  areYouSureToDeleteShift: "Är du säker på att radera ändring?",
  deleteShiftsFor: "Radera ändringar för",
  selectShiftsFrom: "Sök ändringar från",

  // OrganizationStatistics
  payments: "Betalningar",
  month: "Månad",
  amount: "Summa",
  status: "Status",
  pay: "Betala",
  paySelected: "Betalning vald",
  payWithRegisteredCard: "Betala med registrerat kort",
  payWithDifferentCard: "Betala med annat kort",
  paymentSuccessful: "Betalningen lyckades",
  paymentUnSuccessful: "Betalningen misslyckades",
  cause: "Orsak",
  downloadInvoicePdf: "Ladda ner faktura",

  // Verification
  emailVerificationIsSuccessful: "Bekräftelse av email lyckades",
  emailVerificationIsSuccessfulInfo: "You can now close this tab or continue the process with signing in.",
  errorDuringEmailVerification: "Ett fel uppstod under email bekräftelsen",
  confirmEmail: "Bekräfta email",
  goToLogin: "Gå till inloggningen",
  goToMainPage: "Gå till huvudsidan",
  redirectInSeconds: "Du kommer att omdirigeras om {1} sekund...",

  // CreateClinic
  createClinic: "Skapa en klinik",
  bracnhName: "Kliniksnamn",
  create: "Skapa",
  addBasicDetails: "Grundläggande information",
  addressSameAsOrgPrimaryAddress: "Same as primary organization address",
  orgHasNoPrimaryAddress: "The organization has no primary address",
  invalidAddress: "Invalid address",

  // CreateOrganization
  createOrganization: "Skapa organisation",
  vatNotValid: "Momsregistreringsnummer måste innehålla 5 nummer.",
  chargeWarning:
    "Klinikens månatliga kostnader kommer debiteras den första dagen av nästa månad. Priset beslutas baserat på användning.",
  baseChargeWarning:
    "En grundreservation kommer bli debiterad på kortet för att testa det och det blir återbetalat efter kortreservation är färdig. ",
  creditCardModification: "Kreditkortsändring",
  giveNewCardData: "Skriv in ditt nya korts uppgifter",
  creditCardAuthFailed: "Kreditkortsautentisering misslyckades",
  creditCardDeclined: "Ditt kreditkort blev avvisat",
  creditCardExpired: "Ditt kreditkort har gått ut",
  incorrectCVC: "Din pinkod på ditt kreditkort är fel",
  incorrectZIP: "Ditt postnummer på kreditkortel är fel",
  incorrectNumber: "Ditt lösenord på ditt kreditkort är fel",
  invalidCVC: "Din säkerhetskod på ditt kreditkort är ogiltig",
  invalidNumber: "Ditt kortnummer var ogiltig",
  stripeHandleError: "Vår service relaterad till betalningar är för närvarande inte tillgänglig",
  incorrectName: "Namnet på ditt kort är inte giltigt",
  chargeFailed: "Betalningen misslyckades. Vänligen se över dina kortdetaljer. ",
  chargeSucceeded: "Betalningen lyckades",
  businessName: "Företagsnamn",
  businessRegistrationNumber: "Registration number",
  invalidRegistrationNumber: "Invalid registration number",
  businessStructure: "Företagsform",

  // EditCategory
  cannotRetriveCatData: "kan inte hämta kategoridata",
  deleteError: "Radera fel",
  editCategory: "Ändra kategori",
  createCategory: "Skapa kategori",
  catNameWithDots: "Produktnamn...",
  delete: "Radera",
  deletingCat: "Raderad kategori",
  areYouSureDeleteCat: "Är du säker på att radera {0} ?",
  hide: "Dölj arikel",
  see: "Se artikel",
  newItem: "Ny artikel",

  // EditItemModal
  cannotRetriveItemData: "Kan ej hämta artikeldata",
  editItem: "Ändra artikel",
  createItem: "Skapa artikel",
  price: "Pris",
  itemPriceWithDots: "Artikelpris...",
  minimalLevel: "minimun nivå ",
  stock: "Lager",
  deleteItem: "Radera artikel",
  areYouSureDeleteItem: "Är du säker på att readera {0} ?",
  chooseSpecies: "-- Välj ras --",
  nameCannotBeEmpty: "Namn kan ej vara tomt!",
  itemNameWithDots: "Artikelnamn...",
  itemUnitWithDots: "Artikelenhet...",
  deletingItem: "Radera Artikel",
  acquisitionPrice: "Anskaffningspris",
  acquisitionVat: "Förvärvsmoms",
  salePrice: "Försäljningspris",
  sellingVat: "Säljmoms",
  acquisitionUnitQuantity: "Anskaffningsenhet kvantitet",
  acquisitionUnit: "Förvärvsenhet",
  sellingDivisor: "Säljande divisor",
  currency: "Valuta",
  applicableToSpecificSpecies: "Gäller endast specifika arter",
  customPrice: "Anpassat pris",

  // FindClinic
  setAddress: "Skriv din adress i din Profil.",
  choooseThisClinic: "Välj klinik",
  warningUserHasNoPrimaryAddress: "Användaren måste ange en primär adress först för att göra bokningen.",
  warningUserHasNoPrimaryAddressLinkToProfile: "Öppna dina profilinställningar.",

  // Inventory
  cannotRetriveClinicData: "Kan ej hämta kliniksdata",
  inventorySettings: "Lagerinställningar",
  filter: "Filter",
  filters: "Filters",
  clearFilters: "Clear filters",
  addItemFromVara: "Lägg till föremål från VARA",
  addItem: "Lägg till vara",
  createNewInventoryItem: "Skapa ny artiker",
  noItemsAdded: "Inga objekt har lagts till ännu.",
  areYouSureToDeleteCategory:
    "Är du säker på att du vill ta bort inventeringskategorin? Alla objekt som tilldelats i kategorin kommer också att raderas.",
  refillStock: "Lagersaldo",
  resetRefillValues: "Reset refill values",
  inventoryItemDetails: "Inventory item details",
  orderHistory: "Order history",
  orderHistoryUnit: "Order",
  newOrder: "New order",
  usageHistory: "Usage history",
  usageHistoryUnit: "Usage",
  countingHistory: "Audit history",
  countingHistoryUnit: "Audit",
  newCounting: "New audit",
  dateOfOrder: "Date of order",
  countedQuantity: "Counted quantity",
  totalQuantityInPackages: "Total quantity in packages",
  unitPriceCalculator: "Unit price calculator",
  totalPackagePrice: "Total package price",
  stockUsage: "Usage",
  editUsage: "Edit usage",
  editStock: "Edit stock",
  deleteUsage: "Delete usage",
  deleteStock: "Delete stock",
  deleteCounting: "Delete counting",
  countingDetails: "Counting details",
  stockAddedSuccessfully: "Stock added successfully.",
  stockUpdatedSuccessfully: "Stock updated successfully.",
  stockDeletedSuccessfully: "Stock deleted successfully",
  countingDeletedSuccessfully: "Item counting deleted successfully",
  areYouSureToDeleteItemStock: "Are you sure to delete stock of {0}?",
  areYouSureToDeleteItemUsage: "Are you sure to delete usage of {0}?",
  areYouSureToDeleteItemCounting: "Are you sure to delete counting of {0}?",
  countInventory: "Audit",
  stockOperations: "Stock operations",
  profit: "Vinst",
  profitMargin: "Vinstmarginal",
  profitMarginInfo: "Vinstmarginal är förhållandet mellan vinst och intäkter.",
  currentStock: "Current stock",
  countedStock: "Counted stock",
  countingDifference: "Counting difference",
  addMissingStock: "Add missing stock",
  finishCounting: "Finish counting",
  stockIsTheSame: "Quantity is the same",
  stockIsDifferent: "Quantity is different",
  salePriceOverTime: "Sale price ({0}) over time",
  purchasePriceOverTime: "Purchase price ({0}) over time",
  purchaseVsSalePriceTitle: "Change of purchase price and sale price",
  skip: "Skip this step",
  lowStockToolTipInfo: "When to remind you about low level.",
  stockSkipped: "Stock skipped.",
  itemCreatedSuccessfully: "Inventory item {0} was created successfully.",
  itemUpdatedSuccessfully: "Inventory item {0} was updated successfully.",
  inventoryManagementUpdatedInfo1: "New features available",
  inventoryManagementUpdatedInfo2:
    "Stock handling, inventory counting, item analytics and history, profit margin and unit price calculators.",

  // Analytics
  analytics: "Analytics",
  notEnoughData: "Not enough data",

  // InventoryStatistics
  cannotRetriveInventoryData: "Kan ej hämta avdelningens lagerinställningar",
  itemName: "Artikelnamn",
  allItems: "Visa allt",
  showOnlyLow: "Visa endast låga nivåer",
  showOnlyStock: "In stock",
  category: "Kategori",
  warningAt: "Varna vid",
  lowLevel: "Låg nivå",
  searchCategory: "Sök kategorier",

  // InventoryStatus
  refill: "Påfyllning",
  options: "Valmöljigheter",
  edit: "Ändra",
  addToTreatment: "Lägg till behandlingen",
  ok: "Ok",
  operations: "",
  saveChanges: "Spara ändringar",

  // Invoice History
  costAccounting: "Kostnadsredovisning",
  treatmentData: "Behandlingsdata",
  invoiceDetails: "Fakturadetaljer",
  code: "Kod",
  clinicIdentifier: "Avdelningskännetecken",
  totalCost: "Totalkostnad",
  remainingCost: "Återstående kostnad",
  sendReminderEmail: "Skicka emailpåminnelse ",
  amountToPay: "Summa att betala",
  markAsPaid: "Markera som betald",
  partialPayment: "Delbetalning",
  emailReminder: "E-postpåminnelse",
  addCorrection: "Lägg till justering",
  paidAmount: "Betalt belopp",
  payerName: "Betalarens namn",
  payer: "Betalare",
  payerType: "Betalartyp",
  PET_OWNER: "Husdjursägare",
  INSURANCE_COMPANY: "Försäkringsbolag",
  paymentMethod: "Betalningsmetod",
  paymentMethodShort: "Metod",
  newRemainingCost: "Återstående kostnad",
  customer: "Kund",
  issuer: "Emittent",
  paymentHistory: "Betalningshistorik",
  modifierParty: "Modifiera",
  paymentType: "Betalningssätt",
  warningYouAreAboutToSendEmail: "Du kommer att skicka ett mail!",
  emailReminders: "Påminnelse skickad (e-post)",
  onlinePayment: "Onlinebetalning",
  creditCardDetails: "Kreditkortsinformation",
  giveYourCardData: "Uppge din kortinformation:",
  noData: "inte tillämplig",
  noInvoiceHistoryData: "Det finns ingen betalningshistorik.",
  notPaid: "Inte betalt",
  filterDateType: "Datumtyp",
  filterByDateOfIssue: "Filtrera efter utfärdandedatum",
  filterByDateOfPayment: "Filtrera efter betalningsdatum",
  filterByName: "Filtrera efter namn",
  allowOnlinePaymentAndSendEmail: "Tillåt bakre onlinebetalning och e-postlänk",
  invoiceSent: "Sent",
  sentToFortnox: "Skickat till Fortnox",
  sentToSzamlazz: "Skickat till Szamlazz.hu",
  failedToSendToFortnox: "Det gick inte att skicka till Fortnox",
  failedToSendToSzamlazz: "Det gick inte att skicka till Szamlazz.hu",
  failedToConnectToFortnox: "Failed to connect to Fortnox",
  sendToFortnox: "Skicka till Fortnox",
  sendToSzamlazz: "Skicka till Szamlazz.hu",
  fortnox: "Fortnox",
  szamlazz: "Szamlazz.hu",
  szamlazzIntegrationInfo1:
    "GlobalVet uses Számla Agent to send invoices to Számlázz.hu. Read more about Számla Agent: {0}.",
  szamlazzIntegrationInfo2:
    "In order to start using automatic invoicing by Számlázz.hu, copy paste your Számla Agent API key:",
  invoicingMethodNone: "None",
  explanation: "Förklaring",
  paymentDetails: "Betalningsinformation",
  paymentState: "Betalningstillstånd",
  retry: "Försök igen",
  noEmailReminderSent: "E-postpåminnelse skickades inte",
  download: "Ladda ner",
  print: "Skriva ut",
  printOrDownloadInvoice: "Skulle du vilja skriva ut eller ladda ner faktura?",
  noInvoicesToDisplay: "Det finns inga fakturor att visa.",
  withdrawInvoice: "Dra tillbaka faktura",
  estimatedCost: "Beräknad kostnad",
  goToInvoices: "Gå till fakturor",
  editInvoice: "Redigera faktura",
  pendingInvoice: "I väntan på",
  plusVat: "+ moms",
  invalidAmount: "Ogiltigt belopp",
  gross: "Brutto",
  net: "Netto",
  grossPrice: "Bruttopris",
  netPrice: "Nettopris",
  cashIncome: "Kontant inkomst",
  cardIncome: "Kreditskortsinkomst",
  transferIncome: "Transfereringsinkomster",
  directDebitIncome: "Direct debit income",
  onlineIncome: "Inkomst på nätet",
  insuranceIncome: "Försäkringsskyddad inkomst",
  totalIncome: "Total inkomst",
  paymentSummary: "Betalningsöversikt",
  invoicingMethod: "Emissionsmetod",
  costSummary: "Kostnadssammanställning",
  incomeSummary: "Inkomstsammanställning",
  downloadIncomeSummary: "Ladda ner inkomstsammanställning",
  printIncomeSummary: "Skriva ut inkomstsammanställning",
  totalIncomeIsZeroInfo: "Total income is zero.",
  sales: "Försäljning",
  newSale: "New sale",
  needInvoice: "Skicka till Fortnox",
  invoicingSystemIsNotIntegrated: "Inget faktureringssystem är integrerat.",
  goTo: "Go to: {0} to check available integrations.",
  sameAsCustomerData: "Samma som kunddata",
  billingInfo: "Faktureringsinformation",
  invoiceHasBeenSentInEmail: "Fakturan har skickats till följande emailadress:",
  pleaseConfirmMarkAsPaid: "Snälla bekräfta att fakturan är betald:",
  successfullyPaid: "Fakturan har markerats som betald.",
  seeAllPaymentHistory: "Se all betalningshistorik",
  seeAllEmailReminders: "Se alla e-postpåminnelser",
  seeAllInvoiceItems: "Se alla fakturaartiklar",
  withdrawPayment: "Dra tillbaka betalningen",
  payment: "Betalning",
  paidImmediately: "The full amount is paid immediately at the clinic",
  dueDateForPayment: "Sista datum för betalning",
  dueDate: "Innan datum",
  invoiceCreated: "Invoice created successfully",
  deleteInvoice: "Delete invoice",
  withdrawReadyForPayment: "Återkalla 'Redo att betalas'",
  insuranceTitle: "Delad faktura",
  payLater: "Betalas senare",
  invoiceNumber: "Fakturanummer",
  invoiceSearchCategory: "Sökkategori",
  szamlazzAvailable: "Számlázz.hu integration available",
  goToSzamlazz: "Go to Számlázz.hu itegration.",
  invoicing: "Fakturering",
  integrationTurnedOff: "Invoice integration of {0} turned off successfully.",
  noInvoicingSystemSupported: "No invoicing system supported by GlobalVet in your country.",

  // MainDoctor
  welcome: "Välkommen",
  changeClinic: "Ändra klinik",
  selectNone: "Välj ingen",
  selectActiveClinic: "Välj en aktiv klinik",
  as: " som {0}",
  activeClinic: "Aktiv klinik",
  pleaseSelectActiveClinic: "Välj en aktiv klinik",
  noClinicAssigned: "Det finns ingen klinik tilldelad till dig.",
  pending: "Du har en pågående förfrågan från användare {0} för {1} att ansluta {2}. ",
  pendingRequest: "Pågående förfrågan",
  employmentRequestAccepted: "Anställningsbegäran accepterad",
  noReservaionsOnThisDay: "Ingen reservation eller ändring är registrerad idag.",
  noShiftsOnThisWeek: "Inga ändringar denna veckan.",
  newShift: "Ny ändring",
  createShift: "Lägg till ändring",
  noReservationsFound: "Inga reservationer ännu",
  pleaseCheckTheLocation: "Snälla, kontrollera platsen på kartan!",
  owners: "Husdjursägare",
  skipForNow: "Hoppa över nu",
  addItLater: "Add it later?",
  setItLater: "Set it later?",
  setPinNumber: "Set PIN number",
  noOrganizationYet: "No organization added yet",
  noClinicYet: "No clinic added yet",
  addFirstOrganization: "Add your first organization",
  pendingInvitations: "Pending invitations",

  // Management
  fees: "Arvode",
  country: "Land",
  chooseCountry: "--Välj land--",
  baseFeeForClinic: "Grundavgift för avldelningar",
  baseFeeForEmployee: "Grundavgift för anställda per månad",
  baseFeeForVet: "Basavgift för veterinär per månad",
  baseFeeForAssistant: "Basavgift för assistent per månad",
  baseFeeForReceptionist: "Basavgift för receptionist per månad",
  appointmentFee: "Besöksavgift",
  medicalRecordFee: "Journalavgift",
  smsFee: "Smsavgift",
  inventoryUsageFee: "inventeringanvändningskostnad",
  currencyValid: "Valutan måste vara ett flyttal med två nummers noggrannhet ",
  selectedCurrency: "Valt lands valuta {0}",
  editDiscounts: "Ändra Rabatter",
  discounts: "Rabatter",
  existingDiscounts: "Nuvarande Rabatter",
  resetSettings: "Återställ Inställningar",
  freeSubscription: "Gratis prenumeration",
  active: "Aktiv",
  originalPrice: "Original pris",
  discountedPrice: "Nedsatt pris",
  warningSaveDiscount: "Med ändringen av abonnemang, kommer avgifternas beräkning av kliniken ändras omedelbart",
  warningSaveCountryFees:
    "Med ändringen av landets avgifter, kommer avgiftsberäkningen av dem drabbade klinikerna ändras omedelbart. ",
  globalSettings: "Inställningar",
  policies: "Policies",
  unemployedWorkerLoginEnabled: "Möjliggör arbetarlogin till icke anställda användare",
  existingPrivacyPolicies: "Befintliga sekretesspolicyer",
  privacyPolicyIncompleteWarning:
    "This privacy policy statement may be incomplete or missing important information. Please keep in mind that the information presented here is most likely not final and may be subject to modifications and adjustments.",
  createPrivacyPolicy: "Skapa sekretesspolicy ",
  editPrivacyPolicy: "Redigera integritetspolicy",
  languages: "Språk",
  version: "Version",
  grammarChange: "Enbart grammatikändring",
  infoNoNewVersion: "Ingen ny version kommer bli lagrad när inkorgen är kollad. ",
  successfulSave: "Framgångsrikt spara",
  addPolicy: "Add policy",
  editPolicy: "Edit policy",
  deletePolicy: "Delete policy",
  deletePolicyConfirmation: "Are you sure you want to delete this policy?",
  warningDeletePolicy: "Nuvarande policy arkiveras utifall uppkommande stämningar.",
  privacyPolicy: "Integritetspolicy",
  manageCookies: "Manage cookies",
  gdprConsent: "GDPR Consent",
  generalTermsAndConditions: "General Terms and Conditions",
  dataProcessingTermsAndConditions: "Data Processing Terms and Conditions",
  policyStatement: "Policyuttalande",
  insuranceCompany: "Försäkringsbolag",
  insuranceCompanies: "Försäkringsbolag",
  fax: "Fax",
  paymentTerms: "Betalningsvillkor",
  users: "Användare",
  profilePicture: "Profilbild",
  petProfilePicture: "Pet profile picture",
  clinicLogo: "Logo",
  facebookId: "Facebook ID",
  facebookEmail: "Facebook email",
  googleId: "Google ID",
  googleEmail: "Google email",
  ipAddress: "IP-adress",
  userAgent: "Användaragent",
  gdprExtractSentSuccessfully: "Utdraget har skickats.",
  sendExtractToUser: "Skicka utdraget till användaren.",
  changePassword: "Byt lösenord",
  gvAppVersions: "App versions",
  gvServiceName: "Service",
  gvServiceVersion: "Version",
  gvLastCommit: "Last commit",
  serviceFees: "Serviceavgifter",
  customFees: "Custom fees",
  minimalChargeValue: "Minimalt laddningsvärde",
  paymentDate: "Betalningsdatum",
  allOrganizations: "Alla organisationer",
  trialDiscountPercent: "Provrabatt",
  trialPeriodDays: "Provperiodens längd",
  trialEndReminderBeforeDays: "Påminnelse om slut på provperioden",
  daysBefore: "Dagar innan",
  discount: "Rabatt",
  siteManagers: "Site managers",
  siteManager: "Site manager",
  permissions: "Permissions",
  fullAccess: "Full access",
  config: "Config",
  countries: "Countries",
  activeCountries: "Active countries",
  inactiveCountries: "Other countries",
  paymentAndBilling: "Payment and billing",
  paymentSystem: "Payment system",
  secretKey: "Secret key",
  billingSystem: "Billing system",
  clientId: "Client ID",
  clientSecret: "Client secret",
  testInvoices: "Test invoices",
  testInvoice: "Test invoice",
  createTestInvoice: "Create test invoice",
  noTestInvoiceCreatedYet: "No test invoice is created yet.",
  testInvoiceWarning:
    "If you've provided the credentials of a production environment, please don't forget to withdraw the created invoice within the billing system.",
  insuranceInvoiceSettings: "Insurance invoice settings",
  systemIntegrations: "System integrations",
  systemIntegrationsAllWorking: "All the integrations are working",
  discountFree: "Free",
  discountCustom: "Custom",
  untilWithdrawn: "until withdrawn",
  remoteAccess: "Remote access",
  foreignClinicWarning:
    "You are accessing a foreign clinic as a site manager. Be careful not to make any unintended changes!",
  leaveClinic: "Leave clinic",
  clinicFeatures: "Clinic features",
  feature: "Feature",
  commissionRate: "Commission rate",
  discountedCommissionRate: "Discounted commission rate",
  originalCommissionRate: "Original commission rate",

  // NavSiteManager
  SITE_MANAGER_SETTINGS: "Settings",
  BILLING: "Billing",
  VET_DOCUMENTS: "Vet documents",
  INSURANCE: "Insurance",
  USERS: "Users",
  FEEDBACKS: "Feedbacks",
  CLINICS: "Clinics",
  ANALYTICS: "Analytics",

  // MedicalRecord
  open: "Öppna",
  examination: "Undersökning",
  dontHaveAccessToMedicalRecord: "Du har inte behörighet till denna journal.",
  petsMedicalRecord: "{0} Journaler ", // Test this!!!!
  unknownMedicalRecord: "Okänd journal",
  petOwner: "Ägare",
  petName: "Djurnamn",
  anamnesis: "Anamnes",
  physicalExamination: "Undersökning",
  diagnostics: "Diagnostik",
  diagnosis: "Diagnos",
  treatment: "Behandling",
  recommendation: "Hemgångsråd",
  prognosis: "Prognos",
  certificate: "Intyg",
  referral: "Remiss",
  changeIndicator: "Förändringsindikator",
  weights: "Vikter",
  vaccines: "Vacciner",
  medicines: "Mediciner",
  history: "Historik",
  diagnoses: "Diagnoser",
  differentialDiagnoses: "Differentialdiagnoser",
  treatments: "Behandlingar",
  sign: "Signera",
  signCorrection: "Signaturkorrigering",
  openForCorrection: "Öppna för ändringar",
  correction: "Lägg till korrigering",
  signedBy: "Signerad av",
  correctionSignedBy: "Korrigering signerad av",
  signed: "Signerad",
  correctionSigned: "Korrigering signerad",
  signMedicalRecord: "Signera journal",
  signer: "Signerad av",
  signText: "Efter signering kan journalen {0} enbart läsas och inte ändras",
  addNewInfo: "Lägg till ny Information",
  weight: "Vikt",
  chooseFromWithDots: "Välj från...",
  close: "Stäng",
  warning: "Varning",
  error: "Error",
  itemsAtNegativeStock: "Lagerpåfyllnad var troligen glömt, därför lagerstatus av artikeln nu är under noll!",
  invoice: "Faktura",
  cashInvoice: "Kontantfaktura",
  createdBy: "Skapad av",
  created: "Skapad",
  chronicDiseases: "Kroniska sjukdomar",
  chronicDiseaseHistoryAdded: "{0} registrerad.",
  chronicDiseaseHistoryRemoved: "{0} borttagen.",
  connectedPrescriptions: "Recept",
  addInventoryItems: "Lägg till inventeringsartiklar",
  addServiceFees: "Lägg till avgifter",
  usedItems: "Inventeringsartiklar använda",
  usedFees: "Avgifter använda",
  usedItemsAndFees: "Inventeringsartiklar använda och andra avgifter",
  noServiceFeesAdded: "Inga serviceavgifter har lagts till ännu.",
  medicalRecordHistory: "Journalhistorik",
  hematology: "Hematologi",
  biochemistry: "Biokemi",
  parameters: "Parametrar",
  conventionalUnits: "Konventionella enheter",
  referenceRange: "Referensintervall",
  result: "Resultat",
  costs: "Kostnad",
  inProgress: "Pågående",
  notStarted: "Ej påbörjad",
  otherInventoryItems: "Andra inventarier",
  deleteRelatedItems: "Ta bort relaterade objekt också",
  displayPetOverview: "Visa översikt över husdjur",
  followUp: "Uppföljning",
  createInvoice: "Skapa faktura",
  addDocuments: "Add documents",
  addFiles: "Add files",
  fileTooLarge: "File is too large. The maximum allowed size is 10 MB.",
  mailedTo: "Mailed to",
  inventoryItems: "Inventarier",
  searchInventoryItems: "Sök artikel",
  searchFees: "Sök avgifter",
  medicalRecordId: "Journalnummer",
  resetTreatmentDescriptions: "Reset treatment descriptions",
  contentNotAvailable: "Content is not available.",

  // Prescription
  ePrescription: "E-recept",
  pharmacy: "Apotek",
  prescription: "Recept",
  prescriptions: "Recept",
  newPrescription: "Nytt recept",
  prescriptionItem: "Utskrivna preparat",
  addItems: "Lägg till preparat",
  product: "Produkt",
  substance: "Substans",
  barcode: "Streckkod",
  formulation: "Formula",
  pharmaceuticalForm: "Läkemedelsform",
  typeOfPacking: "Förpackningstyp",
  strength: "Styrka",
  manufacturer: "Tillverkare",
  notAddedYet: "Inte tillagt ännu",
  notPrescribable: "Denna produkt kan inte förskrivas.",
  withdrawalDate: "Avregistreringsdatum",
  details: "Detaljer",
  packages: "Förpackning",
  packagingQuantity: "Antal förpackning per uttag",
  ddd: "Definierad dygnsdos",
  noDDD: "Ingen definierad dygnsdos",
  noManufacturer: "Ingen tillverkare",
  addToPrescription: "Lägg till på recept",
  dosage: "Dosering",
  instructions: "Instruktioner",
  dose: "Dos",
  unit: "Enhet",
  period: "Period",
  method: "Appliceras",
  addNewInterval: "Lägg till nytt intervall",
  expeditionInterval: "Expeditionsinterval",
  expeditionIntervalUnit: "Expeditionsinterval enhet",
  timeInterval: "Tidsintervall",
  timeIntervalUnit: "Tidsintervall enhet",
  waitingPeriods: "Karens",
  mustBeBetween: "Det måste vara ett heltal mellan {0} och {1}.",
  days: "dygn",
  Days: "Dygn",
  days2: "dagar",
  Days2: "Dagar",
  dayOrDays: "Dag/Dagar",
  limit: "Antal uttag",
  redemption: "Upprepad expediering",
  expirationDate: "Utgångsdatum",
  redemptionPeriod: "Receptförnyelseperiod",
  firstRedemption: "Första receptförnyelse",
  latestRequestedTimeForDispensing: "Första uttag före",
  latestRequestedTimeForDispensingDate: "Sista datum för första uttag",
  latestRequestedTimeForDispensingDescription:
    "Kan användas för att ange begränsad giltighetstid för första uttaget. Expedieras inte receptet senast detta datum anses hela receptet förverkat. Expedieras receptet senast detta datum gäller resten av uttagen 1 år från utfärdandedatum.",
  finishPrescription: "Färdigt recept",
  allPharmacies: "Alla apotek",
  specificPharmacy: "Specifikt apotek",
  homeDeliveryFromSpecificPharmacy: "Hemleverans från specifikt apotek",
  municipality: "Kommun",
  chargedAutomatically: "Debitera",
  chargedLater: "Skicka till valt apoteket",
  notEnoughFields: "Inte tillräckligt område",
  forPeriod: "under {0} dygn",
  addSpecificPharmacy: "Lägg till specifikt apotek",
  production: "Produktion",
  delivery: "Leverans",
  receiver: "Mottagare",
  verification: "Verifikation",
  continue: "Nästa",
  backToPrescription: "Tillbaka till recept",
  signature: "Signatur",
  selectPharmacy: "Välj apotek",
  instructionLanguage: "Främmande språk",
  unset: "Avstängd",
  swedishCitizen: "Individ med svenskt personnummer",
  swedishOrganisation: "Svensk organisation",
  foreignCitizen: "Individ utan svenskt personnummer",
  organisationName: "Organisationsnamn",
  organisationId: "Organisationsnummer",
  animalType: "Djurtyp",
  pleaseSelectIfNotSwedish: "Välj, om inte svenska",
  quantityUnit: "Enhet för mängd",
  packageContent: "Antal i förpackning",
  licenseInformation: "Licensinformation",
  licenseId: "Licensid",
  licenseProduct: "Licensläkemedel",
  supplementaryInformation: "Preparatnamn",
  dosageForm: "Läkemedelsform",
  pharmacyGLN: "Apotek GLN",
  createLicensedMedication: "Skapa licenspreparat",
  oneYearInterval: "Endast ett års intervall tillåtet.",
  chooseAPharmacy: "Välj ett apotek",
  pleaseChoosePackages: "Välj ett paket.",
  pleaseFillInstructions: "Fyll i instruktionerna.",
  tooManyCharactersEnteredToInstructions: "För många tecken har skrivits i instruktionsavsnittet.",
  tooManyCharactersEntered: "För många tecken har skrivits in.",
  withdraw: "Makulera",
  animalCarerInformed: "Djurvårdare informerad",
  onlyTwoFieldsAllowed: "Endast två fält får fyllas i.",
  commentToPharmacist: "Förskrivarens kommentar",
  mandatoryIfLicenseIdProvided: "Obligatoriskt om Licensid tillhandahålls.",
  groupCode: "Gruppkod",
  searchCommodity: "Sök handelsvara",
  businessCompany: "Företag",
  atcCode: "ATC kod",
  productType: "Produkttyp",
  marketed: "Marknadsförs",
  unMarketed: "Ej marknadsförda",
  drugClass: "Narkotikaklass",
  nplId: "NPL id",
  nplPackId: "NPL pack-id",
  nvn: "Varunummer",
  allProduct: "Alla produkttyper",
  allGroup: "Alla grupper",
  quantityLimit: "Antal",
  prescriptionDrug: "Receptbelagd",
  lactose: "laktos",
  latex: "latex",
  showOnlyMarketed: "Visa endast marknadsförda",
  showOnlyUnMarketed: "Visa endast ej marknadsförda",
  withdrawn: "Makulerad",
  chooseASpecificPharmacy: "Välj ett specifikt apotek",
  searchLicenseProduct: "Sök licensläkemedel",
  searchApprovedProducts: "Sök godkända läkemedel",
  searchNotApprovedProducts: "Sök icke godkända läkemedel",
  receiverPharmacy: "Mottagareapotek",
  none: "Ingen",
  cancelledPrescriptions: "Makulerat recept",
  prescriptionsWithWarnings: "Recept med varningar",
  failedPrescriptions: "Recept med fel",
  cancelledBy: "Makulerad av",
  requestedBy: "På begäran av",
  preview: "Förhandsvisning",
  totalAmount: "Totalmängd",
  draft: "utkast",
  dosageInfo:
    "Fyll i förpackningsetiketten genom att välja i listorna (dos, enhet, period, dag, appliceras) och/eller skriva egen text i instruktionerna. Ett av alternativen är nödvändigt. I förhandsvisning ser du vad som syns på etiketten.",
  defaultFeeToolTip: "Klinikens definierade standardavgift för recept är {0} SEK.",
  successfulPrescription: "Receptet accepterades.",
  warningPrescription: "Receptet accepterades med varningar.",
  failedPrescription: "Ej accepterad.",
  cancellationDetails: "Makulera information",
  statusOfCancellation: "Status på makulering (svarskvittens och returkod)",
  cancellationId: "Identifiering av makulering",
  causeOfCancellation: "Makuleringsorsak",
  partnerPharmacies: "Partnerapotek",
  acceptedPrescriptions: "Receptet accepterades",
  accepted: "Accepterat",
  notAccepted: "Ej accepterat",
  addNewName: "Lägg till nytt namn",
  pinWarning:
    "Du har inte angett ett pin-nummer. Vänligen skapa ett pin-nummer på din profilinställningssida innan du börjar förskriva.",
  treatmentPurpose: "Behandlingsändamål",
  validUntil: "Giltighetstid",
  otherSettings: "Övriga inställningar",
  validUntilToolTip:
    "Antimikrobiella läkemedel: Giltigt 5 dagar från och med dagen för förskrivning oavsett receptformat. Pappersrecept och e-recept: Giltigt 1 år, alternativt förkortad giltighetstid.",
  notAntimicrobial: "Ej antimikrobiellt läkemedel",
  therapyShortHelpText: "behandling av sjuka djur",
  prophylaxisShortHelpText: "förebyggande av sjukdom",
  metaphylaxisShortHelpText: "förhindra smittspridning i djurgrupper",
  prophylaxisLongHelpText:
    "Administrering av ett läkemedel till ett djur eller en grupp av djur innan kliniska tecken på sjukdom uppträder, i syfte att förebygga uppkomsten av en sjukdom eller en infektion.",
  metaphylaxisLongHelpText:
    "Administrering av ett läkemedel till en grupp djur efter det att en klinisk sjukdom har diagnosticerats i en del av gruppen, i syfte att behandla de kliniskt sjuka djuren och begränsa spridningen till djur i nära kontakt och i riskzonen och som redan kan vara subkliniskt infekterade.",
  forFurther: "tills vidare",
  everyOtherWeek: "varannan vecka",
  everyOtherDay: "varannan dag",
  choose: "Välj",
  backToSearch: "Tillbaka till sökning",
  productAddedSuccessfully: "{0} har utskrivna till recept.",

  // Tasks
  task: "Att göra",
  tasks: "Att göra",
  newTask: "Lägg till ny uppgift",
  editTask: "Ändra uppgift",
  deleteTask: "Ta bort uppgift",
  complete: "Slutfört",
  completed: "Slutfört",
  onGoing: "Aktuellt",
  employee: "Anställd",
  petOrOwner: "Husdjur eller ägare",
  areYouSureDeleteTask: "Ár du helt säker pá att du vill ta bort uppgiften?",
  seeAllTasks: "See all tasks",
  taskCreatedSuccessfully: "Todo created successfully.",
  taskSavedSuccessfully: "Uppgiften sparad.",
  taskDeletedSuccessfully: "Uppgiften är borttagen.",
  addToTask: "Lägg till",
  saveAndCreate: "Skapa",

  // ModifyClinic
  basicInfo: "Info",
  contactInfo: "Kontaktinformation",
  bankAndBilling: "Bank och fakturering",
  position: "Roll",
  managers: "Föreståndare",
  vets: "Veterinärer",
  assistants: "Assistenter",
  authorizedAssistants: "Auktoriserade assistenter",
  receptionists: "Receptionister",
  employeeAlreadyAdded: "Den här arbetstagaren jobbar redan på den här avdelningen med en annan roll!",
  modifyClinic: "Ändra klinik",
  inventory: "Lager",
  calendar: "Kalender",
  clinicStatistics: "Användar statistik",
  reservationTypes: "Reservationstyper",
  notifications: "Påminnelser",
  smsWarning: "Sms påminnelser kommer att innebära extra avgifter",
  sendRemindersVia: "Skicka bokningspåminnelser till djurägare via {0}",
  sendRemindersViaEmail: "email",
  sendRemindersViaSms: "SMS",
  inventoryHandling: "Lagerhantering",
  trackInventoryStock: "Spåra lagerinventering",
  inventoryTrackingDisableText:
    "Detta kommer att återställa nuvarande lagervärden! Lagervärden kommer att försvinna men prissättning och behandlingar fungerar fortfarande som vanligt.",
  decline: "Vägra",
  accept: "Acceptera",
  inventoryTrackingEnabled: "Spårning av lagerinventering är möjlig",
  inventoryTrackingDisabled: "Spårning av lagerinventering är ej möjlig",
  clinicHasNoReservationTypes: "Avdelningen har inga reservationer ännu.",
  affiliatedSwedishPharmacies: "Anslutna apotek",
  addPharmacy: "Lägg till apotek",
  order: "Ordning",
  basicInfoTooltip: "Redigera namnet på kliniken.",
  basicInfoContactTooltip: "Redigera kontaktinformation för kliniken: e-post, adress, telefonnumer.",
  collaboratorsToolTip: "Hantera anställda på kliniken: assistenter, veterinärer, receptionister, chefer.",
  reservationTypesToolTip:
    "Bokningstyper av kliniken. Ägare och klinikanställda kommer att kunna använda dessa typer för att boka tider via kalendern.",
  feesToolTip:
    "Klinikens grundavgifter. Standardpriser kan ställas in här, men senare kan dessa anpassas i varje arbetsflöde.",
  prescriptionFeeToolTip:
    "Receptavgiften måste kopplas till ett konto ifall avgiften ska betalas på ett partnerapotek. Se till att ställa in ett bankgiro- eller plusgirokonto på klinikens inställningssida.",
  notificationToolTip: "Meddelandeinställningar för ägare. Systemet skickar e-post eller textmeddelanden före möten.",
  inventoryToolTip:
    "Klinikens lagerinställningar. enom att aktivera funktionen 'Spåra lagerinventering' kan systemet hålla koll på inventarier och därigenom effektivisera lagerhanteringen, vilket minskar onödiga lagerkostnader.",
  clinicPharmacyTooltip: "Receptavgiften betalas vid partnerapoteket enligt avtal med kliniken.",
  deletedProfile: "Raderad profil.",
  notificationOnReservationCancel: "Skicka {0} till djurägaren när reservationer avbokas",
  notificationOnReservationCancelSms: "SMS-aviseringar",
  notificationOnReservationReschedule: "Skicka {0} till djurägaren när bokningar ombokas",
  notificationOnReservationRescheduleSms: "SMS-aviseringar",
  calendarSettings: "Kalenderinställningar",
  calendarSettingsToolTip: "Ställ in start- och sluttider för kalendern. Standard är 08:00-18:00.",
  confirmEmployeeInvitation: "Är du säker på att bjuda in {0}?",
  confirmEmployeeRequestDeletion: "Är du säker på att ta bort inbjudan till {0}?",
  inviteEmployee: "Bjud in medarbetare",

  // Usage statistics
  statisticsTitle: "Statistik",
  statisticsAndPayment: "Statistik och betalning",
  generalData: "Generelldata",
  chooseAMonth: "Välj en månad",
  chooseMonth: "--Välj en månad--",
  priceOfUsedItems: "Kostnad av inventeringsartiklar använda",
  priceOfAppliedFees: "Kostnad för tillämpade serviceavgifter",
  statisticsErrorMsg: "Ingen statistik finns tillgänglig.",
  inventoryStockUsageFee: "Sammanfattning av avgifter för inventeringsanvändning",
  sumFeeAtMoment: "Nuvarande sammanfattning av avgifter ",
  forecastFeeSum: "Prognosen för avgifter denna månaden",
  summaryOfFees: "Sammanfattning av Avgifter",
  noPrevDataFound: "Ingen tidigare data hittades!",
  monthlySubscriptionHistory: "Månadens Prenumerationshistorik ",
  subscriptionPackage: "Prenumerationspaket",
  actual: "Faktisk",
  currentlyActive: "För närvarande aktiv",
  feeName: "Avgiftsnamn",
  count: "Räkna",
  unitPrice: "Enhetspris",
  subtotal: "Delsumma",
  usageStatistics: "Användningsstatistik",
  clinicCosts: "Klinikkostnader",
  organizationCosts: "Organisationskostnader",
  analyticsEventType: "Event type",
  user: "User",
  activityLogs: "Activity logs",
  ownedClinics: "Owned clinics",
  workplaces: "Workplaces",
  clinicsVisited: "clinics visited",
  lastActivity: "Last activity",
  overviewActivity: "Activity",
  overviewLast24hours: "Last 24 hours",
  overviewLast7days: "Last 7 days",
  overviewLast30days: "Last 30 days",
  overviewTotalEvents: "Total events",
  overviewActiveUsers: "Active users",
  overviewActiveClinics: "Active clinics",
  overviewNewUsers: "New users",
  overviewNewClinics: "New clinics",
  softwareUsage: "Mjukvaruanvändning",

  // Organization settings
  modifyOrganization: "Ändra organisation",
  editOrganizationNamePlaceholder: "Name of the organization",
  editBusinessNamePlaceholder: "Name of the business that runs the organization",
  editVATNumberPlaceholder: "VAT number of the organization",
  editRegistrationNumberPlaceholder: "Registration number of the organization",
  clinics: "Kliniker",
  createNewClinic: "Skapa ny klinik",
  createNewOrganization: "Skapa ny organisation",
  manager: "Chef",
  creditCard: "Kreditkortbetalning",
  creditCardNumber: "Kortnummer",
  fortnoxInvoiceSystem: "Fortnox Faktureringssystem",
  state: "Stat",
  integrated: "Integrerad",
  notIntegrated: "Inte integrerad",
  authenticationNeeded: "Autentisering behövs",
  authenticate: "Autentisera",
  fortnoxIntegration: "Fortnox integration",
  addIntegration: "Lägga till integration",
  fortnoxIntegrationSuccess: "Fortnox har anslutits.",
  disableFortnoxConfirmation: "Är du säker på att du vill inaktivera fortnox-integration?",
  fortnoxRedirect: "Du kommer att omdirigeras till Fortnox webbplats. Följ stegen för att slutföra integrationen.",
  editData: "Ändra data",
  turnOff: "Stäng av",
  fortnoxIntegrationCostWarning: "Aktivering och användning av integrationen kommer innebära ytterligare kostnader.",
  invalidData: "Ogiltig Data",
  settingsFailed: "Inställningar misslyckades",
  stripeIntegration: "Stripe integration",
  stripeIntegrationCostWarning: "Aktivering och användning av integrationen kommer innebära ytterligare kostnader.",
  szamlazzIntegration: "Szamlazz.hu integration",
  szamlazzIntegrationCostWarning: "Aktivering och användning av integrationen kommer innebära ytterligare kostnader.",
  szamlazzApiKey: "API key",
  publicKey: "Offentlig nyckel",
  privateKey: "Privat nyckel",
  bankAndPlusGiroAccount: "Bankgirot- och Plusgirot-konton",
  bankgirot: "Bankgirot",
  plusgirot: "Plusgirot",
  prescriptionFee: "Receptavgift",
  videoConsultationFee: "Video consultation fee",
  basicInfoOrgToolTip:
    "Grundläggande organisationsdetaljer. Namnfältet är ett godtyckligt visningsnamn, medan företagsnamnet är det juridiska namnet på enheten.",
  basicInfoOrganizationNameToolTip: "An arbitrary name for the organization, used only in the GlobalVET application.",
  basicInfoBusinessNameToolTip: "The official name of the business.",
  basicInfoContactOrgToolTip:
    "Redigera kontaktinformation för organisationen: e-post, adress, telefonnummer. Primära kontakter kommer att föredras för kommunikation.",
  managersOrgToolTip: "Chefer för organisationen. Chefer kan redigera all organisations data.",
  giroOrgToolTip:
    "För receptavgift krävs bankgiro eller plusgirkonto då receptavgiften betalas på ett samarbetsapotek.",
  creditCardOrgToolTip:
    "Organisationens  kreditkortsuppgifter. Kostnader för betaltjänster från GlobalVET kommer att debiteras på kreditkortet nedan.",
  stripeOrgToolTip:
    "Systemet stöder Stripe för onlinebetalningar. Om organisationen har ett Stripe-konto kan det integreras i systemet här. På så sätt kommer djurägare att kunna betala online också.",
  fortnoxToolTip:
    "Systemet stöder Fortnox för fakturering. Om organisationen har ett Fortnox-konto kan det integreras i systemet här.",
  szamlazzTooltip:
    "You can connect a Szamlazz.hu account to your clinic in order to create and manage invoices automatically.",
  invoicingInfo: "The system supports {0} and {1}. Connect your invoicing integrations here.",
  statisticsToolTip: "Användningsbaserad betalningsstatistik och historik.",
  treatmentToolTip:
    "Behandlingar används av alla kliniker i organisationen. Behandlingar kan tilldelas en art eller alla djur..",
  inventoryOrgToolTip:
    "Denna inventering kommer att visas för alla kliniker i organisationen. Prisvärden här är strandardvärden, kan specificeras för varje klinik i deras inställningar. Lagervärden är endast tillgängliga på klinikens inventering.",
  addFee: "Lägg till avgift",
  createNewServiceFee: "Skapa ny serviceavgift",
  addPaymentMethod: "Lägg till betalningsmetod.",
  supportedPaymentMethods: "Betalningsmetoder som stöds.",
  noPaymentMethods: "Kliniken har ännu ingen betalningsmetod som stöds.",
  createCodeInFortnox: "Skapa kod även i Fortnox.",
  officialLanguage: "Språk",
  syncWithFortnox: "Synka med Fortnox",
  roundingSettings: "Rounding and vat calculation",
  roundingSettingsTooltip:
    "If the clinic uses an integrated invoicing system as well (Fortnox or Számlazz.hu), then please make sure that you use the same rounding settings as your billing system.",
  roundingStrategyItem: "Rounding of an invoice item",
  roundingStrategyTotal: "Rounding of the invoice total",
  vatCalculationStrategy: "VAT calculation",

  // OwnerClinicListPage
  cannotRetriveOrganizationData: "Kan ej återskapa organisationsdata",
  cannotRetrivePetData: "Kan ej återskapa djurdata",
  clinicListByOrganization: "Klinikslista av organisation",
  reserveAppointment: "Reservera bokning",
  organizations: "Organisationer",
  chooseOrganization: "--Välj organisation--",
  chooseClinic: "--Välj klinik--",
  chooseReservationType: "--Välj reservationstyp--",
  choosePet: "--Välj djur--",
  chooseAPet: "Välj ett djur",
  next: "Nästa",
  previous: "Föregående",
  unselected: "Ej vald",

  // OwnerCalendarPage
  reason: "Anledning",
  writeReason: "Skriv anledningen...",

  // OwnerInvoicePage
  invoiceToPay: "Fakturor att Betala",
  creationOfInvoice: "Skapa Faktura",
  invoices: "Fakturor",
  pdf: "PDF",
  paid: "Betald",
  infoOnlinePaymentDisabled: "Fakturor kan inte betalas online för denna klinik!",
  noInvoicesToPay: "Det finns inga fakturor att betala.",
  noInvoices: "Det finns inga fakturor.",

  // WorkerCalendarPage
  back: "Tillbaka",
  chosenDate: "Valt datum",
  chooseADoctor: "Välj en veterinär",
  chooseDoctor: "--Välj veterinär--",
  description: "Beskrivning",
  writeSymptoms: "Beskriv symptomen...",
  reserve: "Reservera",
  reservationError: "Oförmögen att reservera för detta tidsintervall.",
  reservationInProgress: "Reservation pågår.",
  browseNewTimeSlot: "Bläddra i ny tidslucka.",
  newReservation: "Ny reservation",
  deletedItsProfile: "Profilen är raderad.",
  collaborator: "Medarbetare",
  createShiftFor: "Skapa skift för",
  reservationDetails: "Reservationsdetaljer",
  reject: "Avslå",
  showReservations: "Visa reservationer",
  showShifts: "Visa arbetspass",
  shifts: "Arbetspass",
  shift: "Arbetspass",
  modifyShiftFor: "Ändra skrift för {0}",
  reservations: "Reservationer",
  listView: "Listvy",
  doctorView: "Veterinär syn",
  reScheduleAppointment: "Boka om möte",
  reScheduleToolTip: "Hitta och klicka på ett nytt datum och tid i kalendern för att uppdatera bokningsinformation.",
  reSchedule: "Boka om",
  confirmReSchedule: "Bekräfta ombokning",
  startLaterThanEnd: "Sluttiden kan inte vara tidigare än starttiden.",
  startOfShift: "Start of shift",
  endOfShift: "End of shift",
  startDateEndDateErrorMsg: "End date can not be earlier than start date",
  tooLongShiftErrorMsg: "Shift is too long. Max length: 24 hours.",

  // Symptoms
  symptom: "Symptom",
  symptoms: "Symptomer",
  searchOrCreateSymptom: "Sök eller skapa nytt symptom",

  // Profile
  savedSuccessfully: "Sparat framgångsrikt",
  passwordChangedSuccessfully: "Lösenordet har ändrats framgångsrikt",
  changeMyPassword: "Ändra mitt lösenord",
  setDefaultLanguage: "Ställ in förvalt språk",
  notSpecified: "Ej specificerad",
  contacts: "Kontakter",
  language: "Språk",
  upload: "Ladda upp",
  security: "Säkerhetsinställningar",
  linkedAccounts: "Länkade konton",
  googleAccount: "Google-konto",
  facebookAccount: "Facebook-konto",
  noLinkedAccounts: "Det finns inga länkade konton.",
  accountLinkedSuccessfully: "Länkningen av kontot lyckades",
  idNumber: "Personnummer",
  pinCodeMustBeConfirmed: "Innan du börjar använda din PIN-kod måste den bekräftas.",
  codeIsSentToYourMobile: "Vi har skickat bekräftelsekoden till din primära mobiltelefon.",
  addNewPin: "Lägg till ny PIN",
  verifyPin: "Bekräfta PIN",
  userHasPinCode: "You already have a PIN number set up.",
  profilePictureIsPublic: "Profilbilder kommer att vara offentliga för alla andra användare.",
  deleteProfile: "Radera profil",
  deleteProfileWarning:
    "Denna operation kan inte återställas och den tar bort all profildata från systemet. Observera att organisationer som tillhandahållit tjänster till dig fortfarande har rätt att få tillgång till personuppgifter för redovisnings- och spårbarhetsändamål.",
  deleteProfileWarningWorker:
    "Tidigare åtgärder som du utfört i en organisation kommer att förbli synliga för de återstående anställda i organisationen i spårbarhetssyfte.",
  deleteProfileCredentials: "Please give your login password to confirm deletion:",
  deleteContactInformation: "Radera kontaktinformation.",
  areYouSureDeleteContactInformation: "Är du säker på att du vill ta bort din kontaktinformation?",
  profileBasicInfoToolTip:
    "Grundläggande profilinställningar. Personligt ID är kopplat till din adress. För att ändra den, lägg till en ny adress.",
  profileContactToolTip: "Redigera dina kontaktuppgifter såsom e-postadress, adress och telefonnummer.",
  profileVetRegNumberToolTip:
    "Hantera auktoriserade, nationella, veterinära licensnummer. Detta nummer kommer att användas i recept och journaler. ",
  vetRegNumberInfo: "För att kunna skapa e-recept behöver du anger ditt land och veterinärs-licensnummer.",
  vetRegNumbersEmpty: "You have no veterinarian license numbers added yet.",
  vetRegNumberDeleteConfirmation: "Are you sure you want to delete this veterinarian license number?",
  changesSavedSuccessfully: "Ändringarna har sparats framgångsrikt.",

  // ResetPassword
  PasswordRecoveryLinkNotValid: "Lösenordets återställningslänk är ej giltig.",
  resetPassword: "Ändra lösenord",
  resetPasswordEmailInfo: "En länk skickas till din e-postadress så att du kan få hjälp med att återställa lösenordet.",
  resetPasswordMobileInfo:
    "En kod skickas till ditt telefonnummer så att du kan få hjälp med att återställa lösenordet.",

  // SearchResult
  ownerName: "Ägarens namn",
  addNewAppointment: "Lägg till ny bokning",
  newAppointment: "Ny bokning",
  newMedicalRecord: "Ny journal",
  addNewMedicalRecord: "Lägg till ny journal",
  periodDoesNotFitIntoShift: "Reservationsperiod passar inte in i vald period!",
  pages: "sidor",
  openLastMedicalRecord: "Öppna sista journalen",
  petHasNoMedicalRecords: "Detta husdjur har inga medicinska journaler ännu",
  total: "Total",
  results: "resultat",

  // PhoneConfirmation
  phoneConfirmation: "Telefonbekräftelse",
  verifyYourPhone: "Verify your phone",
  confirmationCode: "Bekräftelsekod",
  verificationCode: "Verification code",
  confirmationLinkNotValid: "Telefonbekräftelselänk existerar ej",
  invalidVerificationCode: "Verification code is invalid, please try again.",
  confirmationCodeMustBeSixCharacters: "The confirmation code must be exactly 6 characters.",
  phoneConfirmationSuccess: "Telefonnummerbekräftelse lyckades",
  resendVerificationCode: "Skicka verifieringslänken igen",
  phoneCodeSent: "Bekräftelsekoden har skickats till följande telefonnummer: ",

  // TreatmentPage
  dontHavePermissionToCreateTreatments: "Du har inte tillåtelse att skapa behandlingar!",
  worngNameOrPrice: "Fel namn eller pris!",
  showAll: "Visa allt",
  useFilter: "Använd filter",
  chooseTheSpecies: "Välj djurtyp",
  deleteTreatment: "Ta bort behandling",
  editTreatment: "Redigera behandling",
  nameSuggestionToolTip: "Här kan du välja bland namnförslag, skapade av andra organisationer.",
  generateIntoMedicalRecordToolTip: "Denna beskrivning kommer att automatiskt genereras i journalen.",
  backToTreatments: "Tillbaka till behandlingar",

  // WorkerClinicPage
  thWeek: "{0}. Vecka",
  noShiftOnThisDay: "Inget arbetspass denna dag.",
  owner: "Ägare",
  consultationType: "Konsultationstyp",
  time: "Tid",
  action: "Åtgärd",
  saveAsPDF: "Spara som PDF",
  medicalRecord: "Journal",
  costCalculation: "Kostnadsförslag",

  // Add AddressOwnershipForm
  addAddress: "Lägg till adress",
  city: "Stad",
  zip: "Postnummer",
  postalCode: "Postnummer",
  street: "Gata",
  county: "Län",
  building: "Byggnad",
  addressLine1: "Gatuadress och husnummer ",
  addressLine2: "C/O",
  setPrimaryAddress: "Ange primär adress.",
  deleteAddress: "Delete address",
  deleteAddressConfirmation: "Are you sure you want to delete this address?",
  primaryAddressPromotionTextPart1: "Vill du ställa in",
  primaryAddressPromotionTextPart2: "som primär adress?",
  primaryEmailPromotionTextPart1: "Vill du ställa in ",
  primaryEmailPromotionTextPart2: " som primär e-mail?",
  primaryPhonePromotionTextPart1: "Vill du ställa in ",
  primaryPhonePromotionTextPart2: " som primär telefon?",
  setAsPrimary: "Set as primary",
  setPrimaryEmail: "Ställ in primär e-post",
  deleteEmail: "Delete email",
  deleteEmailConfirmation: "Are you sure you want to delete this email?",
  setPrimaryPhone: "Ställ in primär telefon",
  deletePhoneNumber: "Delete phone number",
  deletePhoneNumberConfirmation: "Are you sure you want to delete this phone number?",
  checkOnTheMap: "Du kan markera platsen genom att dra ock släppa nålen på kartan.",
  iHaveVerifiedTheTimeZoneAndLocation: "Jag bekräftar att adress och tidszon stämmer.",
  timeZoneRequired: "Selecting a time zone is required.",

  // UserAddressForm
  newAddressDetails: "Adress detaljer",
  newAddressCheck: "Kollar adress",
  checkSuccessful: "Adresskontrollen lyckades!",
  found: "Hittades",
  chosen: "Valt",
  timeZone: "Tidszon",
  addressNotFound: "Det gick inte att hitta den angivna adressen.",
  locationDetectedByAddress: "Plats som identifierades av adressen",

  // AddEmailOwnershipForm
  addEmail: "Lägg till e-postadress",
  email: "E-postadress",
  emailExample: "example@company.com",
  emailExampleName: "name@company.com",
  yourEmail: "E-postadress",

  // Switch
  on: "På",
  off: "Av",

  // Modal
  closeModal: "Close modal",

  // Select
  pleaseChoose: "--Välj--",

  // AddPhoneOwnershipForm
  addPhoneNumber: "Lägg till telefonnummer",
  phone: "Telefonnummer",
  emailRequiredForPhone: "Åtminstone en bekräftad email krävs för att lägga till telefonnummer.",

  // Ownerships
  addresses: "Adresser",
  availableAddresses: "Tillgängliga adresser",
  address: "Adress",
  yourAddress: "Adress",
  location: "Location",
  emails: "E-mails",
  phoneNumbers: "Telefonnummer",
  phoneNumber: "Telefonnummer",
  phones: "Telefonnummer",
  availablePhones: "Tillgängliga telefonnummer",
  emailNotConfirmedYet: "Emailadress är inte bekräftad ännu.",
  atLeastOneEmailRequired: "Åtminstone en e-mailadress krävs.",
  primary: "Primär",
  private: "Privat",
  public: "offentlig",
  contactType: "Kontakttyp",
  vat: "Moms",
  vatNumber: "Momsregistreringsnummer",
  vatAsTax: "Moms",
  phoneType: "Telefontyp",
  phoneNotConfirmedYet: "Telefonnummer är ej bekräftat ännu.",
  verificationCodeSentTo: "We sent you a verification code to {0}.",
  verificationError: "Verification error",
  primaryContactNotDeletable:
    "Primary contact is not allowed to delete. Create a new contact first, make it primary, than you can delete the current one.",

  // AddReservationTypeForm
  addReservationType: "Lägg till reservationstyp",
  createNewReservationType: "Lägg till ny reservationstyp",
  duration: "Varaktighet (min)",
  durationMustBePositive: "Varaktighet måste vara ett positivt helt nummer.",
  isReservableByOwnersQuestion: "Tillåta djurägare att reservera denna typ?",
  isReservableByOwners: "Tillåt djurägare att reservera",
  isVideoConsultationAllowedQuestion: "Allow video consultation for this type?",
  isVideoConsultationAllowed: "Allow video consultation",
  onlineReservationIsNotAvailable: "Onlinebokning är inte tillgänglig på denna klinik.",

  // EditReservationTypeForm
  editReservationType: "Ändra reservationstyp",

  // AddTreatmentForm
  addTreatment: "Lägg till behandling",
  createNewTreatment: "Lägg till ny behandling",
  treatmentName: "Behandlingens namn",
  treatmentPrice: "Kostnad för behandling",
  chooseASpecies: "Välj ett djurtyp",
  treatmentNameMustNotBeEmpty: "Behandlingsnamn får inte vara tomt!",
  addTreatmentSuccess: "Tillagd artikel till behandling lyckades.",
  workCost: "Arbetskostnad",
  basePrice: "Baspris",

  // AddUserForm
  addNew: "Lägg till",
  addNewManager: "Lägg till ny chef",

  // BillingSection
  billing: "Fakturering",
  item: "Artikel",
  quantity: "Mängd",
  extraItems: "Extra artikel",
  extraItemsAndFees: "Extra artikel och avgifter",
  addExtraItem: "Lägg till extra artikel",
  add: "Lägg till",
  sum: "Summera",
  taxNumber: "Skattenummer",
  sellItems: "Sälj objekt",
  accountingCode: "Konto",
  accountingCodes: "Konton",
  chargeClinic: "Charge clinic",

  // BanCardForm
  cardData: "Kortdata",

  // CategoryList
  categories: "Kategorier",
  createNewCat: "Skapa ny kategori",
  Vaccines: "Vacciner",
  Medicines: "Mediciner",

  // ChronicDiseasesModal
  alreadyRegisteredDiseases: "Befintliga registrerade kroniska sjukdomar",
  diseaseName: "Namn på Sjukdom",
  certified: "Certifierad",
  notCertified: "Ej certifierad",
  confirmMessage: "Certifiering kommer endast lyckas om sjukdomen är bekräftad och signerad i journalen",
  certificationDate: "Datum för certifiering",
  addNewChronicDisease: "Lägg till ny kronisk sjukdom",

  // DeleteReservation
  AreYouSureDeleteAppointment: "Är du säker på att du vill ta bort denna bokningen?",
  whyDoYouWantToRejectAppointment: "Varför vill du avboka?",
  confirm: "Bekräfta",
  cancelAppointment: "Avbryt bokning",
  rejectAppointment: "Avvisa bokning",
  rejectError: "Reservationen kan inte avbrytas",
  cancelAppointmentSuccess: "The appointment is successfully canceled.",
  cancelAppointmentError: "This appointment cannot be canceled. Please contact the clinic.",

  // InventoryNavBar.tsx
  bracnhesInventory: "{0} / Lager",
  home: "Hem",
  inventoryStatus: "Inventeringsstatus",
  statistics: "Statistik",

  // Treatments
  comment: "Tilläggstext",
  items: "Artikel",
  remove: "Ta bort",
  saveAsNew: "Spara som ny",
  use: "Använd",
  treatmentDescription: "Behandlingsbeskrivning",
  advices: "Råd till husdjursägare",
  suggestedMedicines: "Föreslagna läkemedel",
  complications: "Komplikationer",

  // Inventory
  searchWithDots: "Sök...",
  new: "Ny",

  // OwnerCalendar
  toggleWeekends: "Växla helger",
  prevWeek: "Föregående",
  nextWeek: "Nästa ",
  appointment: "Bokning",
  createUnregisteredPet: "Registrera",
  theOwnerWillNotSee: "Ägaren kommer inte att se detta",
  personalId: "Personnummer",
  personalIdFormatPlaceholder: "yyyymmddpppp",
  invalidFormat: "Ogiltligt format, korrekt format är: {0}",
  iDontHavePersonalIdInThisCountry: "Jag har inget personnummer i detta landet",
  doesntHavePersonalIdInThisCountry: "Har inget personnummer i detta landet",
  checkClinicRegistrationsNotificationMsg: "Du hade besök på kliniker innan du registrerades.",
  resolveClinicRegsBtnTitle: "Upplösa",
  pendingClinicRegistrations: "Pågående kliniksregistreringar ",
  createAsNewPet: "Skapa som nytt djur",
  claimAsMe: "Påstående",
  itWasntMe: "Det var inte jag",
  thereAreNoPendingClinicRegistrations: "Det finns inga pågående kliniksregistreringar",
  iAcceptThatOrganizationWillReceiveMyData:
    "Jag accepterar att organisationen kommer att ta emot mina personuppgifter och husdjurets journaler för att underlätta behandlingen.",
  noAvailableShiftsForThisReservationType: "There are no available shifts for this reservation type.",

  // Video consultation
  videoConsultation: "Video consultation",
  globalVetVideoConsultation: "GlobalVET video consultation",
  usersInTheRoom: "Users already in the room:",
  enableMicrophone: "Enable microphone",
  enableCamera: "Enable camera",
  noMicrophoneDetected:
    "We can't detect your microphone. Please ensure it's properly connected if you'd like to speak.",
  microphoneAccessDenied: "Microphone access denied! Click here for more information.",
  microphoneWorks: "Your microphone should work as expected.",
  noWebcamDetected:
    "We can't detect your webcam. Please ensure it's properly connected if you'd like others to see you.",
  webcamAccessDenied: "Webcam access denied! Click here for more information.",
  webcamWorks: "Your webcam should work as expected.",
  mediaErrorTooltip: "Click here for more information",
  mediaErrorTitle: "GlobalVET can't use this media device",
  mediaErrorWarningText:
    "There was an error setting up this media device. Please try to do the following steps to see if that can resolve the problem.",
  mediaErrorSuggestion1: "Close any applications that might be using the camera",
  mediaErrorSuggestion2: "Close and reopen the browser",
  mediaErrorSuggestion3: "If you are using an external camera or microphone, disconnect and reconnect it",
  mediaErrorSuggestion4: "Check the media settings of your operating system",
  permissionRequestTitle: "Permission required",
  permissionRequestText:
    "To be able to use this media device, please click 'Allow' or 'Block' in the permission popup displayed by your browser. The appearance of this popup may vary between different browsers.",
  permissionRequestInfo:
    "You can deny the permission if you prefer not to allow access to your microphone or camera. You will still be able to join the meeting.",
  startConsultation: "Start consultation",
  resumeConsultation: "Resume consultation",
  pauseConsultation: "Pause consultation",
  finishConsultation: "Finish consultation",
  copyMeetingLinkCopiedToClipboard: "Möteslänk har kopierats till urklipp",
  meetingLinkCopiedToClipboard: "Meeting link has been copied to clipboard!",
  linkCopied: "Link copied!",
  disableCamera: "Disable camera",
  disableMicrophone: "Disable microphone",
  leaveMeeting: "Leave meeting",
  waitingForOtherParticipants: "Det finns ingen ännu som väntar på att andra parter ska ansluta",
  inviteToMeeting: "Bjud in andra",
  joinConsultation: "Join consultation",
  connectingToVideoConsultationRoom: "Connecting to the video consultation room...",
  areYouSureToClose: "Är du säker på att du vill stänga mötet?",
  youHaveDisconnected: "You have disconnected from the consultation room.",
  meetingDuration: "Varaktighet: ",
  goBackToWaitingRoom: "Go back to waiting room",
  meetingDeviceError: "Enhetsfel",
  meetingGrantPermissions:
    "Anslut en mikrofon och en webbkamera, ge de nödvändiga behörigheterna och ladda sedan om sidan.",
  reloadMeeting: "Ladda om sidan",
  addVideoConsultation: "Add video consultation",
  inPersonExamination: "In preson examination",
  onlineVideoConsultation: "Online video consultation",
  connectToVideoConsultation: "Connect to video consultation",
  needPetOwnerEmailError: "Pet owner's e-mail address is required for video consultation.",
  setPetOwnerEmailLink: "Add e-mail address for pet owner.",
  videoConsultationError: "Video consultation error",
  payableCallTime: "Payable call time",
  consultationNotStartedYet: "The consultation has not started yet",
  consultationInProgress: "The consultation is in progress",
  consultationPaused: "The consultation is paused",
  consultationFinished: "The consultation has finished",
  yearSingular: "år",
  yearPlural: "år",
  monthSingular: "månad",
  monthPlural: "månader",
  daySingular: "dag",
  dayPlural: "dagar",
  hourSingular: "timme",
  hourPlural: "timmar",
  minuteSingular: "minut",
  minutePlural: "minuter",
  secondSingular: "sekund",
  secondPlural: "sekunder",
  finishConsultationInfo:
    "You are about to finish an active consultation that is currently paused. You can adjust the payable call time before adding it to the medical record. Alternatively, you have the option to finish the consultation without adding it to the medical record. Please note that finishing the consultation will remove all users from the room and notify them of this decision.",
  finalPayableCallTime: "Final payable call time",
  finalPayableAmount: "Final payable amount",
  continueConsultation: "Continue consultation",
  finishAndAddToMedicalRecord: "Finish and add to medical record",
  justDisconnect: "Just disconnect",
  resetToCurrentTime: "Reset to current time",
  consultationSuccessfullyFinished:
    "Your video consultation has finished successfully, and the corresponding entry has been added to the medical record.",
  newConsultationSession: "New consultation session",
  newConsultationSessionWarning:
    "A consultation has already been completed for this reservation. Would you like to start a new one? It will appear as a separate entry in the medical record.",
  startNewConsultation: "Start new consultation",

  // Video consultation monitor
  roomId: "Room ID",
  noActiveRoomsMessage: "No one is using the videochat service at the moment.",
  noInactiveRoomsMessage: "There are no recently deactivated rooms.",
  videoConsultationRoomMonitor: "Video consultation room monitor",
  videoChatUser: "Videochat user",
  latestJoinDate: "Latest join date",
  timeSinceLatestJoin: "Time since latest join",
  stateOfMediaDevices: "State of media devices",
  micEnabled: "Microphone is enabled.",
  micDisabled: "Microphone is disabled.",
  camEnabled: "Camera is enabled.",
  camDisabled: "Camera is disabled.",
  micWorking: "Microphone is working properly.",
  micMissing: "Microphone could not be detected.",
  micError: "Microphone could not be set up properly.",
  camWorking: "Camera is working properly.",
  camMissing: "Camera could not be detected.",
  camError: "Camera could not be set up properly.",
  roomIsBeingDeleted: "This room is about to be deleted...",
  consultationStatus: "Consultation status",
  waitingForStart: "Waiting for the vet to start the consultation...",
  started: "Started",
  paused: "paused",
  finished: "finished",
  waitingForVet: "Waiting for the vet to arrive...",
  waitingForPetOwner: "Waiting for the pet owner to arrive...",
  waitingForBoth: "Waiting for both the vet and the pet owner to arrive...",
  bothPresent: "Both the vet and the pet owner are present",
  eventSequenceNumber: "Event sequence number",
  eventType: "Event type",
  eventDate: "Event date",
  eventData: "Event data",
  thereAreNoEvents: "There are no events associated to this room",
  reservation: "Reservation",
  liveUserInformation: "Live user information",
  eventHistory: "Event history",
  goToMedicalRecord: "Go to medical record",
  startedAt: "Started at",
  totalConsultationTime: "Total consultation time",
  payableConsultationTime: "Payable consultation time",
  totalBreakTime: "Total break time",
  videoConsultationFeeOfTheClinic: "Video consultation fee of the clinic",
  globalVetFee: "GlobalVET fee",
  currentClinicFee: "Current clinic fee",
  currentGlobalVetFee: "Current GlobalVET fee",
  activeRooms: "Active rooms",
  inactiveRooms: "Recently deactivated rooms",
  guest: "Guest",
  roles: "Roles",
  session: "Session",
  sessionHistory: "Session history",
  thereAreNoPreviousSessions: "There are no previous video consultation sessions",
  deleteAllRooms: "Delete all rooms",
  deleteAllRoomsWarning:
    "Attention! Deleting all rooms will result in a clean state for the video chat signal service. This operation can be useful when some rooms have entered an invalid state.",
  addTestMeetingButtonText: "Add 10 minutes of meeting",
  addTestMeetingSuccess: "Successfully added 10 minutes of test meeting to the medical record!",
  logs: "Logs",

  // NumberFormatter
  thousandsSeparator: "\u2009",

  willBeSentToEmail: "Journal pdf skickas till följande e-postadress:",
  mute: "Tyst",

  anyVet: "Någon veterinär",
  appointmentPeriodOnOverlappingFreeTimeSlots:
    "The appointment time is not optimal for the clinic. Please, try to adjust it to the start or the end of the free timeslot or choose a specific vet for this reservation!",
  changeLogo: "Ändra logotyp",
  uploadLogo: "Ladda upp logotyp",
  deleteLogo: "Delete logo",
  unsupportedFileType: "Image file type is unsupported. Must be one of the following:",
  changeProfilePicture: "Ändra profilbild",
  uploadProfilePicture: "Ladda upp profilbild",
  deleteProfilePicture: "Delete profile picture",
  pictureChanged: "Bilden ändrades framgångsrikt.",
  prescriptionOnlyMode: "Receptbelagt läge",
  prescriptionOnlyModeTooltip:
    "I receptbelagt läge kan du inte använda och debiteras inte för andra funktioner, som skift-/reservationshantering, lagerhantering och fakturagenerering.",
  switchToPrescriptionOnlyMode: "Växla till receptbelagt läge",
  fullMode: "Fullt-läge",
  switchToFullMode: "Växla till fullt-läge",
  switchToPrescriptionOnlyModeWarning:
    "Byte till receptbelagt läge inaktiverar andra funktioner som lager, faktura, skift och reservationshantering.",
  switchToFullModeWarning:
    "Byte till fullt-läge kommer att medföra extra avgifter eftersom prenumerationsavgifter och andra avgifter kommer att debiteras också.",
  switch: "Byta",
  usePrescriptionOnlyMode: "Använd receptbelagt läge",
  usageMode: "Usage mode",

  // Support
  helpCenter: "Hjälpcenter",
  guidesForGettingStarted: "Guider för att komma igång",
  topSupportTopics: "Toppartiklar",
  seeAllSupportTopics: "Se alla artiklar",
  supportTopics: "Artiklar",
  stripeIntegrationSupportContent:
    "GlobalVet uses {0} for online payments. The Stripe framework requires registration and might use additional fees. Steps to integrate Stripe into GlobalVet:",
  stripeIntegrationSupportStep1: "Create Stripe account",
  stripeIntegrationSupportStep2: "Copy Stripe API keys",
  stripeIntegrationSupportStep3: "Paste Stripe API keys",
  szamlazzIntegrationSupportContent: "Steps to integrate automatic invoicing by Számlázz.hu:",
  costsOfSzamlazz: "Costs of Számla Agent.",
  szamlazzIntegrationSupportStep1: "Create Számlázz.hu account and log in",
  szamlazzIntegrationSupportStep2: "Copy Számla Agent API key",
  szamlazzIntegrationSupportStep3: "Paste Számla Agent API key",
  needToContactUs: "Behöver du ta kontakt med oss?",
  feelFreeToCotactUs: "Om du har några frågor är du välkommen att kontakta oss via {0}.",
  registerFirst: "Först behöver du en användarregistrering. Om du inte har ett konto ännu, {0}.",
  userRegistration: "Användarregistrering",
  userRegistrationContent:
    "Det är simpelt, du behöver endast en registering oavsett om du är en djurägare eller en anställd. När du loggar in efter registrering, kan du välja om du vill gå vidare i egenskap av djurägare eller anställd.",
  clinicRegistration: "Registrera klinik",
  clinicRegistrationContent:
    "Som chef eller ägare av en veterinärklinik kan du registrera en eller flera veterinärkliniker och därefter bjuda in kollegor.",
  becomePetOwner: "Registrera dig som djurägare",
  becomePetOwnerContent:
    "Som husdjursägare kan du registrera ett eller flera av dina husdjur för att enkelt boka tid på din veterinärklinik som är ansluten till GlobalVET.",
  becomeClinicEmployee: "Registrera dig som klinikanställd",
  becomeClinicEmployeeContent:
    "Som anställd kan du se kliniken du arbetar för och de förfrägningar som finns. När en klinikägare eller chef bjuder in dig till sin klinik fär du en notifikation och ett e-postmeddelande.",
  supportTicketHeader: "Skapa biljett",
  supportTicketTitle: "Titel",
  supportTicketBody: "Meddelande",
  supportTicketType: "Typ",
  supportTicketTypeChoose: "-- Välj en biljetttyp --",
  supportTicketFullName: "Fullständigt namn",
  supportTicketEmail: "E-postadress",
  supportTicketPhoneNumber: "Telefonnummer",
  supportTicketLanguage: "Språk",
  supportTicketSendButton: "Skapa biljett",

  supportTicketCreated:
    "Din biljett skickades in, du bör få ett e-postmeddelande med en länk till dig inom några ögonblick.",

  supportTicketChatAnswerTitle: "Nytt meddelande",
  supportTicketChatAnswerButton: "Skicka",

  supportTicketState: "Stat",
  supportTicketIsClosed: (date: string): string => `Din biljett har stängts ${date}. Tack för att du kontaktade oss!`,

  // Support Ticket Types
  supportTicketTypeError: "Fel",
  supportTicketTypeSupport: "Stöd",
  supportTicketTypeSubscription: "Prenumeration",

  // Support Ticket States
  supportTicketOpen: "Öppna",
  supportTicketClosed: "Stängd",
  backToTicketsPage: "Tillbaka till biljetter",
  myTickets: "Mina biljetter",
  closed: "Stängd",
  createTicket: "Skapa biljett",
  subject: "Ämne",
  supportTicketDescription: "Beskrivning",
  invalidTicketUrl: "Ogiltig biljett-URL",
  ticketNotFoundOrNotAuthorized: "Biljetten hittades inte eller så har du inte behörighet att se den.",
  help: "Behövs hjälp?",
  siteManagerMode: "Platschefsläge",
  shiftSuccessfullyModified: "The shift is successfully modified.",
  weAreSorry: "Vi är ledsna.",

  // Feedbacks
  feedbacks: "Feedbacks",
  survey: "Survey",
  sendFeedback: "Send",
  feedbackAlreadySent: "You have already sent your feedback. Thank you!",
  feedbackSuccess: "Thank you for your feedback!",
  feedbackQuestion: "What do you think of our system?",
  feedbackAnswerPlaceholder: "Please give us feedback",
  feedbackAge: "years old",
  feedbackPets: "pets",
};
