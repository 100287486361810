/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Control } from "react-hook-form";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { getVatOptions } from "../../../util/helperFunctions";
import { FeeItemResponse } from "../../../models/management/FeeItemResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import {
  positiveOrZeroDouble,
  positiveOrZeroTwoDecimal,
} from "../../../util/Validations";
import ControlledField from "../../../components/ReactHookFormFields/General/Controlled/ControlledField";
import ControlledSelect from "../../../components/ReactHookFormFields/General/Controlled/ControlledSelect";
import {
  ClinicCost,
  commissionBasedFees,
} from "../../../models/cost/ClinicCost";

interface Props {
  control: Control<any>;
  cost: ClinicCost;
  countryDetails?: CountryDetailsResponse;
  feeItem?: FeeItemResponse;
}

const FeeItemInputs: React.FC<Props> = ({
  control,
  cost,
  countryDetails,
  feeItem,
}: Props) => {
  return (
    <>
      <div className="font-weight-bold pt-3 pb-2">
        {getStringFromEnumsOrReturn(cost)}
      </div>
      <Row>
        <Col>
          {commissionBasedFees.includes(cost) ? (
            <ControlledField
              name={`${cost.toLowerCase()}.commissionRate`}
              label={strings.commissionRate}
              fieldOptions={{
                pattern: {
                  value: positiveOrZeroDouble.matchRegexp,
                  message: strings.notAllowedCharacter,
                },
              }}
              control={control}
              value={feeItem?.commissionRate}
              autoComplete={AutoCompleteOptions.off}
            />
          ) : (
            <ControlledField
              name={`${cost.toLowerCase()}.price`}
              label={strings.price}
              fieldOptions={{
                pattern: {
                  value: positiveOrZeroTwoDecimal.matchRegexp,
                  message: strings.notAllowedCharacter,
                },
              }}
              control={control}
              value={feeItem?.price}
              autoComplete={AutoCompleteOptions.off}
              suffix={countryDetails?.currency}
            />
          )}
        </Col>
        <Col>
          <ControlledSelect
            name={`${cost.toLowerCase()}.vat`}
            label={strings.vatAsTax}
            control={control}
            options={getVatOptions(countryDetails?.vatRates)}
            value={`${feeItem?.vat}`}
          />
        </Col>
        <Col>
          <ControlledField
            name={`${cost.toLowerCase()}.accountingCode`}
            label={strings.accountingCode}
            control={control}
            value={feeItem?.accountingCode}
            autoComplete={AutoCompleteOptions.off}
          />
        </Col>
      </Row>
    </>
  );
};

export default FeeItemInputs;
