/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment/moment";
import { ReservationResponse } from "../../../models/reservation/ReservationResponse";
import IconBubble from "../../../components/IconBubble";
import { strings } from "../../../common/Strings/Strings";
import { CalendarIcon } from "../../../common/Icons/Calendar";

interface Props {
  reservation: ReservationResponse;
  roomId: string;
}

const RoomTitle: React.FC<Props> = ({ reservation, roomId }: Props) => (
  <div>
    <section className="flex flex-row space-x-6 justify-between items-center">
      {/* Reservation type and clinic name */}
      <h1 className="font-bold text-2xl text-zinc-800 dark:text-white">
        {`${
          reservation.isEmergency
            ? strings.emergencyAppointment
            : reservation?.reservationType?.name
        } (${reservation.clinicName})`}
      </h1>
      {/* Reservation date */}
      <div className="flex space-x-3 items-center">
        <IconBubble>
          <CalendarIcon
            className="w-6 h-6 flex-shrink-0 text-zinc-800 dark:text-white"
            variant="outline"
          />
        </IconBubble>
        <div className="text-zinc-800 dark:text-white">
          {moment(reservation?.startDateTime).isSame(
            reservation?.endDateTime,
            "day"
          ) ? (
            <>
              <div className="font-semibold text-base">
                {moment(reservation?.startDateTime).format("ll, dddd")}
              </div>
              <div className="font-normal text-sm">
                {moment(reservation?.startDateTime).format("LT")} -{" "}
                {moment(reservation?.endDateTime).format("LT")}
              </div>
            </>
          ) : (
            <>
              <div className="font-semibold text-base">
                <span>
                  {moment(reservation?.startDateTime).format("ll, ddd, ")}
                </span>
                <span>{moment(reservation?.startDateTime).format("LT")}</span>
              </div>
              <div className="font-normal text-sm">
                <span style={{ fontWeight: 700 }}>
                  {moment(reservation?.endDateTime).format("ll, ddd, ")}
                </span>
                <span>{moment(reservation?.endDateTime).format("LT")}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
    {/* Room ID */}
    <p className="font-italic text-sm text-gray-500 dark:text-gray-400">
      {`${strings.roomId}: ${roomId}`}
    </p>
  </div>
);

export default RoomTitle;
