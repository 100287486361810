/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { ShiftResponse } from "../../../models/shift/ShiftResponse";
import DeleteShiftsModal from "../Modals/SubstitutionModal/DeleteShiftsModal";
import CalendarWeekEditShiftModal from "./CalendarWeekEditShiftModal";

interface Props {
  selectedShiftData?: ShiftResponse;
  onHide: (isSuccess?: boolean) => void;
  reload(): void;
  show: boolean;
}

type ModalState = "delete" | "modify";

const ShiftModal = ({
  selectedShiftData,
  onHide,
  reload,
  show,
}: Props): ReactElement => {
  const [modalState, setModalState] = useState<ModalState>("modify");

  const onClose = () => {
    setModalState("modify");
    onHide();
  };

  if (modalState === "delete") {
    return (
      <DeleteShiftsModal
        changeState={() => {
          setModalState("modify");
        }}
        onHide={onClose}
        reload={reload}
        selectedShiftData={selectedShiftData}
        show={show}
      />
    );
  }
  if (selectedShiftData) {
    return (
      <CalendarWeekEditShiftModal
        onHide={onClose}
        reload={reload}
        selectedShiftData={{
          ...selectedShiftData,
          collabName: selectedShiftData.collaborator.fullName,
        }}
        show={show}
      />
    );
  }

  return <></>;
};

export default ShiftModal;
