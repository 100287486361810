/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useMemo, useState } from "react";
import {
  getGeneralError,
  isFeatureAvailable,
} from "../../../util/helperFunctions";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";
import { clinicFeeUnits, strings } from "../../../common/Strings/Strings";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";
import VideoConsultationApi from "../../../api/VideoConsultationApi";
import ClinicApi from "../../../api/ClinicApi";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import CountryApi from "../../../api/CountryApi";
import { VideoConsultationResponse } from "../../../models/videoConsultation/VideoConsultationResponse";
import { CountryFeesResponse } from "../../../models/management/CountryFeesResponse";
import ClinicManagementApi from "../../../api/ClinicManagementApi";
import {
  calculateConsultationPrice,
  formatCallTimeSeconds,
} from "../../VideoConsultation/Utils";

interface Props {
  payableCallTimeInSeconds: number;
  videoConsultationId: string;
}

const FeesInfo: React.FC<Props> = ({
                                     payableCallTimeInSeconds,
                                     videoConsultationId,
                                   }) => {
  const [clinic, setClinic] = useState<ClinicResponse | null>(null);
  const [videoConsultationInfo, setVideoConsultationInfo] =
    useState<VideoConsultationResponse | null>(null);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse | null>(null);
  const [countryFees, setCountryFees] = useState<CountryFeesResponse | null>(
    null
  );

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchReservationData = async () => {
      setLoading(true);
      try {
        const response = await VideoConsultationApi.getVideoConsultation(
          videoConsultationId
        );
        setVideoConsultationInfo(response.data);

        const clinicResponse = await ClinicApi.getClinic(
          response.data.reservation.clinicId
        );
        setClinic(clinicResponse.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchReservationData();
  }, [videoConsultationId]);

  useEffect(() => {
    if (!clinic) return;

    const fetchCountryDetails = async () => {
      setLoading(true);
      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinic.id);
        setCountryDetails(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchCountryDetails();
  }, [clinic]);

  useEffect(() => {
    if (!clinic) return;

    const fetchCountryFees = async () => {
      setLoading(true);
      try {
        const clinicResponse = await ClinicManagementApi.getClinicDetails(
          clinic.id
        );
        const countryCode = clinicResponse.data.countryCode;

        if (countryCode) {
          const response = await CountryApi.getCountryFees(countryCode);
          setCountryFees(response.data);
        }
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchCountryFees();
  }, [clinic]);

  const videoConsultationFee = videoConsultationInfo?.fee;

  const videoConsultationFeeAmount = videoConsultationFee?.amount || 0;
  const videoConsultationFeeAmountGross = CostCalculator.getGrossPrice(
    videoConsultationFeeAmount,
    videoConsultationFee?.vat,
    countryDetails ?? undefined
  );

  const videoConsultationCurrentPrice = useMemo(
    () =>
      calculateConsultationPrice(
        payableCallTimeInSeconds,
        videoConsultationFee
      ),
    [payableCallTimeInSeconds, videoConsultationFee]
  );

  const videoConsultationCurrentPriceGross = CostCalculator.getGrossPrice(
    videoConsultationCurrentPrice,
    videoConsultationFee?.vat,
    countryDetails ?? undefined
  );

  const globalvetFeePercent =
    countryFees?.clinicFees.find((fee) => fee.name === "VIDEO_CONSULTATION_FEE")
      ?.commissionRate || 0;
  const globalvetFee =
    (videoConsultationCurrentPriceGross * globalvetFeePercent) / 100;

  if (loading) return <LoaderInline />;
  if (error) return <AlertBox message={error} />;
  if (!clinic || !isFeatureAvailable(clinic, ClinicFeature.VIDEO_CONSULTATION))
    return null;

  return (
    <div className="space-y-3">
      <div className="flex">
        <p>{`${strings.payableConsultationTime}:`}</p>
        <div>
          <b className="ml-2">
            {formatCallTimeSeconds(payableCallTimeInSeconds)}
          </b>
        </div>
      </div>
      <div className="flex">
        <p>{`${strings.videoConsultationFeeOfTheClinic}:`}</p>
        <div>
          <b className="ml-2">
            {NumberFormatter.formatPrice(
              videoConsultationFeeAmountGross,
              countryDetails?.currency
            )}
          </b>
          <span className="ml-2 opacity-50">
            (
            {NumberFormatter.formatPrice(
              videoConsultationFeeAmount,
              countryDetails?.currency
            )}{" "}
            {strings.plusVat})
          </span>
          <span className="ml-2">
            {videoConsultationFee?.unit
              ? clinicFeeUnits[videoConsultationFee.unit]
              : ""}
          </span>
        </div>
      </div>
      <div className="flex">
        <p>{`${strings.globalVetFee}:`}</p>
        <b className="ml-2">{`${globalvetFeePercent}%`}</b>
      </div>
      <div className="flex">
        <p>{`${strings.currentClinicFee}:`}</p>
        <b className="ml-2">
          {NumberFormatter.formatPrice(
            videoConsultationCurrentPriceGross,
            countryDetails?.currency
          )}
        </b>
        <span className="ml-2 opacity-50">
          (
          {NumberFormatter.formatPrice(
            videoConsultationCurrentPrice,
            countryDetails?.currency
          )}{" "}
          {strings.plusVat})
        </span>
      </div>
      <div className="flex">
        <p>{`${strings.currentGlobalVetFee}:`}</p>
        <b className="ml-2">
          {NumberFormatter.formatPrice(globalvetFee, countryDetails?.currency)}
        </b>
      </div>
    </div>
  );
};

export default FeesInfo;
