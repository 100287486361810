/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import DiscountItemInputs from "./DiscountItemInputs";
import { ClinicCost } from "../../../models/cost/ClinicCost";
import AlertBox from "../../../components/AlertBox";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import Switch from "../../../components/ReactHookFormFields/General/Switch";
import { getGeneralError } from "../../../util/helperFunctions";
import { BasicClinicResponse } from "../../../models/clinic/BasicClinicResponse";
import { ClinicDiscountResponse } from "../../../models/discount/ClinicDiscountResponse";
import { ClinicDiscountRequest } from "../../../models/discount/ClinicDiscountRequest";
import { Option } from "../../../models/Option";
import ClinicDiscountApi from "../../../api/ClinicDiscountApi";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import CountryApi from "../../../api/CountryApi";
import { CountryFeesResponse } from "../../../models/management/CountryFeesResponse";
import Field from "../../../components/ReactHookFormFields/General/Field";
import moment from "moment/moment";
import LoaderInline from "../../../components/LoaderInline";
import ClinicManagementApi from "../../../api/ClinicManagementApi";

interface Props {
  getDiscounts: () => void;
  onHide: () => void;
  clinics?: BasicClinicResponse[];
  selectedDiscount?: ClinicDiscountResponse;
  selectedClinicId?: string;
  show: boolean;
}

interface EditDiscountForm {
  clinicId: string | "";
  isFree: boolean;
  [key: string]: any;
  validityStart: string;
  validityEnd?: string;
}

interface DiscountItemForm {
  price?: number;
  commissionRate?: number;
}

const defaultFormValues = (
  selectedDiscount?: ClinicDiscountResponse,
  selectedClinicId?: string
): EditDiscountForm => ({
  clinicId: selectedDiscount?.clinicId || selectedClinicId || "",
  isFree: selectedDiscount?.isFree ?? false,
  validityStart: moment(selectedDiscount?.validityStart ?? new Date()).format(
    "YYYY-MM-DD"
  ),
  validityEnd: selectedDiscount?.validityEnd
    ? moment(selectedDiscount.validityEnd).format("YYYY-MM-DD")
    : undefined,
});

const EditDiscountModal: React.FC<Props> = ({
  getDiscounts,
  onHide,
  clinics,
  selectedDiscount,
  selectedClinicId,
  show,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [feesLoading, setFeesLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [countryFees, setCountryFees] = useState<CountryFeesResponse>();

  const getCountryFees = async (clinicId: string) => {
    try {
      setFeesLoading(true);
      const clinicResponse = await ClinicManagementApi.getClinicDetails(
        clinicId
      );
      const countryCode = clinicResponse.data.countryCode;
      if (countryCode) {
        const response = await CountryApi.getCountryFees(countryCode);
        setCountryFees(response.data);
      }
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setFeesLoading(false);
    }
  };

  const saveDiscount = async (formData: EditDiscountForm) => {
    if (formData === undefined || formData?.clinicId === "") return;

    setLoading(true);

    const clinicFees = Object.keys(ClinicCost).map((cost) => {
      const data = formData[cost] as DiscountItemForm;
      return {
        cost,
        price: Number(data?.price || ""),
        commissionRate: Number(data?.commissionRate || ""),
      };
    });

    const request: ClinicDiscountRequest = {
      clinicId: formData.clinicId,
      isFree: formData.isFree,
      validityStart: formData.validityStart,
      validityEnd: formData.validityEnd,
      clinicFees,
    };

    try {
      if (selectedDiscount) {
        await ClinicDiscountApi.changeDiscount(selectedDiscount.id, request);
      } else {
        await ClinicDiscountApi.addDiscount(request);
      }

      onHide();
      getDiscounts();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const {
    watch,
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<EditDiscountForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(selectedDiscount, selectedClinicId),
  });

  useLayoutEffect(() => {
    reset(defaultFormValues(selectedDiscount, selectedClinicId));
    setError(null);
  }, [reset, selectedDiscount, selectedClinicId, show]);

  const [clinicId, isFree] = watch(["clinicId", "isFree"]);

  useEffect(() => {
    setCountryFees(undefined);
    void getCountryFees(clinicId);
  }, [clinicId]);

  return (
    <>
      <Modal size="modal-lg" show={show} handleClose={onHide}>
        <Modal.Header
          closeButton
          title={
            selectedDiscount ? selectedDiscount.clinicName : strings.discounts
          }
        ></Modal.Header>
        <Modal.Body>
          <div hidden={!feesLoading}>
            <LoaderInline />
          </div>
          <form>
            <div hidden={feesLoading}>
              {!selectedDiscount && !selectedClinicId && (
                <Select
                  name="clinicId"
                  label={strings.clinic}
                  disabledChooseOptionTitle={strings.chooseClinic}
                  register={register}
                  options={(clinics || []).map((s) => {
                    const option: Option = {
                      title: s.name,
                      value: s.id,
                    };
                    return option;
                  })}
                  error={errors.clinicId}
                  required
                />
              )}

              {clinicId && countryFees && (
                <div>
                  <div className="row mt-3">
                    <div className="col">
                      <Field
                        register={register}
                        type="date"
                        label={strings.startDate}
                        name="validityStart"
                      />
                    </div>
                    <div className="col">
                      <Field
                        register={register}
                        type="date"
                        label={strings.endDate}
                        name="validityEnd"
                      />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col align-middle">
                      {strings.freeSubscription}
                    </div>
                    <div className="col align-middle">
                      <Switch
                        name="isFree"
                        className="float-right"
                        control={control}
                        value={isFree}
                      />
                    </div>
                  </div>

                  <div hidden={isFree} className="mt-3">
                    {Object.keys(ClinicCost).map((clinicCost) => (
                      <div key={clinicCost}>
                        <DiscountItemInputs
                          control={control}
                          cost={clinicCost as ClinicCost}
                          fee={countryFees?.clinicFees?.find(
                            (i) => i.name === clinicCost
                          )}
                          currency={countryFees?.currency}
                          discount={selectedDiscount?.clinicFees?.find(
                            (i) => i.cost === clinicCost
                          )}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <AlertBox message={error} className="my-3" />
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={!isValid || loading || !isDirty || feesLoading}
            fullWidth={false}
            loading={loading}
            onClick={handleSubmit(saveDiscount)}
            variant="primary"
          >
            {strings.save}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditDiscountModal;
