/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import MediaDevicesState from "./MediaDevicesState";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import { PagerState } from "../../../hooks/hooks";
import { VideoChatUser } from "../../../models/videoConsultation/VideoChatUser";
import { VideoChatRoom } from "../../../models/videoConsultation/VideoChatRooms";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { calculateDurationDate } from "../../VideoConsultation/Utils";
import RoleBadge from "../../VideoConsultation/RoleBadge";

interface Props {
  room: VideoChatRoom;
  pager: PagerState;
}

const LiveUserInfo: React.FC<Props> = ({ room, pager }: Props) => {
  const renderUserRow = (user: VideoChatUser) => (
    <tr key={user.id}>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <div className="flex flex-row items-center">
          <div className="w-10 h-10 mr-3">
            <UserProfilePicture userId={user.id} />
          </div>
          <p>{user.name}</p>
        </div>
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <div className="flex flex-row justify-start items-center gap-2">
          {user.roles.map((role) => (
            <RoleBadge role={role} />
          ))}
        </div>
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        {user.joined
          ? moment(user.joined).format(
              dateAndTime.momentDateTimeWithSecondsFormat
            )
          : "-"}
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        {user.joined ? calculateDurationDate(user.joined) : "-"}
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <MediaDevicesState
          camEnabled={user.mediaConfig?.camEnabled || false}
          camMissing={user.mediaConfig?.camMissing || false}
          camError={user.mediaConfig?.camError || false}
          micEnabled={user.mediaConfig?.micEnabled || false}
          micMissing={user.mediaConfig?.micMissing || false}
          micError={user.mediaConfig?.micError || false}
        />
      </td>
    </tr>
  );

  const renderAlertBox = () => (
    <AlertBox
      closeAble={false}
      message={
        <div className="flex flex-row gap-2">
          <p>{strings.roomIsBeingDeleted}</p>
          <LoaderInline />
        </div>
      }
      type={AlertType.INFO}
    />
  );

  return (
    <>
      {room.isEndingCall && renderAlertBox()}

      <PageableTable
        content={room.users}
        hidden={room.users.elements.length === 0}
        isLoading={false}
        pager={pager}
      >
        <PageableTableHead>
          <tr>
            <th className="text-left tw-th">{strings.videoChatUser}</th>
            <th className="text-left tw-th">{strings.roles}</th>
            <th className="text-left tw-th">{strings.latestJoinDate}</th>
            <th className="text-left tw-th">{strings.timeSinceLatestJoin}</th>
            <th className="text-left tw-th">{strings.stateOfMediaDevices}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {room.users.elements.map(renderUserRow)}
        </PageableTableBody>
      </PageableTable>
    </>
  );
};

export default LiveUserInfo;
