/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import TimeSelectComponent, {
  TimeSelectProps,
} from "../../../InputFieldsSimple/TimeSelectComponent";

export interface TimeSelectFieldProps {
  control: Control<any>;
  /* HH:mm */
  defaultValue?: string | null;
  name: string;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  onChange?(time: string | null): void;
}

const TimeSelect = ({
  control,
  defaultValue,
  name: n,
  fieldOptions,
  onChange: customOnchange,
  required,
  readOnly,
  ...rest
}: TimeSelectFieldProps & TimeSelectProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});

  useEffect(() => {
    if (required) {
      setRegisterOptions({
        ...registerOptions,
        required: true,
        validate: { isValidTime: (t) => moment(t, "LT").isValid() },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <Controller
      name={n}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => {
        return (
          <TimeSelectComponent
            name={n}
            error={error}
            readOnly={readOnly}
            onChange={(time: string | null) => {
              const formattedTime = time
                ? moment(time, "LT").format("HH:mm")
                : null;
              onChange(formattedTime);

              if (customOnchange) {
                customOnchange(formattedTime);
              }
            }}
            value={
              moment(value, "HH:mm").isValid()
                ? moment(value, "HH:mm").format("LT")
                : null
            }
            {...rest}
          />
        );
      }}
    />
  );
};

export default TimeSelect;
