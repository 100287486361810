/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { quantityUnits, strings } from "../../../../common/Strings/Strings";
import { InventoryItemDetailsResponse } from "../../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import Button from "../../../../components/Button";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import { Trash } from "../../../../common/Icons/Trash";
import InventoryItemApi from "../../../../api/InventoryItemApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { InventoryItemStockCountingResponse } from "../../../../models/inventory/item/Response/InventoryItemStockCountingResponse";
import NumberFormatter from "../../../../util/NumberFormatter";
import LowLevelIndicator from "../../LowLevelIndicator";
import EmptyListText from "../../../../components/EmptyListText";
import { InventoryItemStockItemResponse } from "../../../../models/inventory/item/Response/InventoryItemStockItemResponse";
import { Eye } from "../../../../common/Icons/Eye";
import CountingViewModal from "../../Counting/CountingViewModal";
import Tooltip from "../../../../components/Tooltip";

interface Props {
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemDetailsResponse;
  reloadItem(): void;
}

export const getSumOfInventoryCounting = (
  stocks: InventoryItemStockItemResponse[]
) => {
  let sum = 0;
  stocks.forEach((stock) => {
    sum += stock.quantity;
  });
  return sum;
};

const CountingHistory: React.FC<Props> = ({
  countryDetails,
  inventoryItem,
  reloadItem,
}) => {
  const [openCountingViewModal, setOpenCountingViewModal] =
    useState<InventoryItemStockCountingResponse>();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const deleteStockCounting = async (stockCountingId: string) => {
    setSuccess(null);
    setError(null);

    if (inventoryItem) {
      setDeleteLoading(true);

      try {
        await InventoryItemApi.deleteItemCounting(
          inventoryItem.id,
          stockCountingId
        );
        setSuccess(strings.countingDeletedSuccessfully);
        reloadItem();
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="flex items-center">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.countInventory}
        </h2>
        <div className="ml-auto">
          <Link to={`/inventory/item/${inventoryItem?.id}/counting-stock`}>
            <Button minW>{strings.newCounting}</Button>
          </Link>
        </div>
      </div>
      <AlertBox message={error} />
      <AlertBox message={success} type={AlertType.SUCCESS} />
      <div className="flex items-center">
        <div className="mr-2">
          {strings.stock}:{" "}
          <span className="font-bold">
            {NumberFormatter.format(inventoryItem?.stock || 0)}{" "}
            {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
          </span>
        </div>
        <LowLevelIndicator
          lowLevel={inventoryItem?.lowLevel}
          stock={inventoryItem?.stock}
        />
      </div>
      <div className="tw-table-container">
        <table className="tw-table">
          <thead className="tw-thead">
            <tr>
              <th className="tw-th">{strings.date}</th>
              <th className="tw-th">{strings.quantity}</th>
              <th className="tw-th">{strings.comment}</th>
              <th />
            </tr>
          </thead>
          <tbody className="tw-tbody">
            {inventoryItem?.stockCountings?.length === 0 ? (
              <tr>
                <td
                  colSpan={6}
                  className="py-3.5 px-4 text-sm font-medium whitespace-nowrap"
                >
                  <EmptyListText />
                </td>
              </tr>
            ) : (
              <></>
            )}
            {inventoryItem?.stockCountings?.map(
              (stockCounting: InventoryItemStockCountingResponse) => (
                <tr key={stockCounting.id}>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {stockCounting.dateTime &&
                      moment(stockCounting.dateTime).format("LL")}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {NumberFormatter.format(
                      getSumOfInventoryCounting(stockCounting.stocks)
                    )}{" "}
                    {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium break-all">
                    {stockCounting.comment}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap text-right">
                    <div className="flex justify-end space-x-3">
                      <Tooltip content={strings.show}>
                        <Button
                          variant="icon"
                          onClick={() => {
                            setOpenCountingViewModal(stockCounting);
                          }}
                        >
                          <Eye />
                        </Button>
                      </Tooltip>
                      <Button
                        askAreYouSure={{
                          enable: true,
                          content: strings.formatString(
                            strings.areYouSureToDeleteItemCounting,
                            <div className="font-bold">
                              {inventoryItem.name}
                            </div>
                          ),
                          title: strings.deleteCounting,
                        }}
                        disabled={deleteLoading}
                        loading={deleteLoading}
                        onClick={() => {
                          void deleteStockCounting(stockCounting.id);
                        }}
                        variant="icon"
                      >
                        <Trash />
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <CountingViewModal
        acquisitionUnit={inventoryItem?.acquisitionUnit}
        close={() => {
          setOpenCountingViewModal(undefined);
        }}
        counting={openCountingViewModal}
        countryDetails={countryDetails}
        isOpen={!!openCountingViewModal}
        stocks={openCountingViewModal?.stocks}
      />
    </div>
  );
};

export default CountingHistory;
