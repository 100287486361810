/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import Moment from "react-moment";
import { strings } from "../../../common/Strings/Strings";
import {
  getGeneralError,
  localDateFormat,
  mergeTimeAndDate,
} from "../../../util/helperFunctions";
import ShiftApi from "../../../api/ShiftApi";
import { TimePeriod } from "../../../models/shift/TimePeriod";
import { Shift } from "../../../models/calendar/Shift";
import { useClinic } from "../../../contexts/ClinicContext";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import IconBubble from "../../../components/IconBubble";
import { CalendarIcon } from "../../../common/Icons/Calendar";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { Trash } from "../../../common/Icons/Trash";
import { PencilSquare } from "../../../common/Icons/PencilSquare";
import { ReservationResponse } from "../../../models/reservation/ReservationResponse";
import ReservationsForm from "../Modals/SubstitutionModal/ReservationsForm";
import ShiftDateTimes from "./ShiftDateTimes";

interface Props {
  show: boolean;
  onHide(): void;
  reload(): void;
  selectedShiftData: Shift;
}

interface FormData {
  endDate: Date;
  endTime: string;
  startTime: string;
  startDate: Date;
}

const CalendarWeekEditShiftModal: React.FC<Props> = ({
  reload,
  show,
  selectedShiftData,
  onHide,
}: Props) => {
  const { clinic } = useClinic();
  const [modalState, setModalState] = useState<
    "view" | "edit" | "delete" | "deleteForm"
  >("view");
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateTimeValidation, setDateTimeValidation] = useState<
  string | undefined
  >();
  const methods = useForm<FormData>({
    mode: "onChange",
  });
  const { formState: { isDirty }, setValue, handleSubmit, reset } = methods;

  useEffect(() => {
    const endDateTime = moment(selectedShiftData.endDateTime);
    const startDateTime = moment(selectedShiftData.startDateTime);
    setValue("endDate", endDateTime.toDate());
    setValue("endTime", endDateTime.format("HH:mm"));
    setValue("startDate", startDateTime.toDate());
    setValue("startTime", startDateTime.format("HH:mm"));
  }, [selectedShiftData, setValue, show]);

  const clearResponses = () => {
    if (error) {
      setError(null);
    }

    if (success) {
      setSuccess(null);
    }
  };

  const closeModal = (): void => {
    reset();
    clearResponses();
    onHide();
  };

  const submitEdit = async ({
    endDate,
    endTime,
    startDate,
    startTime,
  }: FormData) => {
    clearResponses();

    if (!clinic || !selectedShiftData) {
      return;
    }

    setLoading(true);

    const putData: TimePeriod = {
      startTime: moment(mergeTimeAndDate(startTime, startDate)).format(
        localDateFormat()
      ),
      endTime: moment(mergeTimeAndDate(endTime, endDate)).format(
        localDateFormat()
      ),
    };

    try {
      await ShiftApi.updateTimePeriod(clinic.id, selectedShiftData.id, putData);
      setSuccess(strings.shiftSuccessfullyModified);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const shiftInfoComponent = (
    <>
      <div>
        <h4 className="text-lg font-semibold leading-tight text-zinc-800 dark:text-white">
          {selectedShiftData.collaborator.fullName} (
          <Moment format="LT">{selectedShiftData.startDateTime}</Moment>
          &nbsp;-&nbsp;
          <Moment format="LT">{selectedShiftData.endDateTime}</Moment>)
        </h4>
        <div className="flex flex-row space-x-1 ml-auto mt-1">
          {selectedShiftData.reservations?.map(
            (reservation: ReservationResponse) => (
              <div
                className="rounded-full w-4 h-4 flex-shrink-0"
                style={{
                  backgroundColor: reservation.reservationType?.color,
                }}
              />
            )
          )}
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <IconBubble>
          <UserProfilePicture userId={selectedShiftData.collaborator.userId} />
        </IconBubble>
        <div className="font-semibold text-base text-zinc-800 dark:text-white">
          {selectedShiftData.collaborator.fullName}
        </div>
      </div>
      {moment(selectedShiftData.startDateTime).isSame(
        selectedShiftData.endDateTime,
        "days"
      ) ? (
        <div className="flex items-center space-x-3">
          <IconBubble>
            <CalendarIcon />
          </IconBubble>
          <div>
            <div className="font-semibold text-base text-zinc-800 dark:text-white">
              <Moment
                format="LL"
                locale={
                  strings.getLanguage() === "gb" ? "en-gb" : strings.getLanguage()
                }
              >
                {selectedShiftData.startDateTime}
              </Moment>
            </div>
            <div className="font-normal text-sm text-zinc-800 dark:text-white">
              <Moment format="LT">{selectedShiftData.startDateTime}</Moment>
              &nbsp;-&nbsp;
              <Moment format="LT">{selectedShiftData.endDateTime}</Moment>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center space-x-3">
          <IconBubble>
            <CalendarIcon />
          </IconBubble>
          <div className="font-semibold text-base text-zinc-800 dark:text-white">
            <div>
              <Moment
                format="LLLL"
                locale={
                  strings.getLanguage() === "gb"
                    ? "en-gb"
                    : strings.getLanguage()
                }
              >
                {selectedShiftData.startDateTime}
              </Moment>{" "}
              -
            </div>
            <div>
              <Moment
                format="LLLL"
                locale={
                  strings.getLanguage() === "gb"
                    ? "en-gb"
                    : strings.getLanguage()
                }
              >
                {selectedShiftData.endDateTime}
              </Moment>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <Modal
      handleClose={() => {
        if (success) {
          reload();
        } else {
          closeModal();
        }
      }}
      show={show}
    >
      <Modal.Header>
        <div className="truncate overflow-hidden xl:text-xl font-bold leading-tight text-zinc-800 dark:text-white grow">
          {strings.shift}
        </div>
        <div hidden={modalState !== "view"} className="ml-auto mr-3">
          <Button
            onClick={() => {
              setModalState("edit");
            }}
            variant="icon"
          >
            <PencilSquare />
          </Button>
        </div>
        <div hidden={modalState !== "view"} className="mr-3">
          <Button
            onClick={() => {
              setModalState("delete");
            }}
            variant="icon"
          >
            <Trash />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {modalState === "view" ? <>{shiftInfoComponent}</> : <></>}
        {modalState === "delete" ? (
          <>
            <div>{strings.areYouSureToDeleteShift}</div>
            {shiftInfoComponent}
            <ReservationsForm
              employeeUserId={selectedShiftData?.collaborator.userId}
              start={selectedShiftData?.startDateTime}
              end={selectedShiftData?.endDateTime}
              onHide={onHide}
              setLoading={setLoading}
              reload={reload}
            />
          </>
        ) : (
          <></>
        )}
        {modalState === "edit" ? (
          <FormProvider {...methods}>
            <form className="space-y-4" id="editShiftForm">
              <ShiftDateTimes setDateTimeValidation={setDateTimeValidation} />
              <AlertBox message={error} />
              <AlertBox type={AlertType.SUCCESS} message={success} />
            </form>
          </FormProvider>
        ) : (
          <></>
        )}
      </Modal.Body>
      {modalState !== "view" ? (
        <Modal.Footer>
          <Button
            onClick={() => {
              if (success) {
                reload();
              } else {
                setModalState("view");
              }
            }}
            variant="secondary"
          >
            {success ? strings.close : strings.cancel}
          </Button>
          {modalState === "delete" ? (
            <Button
              disabled={loading}
              form="reservationsForm"
              loading={loading}
              type="submit"
              variant="danger"
            >
              {strings.delete}
            </Button>
          ) : (
            <Button
              className="modal-main-button"
              disabled={loading || !!dateTimeValidation || !isDirty}
              form="editShiftForm"
              loading={loading}
              onClick={handleSubmit(submitEdit)}
            >
              {strings.save}
            </Button>
          )}
        </Modal.Footer>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default CalendarWeekEditShiftModal;
