/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import { useClinic } from "../../../contexts/ClinicContext";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { strings } from "../../../common/Strings/Strings";
import UrlTab from "../../../components/Tabs/UrlTab";
import LocationIndicator from "../../../components/Tabs/LocationIndicator";
import StockHistory from "./IventoryItemTabs/StockHistory";
import { InventoryItemDetailsResponse } from "../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import UsageHistory from "./IventoryItemTabs/UsageHistory";
import CountingHistory from "./IventoryItemTabs/CountingHistory";
import ItemQuantityHistory from "./IventoryItemTabs/ItemQuantityHistory";
import InventoryItemAnalytics from "./IventoryItemTabs/InventoryItemAnalytics";
import BasicEditItemTab from "./IventoryItemTabs/BasicEditItemTab";
import { Trash } from "../../../common/Icons/Trash";
import Button from "../../../components/Button";
import SalePriceTab from "./IventoryItemTabs/SalePriceTab";

export type InventoryItemDetailsKeys =
  | "info"
  | "sale"
  | "stock-history"
  | "usage-history"
  | "counting-history";

const InventoryItemDetailsPage: React.FC<PageProps> = ({
  setPageLoading,
}: PageProps) => {
  const [inventoryItem, setInventoryItem] =
    useState<InventoryItemDetailsResponse>();
  const [key, setKey] = useState<InventoryItemDetailsKeys>("info");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [reloadItem, setReloadItem] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { clinic } = useClinic();
  const { itemId } = useParams();

  useEffect(() => {
    const getCountryDetails = async () => {
      if (clinic?.id) {
        try {
          const response = await CountryApi.getCountryDetailsOfClinic(
            clinic.id
          );
          setCountryDetails(response.data);
        } catch (e) {
          setError(await getGeneralError(e));
        }
      }
    };

    void getCountryDetails();
  }, [clinic]);

  useEffect(() => {
    const getInventoryItemDetails = async () => {
      if (!clinic?.id || !itemId) return;
      try {
        const response = await InventoryItemApi.getItem(itemId);
        setInventoryItem(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    void getInventoryItemDetails();
  }, [clinic, itemId, reloadItem, setPageLoading]);

  const triggerReload = (message?: string) => {
    setPageLoading(true);
    setReloadItem((prev) => !prev);
    message && setSuccess(message);
  };

  const deleteItem = async (id: string) => {
    setDeleteLoading(true);

    try {
      await InventoryItemApi.removeItem(id);
      navigate("/inventory");
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {inventoryItem?.name}
            </h1>
            <div className="ml-auto">
              <Button
                askAreYouSure={{
                  enable: true,
                  content: strings.formatString(
                    strings.areYouSureDeleteItem,
                    <b>{inventoryItem?.name}</b>
                  ),
                  title: strings.deletingItem,
                }}
                disabled={deleteLoading}
                loading={deleteLoading}
                variant="icon"
                onClick={() => {
                  inventoryItem && void deleteItem(inventoryItem?.id);
                }}
              >
                <Trash />
              </Button>
            </div>
          </div>
          <AlertBox
            message={success}
            hidden={!success}
            type={AlertType.SUCCESS}
          />
          <div className="border border-gray-200 rounded-md bg-gray-50 !p-4 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
            <ul className="flex flex-wrap -mb-px">
              <UrlTab
                useBaseUrlOnly
                currentTabKey={key}
                tabKey="info"
                title={strings.basicInfo}
              />
              <UrlTab
                currentTabKey={key}
                tabKey="sale"
                title={strings.salePrice}
              />
              <div className="xl:hidden">
                <UrlTab
                  currentTabKey={key}
                  tabKey="history"
                  title={strings.history}
                />
              </div>
              <div className="hidden xl:block">
                <UrlTab
                  currentTabKey={key}
                  tabKey="stock-history"
                  title={strings.refillStock}
                />
              </div>
              <div className="hidden xl:block">
                <UrlTab
                  currentTabKey={key}
                  tabKey="usage-history"
                  title={strings.stockUsage}
                />
              </div>
              <div className="hidden xl:block">
                <UrlTab
                  currentTabKey={key}
                  tabKey="counting-history"
                  title={strings.countInventory}
                />
              </div>
              <UrlTab
                currentTabKey={key}
                tabKey="analytics"
                title={strings.analytics}
              />
            </ul>
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <LocationIndicator
                    location="info"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <BasicEditItemTab
                        clinicId={clinic?.id}
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="sale"
              element={
                <>
                  <LocationIndicator
                    location="sale"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <SalePriceTab
                        clinicId={clinic?.id}
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="history"
              element={
                <>
                  <LocationIndicator
                    location="history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <ItemQuantityHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="stock-history"
              element={
                <>
                  <LocationIndicator
                    location="stock-history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <StockHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="usage-history"
              element={
                <>
                  <LocationIndicator
                    location="usage-history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <UsageHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="counting-history"
              element={
                <>
                  <LocationIndicator
                    location="counting-history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <CountingHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="analytics"
              element={
                <>
                  <LocationIndicator
                    location="analytics"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    {inventoryItem && (
                      <InventoryItemAnalytics
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                      />
                    )}
                  </div>
                </>
              }
            />
          </Routes>
        </div>
      </section>
    </main>
  );
};

export default Loader(InventoryItemDetailsPage);
