/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import {
  petGenders,
  petSpecies,
  strings,
} from "../../../common/Strings/Strings";
import { useDebouncedState } from "../../../hooks/hooks";
import { useClinic } from "../../../contexts/ClinicContext";
import { HighlightedText } from "../../../util/StringUtils";
import LoaderInline from "../../../components/LoaderInline";
import ClinicPetApi from "../../../api/ClinicPetApi";
import { ClinicPetAndOwnerResponse } from "../../../models/pet/ClinicPetAndOwnerResponse";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import EmptyListText from "../../../components/EmptyListText";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { MagnifyingGlass } from "../../../common/Icons/MagnifyingGlass";
import { Heart } from "../../../common/Icons/Heart";
import { Link } from "react-router-dom";
import { PageResponse } from "../../../models/PageResponse";
import PetOwnerApi from "../../../api/PetOwnerApi";
import Button from "../../../components/Button";
import { PublicPhoneResponse } from "../../../models/contact/PublicPhoneResponse";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  onClickNewPetAndOwner?: () => void;
  onSelectPet: (petAndOwner: ClinicPetAndOwnerResponse) => void;
  onSelectOwner?: (petOwner: PetOwnerResponse) => void;
  onClickNewPet?: (petOwner: PetOwnerResponse) => void;
  onError?: (err: AxiosError) => void;
}

const PetSearchSection: React.FC<Props> = ({
  onClickNewPetAndOwner,
  onSelectPet,
  onSelectOwner,
  onClickNewPet,
  onError,
}: Props) => {
  const { clinic } = useClinic();
  const [isDown, setIsDown] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<ClinicPetAndOwnerResponse[]>(
    []
  );
  const [petlessOwners, setPetlessOwners] = useState<PetOwnerResponse[]>([]);
  const [petlessSearchLoading, setPetlessSearchLoading] =
    useState<boolean>(false);
  const { value: searchText, setValue: setSearchText } =
    useDebouncedState<string>("", 400);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  const searchChange = ({ target: { value } }: any) => {
    setSearchResult([]);
    setPetlessOwners([]);
    setSearchText(value);
  };

  useEffect(() => {
    const findPets = async () => {
      setIsSearchLoading(true);
      if (clinic?.id) {
        setIsDown(true);
        try {
          const response = await ClinicPetApi.searchClinicPet(
            clinic.id,
            searchText
          );
          setSearchResult(response.data);
        } catch (err: any) {
          if (onError) {
            onError(err);
          }
        } finally {
          setIsSearchLoading(false);
        }
      }
    };

    const findPetlessOwners = async () => {
      setPetlessSearchLoading(true);
      if (clinic?.id) {
        try {
          const response: AxiosResponse<PageResponse<PetOwnerResponse>> =
            await PetOwnerApi.getPetOwners(clinic.id, {
              pageSize: 5,
              pageNumber: 0,
              query: searchText,
              hasNoPet: true,
            });
          setPetlessOwners(response.data.elements);
        } catch (err: any) {
          if (onError) {
            onError(err);
          }
        } finally {
          setPetlessSearchLoading(false);
        }
      }
    };

    if (searchText.length >= 3) {
      void findPets();
      void findPetlessOwners();
    }
  }, [searchText]);

  return (
    <ClickOutsideDetector
      listen
      onClickOutside={() => {
        setIsDown(false);
      }}
    >
      <div className="dropdown">
        <div className="relative w-full">
          <label htmlFor="search">{strings.petOrOwner} *</label>
          <div
            role="button"
            tabIndex={0}
            hidden={!onClickNewPetAndOwner}
            className="absolute right-0 top-0 tw-link text-sm"
            onClick={onClickNewPetAndOwner}
            style={{ cursor: "pointer" }}
          >
            {strings.newPetAndOwner}
          </div>
          <div className="relative w-full">
            <button
              className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              style={{ zIndex: 0 }}
              type="button"
            >
              {isSearchLoading ? (
                <LoaderInline />
              ) : (
                <MagnifyingGlass className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              )}
            </button>
            <input
              autoComplete={AutoCompleteOptions.off}
              className="tw-input pl-10"
              name="search"
              onChange={searchChange}
              onClick={() => {
                if (searchResult.length > 0 && !isDown) {
                  setIsDown(true);
                }
              }}
              placeholder={strings.searchPetOwner}
              type="text"
            />
          </div>
        </div>
        <ul
          className={`dropdown-menu w-full ${isDown ? "show" : ""}`}
          style={{
            maxHeight: "300px",
            minWidth: 0,
            padding: 0,
            overflowY: "scroll",
          }}
        >
          <div className="divide-y dark:divide-gray-900 text-sm">
            {isSearchLoading || petlessSearchLoading ? (
              <div className="px-4 py-3">
                <LoaderInline />
              </div>
            ) : (
              <></>
            )}
            {isSearchLoading || petlessSearchLoading ? (
              <></>
            ) : (
              searchResult.map(
                (
                  { pet, petOwner }: ClinicPetAndOwnerResponse,
                  index: number
                ) => (
                  <div className="px-4 py-3" key={index}>
                    <div
                      className="cursor-pointer mb-1"
                      onClick={() => {
                        onSelectPet({ pet, petOwner });
                        setIsDown(false);
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="flex flex-wrap items-center space-x-2">
                        <div>
                          <div style={{ width: "30px", height: "30px" }}>
                            <PetProfilePicture
                              species={pet.clinicPetDetails.species}
                              petId={pet.clinicPetDetails.id}
                            />
                          </div>
                        </div>
                        <HighlightedText
                          text={pet?.clinicPetDetails?.name}
                          highlights={searchText.split(" ")}
                        />{" "}
                        {pet?.clinicPetDetails?.deceased && (
                          <div>
                            <Heart className="h-5 w-5" variant="solid" />
                          </div>
                        )}
                        <div>
                          (
                          {pet?.clinicPetDetails?.species &&
                            petSpecies[pet?.clinicPetDetails?.species]}
                          ,{" "}
                          {pet?.clinicPetDetails?.gender &&
                            petGenders[pet?.clinicPetDetails?.gender]}
                          , {pet?.clinicPetDetails?.breed})
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div>
                        <HighlightedText
                          text={petOwner.petOwnerDetails.fullName}
                          highlights={searchText.split(" ")}
                        />
                      </div>
                      {petOwner.phones.length > 0 ? (
                        <div className="ml-2">
                          <HighlightedText
                            text={petOwner.phones[0].value}
                            highlights={searchText.split(" ")}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="ml-auto" hidden={!onClickNewPet}>
                        <Button
                          variant="link"
                          onClick={() => {
                            if (onClickNewPet) {
                              onClickNewPet(petOwner);
                              setIsDown(false);
                            }
                          }}
                        >
                          <PlusIcon className="h-5 w-5 flex-shrink-0" />{" "}
                          {strings.newPet}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              )
            )}
            {searchResult.length === 0 &&
            petlessOwners.length > 0 &&
            !isSearchLoading &&
            !petlessSearchLoading &&
            searchText.length > 0 ? (
              <div className="p-4">
                <EmptyListText text={strings.noPetResult} />
              </div>
            ) : null}
            {searchResult.length === 0 &&
            petlessOwners.length === 0 &&
            !isSearchLoading &&
            !petlessSearchLoading ? (
              <div className="p-4">
                <EmptyListText text={strings.noResult} />
              </div>
            ) : null}
            {isSearchLoading || petlessSearchLoading ? (
              <></>
            ) : (
              petlessOwners.map((petlessOwner) => (
                <div
                  className={`flex flex-wrap items-center text-sm px-4 py-3${
                    onSelectOwner ? " cursor-pointer" : " cursor-text"
                  }`}
                  onClick={() => {
                    if (onSelectOwner) {
                      onSelectOwner(petlessOwner);
                      setIsDown(false);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div>
                    <HighlightedText
                      text={petlessOwner.petOwnerDetails.fullName}
                      highlights={searchText.split(" ")}
                    />
                    <div className="flex space-x-2">
                      {petlessOwner.petOwnerDetails.personalId && (
                        <div className="text-sm">
                          <HighlightedText
                            text={petlessOwner.petOwnerDetails.personalId}
                            highlights={searchText.split(" ")}
                          />
                        </div>
                      )}
                      {petlessOwner.petOwnerDetails.phones?.map(
                        (phone: PublicPhoneResponse) => (
                          <div className="text-sm">
                            <HighlightedText
                              text={phone.value}
                              highlights={searchText.split(" ")}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="ml-auto" hidden={!onClickNewPet}>
                    <Button
                      variant="link"
                      onClick={() => {
                        if (onClickNewPet) {
                          onClickNewPet(petlessOwner);
                          setIsDown(false);
                        }
                      }}
                    >
                      <PlusIcon className="h-5 w-5 flex-shrink-0" />{" "}
                      {strings.newPet}
                    </Button>
                  </div>
                </div>
              ))
            )}
            <div
              className="px-4 py-3"
              hidden={petlessSearchLoading || isSearchLoading}
            >
              <Link to="/create-pet-owner">{strings.createPetOwner}</Link>
            </div>
          </div>
        </ul>
      </div>
    </ClickOutsideDetector>
  );
};

export default PetSearchSection;
