/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { VideoCamera } from "../../../common/Icons/VideoCamera";
import Button from "../../../components/Button";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";
import { VideoChatRoom } from "../../../models/videoConsultation/VideoChatRooms";
import { Card } from "../../../components/Cards/Card";
import ConsultationStatusInfo from "./ConsultationStatusInfo";
import ReservationInfo from "./ReservationInfo";
import LiveUserInfo from "./LiveUserInfo";
import EventHistory from "./EventHistory";
import FeesInfo from "./FeesInfo";
import VideoConsultationApi from "../../../api/VideoConsultationApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { PagerState } from "../../../hooks/hooks";
import { VideoConsultationResponse } from "../../../models/videoConsultation/VideoConsultationResponse";
import SessionHistory from "./SessionHistory";
import MedicalRecordButton from "../../VideoConsultation/MedicalRecordButton";
import RoomTitle from "./RoomTitle";
import SimpleSelect from "../../../components/InputFieldsSimple/SimpleSelect";

interface Props {
  currentSession: number;
  room: VideoChatRoom;
  roomEventsPager: PagerState;
  setCurrentSession: (currentSession: number) => void;
  usersPager: PagerState;
}

const RoomInfo: React.FC<Props> = ({
  currentSession,
  room,
  roomEventsPager,
  setCurrentSession,
  usersPager,
}: Props) => {
  const [videoConsultationInfo, setVideoConsultationInfo] =
    useState<VideoConsultationResponse>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    const getVideoConsultationInfo = async () => {
      setLoading(true);

      try {
        const response = await VideoConsultationApi.getVideoConsultation(
          room.id
        );
        setVideoConsultationInfo(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getVideoConsultationInfo();
  }, [room.id]);

  const sessionOptions = Array.from(
    { length: room.sessionCount },
    (_, index) => ({
      title: `${strings.session} ${index + 1}`,
      value: index + 1,
    })
  );

  const selectSession = (sessionId: string) => {
    roomEventsPager.setPageNumber(0);
    const sessionSequenceNumber = Number(sessionId);
    setCurrentSession(sessionSequenceNumber);
  };

  if (loading) {
    return <LoaderInline />;
  }

  if (error) {
    return <AlertBox message={error} />;
  }

  return (
    <Card key={room.id} size="max" type="simple">
      {videoConsultationInfo?.reservation && (
        <>
          <RoomTitle
            reservation={videoConsultationInfo.reservation}
            roomId={room.id}
          />
          <div id="reservation-info">
            <h4 className="text-xl font-bold text-zinc-800 dark:text-zinc-100 mb-4">
              {strings.reservation}
            </h4>
            <ReservationInfo reservation={videoConsultationInfo.reservation} />
          </div>
        </>
      )}
      <div id="consultation-status-info">
        <h4 className="text-xl font-bold text-zinc-800 dark:text-zinc-100 mb-4">
          {strings.consultationStatus}
        </h4>
        <ConsultationStatusInfo
          consultationStatus={room.consultationStatus}
          payableCallTimeInSeconds={room.payableCallTimeInSeconds}
          presenceStatus={room.presenceStatus}
          startTimestamp={room.startTimestamp}
          totalCallTimeInSeconds={room.totalCallTimeInSeconds}
        />
      </div>
      {room.active && (
        <div id="live-user-info">
          <h4 className="text-xl font-bold text-zinc-800 dark:text-zinc-100 mb-4">
            {strings.liveUserInformation}
          </h4>
          <LiveUserInfo room={room} pager={usersPager} />
        </div>
      )}
      <div id="event-info">
        <h4 className="text-xl font-bold text-zinc-800 dark:text-zinc-100 mb-4">
          {strings.eventHistory}
        </h4>
        <div className="max-w-max mb-4">
          <SimpleSelect
            name="sessionSelect"
            isChooseOption={false}
            onChange={(e) => selectSession(e?.target?.value)}
            options={sessionOptions}
            value={currentSession}
          />
        </div>
        <div>
          <EventHistory events={room.events} pager={roomEventsPager} />
        </div>
      </div>
      <div id="fees-info">
        <h4 className="text-xl font-bold text-zinc-800 dark:text-zinc-100 mb-4">
          {strings.fees}
        </h4>
        <FeesInfo
          payableCallTimeInSeconds={room.payableCallTimeInSeconds}
          videoConsultationId={room.id}
        />
      </div>
      <div id="session-history">
        <h4 className="text-xl font-bold text-zinc-800 dark:text-zinc-100 mb-4">
          {strings.sessionHistory}
        </h4>
        <SessionHistory
          medicalRecord={videoConsultationInfo?.medicalRecord}
          videoConsultationId={room.id}
        />
      </div>
      <div className="flex justify-end gap-4">
        <Link to={`/meet/${room.id}`} target="_blank" rel="noopener noreferrer">
          <Button minW>
            <VideoCamera
              className="w-6 h-6 mr-2 flex-shrink-0 text-white"
              variant="outline"
            />
            {strings.connectToVideoConsultation}
          </Button>
        </Link>
        <MedicalRecordButton
          minW
          reservation={videoConsultationInfo?.reservation}
          variant="primary"
        />
      </div>
    </Card>
  );
};

export default RoomInfo;
