/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import { getGeneralError } from "../../../util/helperFunctions";
import ReservationApi from "../../../api/ReservationApi";
import { ReservationDeletionRequest } from "../../../models/reservation/ReservationDeletionRequest";
import {
  calendarSelectedViewKey,
  deleteFromLocalStorage,
  getValueOfActiveUser,
  newReservationFormDataKey,
  saveReservationToReschedule,
  UserRole,
} from "../../../util/LocalStorageVariables";
import AlertBox from "../../../components/AlertBox";
import { CalendarReservation } from "../../../models/calendar/CalendarReservation";
import ReservationStateSelect from "./ReservationStateSelect";
import IconBubble from "../../../components/IconBubble";
import Tag, { TagType } from "../../../components/Tag";
import PetInfo from "../../Todo/PetInfo";
import { Pencil } from "../../../common/Icons/Pencil";
import { Trash } from "../../../common/Icons/Trash";
import { ViewTypes } from "../MainCalendar";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";
import CloseButton from "../../../components/CloseButton";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { Clock } from "../../../common/Icons/Clock";
import { DocumentText } from "../../../common/Icons/DocumentText";
import { Assignment } from "../../../common/Icons/Assignment";
import { CalendarIcon } from "../../../common/Icons/Calendar";
import { VideoCamera } from "../../../common/Icons/VideoCamera";

interface Props {
  activeReservation: CalendarReservation;
  closeModal(): void;
  modalIsOpen: boolean;
  navigateForEdit?: boolean;
  refreshReservationInProgress?(): void;
  reload(): void;
}

const CalendarDayReservationModal: React.FC<Props> = (props: Props) => {
  const role: UserRole | null = getValueOfActiveUser("role");
  const {
    activeReservation,
    closeModal,
    modalIsOpen,
    navigateForEdit = true,
    refreshReservationInProgress,
    reload,
  } = props;
  const [deleteSection, setDeleteSection] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [loadingDeletion, setLoadingDeletion] = useState<boolean>(false);
  const [isReloadOnClose, setIsReloadOnClose] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<string>();

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { isDirty, errors },
  } = useForm<{ explanation: string }>();

  useEffect(() => {
    setDeleteSection(false);
  }, [props]);

  useEffect(() => {
    setInvoiceId(activeReservation?.invoiceId);
  }, [activeReservation]);

  const openDeleteModal = () => {
    setDeleteSection(true);
  };

  const close = () => {
    closeModal();

    if (error) {
      setError(null);
    }

    if (isReloadOnClose) {
      reload();
    }
  };

  const submit = async (values: { explanation: string }) => {
    const request: ReservationDeletionRequest = {
      explanation: values.explanation,
      employeeRequest: role === "doctor",
    };

    setLoadingDeletion(true);

    try {
      await ReservationApi.deleteReservation(activeReservation.id, request);
      if (navigateForEdit) {
        navigate("/vet");
      }
      close();
      setIsReloadOnClose(true);
      reload();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoadingDeletion(false);
    }
  };

  const saveReservationData = (): void => {
    saveReservationToReschedule(activeReservation);
    deleteFromLocalStorage(newReservationFormDataKey);
    if (refreshReservationInProgress) {
      refreshReservationInProgress();
    }
    if (navigateForEdit) {
      sessionStorage.setItem(calendarSelectedViewKey, ViewTypes.DAY);
      navigate("/vet");
    }
    close();
  };

  const medicalRecordLink = (): string => {
    if (!activeReservation.lastMedicalRecordId) {
      return `/reservation/${activeReservation.id}/medical-record/`;
    }
    if (!activeReservation.lastSignedMedicalRecordId) {
      return `/medical-record/${activeReservation.lastMedicalRecordId}`;
    }
    if (
      activeReservation.lastMedicalRecordId !==
      activeReservation.lastSignedMedicalRecordId
    ) {
      return `/medical-record/${activeReservation.lastMedicalRecordId}`;
    }

    return `/medical-record/${activeReservation.lastSignedMedicalRecordId}`;
  };

  const medicalRecordStatus = () => {
    /* Created */
    if (
      !activeReservation?.lastMedicalRecordId ||
      (activeReservation?.lastMedicalRecordId &&
        !activeReservation?.lastSignedMedicalRecordId)
    ) {
      return (
        <div className="d-flex">
          <div style={{ marginRight: "8px" }}>
            <Tag type={TagType.secondary} text={strings.created} />
          </div>
          <div>{activeReservation?.createdBy?.fullName}</div>
        </div>
      );

      /* Signed */
    }
    if (
      activeReservation?.lastSignedMedicalRecordId ===
      activeReservation?.lastMedicalRecordId
    ) {
      return <Tag type={TagType.primary} text={strings.signed} />;

      /* Under correction */
    }
    return <Tag type={TagType.secondary} text={strings.underCorrection} />;
  };

  return (
    <Modal handleClose={close} show={modalIsOpen} size="modal-lg">
      <Modal.Header
        titleClassName={
          activeReservation.isEmergency
            ? "truncate overflow-hidden xl:text-xl font-bold leading-tight text-red-500 dark:text-red-400"
            : ""
        }
        title={
          activeReservation.isEmergency
            ? strings.emergencyAppointment
            : activeReservation.reservationType?.name
        }
      >
        <div>
          <Button
            className="mr-3"
            disabled={deleteSection || !activeReservation.cancellable}
            onClick={saveReservationData}
            variant="icon"
          >
            <Pencil />
          </Button>
          <Button
            className="mr-3"
            disabled={deleteSection || !activeReservation.cancellable}
            onClick={openDeleteModal}
            variant="icon"
          >
            <Trash />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
            <div className="col-span-1 space-y-6">
              <div className="flex space-x-3 items-center">
                <IconBubble
                  backgroundColor={activeReservation?.reservationType?.color}
                />
                <div className="font-semibold text-base text-zinc-800 dark:text-white">
                  {activeReservation.isEmergency
                    ? strings.emergencyAppointment
                    : activeReservation.reservationType?.name}
                </div>
              </div>
              {activeReservation?.videoConsultationRoomId ? (
                <div className="flex items-center space-x-3">
                  <IconBubble>
                    <VideoCamera variant="outline" />
                  </IconBubble>
                  {activeReservation?.startDateTime &&
                  moment().isAfter(activeReservation?.startDateTime, "days") ? (
                    <div className="font-bold">
                      {strings.onlineVideoConsultation}
                    </div>
                  ) : (
                    <Link
                      to={`/meet/${activeReservation?.videoConsultationRoomId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button>{strings.connectToVideoConsultation}</Button>
                    </Link>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="flex space-x-3 items-center">
                <IconBubble>
                  <CalendarIcon className="w-6 h-6 flex-shrink-0 text-zinc-800" />
                </IconBubble>
                <div className="text-zinc-800 dark:text-white">
                  {moment(activeReservation.startDateTime).isSame(
                    activeReservation.endDateTime,
                    "day"
                  ) ? (
                    <>
                      <div className="font-semibold text-base">
                        {moment(activeReservation.startDateTime).format(
                          "ll, dddd"
                        )}
                      </div>
                      <div className="font-normal text-sm">
                        {moment(activeReservation.startDateTime).format("LT")} -{" "}
                        {moment(activeReservation.endDateTime).format("LT")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="font-semibold text-base">
                        <span>
                          {moment(activeReservation.startDateTime).format(
                            "ll, ddd, "
                          )}
                        </span>
                        <span>
                          {moment(activeReservation.startDateTime).format("LT")}
                        </span>
                      </div>
                      <div className="font-normal text-sm">
                        <span style={{ fontWeight: 700 }}>
                          {moment(activeReservation.endDateTime).format(
                            "ll, ddd, "
                          )}
                        </span>
                        <span>
                          {moment(activeReservation.endDateTime).format("LT")}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex space-x-3 items-center">
                <div style={{ width: "40px", height: "40px" }}>
                  <UserProfilePicture
                    userId={activeReservation.collaborator.userId}
                  />
                </div>
                <div className="font-semibold text-base text-zinc-800 dark:text-white">
                  {activeReservation.collaborator.fullName}
                </div>
              </div>
              <div className="flex space-x-3 items-center">
                <IconBubble>
                  <Clock className="w-6 h-6 flex-shrink-0 text-zinc-800" />
                </IconBubble>
                <div className="font-semibold text-base text-zinc-800 dark:text-white">
                  {medicalRecordStatus()}
                </div>
              </div>
              <div hidden={!invoiceId}>
                <div className="flex space-x-3 items-center">
                  <IconBubble>
                    <DocumentText className="w-6 h-6 flex-shrink-0 text-zinc-800" />
                  </IconBubble>
                  <Link to={`/invoices/clinic/${invoiceId}`}>
                    <div className="tw-link-tercier">{strings.invoice}</div>
                  </Link>
                </div>
              </div>
              <div className="flex space-x-3 items-center">
                <IconBubble>
                  <Assignment className="w-6 h-6 flex-shrink-0 text-zinc-800" />
                </IconBubble>
                <Link to={medicalRecordLink()}>
                  <div className="tw-link-tercier">{strings.medicalRecord}</div>
                </Link>
              </div>
            </div>
            <div className="col-span-1 space-y-4 md:space-y-6">
              <div className="flex items-center">
                <ReservationStateSelect
                  medicalRecordId={activeReservation?.lastMedicalRecordId}
                  reload={() => {
                    setIsReloadOnClose(true);
                  }}
                  reservationId={activeReservation?.id}
                  reservationStatus={activeReservation?.state}
                  setError={setError}
                  setInvoice={setInvoiceId}
                />
              </div>
              <div className="flex space-x-3 items-center">
                <div className="">
                  {!activeReservation?.petOfNewOwner &&
                  activeReservation?.pet ? (
                    <PetInfo
                      pet={{
                        clinicPetDetails: {
                          ...activeReservation.pet,
                          chronicDiseases:
                            activeReservation.pet.chronicDiseases || [],
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {activeReservation?.petOfNewOwner ? (
                    <b className="mb-2">
                      {activeReservation?.petOfNewOwner?.name}
                    </b>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="flex space-x-3 items-center">
                <div style={{ width: "40px", height: "40px" }}>
                  <UserProfilePicture userId={activeReservation.petOwner.id} />
                </div>
                <div>
                  <div className="font-semibold text-base text-zinc-800 dark:text-white">
                    <b>{activeReservation.petOwner.fullName}</b>
                  </div>
                  <div className="font-normal text-sm text-zinc-800 dark:text-white">
                    {activeReservation.petOwner.phones[0]?.value}
                  </div>
                </div>
              </div>
              <div hidden={!activeReservation.description}>
                <div className="flex space-x-3 items-center">
                  <IconBubble>
                    <Pencil className="w-6 h-6 flex-shrink-0 text-zinc-800" />
                  </IconBubble>
                  <div className="font-semibold text-base text-zinc-800 dark:text-white">
                    {activeReservation.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {deleteSection ? (
          <div className="flex justify-center">
            <div className="p-3">
              <form>
                <Textarea
                  classLabel="bold"
                  error={errors.explanation}
                  label={strings.whyDoYouWantToRejectAppointment}
                  labelIcon="fas fa-exclamation-circle"
                  name="explanation"
                  minRows={2}
                  register={register}
                  required
                />
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        <AlertBox className="m-3" message={error} />
      </Modal.Body>
      <Modal.Footer hidden={!deleteSection}>
        <Button
          className="modal-main-button"
          disabled={!isDirty}
          form="newReservationTypeForm"
          fullWidth={false}
          loading={loadingDeletion}
          onClick={handleSubmit(submit)}
          type="submit"
          variant="danger"
        >
          {activeReservation.cancellable ? strings.confirm : strings.reject}
        </Button>
        <CloseButton
          onClick={() => {
            setDeleteSection(false);
          }}
          text={strings.cancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarDayReservationModal;
