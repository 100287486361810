/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { DataConnection, MediaConnection } from "peerjs";
import { MediaConfig, VideoChatUserConnection } from "./Utils";
import { VideoChatUser } from "../../models/videoConsultation/VideoChatUser";

export interface MediaConnectionPayload {
  mediaConnection: MediaConnection;
}

export interface DataConnectionPayload {
  dataConnection: DataConnection;
  user: VideoChatUser;
  mediaConfig: MediaConfig;
}

export interface ConnectionsPayload {
  dataConnection: DataConnection;
  mediaConfig: MediaConfig;
  mediaConnection: MediaConnection;
  user: VideoChatUser;
}

type Action =
  | { type: "ADD_USER_MEDIA_CONNECTION"; payload: MediaConnectionPayload }
  | { type: "ADD_USER_DATA_CONNECTION"; payload: DataConnectionPayload }
  | { type: "ADD_USER_CONNECTIONS"; payload: ConnectionsPayload }
  | { type: "REMOVE_USER"; payload: string }
  | { type: "CLEAR_USERS" };

export const remoteUsersReducer = (state: VideoChatUserConnection[], action: Action): VideoChatUserConnection[] => {
  switch (action.type) {
    case "ADD_USER_MEDIA_CONNECTION": {
      // Check if the user already exists by their peer id
      const existingUser = state.find(
        (user: VideoChatUserConnection) => user.id === action.payload.mediaConnection.peer
      );

      if (existingUser) {
        // If the user exists, update their media connection
        return state.map((user: VideoChatUserConnection) =>
          user.id === action.payload.mediaConnection.peer
            ? { ...user, mediaConnection: action.payload.mediaConnection }
            : user
        );
      }

      // If the user doesn't exist, create a new user with the media connection
      return [
        ...state,
        {
          id: action.payload.mediaConnection.peer, // Assuming peer is the user id
          mediaConnection: action.payload.mediaConnection,
          dataConnection: undefined, // Data connection will be added later
          user: undefined, // User info will be added later
          mediaConfig: undefined, // Media config will be added later
        },
      ];
    }

    case "ADD_USER_DATA_CONNECTION": {
      // Check if the user already exists by their peer id
      const existingUser = state.find(
        (user: VideoChatUserConnection) => user.id === action.payload.dataConnection.peer
      );

      if (existingUser) {
        // If the user exists, update their data connection and other details
        return state.map((user: VideoChatUserConnection) =>
          user.id === action.payload.dataConnection.peer
            ? {
                ...user,
                dataConnection: action.payload.dataConnection,
                user: action.payload.user,
                mediaConfig: action.payload.mediaConfig,
              }
            : user
        );
      }

      // If user doesn't exist, create a new user with data connection, user, and media config
      return [
        ...state,
        {
          id: action.payload.dataConnection.peer,
          dataConnection: action.payload.dataConnection,
          user: action.payload.user,
          mediaConfig: action.payload.mediaConfig,
          mediaConnection: undefined, // Media connection will be added later
        },
      ];
    }

    case "ADD_USER_CONNECTIONS": {
      // Check if the user exists by their id (mediaConnection.peer or dataConnection.peer can be used)
      const existingUser = state.find(
        (user: VideoChatUserConnection) => user.id === action.payload.mediaConnection.peer
      );

      if (existingUser) {
        // If the user exists, update both the media and data connections, along with user and mediaConfig
        return state.map((user: VideoChatUserConnection) =>
          user.id === action.payload.mediaConnection.peer
            ? {
                ...user,
                mediaConnection: action.payload.mediaConnection,
                dataConnection: action.payload.dataConnection,
                user: action.payload.user,
                mediaConfig: action.payload.mediaConfig,
              }
            : user
        );
      }

      // If the user doesn't exist, create a new user with both the media and data connections, and other info
      return [
        ...state,
        {
          id: action.payload.mediaConnection.peer,
          mediaConnection: action.payload.mediaConnection,
          dataConnection: action.payload.dataConnection,
          user: action.payload.user,
          mediaConfig: action.payload.mediaConfig,
        },
      ];
    }

    case "REMOVE_USER": {
      // Remove the user by filtering out the one with the given id
      return state.filter((user: VideoChatUserConnection) => user.id !== action.payload);
    }
    case "CLEAR_USERS": {
      // Return an empty array to clear all users
      return [];
    }
    default:
      return state;
  }
};
