/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Tooltip as FlowbiteTooltip } from "flowbite-react";
import { Placement } from "@floating-ui/react";

interface Props {
  children: ReactElement;
  content: string | ReactElement;
  arrow?: boolean;
  hidden?: boolean;
  placement?: Placement;
  style?: "dark" | "light" | "auto";
  trigger?: "hover" | "click";
}

export const Tooltip: React.FC<Props> = ({
  children,
  content,
  arrow = true,
  hidden = false,
  placement = "top",
  style = "auto",
  trigger = "hover",
}: Props) => {
  if (hidden) {
    return children;
  }

  return (
    <div>
      <FlowbiteTooltip
        arrow={arrow}
        content={content}
        placement={placement}
        style={style}
        trigger={trigger}
      >
        {children}
      </FlowbiteTooltip>
    </div>
  );
};

export default Tooltip;
