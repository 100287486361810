/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useRef, useState } from "react";
import { strings } from "../../common/Strings/Strings";
import { InvoiceResponse } from "../../models/invoice/InvoiceResponse";
import StripeSection from "../Stripe/StripeSection";
import OnlinePaymentForm, {
  OnlinePaymentFormRef,
} from "../Stripe/OnlinePaymentForm";
import CostAccountingApi from "../../api/CostAccountingApi";
import { getGeneralError } from "../../util/helperFunctions";
import { PaymentRequest } from "../../models/costaccounting/PaymentRequest";
import { GroupPaymentRequest } from "../../models/costaccounting/GroupPaymentRequest";
import LoaderInline from "../../components/LoaderInline";
import NumberFormatter from "../../util/NumberFormatter";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button";
import AlertBox from "../../components/AlertBox";

interface Props {
  isOpen: boolean;
  close: () => void;
  onInvoiceUpdated?: (invoice: InvoiceResponse) => void;
  invoice?: InvoiceResponse;
  stripeApiKey: string;
  invoiceGroup?: InvoiceResponse[];
  onInvoiceGroupUpdated?: () => void;
}

const InvoicePaymentModal: React.FC<Props> = (props: Props) => {
  const {
    isOpen,
    close,
    onInvoiceUpdated,
    invoice,
    stripeApiKey,
    invoiceGroup,
    onInvoiceGroupUpdated,
  } = props;

  const [error, setError] = useState<string | null>(null);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onlinePaymentRef = useRef<OnlinePaymentFormRef>(null);
  const isLoggedIn = localStorage.getItem("accessToken") != null;

  const closeModal = () => {
    if (!loading) {
      close();
    }
  };

  const payInvoice = async (request: PaymentRequest) => {
    if (!invoice) {
      return;
    }

    setLoading(true);

    try {
      let response;
      if (isLoggedIn) {
        response = await CostAccountingApi.payCostAccounting(
          invoice.id,
          request
        );
      } else {
        response = await CostAccountingApi.quickPayCostAccounting(
          invoice.longId,
          request
        );
      }

      if (onInvoiceUpdated) {
        onInvoiceUpdated(response.data);
      }
      setLoading(false);
      closeModal();
    } catch (e) {
      const err: any = e;
      if (
        err?.response?.data?.id === "ACTION_REQUIRED" &&
        err?.response?.data?.clientSecret
      ) {
        // prettier-ignore
        // eslint-disable-next-line no-use-before-define
        confirmPayment(err.response.data.clientSecret);
      } else {
        setError(await getGeneralError(err));
        setLoading(false);
      }
    }
  };

  const payInvoices = async (request: GroupPaymentRequest) => {
    setLoading(true);

    try {
      await CostAccountingApi.payAllCostAccounting(request);
      if (onInvoiceGroupUpdated) {
        onInvoiceGroupUpdated();
      }
      setLoading(false);
      closeModal();
    } catch (e) {
      const err: any = e;
      if (
        err?.response?.data?.id === "ACTION_REQUIRED" &&
        err?.response?.data?.clientSecret
      ) {
        // prettier-ignore
        // eslint-disable-next-line no-use-before-define
        confirmPayment(err.response.data.clientSecret);
      } else {
        setError(await getGeneralError(err));
        setLoading(false);
      }
    }
  };

  const submitPayment = (request: PaymentRequest) => {
    if (invoice) {
      void payInvoice(request);
    } else if (invoiceGroup) {
      const ids: string[] = [];
      invoiceGroup.forEach((i) => {
        if (i?.costAccounting?.id) {
          ids.push(i?.costAccounting?.id);
        }
      });
      void payInvoices({ ...request, ids });
    }
  };

  const startPayment = (ev: any) => {
    ev.preventDefault();
    setLoading(true);

    const startPaymentCall = onlinePaymentRef?.current?.createPaymentMethod();

    if (!startPaymentCall) {
      return;
    }

    startPaymentCall
      .then((response) => {
        if (response.error) {
          setError(response?.error?.message ?? strings.simpleError);
          setLoading(false);
        } else {
          const request = {
            paymentMethodId: response?.paymentMethod?.id,
            cardHolder: onlinePaymentRef?.current?.getCardHolder() || "",
          };
          submitPayment(request);
        }
      })
      .catch((err) => {
        setError(err ?? strings.simpleError);
        setLoading(false);
      });
  };

  const confirmPayment = (clientSecret: string) => {
    setLoading(true);

    const confirmPaymentCall =
      onlinePaymentRef?.current?.confirmPayment(clientSecret);

    if (!confirmPaymentCall) {
      return;
    }

    confirmPaymentCall
      .then((response) => {
        if (response.error) {
          setError(response?.error?.message ?? strings.simpleError);
          setLoading(false);
        } else {
          const request = {
            paymentIntentId: response?.paymentIntent?.id,
            cardHolder: onlinePaymentRef?.current?.getCardHolder() || "",
          };
          submitPayment(request);
        }
      })
      .catch((err) => {
        setError(err ?? strings.simpleError);
        setLoading(false);
      });
  };

  const calculateRemainingCost = (): number => (
      invoiceGroup?.reduce(
        (sum, current) => sum + (current.costAccounting?.remainingCost ?? 0),
        0
      ) ??
      invoice?.costAccounting?.remainingCost ??
      0
    );

  return (
    <>
      <Modal show={isOpen} handleClose={closeModal} size="modal-md">
        <Modal.Header title={strings.onlinePayment} />
        <Modal.Body>
          {loading ? (
            <LoaderInline />
          ) : (
            <>
              <div className="flex">
                <div className="font-bold">{strings.amountToPay}:</div>
                <div className="ml-auto">
                  {NumberFormatter.formatPrice(
                    calculateRemainingCost(),
                    invoiceGroup
                      ? invoiceGroup[0]?.costAccounting?.currency
                      : invoice?.costAccounting?.currency
                  )}
                </div>
              </div>
              <div className="font-bold">{strings.creditCardDetails}:</div>
              <div>
                <StripeSection apiKey={stripeApiKey}>
                  <OnlinePaymentForm
                    ref={onlinePaymentRef}
                    onValidation={(isValid) => setFormValid(isValid)}
                  />
                </StripeSection>
              </div>
              <AlertBox message={error} />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {strings.cancel}
          </Button>
          <Button
            className="ml-auto"
            onClick={(e: any) => startPayment(e)}
            disabled={!formValid || loading}
          >
            {strings.pay}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvoicePaymentModal;

InvoicePaymentModal.defaultProps = {
  invoice: undefined,
  onInvoiceUpdated: undefined,
  invoiceGroup: undefined,
  onInvoiceGroupUpdated: undefined,
};
