import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import Select from "../../components/ReactHookFormFields/General/Select/Select";
import { strings } from "../../common/Strings/Strings";
import { ConsultationTime } from "./FinishConsultationModal";
import Button from "../../components/Button";

interface Props {
  consultationTime: ConsultationTime;
  setRevisedConsultationTime: (data: ConsultationTime) => void;
  show: boolean;
}

const generateOptions = (max: number) =>
  Array.from({ length: max }, (_, i) => ({
    value: i.toString(),
    title: i.toString(),
  }));

const ConsultationTimeForm: React.FC<Props> = ({
  consultationTime,
  setRevisedConsultationTime,
  show,
}: Props) => {
  const {
    register,
    reset,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm<ConsultationTime>({
    mode: "onChange",
    defaultValues: consultationTime,
  });

  const resetForm = useCallback(() => {
    reset(consultationTime);
    setRevisedConsultationTime(getValues());
  }, [consultationTime, getValues, reset, setRevisedConsultationTime]);

  useEffect(() => {
    if (show) {
      resetForm();
    }
  }, [resetForm, show]);

  const updateRevisedTime = () => {
    const values = getValues();
    // Convert string values to numbers
    const revisedTime: ConsultationTime = {
      hours: Number(values.hours),
      minutes: Number(values.minutes),
      seconds: Number(values.seconds),
    };
    setRevisedConsultationTime(revisedTime);
  };

  // Triggers isDirty recalculation for setValue()
  watch();

  return (
    <form
      className="flex gap-4 justify-start items-end"
      id="consultationTimeForm"
    >
      <Select
        name="hours"
        options={generateOptions(24)}
        register={register}
        isChooseOption={false}
        onChange={(e) => {
          setValue("hours", Number(e.target.value));
          updateRevisedTime();
        }}
        label={strings.hourPlural}
        required
      />
      <Select
        name="minutes"
        options={generateOptions(60)}
        register={register}
        isChooseOption={false}
        onChange={(e) => {
          setValue("minutes", Number(e.target.value));
          updateRevisedTime();
        }}
        label={strings.minutePlural}
        required
      />
      <Select
        name="seconds"
        options={generateOptions(60)}
        register={register}
        isChooseOption={false}
        onChange={(e) => {
          setValue("seconds", Number(e.target.value));
          updateRevisedTime();
        }}
        label={strings.secondPlural}
        required
      />
      <Button hidden={!isDirty} onClick={resetForm} minW>
        {strings.resetToCurrentTime}
      </Button>
    </form>
  );
};

export default ConsultationTimeForm;
