/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { MutableRefObject, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import { SpinnerSize } from "../../common/Icons/Spinner";
import LoaderInline from "../../components/LoaderInline";
import { countMediaTracksForVideo, displayMediaDebug, isVideoLive, LoadState, MediaFeatures, playVideo } from "./Utils";
import { Speaker } from "../../common/Icons/Speaker";
import { combineClassNames } from "../../util/HtmlUtils";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import { Fullscreen } from "../../common/Icons/Fullscreen";
import { FullscreenExit } from "../../common/Icons/FullscreenExit";
import logger from "../../util/logger";
import HoverChecker from "../../util/HoverChecker";
import { useFullscreen } from "../../hooks/useFullscreen";
import { useVoiceDetector } from "./useVoiceDetector";
import { VideoConsultationRole } from "../../models/videoConsultation/VideoConsultationRole";
import RoleBadge from "./RoleBadge";

interface Props {
  callControls: ReactElement;
  camEnabled: boolean;
  joinRoom(): void;
  mediaFeatures: MediaFeatures;
  micEnabled: boolean;
  roles: VideoConsultationRole[];
  updateVideoRef: () => void;
  toggleCam: (setTo?: boolean) => void;
  toggleMic: (setTo?: boolean) => void;
  userId: string;
  userName: string;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
}

const TRANSPARENT_BORDER = true;

/*
 * Video component for the local user.
 */
export const LocalVideo: React.FC<Props> = ({
  callControls,
  camEnabled,
  joinRoom,
  mediaFeatures,
  micEnabled,
  roles,
  updateVideoRef,
  toggleCam,
  toggleMic,
  userId,
  userName,
  videoRef,
}: Props): ReactElement => {
  const [joinedRoom, setJoinedRoom] = useState<boolean>(false);

  const micMissing = !mediaFeatures.hasMicrophonePermission || !mediaFeatures.hasMicrophone;
  const camMissing = !mediaFeatures.hasWebcamPermission || !mediaFeatures.hasWebcam;

  const [videoLoadState, setVideoLoadState] = useState<LoadState>(
    camMissing || !camEnabled ? LoadState.MISSING : LoadState.INIT
  );
  const [placeholderLoadState, setPlaceholderLoadState] = useState<LoadState>(LoadState.INIT);

  const [showVideo, setShowVideo] = useState<boolean>(false);
  useEffect(() => {
    setShowVideo(!camMissing && camEnabled && videoLoadState === LoadState.LOADED && isVideoLive(videoRef));
  }, [camEnabled, camMissing, videoLoadState, videoRef]);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  useEffect(() => {
    setShowLoader(
      placeholderLoadState !== LoadState.LOADED ||
        (videoLoadState !== LoadState.LOADED && videoLoadState !== LoadState.MISSING)
    );
  }, [placeholderLoadState, videoLoadState]);

  const isVoiceActive = useVoiceDetector(videoRef, micEnabled, micMissing);

  const [bgColor, setBgColor] = useState<string>("rgb(100 116 139)"); // bg-slate-500
  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [debugMode] = useLocalStorage<boolean>("debugMode");

  const toggleFullscreen = async () => {
    if (!containerRef.current) {
      return;
    }

    try {
      if (!isFullscreen) {
        await enterFullscreen(containerRef.current);
      } else {
        await exitFullscreen();
      }
    } catch (error) {
      logger.error("[📹VideoChat] Error toggling fullscreen:", error);
    }
  };

  useEffect(() => {
    const setupVideoAndJoinRoom = async () => {
      // Assign the stream ref to the user video (from the preview video)
      updateVideoRef();

      // Join the videochat room
      joinRoom();
      setJoinedRoom(true);
    };

    // Guard condition to prevent double join event
    if (!joinedRoom) {
      void setupVideoAndJoinRoom();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, toggleCam, toggleMic]);

  useEffect(() => {
    if (camEnabled) {
      setVideoLoadState(LoadState.INIT);
    }
  }, [camEnabled]);

  const handleCalculateAverageColor = useCallback((color?: string) => {
    color && setBgColor(color);
    setPlaceholderLoadState(LoadState.LOADED);
  }, []);

  // Autoplay the video
  playVideo(videoRef, true);

  return (
    <HoverChecker className="w-full h-full select-none" hoverTimeout={2000}>
      {(_isHovered, isRecentlyHovered) => (
        <div className="relative w-full h-full flex justify-center items-center rounded-2xl" ref={containerRef}>
          {/* User video */}
          <video
            className={combineClassNames(
              "w-full h-full rounded-2xl object-cover",
              micEnabled && isVoiceActive
                ? "outline outline-4 -outline-offset-4 outline-blue-600 dark:outline-blue-400"
                : "",
              isFullscreen ? "fullscreen-video" : ""
            )}
            autoPlay
            controls={false}
            hidden={!showVideo || !isVideoLive(videoRef)}
            id="user-video"
            muted
            onLoadStart={() => setVideoLoadState(LoadState.LOADING)}
            onCanPlayThrough={() => setVideoLoadState(LoadState.LOADED)}
            playsInline
            ref={videoRef}
          />

          {/* User video placeholder: Profile picture and loader */}
          <div
            className={combineClassNames(
              !showVideo || !isVideoLive(videoRef) ? "flex justify-center items-center" : "hidden",
              "w-full h-full border-4 rounded-xl",
              micEnabled && isVoiceActive
                ? "border-blue-600 dark:border-blue-400"
                : TRANSPARENT_BORDER
                ? "border-transparent"
                : "border-gray-400 dark:border-gray-700"
            )}
            style={{ backgroundColor: bgColor }}
          >
            <div className="absolute w-28 h-28" hidden={showLoader}>
              <UserProfilePicture onCalculateAverageColor={handleCalculateAverageColor} userId={userId} />
            </div>
            <LoaderInline hidden={!showLoader} size={SpinnerSize.VideoChat} />
          </div>

          {/* User information */}
          <p className="text-white absolute top-5 left-5 bg-gray-500 bg-opacity-40 rounded-full p-2">{userName}</p>

          {/* Debug information (only in debug mode) */}
          {debugMode &&
            displayMediaDebug({
              audioTracks: countMediaTracksForVideo(videoRef, "audio"),
              camEnabled,
              camMissing,
              liveAudioTracks: countMediaTracksForVideo(videoRef, "audio", true),
              liveVideoTracks: countMediaTracksForVideo(videoRef, "video", true),
              micEnabled,
              micMissing,
              placeholderLoadState,
              streamId: (videoRef.current?.srcObject as MediaStream)?.id ?? "No stream",
              videoIsLive: isVideoLive(videoRef),
              videoIsPaused: videoRef?.current?.paused ?? "Unknown",
              videoLoadState,
              videoReadyState: videoRef?.current?.readyState ?? "Unknown",
              videoTracks: countMediaTracksForVideo(videoRef, "video"),
            })}

          {/* Disabled microphone icon */}
          {!micEnabled && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <MicrophoneOff variant="outline" />
            </div>
          )}

          {/* Voice activity icon */}
          {micEnabled && isVoiceActive && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <Speaker />
            </div>
          )}

          {/* Roles */}
          {roles.length > 0 && (
            <div
              className="absolute bottom-5 left-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                {roles.map((role) => (
                  <RoleBadge key={role.toString()} role={role} />
                ))}
              </div>
            </div>
          )}

          {/* Fullscreen button */}
          <div
            className="absolute bottom-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
            style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            onClick={toggleFullscreen}
            role="button"
            tabIndex={0}
          >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </div>

          {/* Call control buttons */}
          {isFullscreen && (
            <div
              className="absolute bottom-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            >
              {callControls}
            </div>
          )}
        </div>
      )}
    </HoverChecker>
  );
};
