/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import VideoConsultationApi from "../../api/VideoConsultationApi";
import CountryApi from "../../api/CountryApi";
import { getGeneralError } from "../../util/helperFunctions";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import CostCalculator from "../../util/CostCalculator";
import NumberFormatter from "../../util/NumberFormatter";
import { calculateConsultationPrice, formatCallTimeSeconds, getSecondNavbarHeight } from "./Utils";
import { ConsultationStatus } from "../../models/videoConsultation/ConsultationStatus";
import { strings } from "../../common/Strings/Strings";
import LoaderInline from "../../components/LoaderInline";
import { VideoConsultationResponse } from "../../models/videoConsultation/VideoConsultationResponse";
import { TailwindResponsiveBreakpoints } from "../../util/TailwindResponsiveBreakpoints";

interface Props {
  consultationStatus?: ConsultationStatus | null;
  payableCallTimeInSeconds: number;
  revised?: boolean;
  videoConsultationId?: string;
}

const getConsultationStatusString = (status: ConsultationStatus): string => {
  switch (status) {
    case ConsultationStatus.NOT_YET_STARTED:
      return strings.consultationNotStartedYet;
    case ConsultationStatus.IN_PROGRESS:
      return strings.consultationInProgress;
    case ConsultationStatus.PAUSED:
      return strings.consultationPaused;
    case ConsultationStatus.FINISHED:
      return strings.consultationFinished;
    default:
      return "Invalid consultation status";
  }
};

const ConsultationStateInfo: React.FC<Props> = ({
  consultationStatus,
  payableCallTimeInSeconds,
  revised = false,
  videoConsultationId,
}) => {
  const [videoConsultationInfo, setVideoConsultationInfo] = useState<VideoConsultationResponse>();
  const [countryDetails, setCountryDetails] = useState<CountryDetailsResponse>();
  const [_error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchConsultationData = async () => {
      if (!videoConsultationId) return;
      setLoading(true);
      try {
        const response = await VideoConsultationApi.getVideoConsultation(videoConsultationId);
        setVideoConsultationInfo(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchConsultationData();
  }, [videoConsultationId]);

  useEffect(() => {
    const fetchCountryDetails = async (clinicId: string) => {
      if (!clinicId) return;
      try {
        setLoading(true);
        const { data } = await CountryApi.getCountryDetailsOfClinic(clinicId);
        setCountryDetails(data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    const clinicId = videoConsultationInfo?.reservation.clinicId;
    if (clinicId) {
      void fetchCountryDetails(clinicId);
    }
  }, [videoConsultationInfo?.reservation.clinicId]);

  const videoConsultationFee = videoConsultationInfo?.fee;

  const clinicGrossPrice = CostCalculator.getGrossPrice(
    calculateConsultationPrice(payableCallTimeInSeconds, videoConsultationFee),
    videoConsultationFee?.vat,
    countryDetails
  );

  const isMobile = window.innerWidth < TailwindResponsiveBreakpoints.xl;
  const isSmallMobile = window.innerWidth < TailwindResponsiveBreakpoints.sm;

  return (
    <div
      className={
        revised
          ? undefined
          : `absolute ${isMobile ? "top-[10px] left-1/2 transform -translate-x-1/2" : "bottom-[20px] left-[20px]"} ${
              isSmallMobile ? "hidden" : "flex flex-col justify-center"
            }`
      }
      style={isMobile ? { marginTop: -getSecondNavbarHeight() } : undefined}
    >
      {loading ? (
        <LoaderInline />
      ) : (
        <>
          {consultationStatus && <b>{getConsultationStatusString(consultationStatus)}</b>}
          <div>
            <p className={revised ? "block" : "inline"}>
              {revised ? strings.finalPayableCallTime : strings.payableCallTime}
              {": "}
              <b>{formatCallTimeSeconds(payableCallTimeInSeconds)}</b>
            </p>
            {revised ? (
              <p>
                {strings.finalPayableAmount}
                {": "}
                <b>{NumberFormatter.formatPrice(clinicGrossPrice, countryDetails?.currency, 2)}</b>
              </p>
            ) : (
              <b className="inline">
                {" ("}
                {NumberFormatter.formatPrice(clinicGrossPrice, countryDetails?.currency, 2)})
              </b>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ConsultationStateInfo;
