/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { strings } from "../common/Strings/Strings";
import { RoundingStrategy } from "../models/clinic/RoundingStrategy";

export default class NumberFormatter {
  // Round a number to a specified decimal precision
  static round(number: number, decimalPrecision = 2): number {
    const precisionFactor = 10 ** decimalPrecision;
    return (
      Math.round((Number(number) + Number.EPSILON) * precisionFactor) /
      precisionFactor
    );
  }

  // Round a number to the nearest half
  static roundToNearestHalf(number: number): number {
    return this.round(number * 2, 0) / 2;
  }

  // Round based on a specific rounding strategy
  static roundBy(number: number, strategy: RoundingStrategy): number {
    switch (strategy) {
      case RoundingStrategy.INTEGER:
        return this.round(number, 0);
      case RoundingStrategy.TWO_DECIMAL:
        return this.round(number, 2);
      case RoundingStrategy.NEAREST_HALF:
        return this.roundToNearestHalf(number);
      default:
        throw new Error(`Unknown rounding strategy: ${strategy}`);
    }
  }

  // Format a number with a specific number of decimal places and add thousands separator
  static format(number: number, fixedDigits = 2): string {
    const roundedNumber = this.round(number, fixedDigits);
    const [integerPart, decimalPart = ""] = roundedNumber
      .toFixed(fixedDigits)
      .split(".");

    // Format integer part with thousands separator
    const formattedIntegerPart = integerPart.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      `$1${strings.thousandsSeparator}`
    );

    return `${formattedIntegerPart}.${decimalPart}`;
  }

  // Format a price with currency and specified decimal places
  static formatPrice(
    price: number,
    currency?: string | null,
    fixedDigits = 2
  ): string {
    const formattedNumber = this.format(price, fixedDigits);
    return currency ? `${formattedNumber} ${currency}` : formattedNumber;
  }
}
