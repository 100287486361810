/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { ClinicDetailsResponse } from "../../../models/management/ClinicDetailsResponse";
import ClinicChargesTable from "../Billing/ClinicChargesTable";
import StartClinicChargeModal from "../Billing/StartClinicChargeModal";
import ClinicDiscountsSection from "./ClinicDiscountsSection";
import Button from "../../../components/Button";

interface Props {
  clinic: ClinicDetailsResponse;
}

const ClinicBillingTab: React.FC<Props> = (props: Props) => {
  const { clinic } = props;
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isChargeModalVisible, setChargeModalVisible] =
    useState<boolean>(false);

  const openChargeModal = () => {
    setChargeModalVisible(true);
  };

  const closeChargeModal = () => {
    setChargeModalVisible(false);
    setRefresh(!refresh);
  };

  return (
    <>
      <ClinicDiscountsSection clinicId={clinic.id} />
      <div className="flex">
        <div className="ml-auto">
          <Button variant="primary" onClick={() => openChargeModal()}>
            {strings.chargeClinic}
          </Button>
        </div>
      </div>
      <ClinicChargesTable clinicId={clinic.id} refresh={refresh} />
      <StartClinicChargeModal
        clinicId={clinic.id}
        onHide={closeChargeModal}
        show={isChargeModalVisible}
      />
    </>
  );
};

export default ClinicBillingTab;
