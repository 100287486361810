/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import { InventoryItemResponse } from "../../../../models/inventory/item/Response/InventoryItemResponse";
import { CreateInventoryItemForm } from "../../CreateItem/InventoryItemForm";
import { NewInventoryItemRequest } from "../../../../models/inventory/item/Request/NewInventoryItemRequest";
import InventoryItemApi from "../../../../api/InventoryItemApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";
import { calculateProfitMargin } from "../InventoryItemDetails";
import InventoryItemSellingStep from "../../CreateItem/CreationSteps/InventoryItemSellingStep";
import { strings } from "../../../../common/Strings/Strings";

interface Props {
  clinicId?: string;
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemResponse;
  reloadItem(successMessage?: string): void;
}

const SalePriceTab: React.FC<Props> = ({
  clinicId,
  countryDetails,
  inventoryItem,
  reloadItem
}) => {
  const methods = useForm<CreateInventoryItemForm>({
    mode: "onChange",
  });
  const { handleSubmit, setValue } = methods;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fillForm = (data: InventoryItemResponse) => {
      if (data.acquisitionPrice) {
        setValue("acquisitionPrice", data.acquisitionPrice);
      }
      if (data.acquisitionUnit) {
        setValue("acquisitionUnit", data.acquisitionUnit);
      }
      if (data.acquisitionVat) {
        setValue("acquisitionVat", data.acquisitionVat || 0);
      }
      setValue("name", data.name);
      if (data.sellingPrice) {
        setValue("sellingPrice", data.sellingPrice);
      }
      if (data.sellingVat) {
        setValue("sellingVat", data.sellingVat || 0);
      }
      if (data.acquisitionPrice && data.sellingPrice) {
        setValue(
          "profitMargin",
          calculateProfitMargin(data.acquisitionPrice, data.sellingPrice)
        );
      }
    };

    if (inventoryItem) {
      fillForm(inventoryItem);
    }
  }, [countryDetails, setValue, inventoryItem]);

  const submit = async (data: CreateInventoryItemForm) => {
    if (clinicId && inventoryItem && inventoryItem?.categoryId) {
      const request: NewInventoryItemRequest = {
        ...inventoryItem,
        sellingPrice: data.sellingPrice,
        acquisitionUnit: inventoryItem.acquisitionUnit || null,
        sellingVat: data.sellingVat,
      };

      setLoading(true);

      try {
        const response = await InventoryItemApi.updateItemInfo(
          clinicId,
          inventoryItem.categoryId,
          inventoryItem.id,
          { ...request, categoryId: inventoryItem.categoryId}
        );

        reloadItem(strings.formatString(
          strings.itemUpdatedSuccessfully, <b>{response.data.name}</b>
        ));
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="md:p-8 md:tw-card ">
      <form className="w-full md:max-w-lg space-y-6">
        <FormProvider {...methods}>
          <InventoryItemSellingStep
            countryDetails={countryDetails}
            isEdit
            isStockStepSkipped={false}
            submitLoading={loading}
            submit={handleSubmit(submit)}
          />
        </FormProvider>
      </form>
      <AlertBox message={error} />
    </div>
  );
};

export default SalePriceTab;
