/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export enum ClinicCost {
  BASE_CLINIC_FEE = "BASE_CLINIC_FEE",
  APPOINTMENT_FEE = "APPOINTMENT_FEE",
  MEDICAL_RECORD_FEE = "MEDICAL_RECORD_FEE",
  SMS_FEE = "SMS_FEE",
  INVENTORY_FEE = "INVENTORY_FEE",
  PRESCRIPTION_FEE = "PRESCRIPTION_FEE",
  EMPLOYEE_FEE = "EMPLOYEE_FEE",
  VET_FEE = "VET_FEE",
  ASSISTANT_FEE = "ASSISTANT_FEE",
  RECEPTIONIST_FEE = "RECEPTIONIST_FEE",
  VIDEO_CONSULTATION_FEE = "VIDEO_CONSULTATION_FEE",
}

export const commissionBasedFees = [ClinicCost.VIDEO_CONSULTATION_FEE];
