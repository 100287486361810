/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { strings } from "../../../../common/Strings/Strings";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import { getAddressString } from "../../../../util/helperFunctions";
import Button from "../../../../components/Button";
import { DocumentText } from "../../../../common/Icons/DocumentText";
import Tag, { TagType } from "../../../../components/Tag";
import { VideoCamera } from "../../../../common/Icons/VideoCamera";

interface Props {
  appointment: ReservationResponse;
}

const AppointmentListRow = ({ appointment }: Props): ReactElement => {
  const status = (): ReactElement => {
    if (appointment.lastSignedMedicalRecordId) {
      return (
        <div className="flex items-center space-x-3">
          <Link to={`/medical-record/${appointment.lastSignedMedicalRecordId}`}>
            <Button className="tw-link" variant="icon">
              <DocumentText />
            </Button>
          </Link>
          <Tag type={TagType.primary} text={strings.signed} />
        </div>
      );
    }

    if (
      appointment.lastMedicalRecordId === null &&
      moment(appointment.startDateTime).isAfter()
    ) {
      return (
        <Link className="text-sm" to={`/reservations/${appointment.id}/delete`}>
          {strings.cancelAppointment}
        </Link>
      );
    }

    return <Tag type={TagType.primaryOutlined} text={strings.inProgress} />;
  };

  return (
    <div className="grid grid-cols-12 gap-4 p-4">
      <div className="col-span-12 lg:col-span-2">
        <div className="text-xs uppercase">{appointment.pet?.name}</div>
        <div>
          {moment(appointment.startDateTime).isSame(
            appointment.endDateTime,
            "days"
          ) ? (
            <div>
              {moment(appointment.startDateTime).format("LL")}
              <div>
                {moment(appointment.startDateTime).format("LT")} -{" "}
                {moment(appointment.endDateTime).format("LT")}
              </div>
            </div>
          ) : (
            <div>
              {moment(appointment.startDateTime).format("LLL")} -
              <div>{moment(appointment.endDateTime).format("LLL")}</div>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-5 flex items-center flex-wrap">
        <div>
          <div className="font-bold">{appointment.reservationType?.name} </div>
          <div>{appointment.description}</div>
          <div
            className="pt-1"
            hidden={
              moment().isAfter(appointment.startDateTime, "day") ||
              !appointment.videoConsultationRoomId
            }
          >
            <Link
              to={`/meet/${appointment.videoConsultationRoomId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button small>
                <VideoCamera />
                <span className="ml-2">
                  {strings.connectToVideoConsultation}
                </span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-5 flex items-center flex-wrap">
        <div className="mr-4">
          <div>{appointment.clinicName}</div>
          <div>{getAddressString(appointment.clinicAddresses[0])}</div>
          <div>{appointment.collaborator.fullName}</div>
        </div>
        <div className="grow flex justify-end">{status()}</div>
      </div>
    </div>
  );
};

export default AppointmentListRow;
