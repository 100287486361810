/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import { Assignment } from "../../common/Icons/Assignment";
import { ReservationResponse } from "../../models/reservation/ReservationResponse";
import { strings } from "../../common/Strings/Strings";
import Button, { ButtonVariant } from "../../components/Button";
import { combineClassNames } from "../../util/HtmlUtils";

const getMedicalRecordLink = (reservation?: ReservationResponse): string => {
  if (!reservation?.lastMedicalRecordId) {
    return `/reservation/${reservation?.id}/medical-record/`;
  }
  if (!reservation?.lastSignedMedicalRecordId) {
    return `/medical-record/${reservation?.lastMedicalRecordId}`;
  }
  if (
    reservation.lastMedicalRecordId !== reservation.lastSignedMedicalRecordId
  ) {
    return `/medical-record/${reservation.lastMedicalRecordId}`;
  }

  return `/medical-record/${reservation.lastSignedMedicalRecordId}`;
};

interface Props {
  minW: boolean;
  reservation?: ReservationResponse;
  variant?: ButtonVariant;
}

const MedicalRecordButton: React.FC<Props> = ({
  minW = false,
  reservation,
  variant = "secondary",
}: Props) => (
  <div>
    <Link
      className="my-3"
      to={getMedicalRecordLink(reservation)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button fullWidth={!minW} minW={minW} variant={variant}>
        <Assignment
          className={combineClassNames(
            "w-6 h-6 mr-2 flex-shrink-0",
            variant === "primary"
              ? "text-white"
              : "text-zinc-800 dark:text-white"
          )}
          variant="outline"
        />
        {strings.goToMedicalRecord}
      </Button>
    </Link>
  </div>
);

export default MedicalRecordButton;
