/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { RefObject, useMemo, useState } from "react";
import { Socket } from "socket.io-client";
import { getAccessToken } from "../../util/LocalStorageVariables";
import { strings } from "../../common/Strings/Strings";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button";
import { VideoChatUser } from "../../models/videoConsultation/VideoChatUser";
import ConsultationTimeForm from "./ConsultationTimeForm";
import { calculateConsultationTime, calculateTotalSeconds } from "./Utils";
import AlertBox, { AlertType } from "../../components/AlertBox";
import ConsultationStateInfo from "./ConsultationStateInfo";
import ModalButtonGroup from "../../components/Modal/ModalButtonGroup";

export interface ConsultationTime {
  hours: number;
  minutes: number;
  seconds: number;
}

interface Props {
  disconnectFromRoom: () => void;
  getLocalUserConfig: () => VideoChatUser;
  onHide: () => void;
  payableCallTime: number;
  roomId?: string;
  show: boolean;
  socketRef: RefObject<Socket | undefined>;
}

const FinishConsultationModal: React.FC<Props> = ({
  disconnectFromRoom,
  getLocalUserConfig,
  onHide,
  payableCallTime,
  roomId,
  show,
  socketRef,
}: Props) => {
  const consultationTime = useMemo(
    () => calculateConsultationTime(payableCallTime),
    [payableCallTime]
  );
  const [revisedConsultationTime, setRevisedConsultationTime] =
    useState<ConsultationTime>(consultationTime);

  const finishConsultation = () => {
    if (!roomId || !socketRef.current) return;

    const accessToken = getAccessToken();

    const finalConsultationTime = calculateTotalSeconds(
      revisedConsultationTime
    );

    // Finish the consultation
    socketRef.current.emit(
      "finishCall",
      roomId,
      finalConsultationTime,
      getLocalUserConfig(),
      accessToken
    );

    // Disconnect all users
    socketRef.current.emit(
      "disconnectAll",
      roomId,
      finalConsultationTime,
      getLocalUserConfig(),
      accessToken
    );
  };

  return (
    <Modal handleClose={onHide} show={show} size="modal-lg">
      <Modal.Header title={strings.finishConsultation} />
      <Modal.Body>
        <AlertBox
          closeAble={false}
          type={AlertType.INFO}
          message={strings.finishConsultationInfo}
        />
        <ConsultationStateInfo
          payableCallTimeInSeconds={calculateTotalSeconds(
            revisedConsultationTime
          )}
          revised
          videoConsultationId={roomId}
        />
        <ConsultationTimeForm
          consultationTime={consultationTime}
          setRevisedConsultationTime={setRevisedConsultationTime}
          show={show}
        />
      </Modal.Body>
      <Modal.Footer extraClassName="xl:justify-around">
        <ModalButtonGroup>
          <Button
            className="modal-main-button"
            fullWidth={false}
            onClick={disconnectFromRoom}
            variant="danger"
          >
            {strings.justDisconnect}
          </Button>
          <Button
            className="modal-main-button"
            fullWidth={false}
            onClick={finishConsultation}
          >
            {strings.finishAndAddToMedicalRecord}
          </Button>
          <Button
            className="modal-sub-button"
            onClick={onHide}
            variant="secondary"
          >
            {strings.continueConsultation}
          </Button>
        </ModalButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default FinishConsultationModal;
