/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";
import { CountryPriceSettingsResponse } from "../../../models/management/CountryPriceSettingsResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import FeesModal from "./FeesModal";
import Button from "../../../components/Button";

interface Props {
  isActiveTab: boolean;
  countryDetails: CountryDetailsResponse;
  country: CountrySettingsResponse;
}

const PricesSection = ({
  isActiveTab,
  country,
  countryDetails,
}: Props): ReactElement => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] =
    useState<CountryPriceSettingsResponse>();
  const [priceSettings, setPriceSettings] =
    useState<CountryPriceSettingsResponse[]>();

  const [error, setError] = useState<string | null>(null);

  const getPriceSettings = async () => {
    try {
      setIsLoaded(false);
      const resp = await CountryApi.getCountryPriceSettings(
        country.countryCode
      );
      setPriceSettings(resp.data);
      setIsLoaded(true);
    } catch (err) {
      setError(await getGeneralError(err));
      setIsLoaded(true);
    }
  };

  const deletePriceSettings = async (id: string) => {
    try {
      setIsLoaded(false);
      await CountryApi.deleteCountryPriceSettings(country.id, id);
      await getPriceSettings();
      setIsLoaded(true);
    } catch (err) {
      setError(await getGeneralError(err));
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getPriceSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, countryDetails, isActiveTab]);

  const openModal = (item?: CountryPriceSettingsResponse) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedItem(undefined);
    void getPriceSettings();
  };

  return (
    <>
      {!isLoaded ? (
        <LoaderInline className="my-3" />
      ) : (
        <div className="mt-4">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2">
              <AlertBox message={error} className="my-3" />
            </div>
          </div>
          {priceSettings && (
            <div className="row">
              <div className="col-sm-12 col-md-8 offset-md-2">
                <div className="card">
                  <div className="block-label p-3 d-flex border-bottom-0">
                    <div className="flex-grow-1 h5">Price lists</div>
                    <div>
                      <Button variant="primary" onClick={() => openModal()}>
                        {strings.addNew}
                      </Button>
                    </div>
                  </div>

                  <table className="table table-striped">
                    <tbody>
                      {priceSettings.map((prices) => (
                        <tr>
                          <td className="align-middle">
                            {moment(prices.validityStart).format(
                              dateAndTime.momentDateFormat
                            )}
                          </td>
                          <td className="text-right">
                            <Button
                              className="mr-2"
                              variant="secondary"
                              onClick={() => openModal(prices)}
                            >
                              <i className="fas fa-pencil-alt" />
                            </Button>
                            <Button
                              variant="secondary"
                              onClick={() => deletePriceSettings(prices.id)}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <FeesModal
        country={country}
        countryDetails={countryDetails}
        isOpen={isModalOpen}
        close={closeModal}
        settings={selectedItem}
      />
    </>
  );
};

export default PricesSection;
