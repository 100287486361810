/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import InventoryItemBasicStep from "../../CreateItem/CreationSteps/InventoryItemBasicStep";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import { InventoryItemResponse } from "../../../../models/inventory/item/Response/InventoryItemResponse";
import { CreateInventoryItemForm } from "../../CreateItem/InventoryItemForm";
import { PetSpecies } from "../../../../models/pet/PetSpeciesEnum";
import { NewInventoryItemRequest } from "../../../../models/inventory/item/Request/NewInventoryItemRequest";
import InventoryItemApi from "../../../../api/InventoryItemApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";
import { strings } from "../../../../common/Strings/Strings";

interface Props {
  clinicId?: string;
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemResponse;
  reloadItem(successMessage?: string): void;
}

const BasicEditItemTab: React.FC<Props> = ({
  clinicId,
  countryDetails,
  inventoryItem,
  reloadItem
}) => {
  const methods = useForm<CreateInventoryItemForm>({
    mode: "onChange",
  });
  const { handleSubmit, setValue, reset } = methods;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fillForm = (data: InventoryItemResponse) => {
      if (data.accountingCode) {
        setValue(
          "accountingCode",
          countryDetails?.accountingCodes.filter(
            (a) => a.code === data.accountingCode
          ) || null
        );
      }
      if (data.acquisitionUnit) {
        setValue("acquisitionUnit", data.acquisitionUnit);
      }
      setValue("barcode", data.barcode);
      setValue("categoryId", data.categoryId);
      setValue("lowLevel", data.lowLevel);
      setValue("name", data.name);

      if (data.species && data.species.length > 0) {
        setValue("applicableToSpecificSpecies", true);
      }
      setValue(
        "species",
          data.species?.map((s: PetSpecies) => ({
          value: s,
          title: PetSpecies[s],
        })) || []
      );
    };

    if (inventoryItem) {
      fillForm(inventoryItem);
    }
  }, [countryDetails, setValue, inventoryItem]);

  const submit = async (data: CreateInventoryItemForm) => {
    if (!data.categoryId || !data.name) return;

    if (clinicId && inventoryItem && inventoryItem?.categoryId) {
      const speciesValues = data?.species?.map((i) => i.value);
      const requestSpecies = Object.keys(PetSpecies)
        .filter((i) => speciesValues.includes(i))
        .map((i) => i as PetSpecies);

      const request: NewInventoryItemRequest = {
        ...inventoryItem,
        accountingCode: data.accountingCode?.[0]?.code,
        acquisitionUnit: data.acquisitionUnit,
        barcode: data.barcode,
        lowLevel: data.lowLevel,
        name: data.name,
        species: requestSpecies,
      };

      setLoading(true);

      try {
        const response = await InventoryItemApi.updateItemInfo(
          clinicId,
          inventoryItem.categoryId,
          inventoryItem.id,
          { ...request, categoryId: data.categoryId }
        );

        reloadItem(strings.formatString(
          strings.itemUpdatedSuccessfully, <b>{response.data.name}</b>
        ));
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="md:p-8 md:tw-card ">
      <form className="w-full md:max-w-lg space-y-6">
        <FormProvider {...methods}>
          <InventoryItemBasicStep
            clinicId={clinicId}
            countryDetails={countryDetails}
            isEdit
            isLoading={loading}
            next={handleSubmit(submit)}
          />
        </FormProvider>
      </form>
      <AlertBox message={error} />
    </div>
  );
};

export default BasicEditItemTab;
