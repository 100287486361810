/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { quantityUnits, strings } from "../../../../common/Strings/Strings";
import { getDefaultVat, getVatOptions } from "../../../../util/helperFunctions";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import PriceInputs from "../../../../components/ReactHookFormFields/Specific/PriceInputs";
import ControlledSelect from "../../../../components/ReactHookFormFields/General/Controlled/ControlledSelect";
import { CreateInventoryItemForm } from "../InventoryItemForm";
import Button from "../../../../components/Button";
import IconBubble from "../../../../components/IconBubble";
import NumberFormatter from "../../../../util/NumberFormatter";
import CostCalculator from "../../../../util/CostCalculator";
import { useClinic } from "../../../../contexts/ClinicContext";
import { calculateProfitMargin } from "../../Details/InventoryItemDetails";

interface Props {
  countryDetails?: CountryDetailsResponse;
  isEdit: boolean;
  isStockStepSkipped: boolean;
  prev?(): void;
  submit(): void;
  submitLoading: boolean;
}

const InventoryItemSellingStep: React.FC<Props> = ({
  countryDetails,
  isEdit,
  isStockStepSkipped,
  prev,
  submit,
  submitLoading,
}: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    register,
    setValue,
    watch,
  } = useFormContext<CreateInventoryItemForm>();
  const [wait, setWait] = useState<boolean>(false);
  const [
    acquisitionPrice,
    acquisitionUnit,
    acquisitionVat,
    sellingVat,
    profitMargin,
  ] = watch([
    "acquisitionPrice",
    "acquisitionUnit",
    "acquisitionVat",
    "sellingVat",
    "profitMargin",
  ]);

  const calculateAndSetProfitMargin = (
    ap: number | undefined,
    sp: number | undefined
  ) => {
    if (ap && sp) {
      setWait(true);
      setValue("profitMargin", calculateProfitMargin(ap, sp), {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    const calculateAndSetSellingPrice = (
      ap: number | undefined,
      margin: number | undefined
    ) => {
      if (ap && margin) {
        setValue("sellingPrice", ap / (1 - margin / 100), {
          shouldValidate: true,
        });
      }
    };

    if (!isStockStepSkipped) {
      if (wait) {
        setWait(false);
      } else {
        calculateAndSetSellingPrice(acquisitionPrice, profitMargin);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquisitionPrice, isStockStepSkipped, profitMargin, setValue]);

  const { clinic } = useClinic();

  return (
    <>
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
        {strings.salePrice}
      </h2>
      <div className="p-4 tw-card">
        <div className="flex items-center">
          <div>
            <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-lg dark:text-white">
              {watch("name")}
            </h2>
            {acquisitionPrice !== undefined && !isStockStepSkipped ? (
              <div>
                <div>{strings.acquisitionPrice}:</div>
                <div>
                  {NumberFormatter.formatPrice(
                    CostCalculator.getGrossPrice(
                      acquisitionPrice,
                      acquisitionVat,
                      countryDetails,
                      clinic || undefined
                    ),
                    `${countryDetails?.currency}/
              ${quantityUnits[acquisitionUnit || ""]}`
                  )}
                </div>
                <div className="text-xs text-gray-500">
                  {NumberFormatter.formatPrice(
                    acquisitionPrice,
                    `${countryDetails?.currency}/
              ${quantityUnits[acquisitionUnit]}`
                  )}{" "}
                  {strings.plusVat}
                </div>
              </div>
            ) : (
              <i hidden={isEdit}>{strings.stockSkipped}</i>
            )}
          </div>
          <div className="ml-auto">
            <IconBubble>{quantityUnits[acquisitionUnit || ""]}</IconBubble>
          </div>
        </div>
      </div>
      <div className="w-1/2">
        <ControlledSelect
          control={control}
          name="sellingVat"
          options={getVatOptions(countryDetails?.vatRates)}
          autoComplete={AutoCompleteOptions.off}
          defaultValue={getDefaultVat(countryDetails?.vatRates)}
          fieldOptions={{ valueAsNumber: true }}
          isChooseOption={false}
          label={strings.sellingVat}
          required
          value={getValues("sellingVat")}
        />
      </div>
      <div
        className="w-1/2"
        hidden={isStockStepSkipped || !acquisitionPrice}
      >
        <Field
          error={errors.profitMargin}
          fieldOptions={{
            validate: {
              maxValue: (v) =>
                v < 100 ||
                Number.isNaN(v) ||
                strings.profitMarginMustBeLessThan100,
            },
          }}
          label={strings.profitMargin}
          name="profitMargin"
          placeholder="%"
          register={register}
          suffix="%"
          toolTipText={strings.profitMarginInfo}
          type="number"
        />
      </div>
      <div className="flex">
        <PriceInputs
          control={control}
          name="sellingPrice"
          currency={countryDetails?.currency}
          label={strings.salePrice}
          onChange={(price?: number | undefined) => {
            calculateAndSetProfitMargin(acquisitionPrice, price);
          }}
          required
          roundingStrategy={
            clinic?.roundingStrategyItem || countryDetails?.roundingStrategyItem
          }
          vat={sellingVat}
        />
      </div>
      <Button
        loading={submitLoading}
        disabled={submitLoading}
        onClick={submit}
        variant="primary"
      >
        {isEdit ? strings.save : strings.create}
      </Button>
      {!isEdit && <Button className="text-sm" onClick={prev} variant="link">
        {strings.back}
      </Button>
}
    </>
  );
};

export default InventoryItemSellingStep;
