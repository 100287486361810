/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import CalendarWeek from "./CalendarWeek";
import ShiftApi from "../../../api/ShiftApi";
import {
  getGeneralError,
  localDateFormat,
  mergeTimeAndDate,
} from "../../../util/helperFunctions";
import { ShiftResponse } from "../../../models/shift/ShiftResponse";
import { ClinicEmploymentType } from "../../../models/employment/ClinicEmploymentType";
import LoaderInline from "../../../components/LoaderInline";
import { calendarTimeGapsMin } from "../calendarConfig";
import { useClinic } from "../../../contexts/ClinicContext";
import { ViewTypes } from "../MainCalendar";
import ShiftListView from "./ShiftListView/ShiftListView";
import WeekCollaboratorChecks from "./WeekCollaboratorChecks";

interface Props {
  selectedDay: string;
  calendarStartTime: moment.Moment;
  calendarEndTime: moment.Moment;
  weekEnd: boolean;
  triggerReload?: boolean;
  viewType: ViewTypes;
}

interface StringMap {
  [key: string]: any;
}

export interface WeekCollaborator extends StringMap {
  userId: string;
  fullName: string;
  checked: boolean;
  deleted: boolean;
  employmentType?: ClinicEmploymentType;
}

interface Shift extends ShiftResponse {
  collabName: string;
  rowSpan?: number;
}

type MergedCalendarWeekRow = any;

const WeekController: React.FC<Props> = ({
  selectedDay,
  calendarEndTime,
  calendarStartTime,
  weekEnd,
  triggerReload: mainReload,
  viewType,
}: Props) => {
  const { clinic } = useClinic();
  const [weekDataOrigin, setWeekDataOrigin] = useState<Shift[]>([]);
  const [weekData, setWeekData] = useState<Shift[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState(false);
  const [collaboratorChecks, setCollaboratorChecks] = useState<
    WeekCollaborator[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [weekStartDay, setWeekStartDay] = useState<Date>();
  const [weekEndDay, setWeekEndDay] = useState<Date>();
  const rowEntityLength = 3;

  useEffect(() => {
    setReload((prev) => !prev);
  }, [mainReload]);

  useEffect(() => {
    setWeekStartDay(
      moment(selectedDay)
        .weekday(0)
        .set({ hour: 0, minute: 0, second: 0 })
        .toDate()
    );

    setWeekEndDay(
      moment(selectedDay)
        .weekday(6)
        .set({ hour: 23, minute: 59, second: 59 })
        .toDate()
    );
  }, [selectedDay]);

  useEffect(() => {
    const getShifts = async () => {
      setLoading(true);

      if (!clinic || !weekStartDay || !weekEndDay) return;
      try {
        const response = await ShiftApi.getShifts(
          clinic.id,
          weekStartDay,
          weekEndDay
        );
        const dataArray: Shift[] = [];
        if (response.data.length !== 0) {
          response.data.forEach((item: ShiftResponse) => {
            dataArray.push({
              ...item,
              collabName: item.collaborator.fullName,
            });
          });
        }

        setWeekData(dataArray);
        setWeekDataOrigin(dataArray);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getShifts();
  }, [clinic, reload, weekStartDay, weekEndDay]);

  const sortCollaborators = (shifts: Shift[]): WeekCollaborator[] => {
    const collaborators: WeekCollaborator[] = shifts.map((shift) => ({
      userId: shift.collaborator.userId,
      fullName: shift.collaborator.fullName,
      checked: true,
      deleted: shift.collaborator.deleted,
      employmentType: shift.employmentType,
    }));

    const uniqueCollaborators: WeekCollaborator[] = [];
    const uniqueIds = new Set();

    collaborators.forEach((c: WeekCollaborator) => {
      if(!uniqueIds.has(c.userId)){
        uniqueCollaborators.push(c);
        uniqueIds.add(c.userId);
      }
    });

    return uniqueCollaborators;
  };

  useEffect(() => {
    const temp = sortCollaborators(weekDataOrigin);
    setCollaboratorChecks(temp);
  }, [weekDataOrigin]);

  const containsByKey = (
    array: any[],
    key: string,
    findValue: any
  ): boolean => {
    let checked = false;
    array.forEach((i) => {
      if (i[key] === findValue) {
        if (i.checked) {
          checked = true;
        }
      }
    });
    return checked;
  };

  useEffect(() => {
    const newWeekData: Shift[] = [];

    weekDataOrigin.forEach((shift) => {
      if (
        containsByKey(collaboratorChecks, "userId", shift.collaborator.userId)
      )
        newWeekData.push(shift);
    });

    setWeekData(newWeekData);
  }, [collaboratorChecks, weekDataOrigin]);

  const generateTimes = (): string[] => {
    const timeArray: string[] = [];
    let newTime = calendarStartTime;

    while (moment(newTime).diff(calendarEndTime, "minutes") <= 0) {
      timeArray.push(moment(newTime).format("HH:mm"));
      newTime = moment(newTime).add(calendarTimeGapsMin, "m");
    }

    return timeArray;
  };

  const sortShiftsByDate = (shifts: Shift[]) => {
    const shiftsByDate: string[] = [];

    shifts.forEach((shift) => {
      shiftsByDate.push(
        moment(shift.startDateTime, localDateFormat()).format("YYYY-MM-DD")
      );
    });

    const uniquedDates = [...new Set(shiftsByDate)];
    const weekDays = [];
    let date: Date | moment.Moment | undefined = weekStartDay;

    for (let i = 1; i <= 7; i += 1) {
      weekDays.push(date);
      date = moment(date).add(1, "days");
    }

    weekDays.forEach((i) => {
      let contains = true;
      uniquedDates.forEach((j) => {
        if (moment(i).isSame(moment(j), "day")) {
          contains = false;
        }
      });
      if (contains) {
        uniquedDates.push(moment(i).format("YYYY-MM-DD"));
      }
    });

    return uniquedDates.sort(
      (a: string, b: string) => moment(a).valueOf() - moment(b).valueOf()
    );
  };

  const checkReservation = (
    shift: Shift,
    time: string,
    date: string
  ): string | boolean => {
    const { reservations } = shift;

    if (!Array.isArray(reservations)) {
      return false;
    }
    if (reservations.length === 0) {
      return false;
    }

    let color = "transparent";
    reservations.forEach((reservation) => {
      const calendarTime = mergeTimeAndDate(time, new Date(date));

      if (
        moment(calendarTime).isBetween(
          moment(reservation.startDateTime, localDateFormat()),
          moment(reservation.endDateTime, localDateFormat()),
          "minute",
          "[]"
        )
      ) {
        color = reservation.reservationType?.color || "#ccceee";
      }
    });

    return color;
  };

  /* Generate rows for the calendar table from shifts, by date/day, merge all shifts at the end.
   * Return: Shifts of a day.
   * */

  const arrayFactoryWeek = (
    shiftsOfADate: Shift[],
    date: string
  ): Array<MergedCalendarWeekRow[]> => {
    const timeArray = generateTimes();
    const dayShifts: Array<MergedCalendarWeekRow> = [];
    let shiftRows: Array<[number, boolean | Shift, boolean | string]> = [];
    const unit = calendarTimeGapsMin * 60000;

    timeArray.forEach((i) => {
      if (shiftsOfADate.length === 0) {
        dayShifts.push([-2, false, false]);
      } else {
        shiftsOfADate.forEach((j: Shift, index: number) => {
          let rowTuple: [number, boolean | Shift, boolean | string] = [
            -2,
            false,
            false,
          ];

          const startTime = moment(j.startDateTime, localDateFormat());
          const differenceFormUnit = startTime.valueOf() % unit;
          let closestUnitTime: number;

          if (differenceFormUnit <= unit / 2) {
            closestUnitTime = startTime.valueOf() - differenceFormUnit;
          } else {
            closestUnitTime = startTime.valueOf() + (unit - differenceFormUnit);
          }

          let shiftRowSpan = Math.ceil(
            moment(j.endDateTime, localDateFormat()).diff(
              moment(closestUnitTime),
              "minutes"
            ) / calendarTimeGapsMin
          );

          const startTimeOfDay = moment(date, "YYYY-MM-DD").set({
            hour: calendarStartTime.hour(),
            minute: calendarStartTime.minute(),
            second: calendarStartTime.second(),
          });

          if (closestUnitTime < startTimeOfDay.valueOf()) {
            shiftRowSpan = Math.abs(
              Math.ceil(
                startTimeOfDay.diff(
                  moment(j.endDateTime, localDateFormat()),
                  "minutes"
                ) / calendarTimeGapsMin
              )
            );
            closestUnitTime = startTimeOfDay.valueOf();

            if (
              moment(j.endDateTime).isBefore(startTimeOfDay, "second") ||
              moment(j.endDateTime).isSame(startTimeOfDay, "minute")
            ) {
              shiftRowSpan = -2;
            }
          }

          const timeOfDay = moment(i, "hh:mm");
          const exactTimeOfDay = moment(date, "YYYY-MM-DD").set({
            hour: timeOfDay.hour(),
            minute: timeOfDay.minute(),
            second: timeOfDay.second(),
          });

          if (closestUnitTime === exactTimeOfDay.valueOf()) {
            rowTuple = [shiftRowSpan, j, false];
          } else if (
            exactTimeOfDay.isBetween(
              moment(closestUnitTime),
              moment(j.endDateTime, localDateFormat()),
              "minute",
              "()"
            )
          ) {
            rowTuple[0] = -1;
          }
          shiftRows.push(rowTuple);

          if (index === shiftsOfADate.length - 1) {
            dayShifts.push(shiftRows);
            shiftRows = [];
          }

          if (j.reservations !== null) {
            rowTuple[2] = checkReservation(j, i, date);
          }
        });
      }
    });

    const mergedRows: Array<MergedCalendarWeekRow[]> = [];

    dayShifts.forEach((row: MergedCalendarWeekRow) => {
      const merged = [].concat(...row);
      mergedRows.push(merged);
    });

    return mergedRows;
  };

  /* Generate rows for the calendar table, for the whole week. Merges day shifts at the end.
   * Return: Shifts of a week.
   * */

  const generateShifts = (): MergedCalendarWeekRow[] => {
    const shifts = sortShiftsByDate(weekData);
    const shiftsByDate: StringMap = {};

    shifts.forEach((date) => {
      shiftsByDate[date] = [];
    });

    weekData.forEach((shift) => {
      const prop = moment(shift.startDateTime, localDateFormat()).format(
        "YYYY-MM-DD"
      );
      const propEnd = moment(shift.endDateTime, localDateFormat()).format(
        "YYYY-MM-DD"
      );

      shiftsByDate[prop].push(shift);

      if (
        !moment(shift.startDateTime).isSame(shift.endDateTime, "day") &&
        propEnd &&
        shiftsByDate[propEnd]
      ) {
        shiftsByDate[propEnd].push(shift);
      }
    });

    const formattedShiftsByDate: Array<any[]> = [];

    shifts.forEach((date) => {
      if (weekEnd || (moment(date).day() !== 0 && moment(date).day() !== 6)) {
        const newList = arrayFactoryWeek(shiftsByDate[date], date);
        formattedShiftsByDate.push(newList);
      }
    });

    const mergedShiftRows: MergedCalendarWeekRow[] = [];
    const rowCnt = generateTimes().length;

    for (let i = 0; i < rowCnt; i += 1) {
      const row: MergedCalendarWeekRow[] = [];
      formattedShiftsByDate.forEach((day: any) => {
        row.push(day[i]);
      });

      const merged = [].concat(...row);
      mergedShiftRows.push(merged);
    }

    return mergedShiftRows;
  };

  const setWeekDays = (): number[] => {
    const shifts = sortShiftsByDate(weekData);
    const shiftsByDate: StringMap = {};

    shifts.forEach((date) => {
      shiftsByDate[date] = [];
    });

    weekData.forEach((shift) => {
      const prop = moment(shift.startDateTime, localDateFormat()).format(
        "YYYY-MM-DD"
      );
      const propEnd = moment(shift.endDateTime, localDateFormat()).format(
        "YYYY-MM-DD"
      );

      shiftsByDate[prop].push(shift);

      if (
        !moment(shift.startDateTime).isSame(shift.endDateTime, "day") &&
        propEnd &&
        shiftsByDate[propEnd]
      ) {
        shiftsByDate[propEnd].push(shift);
      }
    });

    const weekDays = [0, 0, 0, 0, 0, 0, 0];

    shifts.forEach((date) => {
      weekDays[moment(date).day()] =
        shiftsByDate[date].length === 0
          ? rowEntityLength
          : shiftsByDate[date].length * rowEntityLength;
    });

    return weekDays;
  };

  const selectedCollabsCallback = (
    weekCollabList: WeekCollaborator[]
  ): void => {
    setCollaboratorChecks(weekCollabList);
  };

  const triggerReload = (): void => {
    setReload(!reload);
  };

  if (loading && !error) {
    return <LoaderInline />;
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-6">
      <WeekCollaboratorChecks
        collaborators={collaboratorChecks}
        checkedCollaborators={selectedCollabsCallback}
        weekEndDay={weekEndDay}
        weekStartDay={weekStartDay}
      />
      {viewType === ViewTypes.SHIFTLISTVIEW ? (
        <ShiftListView
          collaborators={collaboratorChecks}
          selectedDay={selectedDay}
          weekEnd={weekEnd}
          reload={triggerReload}
          shifts={weekDataOrigin}
        />
      ) : (
        <CalendarWeek
          schedule={generateShifts()}
          times={generateTimes()}
          colSpans={setWeekDays()}
          reload={triggerReload}
          weekEnd={weekEnd}
          selectedDay={selectedDay}
        />
      )}
    </div>
  );
};

export default WeekController;
